import { Descriptions, Divider } from "antd";
import { round } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import IDate from "../../models/IDate";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import {
  createFeaturesAsCommaSeperatedString,
  iDate2dayjs
} from "../../helpers/dataModelHelper";
import { largeTitleStyle, SmallTitle } from "../Common/CommonFonts";
import { interpretSystemInfoHasFeatures } from "../../helpers/parsers/parseSystemInfoHelper";
import { VMDeviceFeatures } from "../../models/VMDeviceFeatures";
import { size } from "../../helpers/pageHelper";

interface VMSystemInfoPreview {
  id: string;
  artNumber: string;
  firmware: string;
  maxG: string;
  supportedParxVersion: string;
  supportedDatxVersion: string;
  mainBatterySource: string;
  mainBatteryLevel: string;
  backgupBatteryLevel: string;
  capacity: string;
  memoryAvailable: string;
  calibrationExpire: string;
  features: VMDeviceFeatures;
}

/**
 * Converts SystemInfo to a ViewModel that is tailored for this view
 * @param systemInfo
 */
const systemInfoToPreviewViewModel = (
  systemInfo: GeneralSystemInfo
): VMSystemInfoPreview => ({
  id: systemInfo.serial.toString(),
  artNumber: systemInfo.artNum.join("").replace(/\0/g, ""),
  firmware: `${systemInfo.fwMajorVersion}.${systemInfo.fwMinorVersion}.${systemInfo.fwMainBuild}.${systemInfo.fwSubBuild}`,
  maxG: systemInfo.accStdMaxG.toString(),
  supportedParxVersion: `${systemInfo.parxVersion}`,
  supportedDatxVersion: `${systemInfo.datxVersion}`,
  mainBatterySource: systemInfo.powerSource.toString(),
  mainBatteryLevel: `${systemInfo.mainBattery}%`,
  backgupBatteryLevel: `${systemInfo.backupBattery}%`,
  capacity: `${round(systemInfo.memoryAvailable, 2)} kB`,
  //change to % and more
  memoryAvailable: `${round(
    calcPercentMemUsed(systemInfo.memoryAvailable, systemInfo.memoryUsed),
    2
  )}% (${round(bToKb(calcMemLeft(systemInfo.memoryAvailable, systemInfo.memoryUsed)), 2)} kB)`,
  calibrationExpire: calcNextCalibration(systemInfo.lastCalibration),
  features: interpretSystemInfoHasFeatures(systemInfo.hasFeatures)
});

/** Calculates how much memory is still available */
const calcMemLeft = (memAvail: number, memUsed: number) =>
  kbToB(memAvail) - memUsed;

/** Calculates how many percent of the memory thats being used */
const calcPercentMemUsed = (memAvail: number, memUsed: number) =>
  (calcMemLeft(memAvail, memUsed) / kbToB(memAvail)) * 100;

/**
  Power Sources
  1:   (0x0001) 4 x AA Lithium
  3:   (0x0003) 2 x C Lithium
  5:   (0x0005) External power source
  257: (0x0101) 4 x AA Alkaline 
  259: (0x0103) 2 x C Alkaline
 */
const getBatteryText = (batteryType: string) => {
  switch (batteryType) {
    case "1":
      return "4 x AA (Lithium)";
    case "3":
      return "2 x C (Lithium)";
    case "5":
      return "External power";
    case "257":
      return "4 x AA (Alkaline)";
    case "259":
      return "2 x C (Alkaline)";
    default:
      return "";
  }
};

/** Convert bytes to kilo bytes */
const bToKb = (n: number) => n / 1024;

/** Convert kilo bytes to bytes */
const kbToB = (n: number) => n * 1024;

/**
 * Return next calibration (last calibration + 2 years) as a formated string
 * @param lastCalibration last calibration property from SystemInfo
 */
const calcNextCalibration = (lastCalibration: IDate) => {
  const date = iDate2dayjs(lastCalibration);
  date.add(2, "years");
  return date.format("YYYY-MM-DD");
};

const renderSubTitle = (title: string) => (
  <SmallTitle style={{ color: largeTitleStyle.color, marginBottom: 0 }}>
    {title}
  </SmallTitle>
);

const renderDescriptionItem = (label: string, content: string | number) => (
  <Descriptions.Item
    label={label}
    style={{ paddingBottom: 0 }}
    labelStyle={{ fontWeight: "bold" }}
    contentStyle={{ paddingBottom: size.m2 }}
  >
    {content}
  </Descriptions.Item>
);

const renderGhostContent = () => (
  <Descriptions.Item label="">{""}</Descriptions.Item>
);

interface IProps {
  systemInfo: GeneralSystemInfo;
}
const SystemInfoPreview: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const vmSystemInfoPreview = systemInfoToPreviewViewModel(props.systemInfo);

  const featuresPreview = createFeaturesAsCommaSeperatedString(
    vmSystemInfoPreview.features,
    t
  );

  return (
    <div>
      <Descriptions layout="vertical" column={2}>
        {/* row 1 */}
        {renderDescriptionItem("Id", vmSystemInfoPreview.id)}
        {/* ghost element */}

        {/* row 2 */}
        {renderDescriptionItem(
          t("sysInfoPreviewArtNum"),
          vmSystemInfoPreview.artNumber
        )}

        {/* row 3 */}
        {renderDescriptionItem(
          t("sysInfoPreviewFw"),
          vmSystemInfoPreview.firmware
        )}
        {/* todo: do this in an earlier step */}
        {renderDescriptionItem(
          t("sysInfoPreviewMaxG"),
          vmSystemInfoPreview.maxG
        )}

        {/* row 4 */}
        {renderDescriptionItem(
          t("sysInfoPreviewSupParx"),
          vmSystemInfoPreview.supportedParxVersion
        )}
        {renderDescriptionItem(
          t("sysInfoPreviewSupDatx"),
          vmSystemInfoPreview.supportedDatxVersion
        )}

        {/* row 5 */}
        {renderDescriptionItem(
          t("sysInfoPreviewCalExp"),
          vmSystemInfoPreview.calibrationExpire
        )}
      </Descriptions>

      <Divider style={{ marginTop: size.s2 }} />

      <Descriptions>
        {renderDescriptionItem(
          t("sysInfoPreviewSectionAvilFeatures"),
          featuresPreview
        )}
      </Descriptions>

      <Divider style={{ marginTop: size.s2 }} />

      <Descriptions
        title={renderSubTitle(t("sysInfoPreviewSubTitleBattery"))}
        layout="vertical"
        column={2}
      >
        {/* row 1 */}
        {renderDescriptionItem(
          t("sysInfoPreviewMainBatterySource"),
          getBatteryText(vmSystemInfoPreview.mainBatterySource)
        )}
        {/* ghost element */}
        {renderGhostContent()}

        {/* row 2 */}
        {renderDescriptionItem(
          t("sysInfoPreviewMainBatteryLevel"),
          vmSystemInfoPreview.mainBatteryLevel
        )}
        {renderDescriptionItem(
          t("sysInfoPreviewBackupBatteryLevel"),
          vmSystemInfoPreview.backgupBatteryLevel
        )}
      </Descriptions>

      <Divider style={{ marginTop: size.s2 }} />

      <Descriptions
        title={renderSubTitle(t("sysInfoPreviewMemInfo"))}
        layout="vertical"
        column={2}
      >
        {/* row1 */}
        {renderDescriptionItem(
          t("sysInfoPreviewCapacity"),
          vmSystemInfoPreview.capacity
        )}
        {renderDescriptionItem(
          t("sysInfoPreviewAvil"),
          vmSystemInfoPreview.memoryAvailable
        )}
      </Descriptions>
    </div>
  );
};

export default SystemInfoPreview;
