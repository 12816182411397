import React, { createRef, useEffect, useState } from "react";
import {
  Col,
  Input,
  Row,
  Select,
  notification,
  Typography,
  Divider,
  Spin
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectParamsFreeText,
  setProjectName,
  setUserInfo
} from "../../state/openParxSlice";
import { SmallText, SmallTitle } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import {
  Project,
  ProjectForCreation,
  useCreateProjectMutation,
  useGetAllProjectsQuery
} from "../../state/cargologRestApi";
import { RefSelectProps } from "antd/lib/select";
import { NormalButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { sessionState } from "../../state/sessionSlice";
import { size } from "../../helpers/pageHelper";

const { Option } = Select;
const { Text } = Typography;

const ParamsBuilderFreeText = () => {
  const { t } = useTranslation();
  const { UserInfo, ProjectName } = useSelector(selectParamsFreeText);
  const dispatch = useDispatch();
  const selectRef = createRef<RefSelectProps>();
  const sessions = useSelector(sessionState);

  const { data: projects, isLoading: loadingProjects } =
    useGetAllProjectsQuery();

  const [createProject] = useCreateProjectMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [projectSearch, setProjectSearch] = useState<string>("");

  const handleCreateProject = async (project: ProjectForCreation) => {
    if (sessions.networkStatus === "online") {
      const result: any = await createProject(project);
      if (result.data) {
        notification.success({ message: t("SuccessfullyCreatedProject") });
        dispatch(setProjectName(project.title));
        setProjectSearch("");
        setOpen(false);
      } else {
        notification.error({ message: t("UnableToCreateProject") });
      }
    } else {
      dispatch(setProjectName(projectSearch));
      setOpen(false);
    }
  };

  const onChange = (value: string) => {
    dispatch(setProjectName(value));
  };

  const onSearch = (value: string) => {
    setProjectSearch(value);
  };

  const onSelect = () => {
    setProjectSearch("");
  };

  let filteredProjects: Project[] = [];
  let titleList: string[] = [];
  if (projects) {
    filteredProjects = projects
      .filter(
        (project) =>
          projectSearch === "" ||
          project.title.toLowerCase().includes(projectSearch.toLowerCase())
      )
      .sort((a, b) => a.title.localeCompare(b.title, "sv"));

    titleList = projects.map((project) => project.title);
  }

  const addNewProject = () => {
    if (!isUndefined(projectSearch)) {
      const request: ProjectForCreation = {
        title: projectSearch,
        description: ""
      };
      handleCreateProject(request);
    }
  };

  const projectOptions = filteredProjects
    ?.filter((project) => project.status !== 3)
    .map((project) => {
      return (
        <Option key={project.id} value={project.title}>
          {project.title}
        </Option>
      );
    });

  const alreadyExists = titleList.includes(projectSearch);
  const tooManyCharacters = projectSearch.length > 50;

  const showText = () => {
    if (tooManyCharacters) {
      return t("ProjectNameIsTooLong");
    } else if (alreadyExists) {
      return t("ProjectNameAlreadyExists");
    } else {
      return projectSearch;
    }
  };

  const rightColStyle: React.CSSProperties = { paddingLeft: "48" };

  return (
    <div style={{ width: "100%" }}>
      {/* Ghost element to create spacing */}
      <Row style={{ height: "35px" }} />
      {/* Project name row */}
      <Row wrap={false}>
        <Col flex="400px">
          <Row justify="start">
            <SmallTitle>{t("ProjectName")}:</SmallTitle>
          </Row>
        </Col>
        <Col>
          <Select
            placeholder={t("SelectProject")}
            style={{ minWidth: 300 }}
            dropdownStyle={{ paddingBottom: 0 }}
            value={ProjectName}
            defaultValue={ProjectName}
            status={ProjectName ? undefined : "error"}
            allowClear
            showSearch
            autoFocus={
              isUndefined(ProjectName) || ProjectName === "" ? true : false
            }
            open={open}
            onDropdownVisibleChange={(visible) => setOpen(visible)}
            ref={selectRef}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={onSelect}
            dropdownRender={(menu) => (
              <>
                {!loadingProjects ? (
                  menu
                ) : (
                  <Row justify="center" align="middle" style={{ height: 54 }}>
                    <Spin />
                  </Row>
                )}
                {projectSearch && (
                  <>
                    <Divider style={{ margin: 0 }} />
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ padding: size.m1 }}
                    >
                      <Col span={15}>
                        <Text>{showText()}</Text>
                      </Col>
                      <Col>
                        <NormalButton
                          loading={loadingProjects}
                          onClick={addNewProject}
                          disabled={
                            alreadyExists || tooManyCharacters ? true : false
                          }
                        >
                          {t("Create")}
                        </NormalButton>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          >
            {projectOptions}
          </Select>
        </Col>
      </Row>
      {/* Ghost element to create spacing */}
      <Row style={{ height: "40px" }} />
      {/* Free text row */}
      <Row justify="space-between" wrap={false}>
        <Col flex="400px">
          <Row justify="start">
            <SmallTitle>{t("DeviceDescription")}:</SmallTitle>
            <SmallText style={{ textAlign: "start", paddingRight: size.xl1 }}>
              {t("cpUserInfoFreeTextDesc")}
            </SmallText>
          </Row>
        </Col>
        <Col flex="auto" style={rightColStyle}>
          <div key={UserInfo}>
            <Input.TextArea
              maxLength={249}
              autoSize={{ minRows: 4 }}
              allowClear
              showCount
              defaultValue={UserInfo}
              onBlur={(e) => dispatch(setUserInfo(e.target.value))}
              style={{ resize: "none" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ParamsBuilderFreeText;
