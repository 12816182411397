import React, { useState } from "react";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Input, Typography, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  size,
  impersonatingHeight143,
  impersonatingHeight320
} from "../../helpers/pageHelper";
import { DangerButtonSmall, PrimaryButton } from "../Common/CommonButtons";
import {
  useGetAllCompanyDiscountsQuery,
  useGetAllLicensePricesQuery,
  useMobitronGetCompaniesQuery
} from "../../state/cargologRestApi";
import { useDispatch, useSelector } from "react-redux";
import {
  openAddDiscountModal,
  openRemoveLicenseDiscountModal,
  setLicenseDiscountToRemove
} from "../../state/modalsSlice";
import AddDiscountModal from "./AddDiscountModal";
import dayjs from "dayjs";
import RemoveLicenseDiscountModal from "../Modals/RemoveLicenseDiscountModal";
import { selectImpersonate } from "../../state/persistantStateSlice";
const { Text } = Typography;

/** All the fields for table used in this component */
export interface ITableData {
  key: number;
  id: number;
  created: string;
  companyData: {
    companyId: string;
    companyName: string;
  };
  licensePriceName: LicensePriceName;
  discountPercent: number;
  actions: IUserActions;
}

interface LicensePriceName {
  name: string;
  deviceType: number;
}

/** all aviable actions for a field in the table */
interface IUserActions {
  remove: () => void;
}

const MobitronAdminDiscounts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isImpersonating } = useSelector(selectImpersonate);

  const columns: ColumnsType<ITableData> = [
    {
      title: t("Company"),
      dataIndex: "companyData",
      key: "companyData",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.companyData.companyName < b.companyData.companyName) {
          return -1;
        }
        if (a.companyData.companyName > b.companyData.companyName) {
          return 1;
        }
        return 0;
      },
      render: (companyData) => (
        <>
          <Text strong>{companyData.companyName}</Text>
          <br />
          <Text type="secondary">{companyData.companyId}</Text>
        </>
      )
    },
    {
      title: t("License"),
      dataIndex: "licensePriceName",
      key: "licensePriceName",
      ellipsis: true,
      sorter: (a, b) => {
        if (a.licensePriceName < b.licensePriceName) {
          return -1;
        }
        if (a.licensePriceName > b.licensePriceName) {
          return 1;
        }
        return 0;
      },
      render: (licensePriceName: LicensePriceName) => (
        <>
          <Text strong>{licensePriceName.name}</Text> <br />
          <Text type="secondary">
            {" "}
            ({licensePriceName.deviceType === 1 ? t("cargolog3rdGen") : null}
            {licensePriceName.deviceType === 2 ? t("cargologEasy") : null}
            {licensePriceName.deviceType === 3 ? t("vehicle") : null}){" "}
          </Text>
        </>
      )
    },
    {
      title: t("Discount") + " (%)",
      dataIndex: "discountPercent",
      key: "discountPercent",
      width: 140,
      ellipsis: true,
      sorter: (a, b) => a.discountPercent - b.discountPercent,
      render: (discountPercent) => (
        <>
          <Text>{discountPercent}</Text>
        </>
      )
    },
    {
      title: t("Created"),
      dataIndex: "created",
      key: "created",
      ellipsis: true
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      ellipsis: true,
      render: (actions: IUserActions) => {
        return (
          <>
            <DangerButtonSmall
              icon={<DeleteOutlined />}
              onClick={actions.remove}
            >
              {t("genDelete")}
            </DangerButtonSmall>
          </>
        );
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  let tableData: ITableData[] | undefined = undefined;

  const { data: discounts, isLoading: discountsIsLoading } =
    useGetAllCompanyDiscountsQuery();
  const { data: allLicensePrices, isLoading: licenseIsLoading } =
    useGetAllLicensePricesQuery();
  const { data: allCompanies, isLoading: companiesIsLoading } =
    useMobitronGetCompaniesQuery();

  const timezoneOffset = new Date().getTimezoneOffset();

  if (discounts) {
    tableData = discounts
      .filter((discount) => {
        const licensePrice = allLicensePrices?.filter(
          (licensePrice) => licensePrice.id === discount.licensePriceId
        )[0];
        const company = allCompanies?.filter(
          (company) => company.id === discount.companyId
        )[0];
        const dataContainsInput =
          stringValue === "" ||
          licensePrice?.name
            .toLowerCase()
            .includes(stringValue.toLowerCase()) ||
          company?.name.toLowerCase().includes(stringValue.toLowerCase()) ||
          stringValue.includes(discount.discountPercent.toString());
        return dataContainsInput;
      })
      .map((discount, index) => {
        const licensePrice = allLicensePrices?.filter(
          (licensePrice) => licensePrice.id === discount.licensePriceId
        )[0];
        const company = allCompanies?.filter(
          (company) => company.id === discount.companyId
        )[0];

        const created = dayjs
          .utc(discount.created)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm");
        let row = {
          key: index,
          id: discount.id,
          created: created,
          companyData: {
            companyId: discount.companyId,
            companyName: company?.name ?? t("NoName")
          },
          licensePriceName: {
            name: licensePrice?.name ?? "",
            deviceType: licensePrice?.deviceType ?? 0
          },
          discountPercent: discount.discountPercent,
          actions: {
            remove: () => {
              dispatch(openRemoveLicenseDiscountModal());
              dispatch(setLicenseDiscountToRemove(discount));
            }
          }
        };
        return row;
      });
  }

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("Discounts")}</LargeHeaderTitle>

              <PrimaryButton
                style={{ marginLeft: size.m1 }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => dispatch(openAddDiscountModal())}
              >
                {t("AddNewDiscount")}
              </PrimaryButton>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("FilterDiscounts")}
              prefix={<SearchOutlined />}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            tableLayout="auto"
            size="small"
            loading={
              discountsIsLoading || licenseIsLoading || companiesIsLoading
            }
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>

      <AddDiscountModal />
      <RemoveLicenseDiscountModal />
    </>
  );
};

export default MobitronAdminDiscounts;
