import {
  AimOutlined,
  CarryOutOutlined,
  ExclamationCircleOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { VariableSizeGrid as Grid } from "react-window";
import { gpsStatusBitMasks } from "../../constants/FAT100-prot-constants";
import { connectionColors, iconColors } from "../../constants/colors";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { toGStr, toMsStr } from "../../helpers/dataModelHelper";
import { RecordingDataBlockUnique } from "../../helpers/datasetHelper";
import { createTzDate } from "../../helpers/dateHelper";
import {
  DT_Acc,
  DT_Angle,
  DT_Pressure,
  DT_Rh,
  DT_Temp
} from "../../models/FAT100DataTypes";
import { IParsedGPSData, setMarkedMapKeys } from "../../state/openDatxSlice";
import {
  selectMySpeed,
  selectTemperatureScale
} from "../../state/sessionSlice";
import { Atable, Ath, Athead } from "../Common/CommonTables";
import { MapProviderLink } from "../MicroComponents/MapProviderLink";
import { SpeedConverter } from "../MicroComponents/SpeedConverter";
import {
  TempSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { size } from "../../helpers/pageHelper";
const { Text } = Typography;
const {
  gpsFailedToInitGPSModule,
  gpsFailedToGetPosition,
  gpsAntennaError,
  gpsFailedToInitCoMCU,
  gpsCommunicationProblemsCoMCU,
  gpsStatusFailedToGetGPSPosition,
  gpsPowerOn,
  gpsPowerOff,
  gpsTriggeredBySensor,
  gpsTriggeredBySchedule
} = gpsStatusBitMasks;

interface VirtualTableProps {
  dataSource?: IParsedGPSData[];
  columns: ColumnsType<IParsedGPSData>;
  height: number;
  width: number;
  rowHeights?: number[];
  rowSelection: {
    selectedRowKeys: number[];
    onSelect: (key: number) => void;
  };
}

const VirtualTable = (props: VirtualTableProps) => {
  const { dataSource, columns, height, width, rowSelection } = props;
  const gridRef = useRef<any>();

  const selectedRow = rowSelection.selectedRowKeys[0];

  useEffect(() => {
    if (!rowSelection) return;
    gridRef.current.scrollToItem({
      align: "smart",
      columnIndex: 0,
      rowIndex: selectedRow
    });
  }, [selectedRow]);

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      rowIndex: 0,
      shouldForceUpdate: true
    });
  };

  useEffect(() => resetVirtualGrid, [width, dataSource]);

  if (!dataSource || !columns) return null;

  const mergedColumns = columns!.map((column) => {
    return {
      ...column,
      width: Math.floor((width - 20) / columns!.length)
    };
  });

  const rowHeights = props.rowHeights ? [...props.rowHeights] : undefined;
  const getRowHeight = (index: number) => {
    return rowHeights ? rowHeights[index] : 32;
  };

  return (
    <div className="virtual-table" style={{ width: "100%" }}>
      <Grid
        ref={gridRef}
        className="virtual-grid"
        height={height}
        width={width}
        columnCount={mergedColumns.length}
        rowCount={dataSource.length}
        columnWidth={(index: number) => mergedColumns[index].width}
        rowHeight={getRowHeight}
        style={{ textAlign: "left", outline: "none" }}
      >
        {({
          columnIndex,
          rowIndex,
          style
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const renderer = mergedColumns[columnIndex].render!;
          const cellData = dataSource[rowIndex];
          const cellKey = cellData.key;
          const rowSelected = cellKey === selectedRow;
          const selectedStyle = rowSelected
            ? { backgroundColor: "#c9d7c8" }
            : {};
          return (
            <div
              className={classNames("virtual-table-cell", {
                "virtual-table-cell-last":
                  columnIndex === mergedColumns.length - 1
              })}
              style={{
                ...style,
                ...selectedStyle,
                borderBottom: "1px solid #f0f0f0",
                padding: size.m2,
                paddingRight: "0px"
              }}
            >
              <div
                onClick={() => rowSelection.onSelect(cellKey)}
                style={{ cursor: "pointer" }}
              >
                <>{renderer(cellData, cellData, rowIndex)}</>
              </div>
            </div>
          );
        }}
      </Grid>
    </div>
  );
};

interface IProps {
  fileId: string;
  tableData?: IParsedGPSData[];
  markedMapKeys: number[];
  isPlaying: boolean;
  tableHeight: number;
  tableWidth: number;
  timezone: string;
}

const renderTriggerText = (status: number, t: TFunction, index: number) => {
  if (status === gpsTriggeredBySensor) {
    return (
      <div key={index} style={{ color: connectionColors.sensor }}>
        <ExclamationCircleOutlined /> {t("TriggeredBySensor")}
      </div>
    );
  }
  if (status === gpsTriggeredBySchedule) {
    return (
      <div key={index} style={{ color: connectionColors.schedule }}>
        <CarryOutOutlined /> {t("TriggeredBySchedule")}
      </div>
    );
  }
  return <></>;
};

const getAlarmDataComponent = (
  alarmSensor: number,
  alarmData: RecordingDataBlockUnique,
  t: TFunction,
  tempScale: string
) => {
  switch (alarmSensor) {
    case DT_Acc:
      if (alarmData.xAcc || alarmData.yAcc || alarmData.zAcc) {
        const [xAcc, xDur] = alarmData.xAcc ?? [];
        const [yAcc, yDur] = alarmData.yAcc ?? [];
        const [zAcc, zDur] = alarmData.zAcc ?? [];
        return (
          <>
            <div>
              <span style={{ color: iconColors.xAcc }}>X:</span> {toGStr(xAcc)}{" "}
              {toMsStr(xDur)}
            </div>
            <div>
              <span style={{ color: iconColors.yAcc }}>Y:</span> {toGStr(yAcc)}{" "}
              {toMsStr(yDur)}
            </div>
            <div>
              <span style={{ color: iconColors.zAcc }}>Z:</span> {toGStr(zAcc)}{" "}
              {toMsStr(zDur)}
            </div>
          </>
        );
      }
      break;
    case DT_Temp:
      if (alarmData.temp) {
        const round2Decimals = (v: number) => Math.round(v * 100) / 100;
        return (
          <>
            <Text>{alarmData.temp} °C</Text>
            <Text>
              {round2Decimals(TemperatureToScale(alarmData.temp, tempScale))}
              <TempSymbol />
            </Text>
          </>
        );
      }
      break;
    case DT_Rh:
      if (alarmData.rh) {
        return <Text>{alarmData.rh} %</Text>;
      }
      break;
    case DT_Angle:
      if (alarmData.angle) {
        const [x, y, z] = alarmData.angle;
        return (
          <>
            <div>
              <span style={{ color: iconColors.xAcc }}>X:</span> {x ?? "N/A"}°
            </div>
            <div>
              <span style={{ color: iconColors.yAcc }}>Y:</span> {y ?? "N/A"}°
            </div>
            <div>
              <span style={{ color: iconColors.zAcc }}>Z:</span> {z ?? "N/A"}°
            </div>
          </>
        );
      }
      break;
    case DT_Pressure:
      if (alarmData.pressureComp) {
        return <Text>{alarmData.pressureComp} mbar</Text>;
      }
      break;
  }
};

const getAlarmSensor = (alarmSensor: number, t: TFunction) => {
  switch (alarmSensor) {
    case DT_Acc:
      return <Text strong>{t("AccSensor")}</Text>;
    case DT_Temp:
      return <Text strong>{t("TempSensor")}</Text>;
    case DT_Rh:
      return <Text strong>{t("RhSensor")}</Text>;
    case DT_Angle:
      return <Text strong>{t("AngleSensor")}</Text>;
    case DT_Pressure:
      return <Text strong>{t("PressureSensor")}</Text>;
  }
};

const renderSensorData = (
  data: IParsedGPSData,
  tempScale: string,
  timezone: string,
  t: TFunction,
  index: number
) => {
  const { alarm } = data;
  if (!alarm) {
    return <>No alarm found</>;
  }

  const { alarmSensor, alarmData, alarmTimestamp } = alarm;
  if (alarmSensor === 0 || !alarmData) {
    return <>{t("SensorConnectionMissing")}</>;
  }

  return (
    <React.Fragment key={index}>
      {alarmSensor && getAlarmSensor(alarmSensor, t)}
      {alarmTimestamp && (
        <div>
          {createTzDate(alarmTimestamp, timezone).format(timestampFormat)}
        </div>
      )}
      {alarmSensor &&
        alarmData &&
        getAlarmDataComponent(alarmSensor, alarmData, t, tempScale)}
    </React.Fragment>
  );
};

const renderStatusData = (status: number, t: TFunction) => {
  let elementArr: JSX.Element[] = [];
  let index = 0;
  const whiteSpaceStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  if (status & gpsFailedToInitGPSModule) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.error, ...whiteSpaceStyle }}
      >
        <WarningOutlined /> {t("gpsStatusFailedToInitGPSModule")}
      </div>
    );
  } else if (status & gpsFailedToGetPosition) {
    elementArr.push(
      <div
        style={{ color: connectionColors.error, ...whiteSpaceStyle }}
        key={index++}
      >
        <WarningOutlined /> {t("gpsStatusFailedToGetPosition")}
      </div>
    );
  } else if (status & gpsAntennaError) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.error, ...whiteSpaceStyle }}
      >
        <WarningOutlined /> {t("antennaError")}
      </div>
    );
  } else if (status & gpsFailedToInitCoMCU) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.error, ...whiteSpaceStyle }}
      >
        <WarningOutlined /> {t("gpsStatusFailedToInitCoMCU")}
      </div>
    );
  } else if (status & gpsCommunicationProblemsCoMCU) {
    elementArr.push(
      <div key={index++} style={{ color: connectionColors.error }}>
        <WarningOutlined /> {t("gpsStatusCommunicationProblemsTowardsCoMCU")}
      </div>
    );
  } else if (status & gpsStatusFailedToGetGPSPosition) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.error, ...whiteSpaceStyle }}
      >
        <WarningOutlined /> {t("gpsStatusFailedToGetGPSPosition")}
      </div>
    );
  } else if (status & gpsPowerOn) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.powerOn, ...whiteSpaceStyle }}
      >
        {t("gpsStatusGPSPowerOn")}
      </div>
    );
  } else if (status & gpsPowerOff) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.powerOff, ...whiteSpaceStyle }}
      >
        {t("gpsStatusGPSPowerOff")}
      </div>
    );
  }
  if (status & gpsTriggeredBySensor) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.sensor, ...whiteSpaceStyle }}
      >
        <ExclamationCircleOutlined /> {t("TriggeredBySensor")}
      </div>
    );
  }
  if (status & gpsTriggeredBySchedule) {
    elementArr.push(
      <div
        key={index++}
        style={{ color: connectionColors.schedule, ...whiteSpaceStyle }}
      >
        <CarryOutOutlined /> {t("TriggeredBySchedule")}
      </div>
    );
  }
  return elementArr;
};

const MapTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const speedUnit = useSelector(selectMySpeed);
  const tempScale = useSelector(selectTemperatureScale);

  const {
    fileId,
    tableData,
    markedMapKeys,
    tableHeight,
    tableWidth,
    timezone
  } = props;

  const columns: ColumnsType<IParsedGPSData> = [
    {
      title: t("Timestamp"),
      key: "timestamp",
      render: (data: IParsedGPSData) => {
        const { blockTimestamp, position } = data;
        const gpsTimestamp = position?.gpsTimestamp;
        return (
          <>
            <div>
              {createTzDate(blockTimestamp, timezone).format(timestampFormat)}
            </div>
            {gpsTimestamp && (
              <Text type="secondary">
                <AimOutlined />{" "}
                {dayjs.unix(gpsTimestamp).utc().format(timestampFormat)} (UTC)
              </Text>
            )}
          </>
        );
      }
    },
    {
      title: "Data",
      key: "data",
      render: (data: IParsedGPSData) => {
        const { rowType, position, status, alarm } = data;
        let elementArr: JSX.Element[] = [];
        let index = 0;
        if (rowType === "gpsPosition" && position) {
          const latitude = position.latitude || 0;
          const longitude = position.longitude || 0;
          const hasPos = position.latitude && position.longitude;
          elementArr.push(
            <React.Fragment key={index}>
              {hasPos && (
                <>
                  <MapProviderLink
                    lat={latitude!}
                    lon={longitude!}
                    text={false}
                    linkStyle={{ padding: 0 }}
                  />
                  <span>
                    {latitude}, {longitude}
                  </span>
                  {position.velocity && (
                    <div>
                      {" "}
                      {SpeedConverter(position.velocity, speedUnit) +
                        " " +
                        t(speedUnit)}
                    </div>
                  )}
                </>
              )}
            </React.Fragment>
          );
          index++;
          elementArr.push(renderTriggerText(position.statusCode, t, index));
          index++;
        }
        if (alarm) {
          elementArr.push(
            renderSensorData(data, tempScale, timezone, t, index)
          );
          index++;
        }
        if (rowType === "gpsStatus" && status) {
          elementArr.push(...renderStatusData(status, t));
          index++;
        }
        return elementArr;
      }
    }
  ];

  const selectRow = (key: number[]) => {
    if (key[0] !== markedMapKeys[0]) {
      dispatch(setMarkedMapKeys({ fileId: fileId, markedKeys: key }));
    } else {
      dispatch(setMarkedMapKeys({ fileId: fileId, markedKeys: [] }));
    }
  };

  /** Table content has a minHeight of 120px or else the same px-height as its row - 87 px*/
  const scrollHeight = () => {
    if (tableHeight < 200) {
      return 120;
    } else {
      return tableHeight - 55;
    }
  };

  const rowHeights =
    tableData?.map((row) => {
      let height = 32; // padding top and bottom are 16px
      const { alarm, position, status } = row;
      if (alarm) {
        height += 22 * 5;
      }
      if (position) {
        height += 22;
      }
      if (position?.velocity) {
        height += 22;
      }
      if (position?.statusCode) {
        height += 22;
      }
      if (status) {
        height += 22;
        if (status & (gpsTriggeredBySensor + gpsTriggeredBySchedule)) {
          height += 22;
        }
      }
      return height;
    }) || undefined;

  return (
    <>
      <Atable>
        <Athead>
          <tr>
            <Ath style={{ width: (tableWidth - 20) / 2 }}>{t("Timestamp")}</Ath>
            <Ath>{t("Data")}</Ath>
          </tr>
        </Athead>
      </Atable>
      <VirtualTable
        dataSource={tableData}
        columns={columns}
        height={scrollHeight()}
        width={tableWidth}
        rowHeights={rowHeights}
        rowSelection={{
          selectedRowKeys: markedMapKeys,
          onSelect: (selectedKey) => selectRow([selectedKey])
        }}
      />
    </>
  );
};

export default MapTable;
