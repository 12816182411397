import React, { useEffect } from "react";
import {
  InfoCircleOutlined,
  PlayCircleOutlined,
  AuditOutlined,
  UserOutlined
} from "@ant-design/icons";
import StandardModal from "../Common/StandardModal";
import {
  DangerButtonSmall,
  NormalButton,
  NormalButtonSmall
} from "../Common/CommonButtons";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import {
  CheckUserRightsAccess,
  License
} from "../MicroComponents/LicenseAccess";
import { setPendingRoute } from "../../state/sessionSlice";
import { Alert, Col, Row, Space, Table, Tooltip, Typography } from "antd";
import { size } from "../../helpers/pageHelper";
import { Company, User } from "../../state/cargologRestApi";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeRenewLicenseModal, modalsState } from "../../state/modalsSlice";
const { Text, Title } = Typography;

export interface LicenseActions {
  renewLicense: () => void;
  removeLicense: () => void;
}

export interface ILicenseTableData {
  userData: User;
  info: {
    text: string;
    licenseId: string;
  };
  endDate: string;
  actions: LicenseActions;
}

interface IProps {
  tableData: ILicenseTableData[];
  licenseLoading: boolean;
  companyData?: Company;
}
const LicenseExpiredModal = (props: IProps) => {
  const { tableData, licenseLoading, companyData } = props;

  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const licensesColumns: ColumnsType<ILicenseTableData> = [
    {
      title: "",
      dataIndex: "userData",
      key: "userId",
      align: "center",
      render: (data: User) => {
        return (
          <Tooltip
            placement="right"
            title={data?.firstName + " " + data?.lastName}
          >
            <UserOutlined />
          </Tooltip>
        );
      }
    },
    {
      title: t("Licenses"),
      dataIndex: "info",
      key: "info",
      render: (info: { text: string; licenseId: string }) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {info.text}
          </Title>
          <Text type="secondary">{` (${info.licenseId})`}</Text>
        </>
      )
    },
    {
      title: t("Expired"),
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => (
        <Text strong type="danger">
          {dayjs(date).format("YYYY-MM-DD")}
        </Text>
      )
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      key: "actions",
      render: (action: LicenseActions) => (
        <License right="AFA">
          <Space direction="vertical">
            <NormalButtonSmall
              icon={<PlayCircleOutlined />}
              onClick={action.renewLicense}
            >
              {t("RenewLicense")}
            </NormalButtonSmall>
            <DangerButtonSmall onClick={action.removeLicense}>
              {t("Remove")}
            </DangerButtonSmall>
          </Space>
        </License>
      )
    }
  ];

  // If user removes all expired licenses, close the modal
  useEffect(() => {
    if (tableData.length === 0) {
      dispatch(closeRenewLicenseModal());
    }
  }, [tableData]);

  return (
    <StandardModal
      open={modals.isRenewLicenseModalOpen}
      closable={true}
      styles={{
        header: {
          paddingInline: size.l2,
          paddingTop: size.l1
        },
        body: {
          paddingInline: size.l2
        },
        footer: {
          paddingInline: size.l2,
          paddingBottom: size.l1
        }
      }}
      title={
        <>
          <InfoCircleOutlined style={{ color: "red", paddingRight: size.m1 }} />
          {t("ExpiredLicenses")}
        </>
      }
      footer={[
        <NormalButton
          key="close"
          onClick={() => dispatch(closeRenewLicenseModal())}
        >
          {t("Close")}
        </NormalButton>,
        <NormalButton
          icon={<AuditOutlined />}
          onClick={() => (
            dispatch(closeRenewLicenseModal()),
            dispatch(setCurrentAdminTab("licenses")),
            dispatch(setPendingRoute("/admin"))
          )}
          disabled={!CheckUserRightsAccess("AFA")}
        >
          {t("OpenLicenses")}
        </NormalButton>
      ]}
      onCancel={() => dispatch(closeRenewLicenseModal())}
      zIndex={1043}
      width={650}
    >
      <Row style={{ marginBottom: size.m1 }}>
        {/* Ghost element to match the modal.confirm() */}
        <Col flex={"32px"} />
        <Text>
          {t("TheFollowingLicensesHaveExpired")}
          <br />
          {CheckUserRightsAccess("AFA") ? (
            <>{t("RenewLicenseToContinueUsing")}</>
          ) : (
            <Row style={{ paddingTop: size.m1 }}>
              <Alert
                type="warning"
                message={
                  <>
                    {t("YouDontHaveAdminRights")}
                    <br />
                    {t("ToManageYourLicenses")}: {companyData?.adminEmail}
                  </>
                }
              />
            </Row>
          )}
        </Text>
      </Row>
      <Row style={{ marginBottom: size.m1 }}>
        {/* Ghost element to match the modal.confirm() */}
        <Col flex={"32px"} />
        <Col>
          <Table
            columns={licensesColumns}
            dataSource={tableData}
            size="small"
            tableLayout="auto"
            loading={licenseLoading}
            pagination={false}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </StandardModal>
  );
};

export default LicenseExpiredModal;
