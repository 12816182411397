import React from "react";
import {
  AndroidOutlined,
  AppleOutlined,
  BarChartOutlined,
  BellOutlined,
  BoxPlotOutlined,
  BugOutlined,
  CarryOutOutlined,
  CheckOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
  ControlOutlined,
  DotChartOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileAddOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FormOutlined,
  FundViewOutlined,
  InsertRowBelowOutlined,
  KeyOutlined,
  LaptopOutlined,
  LineChartOutlined,
  MobileOutlined,
  MonitorOutlined,
  PlayCircleOutlined,
  PrinterOutlined,
  ProjectOutlined,
  ReadOutlined,
  SettingOutlined,
  ShakeOutlined,
  ShareAltOutlined,
  StopOutlined,
  SwitcherOutlined,
  TableOutlined,
  TeamOutlined,
  ToolOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { BluetoothIcon } from "../../icons";

import { Table } from "antd";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  LicensePrice,
  useGetAllLicensePricesQuery
} from "../../state/cargologRestApi";
import { closeLicenseDetailsModal, modalsState } from "../../state/modalsSlice";
import mobitronColors from "../../styles/mobitronColors";
import StandardModal from "../Common/StandardModal";

interface IProps {}

const LicenseDetailsModal: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector(modalsState);
  const selectedLicensePrice = modals.setLicensePrice;

  const { data: LicensePricesData } = useGetAllLicensePricesQuery();

  let licensePrice: LicensePrice = {
    id: "",
    created: "",
    userId: "",
    name: "",
    description: "",
    deviceType: 0,
    color: "green",
    licensePriceDefs: [],
    userRights: [],
    companyDiscountPercent: 0
  };

  /** All the fields for table used in this component */

  if (!isUndefined(selectedLicensePrice)) {
    licensePrice = selectedLicensePrice;
  }

  const GreenCheck = () => (
    <CheckOutlined style={{ color: mobitronColors.altDarkGreen }} />
  );

  const checkBoxData = [
    {
      key: "AAA",
      name: (
        <>
          <MobileOutlined /> {t("PhoneApp")}
        </>
      )
    },
    {
      key: "AAB",
      name: (
        <>
          <LaptopOutlined /> {t("PcApp")}
        </>
      )
    },
    {
      key: "AAC",
      name: (
        <>
          <CloudOutlined /> {t("Cloud")}
        </>
      )
    },
    {
      key: "AAD",
      name: (
        <>
          <BluetoothIcon />
          {t("Bluetooth")}
        </>
      )
    },
    {
      key: "ABA",
      name: (
        <>
          <InsertRowBelowOutlined /> {t("SeeMyUnits")}
        </>
      )
    },
    {
      key: "ABB",
      name: (
        <>
          <BellOutlined /> {t("AccessAllUnitData")}
        </>
      )
    },
    {
      key: "ACA",
      name: (
        <>
          <FileTextOutlined /> {t("ReadData")}
        </>
      )
    },
    {
      key: "ACB",
      name: (
        <>
          <CarryOutOutlined /> {t("QuickReport")}
        </>
      )
    },
    {
      key: "ACC",
      name: (
        <>
          <BoxPlotOutlined /> {t("CompareAndAnalyseData")}
        </>
      )
    },
    {
      key: "ACD",
      name: (
        <>
          <LineChartOutlined /> {t("StandardDashboard")}
        </>
      )
    },
    {
      key: "ACE",
      name: (
        <>
          <BarChartOutlined /> {t("DetailedVibrationAnalysis")}
        </>
      )
    },
    {
      key: "ACF",
      name: (
        <>
          <ProjectOutlined /> {t("ViewParams")}
        </>
      )
    },
    {
      key: "ACG",
      name: (
        <>
          <ShareAltOutlined /> {t("ExportData")}
        </>
      )
    },
    {
      key: "ACH",
      name: (
        <>
          <UserSwitchOutlined /> {t("ShareDataToExternalUsers")}
        </>
      )
    },
    {
      key: "ACJ",
      name: (
        <>
          <ReadOutlined /> {t("ExportReport")}
        </>
      )
    },
    {
      key: "ADA",
      name: (
        <>
          <DotChartOutlined /> {t("CustomDashboard")}
        </>
      )
    },
    {
      key: "ADB",
      name: (
        <>
          <ShareAltOutlined /> {t("CreateEditAndShareTemplates")}
        </>
      )
    },
    {
      key: "ADC",
      name: (
        <>
          <TableOutlined /> {t("ViewTemplates")}
        </>
      )
    },
    {
      key: "ADD",
      name: (
        <>
          <MonitorOutlined /> {t("Debug")}
        </>
      )
    },
    {
      key: "ADE",
      name: (
        <>
          <FormOutlined /> {t("CreateEditLoadandViewParams")}
        </>
      )
    },
    {
      key: "ADF",
      name: (
        <>
          <KeyOutlined /> {t("SetPasswordLevel3")}
        </>
      )
    },
    {
      key: "ADG",
      name: (
        <>
          <KeyOutlined /> {t("SetPasswordLevel2")}
        </>
      )
    },
    {
      key: "ADH",
      name: (
        <>
          <KeyOutlined /> {t("SetPasswordLevel1")}
        </>
      )
    },
    {
      key: "ADI",
      name: (
        <>
          <FolderOpenOutlined /> {t("AccessToProjectsPage")}
        </>
      )
    },
    {
      key: "ADJ",
      name: (
        <>
          <FundViewOutlined /> {t("OpenReportDataFromProject")}
        </>
      )
    },
    {
      key: "ADK",
      name: (
        <>
          <TeamOutlined /> {t("EditProjectDetailsInvitesNotifications")}
        </>
      )
    },
    {
      key: "ADL",
      name: (
        <>
          <StopOutlined /> {t("ArchiveAndRecoverProjects")}
        </>
      )
    },
    {
      key: "ADM",
      name: (
        <>
          <FileProtectOutlined /> {t("MarkLoggerAsFinishedTransport")}
        </>
      )
    },
    {
      key: "ADN",
      name: (
        <>
          <CloudUploadOutlined /> {t("UploadDataToCloud")}
        </>
      )
    },
    {
      key: "ADO",
      name: (
        <>
          <CloudSyncOutlined /> {t("AutoSyncOfDataToCloud")}
        </>
      )
    },
    // { key: "AEA", name: <><ToolOutlined /> {t("SetStatusTestRepair")}</> },
    {
      key: "AEB",
      name: (
        <>
          <ToolOutlined /> {t("UpdateFirmware")}
        </>
      )
    },
    // { key: "AEC", name: <><CodeOutlined /> {t("TerminalMode")}</> },
    // { key: "AED", name: <><MonitorOutlined /> {t("Debug")}</> },
    // { key: "AEE", name: <><MessageOutlined /> {t("SendMessageToUnit")}</> },
    // { key: "AEF", name: <><GlobalOutlined /> {t("SetNewParameterOnline")}</> },
    {
      key: "AFA",
      name: (
        <>
          <TeamOutlined /> {t("HandleAllUsers")}
        </>
      )
    },
    {
      key: "AFB",
      name: (
        <>
          <SwitcherOutlined /> {t("HandleAllCompanyLicenses")}
        </>
      )
    },
    {
      key: "AFC",
      name: (
        <>
          <SettingOutlined /> {t("HandleCompanyDetails")}
        </>
      )
    }
    // { key: "AGA", name: "Mobitron" }
  ];

  const eLabelData = [
    {
      key: "BAA",
      name: (
        <>
          <AndroidOutlined /> {t("AndroidApp")}
        </>
      )
    },
    {
      key: "BAB",
      name: (
        <>
          <AppleOutlined /> {t("iOSApp")}
        </>
      )
    },
    {
      key: "BAC",
      name: (
        <>
          <ShakeOutlined /> {t("NFCCommunication")}
        </>
      )
    },
    {
      key: "BBD",
      name: (
        <>
          <FileTextOutlined /> {t("ReadDataFromLabel")}
        </>
      )
    },
    {
      key: "BBE",
      name: (
        <>
          <EyeOutlined /> {t("ViewQuickDataAndComment")}
        </>
      )
    },
    {
      key: "BBF",
      name: (
        <>
          <FilePdfOutlined /> {t("PrintQuickReportPDF")}
        </>
      )
    },
    {
      key: "BBG",
      name: (
        <>
          <ProjectOutlined /> {t("ViewParameters")}
        </>
      )
    },
    {
      key: "BBH",
      name: (
        <>
          <UserSwitchOutlined /> {t("ShareDataSendFileToOtherUser")}
        </>
      )
    },
    {
      key: "BBI",
      name: (
        <>
          <EyeOutlined /> {t("ViewAllMeasuringData")}
        </>
      )
    },
    {
      key: "BBJ",
      name: (
        <>
          <FileTextOutlined /> {t("ExportAllDataCSV")}
        </>
      )
    },
    {
      key: "BBK",
      name: (
        <>
          <PrinterOutlined /> {t("PrintDataAllData")}
        </>
      )
    },
    {
      key: "BBL",
      name: (
        <>
          <DownloadOutlined /> {t("SaveDataLocally")}
        </>
      )
    },
    {
      key: "BBM",
      name: (
        <>
          <FolderOpenOutlined /> {t("OpenSavedData")}
        </>
      )
    },
    {
      key: "BCA",
      name: (
        <>
          <ControlOutlined /> {t("EditParameters")}
        </>
      )
    },
    {
      key: "BCB",
      name: (
        <>
          <PlayCircleOutlined /> {t("ActivateDVAData")}
        </>
      )
    },
    {
      key: "BCC",
      name: (
        <>
          <FileAddOutlined /> {t("UseTemplates")}
        </>
      )
    },
    {
      key: "BCD",
      name: (
        <>
          <BugOutlined /> {t("Debug")}
        </>
      )
    }
    // { key: "BDA", name: <><CloudUploadOutlined /> {t("UploadDataToCloudOnSetup")}</> },
    // { key: "BDB", name: <><FileAddOutlined /> {t("CreateProjects")}</> },
    // { key: "BDC", name: <><FileDoneOutlined /> {t("MarkLoggerAsTinishedTransport")}</> },
    // { key: "BDD", name: <><CloudSyncOutlined /> {t("UploadDataToCloudOnReadOut")}</> },
    // { key: "BDE", name: <><CloudUploadOutlined /> {t("ManualUploadOfSavedDataToloud")}</> },
    // { key: "BEA", name: <><CodeOutlined /> {t("SetStatusTestRepair")}</> },
    // { key: "BEB", name: <><ToolOutlined /> {t("UpdateFirmware")}</> },
  ];

  const selectData = licensePrice.deviceType === 1 ? checkBoxData : eLabelData;

  const tableColumns = () => {
    let columns: any[] = [
      {
        title: "Rights",
        fixed: "left",
        dataIndex: "name",
        key: "name"
      }
    ];
    if (!isUndefined(LicensePricesData)) {
      // Filter licenses by license type and add one column per license
      columns.push(
        ...LicensePricesData.filter(
          (license) => license.deviceType === licensePrice.deviceType
        ).map((license) => {
          return {
            title: license.name,
            dataIndex: license.id,
            key: license.id,
            align: "center",
            render: (hasKey: boolean) => (
              <>
                {hasKey ? (
                  <>
                    <GreenCheck />
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          };
        })
      );
    }
    return columns;
  };

  const tableData = () => {
    if (!isUndefined(LicensePricesData)) {
      // Loop through licence rights and render one row for each
      const rightsData = selectData.map((data) => {
        // Add license right name
        const licenseData: any = { name: data.name };
        // Add license rights (true/false) with license id as key
        LicensePricesData.forEach((license) => {
          licenseData[license.id] = license.userRights.includes(data.key);
        });
        return licenseData;
      });
      return rightsData;
    } else {
      return undefined;
    }
  };

  return (
    <>
      <StandardModal
        title={
          t("Compare") + " " + licensePrice.name + " " + t("toOtherLicenses")
        }
        open={modals.isLicenseDetailsModalOpen}
        onCancel={() => dispatch(closeLicenseDetailsModal())}
        closable={true}
        footer={null}
        width={900}
        zIndex={1045}
      >
        <Table
          dataSource={tableData()}
          columns={tableColumns()}
          pagination={false}
          size="small"
          scroll={{ x: 800, y: 450 }}
        />
      </StandardModal>
    </>
  );
};

export default LicenseDetailsModal;
