import { DocumentProps } from "./pdfInterfaces";

const rgbToHex = (rgb: string) => {
  const rgbArray = rgb.replace("rgb(", "").replace(")", "").split(", ");
  const hexString = rgbArray.map((color) => {
    const hex = Number(color).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  });
  return "#" + hexString.join("");
};

export const parseBackground = (backgroundColor: string | undefined) => {
  if (backgroundColor === undefined) {
    return undefined;
  } else if (backgroundColor === "transparent") {
    return undefined;
  } else if (backgroundColor.includes("rgb")) {
    return rgbToHex(backgroundColor);
  } else if (backgroundColor.includes("#")) {
    return backgroundColor;
  } else {
    return undefined;
  }
};

/* Returns the full width of the document minus margins */
export const getFullWidth = (documentProps: DocumentProps) => {
  const { orientation, paperFormat } = documentProps;
  switch (paperFormat) {
    case "A4":
      return orientation === "portrait" ? 595 - 40 : 842 - 40;
    case "A3":
      return orientation === "portrait" ? 842 - 40 : 1190 - 40;
    case "LETTER":
      return orientation === "portrait" ? 612 - 40 : 792 - 40;
    default:
      return 595 - 40;
  }
};

export const getFullHeight = (documentProps: DocumentProps) => {
  const { orientation, paperFormat } = documentProps;
  switch (paperFormat) {
    case "A4":
      return orientation === "portrait" ? 842 : 595;
    case "A3":
      return orientation === "portrait" ? 1190 : 842;
    case "LETTER":
      return orientation === "portrait" ? 792 : 612;
    default:
      return 842;
  }
};
