import React, { useState } from "react";
import { Form, Row, Col, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  Company,
  useUpdateCompanyDetailsMutation
} from "../../state/cargologRestApi";
import {
  setCompany,
  modalsState,
  closeEditInvoiceInfoModal
} from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";

const EditInvoiceInfoModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modals = useSelector(modalsState);
  const usersCompany = modals.companyObject;

  let company: Company = {
    id: "",
    name: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    countryCode: "",
    orgNumber: "",
    vatNumber: "",
    invoiceEmail: "",
    invoiceAddress: "",
    invoicePostalCode: "",
    invoiceCity: "",
    parentCompanyId: ""
  };

  if (!isUndefined(usersCompany)) {
    company = usersCompany;
  }

  const [updateCompanyDetails, requestStatus] =
    useUpdateCompanyDetailsMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleUpdateCompanyDetails = async (request: Company) => {
    const result: any = await updateCompanyDetails(request);
    if (result.data) {
      notification.success({ message: t("EditCompanySuccessMessage") });
      setCompany(result.data);
    } else if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("EditCompanyErrorMessage"),
        description: errorDescription
      });
    }
  };

  const [invoiceEmail, setInvoiceEmail] = useState<string>();
  const [invoiceAddress, setInvoiceAddress] = useState<string>();
  const [invoicePostalCode, setInvoicePostalCode] = useState<string>();
  const [invoiceCity, setInvoiceCity] = useState<string>();
  const [vatNumber, setVatNumber] = useState<string>();
  const [orgNumber, setOrgNumber] = useState<string>();

  const changeFinished = () => {
    const newVatNumber = !isUndefined(vatNumber)
      ? vatNumber
      : company.vatNumber;
    const newOrgNumber = !isUndefined(orgNumber)
      ? orgNumber
      : company.orgNumber;
    const newInvoiceEmail = !isUndefined(invoiceEmail)
      ? invoiceEmail
      : company.invoiceEmail;
    const newInvoiceAddress = !isUndefined(invoiceAddress)
      ? invoiceAddress
      : company.invoiceAddress;
    const newInvoicePostalCode = !isUndefined(invoicePostalCode)
      ? invoicePostalCode
      : company.invoicePostalCode;
    const newInvoiceCity = !isUndefined(invoiceCity)
      ? invoiceCity
      : company.invoiceCity;

    const request: Company = {
      id: company.id,
      name: company.name,
      address: company.address,
      address2: company.address2,
      postalCode: company.postalCode,
      city: company.city,
      countryCode: company.countryCode,
      orgNumber: newOrgNumber,
      vatNumber: newVatNumber,
      invoiceEmail: newInvoiceEmail,
      invoiceAddress: newInvoiceAddress,
      invoicePostalCode: newInvoicePostalCode,
      invoiceCity: newInvoiceCity,
      parentCompanyId: company.parentCompanyId
    };
    handleUpdateCompanyDetails(request);
    setInvoiceEmail(undefined);
    setInvoiceAddress(undefined);
    setInvoicePostalCode(undefined);
    setInvoiceCity(undefined);
    setVatNumber(undefined);
    setOrgNumber(undefined);
    dispatch(closeEditInvoiceInfoModal());
  };

  const changeFailed = () => {};

  return (
    <>
      <StandardModal
        title={t("EditInvoiceDetails")}
        zIndex={1045}
        open={modals.isEditInvoiceInfoModalOpen}
        onCancel={() => dispatch(closeEditInvoiceInfoModal())}
        closable={true}
        footer={null}
        width={400}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={changeFinished}
          onFinishFailed={changeFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("InvoiceEmail")}
                name="invoiceEmail"
                initialValue={
                  !isUndefined(company.invoiceEmail) ? company.invoiceEmail : ""
                }
                style={{ marginBottom: size.s1 }}
                rules={[
                  { required: true, message: t("InvoiceEmailIsRequired") }
                ]}
              >
                <Input
                  placeholder=""
                  value={invoiceEmail}
                  onChange={(e) => setInvoiceEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("InvoiceAddress")}
                name="invoiceAddress"
                initialValue={
                  !isUndefined(company.invoiceAddress)
                    ? company.invoiceAddress
                    : ""
                }
                style={{ marginBottom: size.s1 }}
                rules={[{ required: true, message: t("AddressIsRequired") }]}
              >
                <Input
                  placeholder=""
                  value={invoiceAddress}
                  onChange={(e) => setInvoiceAddress(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("InvoicePostalCode")}
                name="invoicePostalCode"
                initialValue={
                  !isUndefined(company.invoicePostalCode)
                    ? company.invoicePostalCode
                    : ""
                }
                style={{ marginBottom: size.s1 }}
                rules={[{ required: true, message: t("PostalCodeIsRequired") }]}
              >
                <Input
                  placeholder=""
                  value={invoicePostalCode}
                  onChange={(e) => setInvoicePostalCode(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("InvoiceCity")}
                name="invoiceCity"
                initialValue={
                  !isUndefined(company.invoiceCity) ? company.invoiceCity : ""
                }
                style={{ marginBottom: size.s1 }}
                rules={[{ required: true, message: t("CityIsRequired") }]}
              >
                <Input
                  placeholder=""
                  value={invoiceCity}
                  onChange={(e) => setInvoiceCity(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {company.countryCode !== "SE" && (
            <Row gutter={size.m1}>
              <Col span={24}>
                <Form.Item
                  label={t("VATNumber")}
                  name="vatNumber"
                  initialValue={
                    !isUndefined(company.vatNumber) ? company.vatNumber : ""
                  }
                >
                  <Input
                    placeholder=""
                    value={vatNumber}
                    onChange={(e) => setVatNumber(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {company.countryCode === "SE" && (
            <Row gutter={size.m1}>
              <Col span={24}>
                <Form.Item
                  label={t("OrgNumber")}
                  name="orgNumber"
                  initialValue={
                    !isUndefined(company.orgNumber) ? company.orgNumber : ""
                  }
                  rules={[
                    {
                      required: company.countryCode === "SE",
                      message: t("OrgNumberRequiredInSweden")
                    }
                  ]}
                >
                  <Input
                    placeholder=""
                    value={orgNumber}
                    onChange={(e) => setOrgNumber(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("SaveChanges")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};
export default EditInvoiceInfoModal;
