import React from "react";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import TopAccTable from "./TopAccTable";
import { useSelector } from "react-redux";
import {
  AccData,
  selectAccData,
  selectDashboardHeader,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo
} from "../../state/openDatxSlice";
import { useTranslation } from "react-i18next";
import { accDataToCsv } from "../../helpers/dataExportHelper";
import { memoize, isEmpty } from "lodash-es";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import {
  DocumentProps,
  ItemHeaderData,
  TransportHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import { selectCsvFormat } from "../../state/sessionSlice";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { getUser } from "../../state/sessionSlice";
import dayjs from "dayjs";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";

const getAccWithDuration = (d: AccData): [number, number] =>
  [d.xAcc, d.yAcc, d.zAcc].find((x) => x[1] !== 0)!;

export const getTop10 = memoize((data: AccData[]) => {
  if (isEmpty(data)) {
    return [];
  }

  const top10 = data
    .sort((a, b) => {
      const aWithDur = getAccWithDuration(a);
      const bWithDur = getAccWithDuration(b);

      return Math.abs(bWithDur[0]) - Math.abs(aWithDur[0]);
    })
    .slice(0, 10);

  return top10;
});

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const TopAccDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const accData = useSelector(selectAccData(props.fileId));
  const top10AccData = getTop10(accData);
  const domains = useSelector(selectGraphDomains(props.fileId));
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);

  const utcOffsetStr = createUtcOffsetStr(timezone);

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const csvFormat = useSelector(selectCsvFormat);

  const exportAsCsv = () => {
    const csvData = accDataToCsv(top10AccData, csvFormat, t);
    saveAsCsv(csvData);
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      csvAction: exportAsCsv
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const documentProps: DocumentProps = {
    documentTitle: t("Top10Accelerations"),
    fileName: "top_10_acc.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "Top10Accelerations",
    itemTitle: t("Top10Accelerations")
  };

  const transportHeader: TransportHeaderData = {
    reportType: "Top10Accelerations",
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("Top10Accelerations")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isExporting && (
        <PdfExportComponent
          ComponentBody={TopAccTable}
          componentProps={{ accData: top10AccData }}
          documentProps={documentProps}
          itemHeader={itemHeader}
          transportHeader={transportHeader}
          reportExportDone={finishExport}
        />
      )}
      <TopAccTable accData={top10AccData} />
    </MoveableCard>
  );
};

export default TopAccDashboardCard;
