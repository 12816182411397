// Utility functions used for printing

import AccHistogramGraph from "../components/GraphPage/AccHistogramGraph";
import DvaGraph from "../components/GraphPage/DvaGraph";
import GeneralRecordingInformation from "../components/MicroComponents/GeneralRecordingInformation";
import {
  PartiallyAppliedPropType,
  PrintableItem
} from "../components/PrintExport/hocPrintables";
import MinMaxTable from "../components/GraphPage/MinMaxTable";
import PrimaryGraph from "../components/GraphPage/PrimaryGraph";
import TopAccTable from "../components/GraphPage/TopAccTable";
import { VMDvaData } from "./dataModelHelper";
import React from "react";
import QuickReport from "../components/GraphPage/QuickReport";
import ExternalIOTable from "../components/GraphPage/ExternalIOTable";
import ExternalTimersTable from "../components/GraphPage/ExternalTimersTable";
import AngleTable from "../components/GraphPage/AngleTable";

// Types

type DvaGraphProps = React.ComponentProps<typeof DvaGraph>;
type PrinteableDvaItem = PrintableItem<PartiallyAppliedPropType<DvaGraphProps>>;

type PrimaryGraphProps = React.ComponentProps<typeof PrimaryGraph>;
type PrinteablePrimaryGraphItem = PrintableItem<
  PartiallyAppliedPropType<PrimaryGraphProps>
>;

type MinMaxTableProps = React.ComponentProps<typeof MinMaxTable>;
type PrintableMinMaxTableItem = PrintableItem<MinMaxTableProps>;

type AccHistogramGraphProps = React.ComponentProps<typeof AccHistogramGraph>;
type PrinteableAccHistogramGraphItem = PrintableItem<
  PartiallyAppliedPropType<AccHistogramGraphProps>
>;

type TopAccTableProps = React.ComponentProps<typeof TopAccTable>;
type PrinteableTopAccTableItem = PrintableItem<TopAccTableProps>;

type GeneralRecordingInfoProps = React.ComponentProps<
  typeof GeneralRecordingInformation
>;
type PrinteableGeneralRecordingInfoItem =
  PrintableItem<GeneralRecordingInfoProps>;

type QuickReportProps = React.ComponentProps<typeof QuickReport>;
type PrintableQuickReport = PrintableItem<QuickReportProps>;

type ExtIOTableProps = React.ComponentProps<typeof ExternalIOTable>;
type PrintableExtIOTable = PrintableItem<ExtIOTableProps>;

type ExtTimersTableProps = React.ComponentProps<typeof ExternalTimersTable>;
type PrintableExtTimersTable = PrintableItem<ExtTimersTableProps>;

type AngleTableProps = React.ComponentProps<typeof AngleTable>;
type PrintableAngleTable = PrintableItem<AngleTableProps>;

// Functions

/**
 * Creates an array of printable items containing the dva graph from every index
 * in markedIndexes
 * @param dvaData
 * @param markedIndexes
 */
export const getDvaGraphsFromMarked = (
  dvaData: VMDvaData[],
  markedIndexes: number[],
  graphsPerPage = 3
): PrinteableDvaItem[] => {
  if (!(markedIndexes?.length > 0)) {
    return [];
  }

  return markedIndexes?.map((arrayIndex) => {
    const dvaDataBlock = dvaData?.[arrayIndex];

    const Component = DvaGraph;

    const props: PartiallyAppliedPropType<DvaGraphProps> = {
      dvaDataBlock
    };

    const heightDenominator = graphsPerPage;

    return { Component, props, heightDenominator };
  });
};

/**
 * Creates a printable item for primary graph that is usefull for multipage reports
 * @param props
 */
export const getPrimaryGraphPrintableItem = (
  props: PartiallyAppliedPropType<PrimaryGraphProps>
): PrinteablePrimaryGraphItem => {
  const Component = PrimaryGraph;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/** TODO: NOT TESTED
 * Creates a printable item for min/max that is usefull for multipage reports
 * @param props
 */
export const getMinMaxTablePrintableItem = (
  props: MinMaxTableProps
): PrintableMinMaxTableItem => {
  const Component = MinMaxTable;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/**
 * Creates a printable item for Acceleration histogram graph that is usefull for multipage reports
 * @param props
 */
export const getAccHistogramGraphPrintableItem = (
  props: PartiallyAppliedPropType<AccHistogramGraphProps>
): PrinteableAccHistogramGraphItem => {
  const Component = AccHistogramGraph;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/**
 * Creates a printable item for top accelerations table that is usefull for multipage reports
 * @param props
 */
export const getTopAccTablePrintableItem = (
  props: TopAccTableProps
): PrinteableTopAccTableItem => {
  const Component = TopAccTable;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/**
 * Creates a printable item general recording information that is usefull for multipage reports
 * @param props
 */
export const getGeneralRecordingInfoPrintableItem = (
  props: GeneralRecordingInfoProps
): PrinteableGeneralRecordingInfoItem => {
  const Component = GeneralRecordingInformation;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/**
 * Creates a printable item for quick report that is usefull for multipage reports
 * @param props
 */
export const getQuickReportPrintableItem = (
  props: QuickReportProps
): PrintableQuickReport => {
  const Component = QuickReport;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/* Creates a printable item for externalIO
 * @param props
 */
export const getExtIOTablePrintableItem = (
  props: ExtIOTableProps
): PrintableExtIOTable => {
  const Component = ExternalIOTable;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/* Creates a printable item for externalIO
 * @param props
 */
export const getExtTimersTablePrintableItem = (
  props: ExtTimersTableProps
): PrintableExtTimersTable => {
  const Component = ExternalTimersTable;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};

/* Creates a printable item for Angle table
 * @param props
 */
export const getAngleTablePrintableItem = (
  props: AngleTableProps
): PrintableAngleTable => {
  const Component = AngleTable;

  //full height
  const heightDenominator = 1;

  return { Component, props, heightDenominator };
};
