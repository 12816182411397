import React from "react";
import { Col, Descriptions, Row, Spin, Tooltip, Alert, Typography } from "antd";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import { useDispatch } from "react-redux";
import {
  LicenseWithCost,
  useGetCompanyDetailsQuery,
  useGetCurrentUserQuery,
  useGetLicensesQuery
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { LargeHeaderTitle, SmallText } from "../Common/CommonFonts";
import { openSettingsModal, setCurrentPage } from "../../state/settingsSlice";
import Online from "../MicroComponents/Online";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";
import dayjs from "dayjs";
import { size } from "../../helpers/pageHelper";
import {
  addToRenewalCart,
  openRenewalModal
} from "../../state/licenseStoreSlice";
import { useNavigate } from "react-router";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
const { Text } = Typography;

const AccountLandingCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userData, isLoading: userLoading } = useGetCurrentUserQuery();
  // Load User Company from API
  const { data: companyData, isLoading: companyLoading } =
    useGetCompanyDetailsQuery(
      userData?.companyId ? { companyId: userData.companyId } : skipToken
    );

  const { data: licenseData, isLoading: licenseLoading } = useGetLicensesQuery(
    userData?.userId ? { userId: userData?.userId } : skipToken
  );
  const userLicenses = licenseData?.filter((license) =>
    isNil(license.projectId)
  );
  const activeLicenses = userLicenses?.filter(
    (license) =>
      dayjs().diff(license.endDate, "days") < 0 || license.renewAuto === true
  );
  const expiredLicenses = userLicenses?.filter(
    (license) =>
      dayjs().diff(license.endDate, "days") >= 0 && license.renewAuto === false
  );

  const isAdmin = CheckUserRightsAccess("AFA");

  interface LicenseContent {
    data: LicenseWithCost[];
    type: "active" | "expired";
  }
  const licensesContent = (props: LicenseContent) => {
    const { data, type } = props;

    const colorType = (within7Days?: boolean) => {
      switch (type) {
        case "active":
          if (within7Days) return "warning";
          else return "secondary";
        case "expired":
          return "danger";
        default:
          return "secondary";
      }
    };

    const titleText = (
      licenseType: "active" | "expired",
      autoRenewal: boolean
    ) => {
      if (licenseType === "active" && autoRenewal) return t("Renews");
      else if (licenseType === "active" && !autoRenewal) return t("Expires");
      else if (licenseType === "expired") return t("Expired");
      else return "";
    };

    return (
      <>
        <Row>
          <div
            className="ant-descriptions-item-label"
            style={{ fontWeight: "bold" }}
          >
            <SmallText type={type === "expired" ? "danger" : undefined}>
              {type === "active" ? t("ActiveLicenses") : t("ExpiredLicenses")}
            </SmallText>
          </div>
        </Row>

        {data.map((license, index) => {
          const daysDiff = dayjs().diff(license.endDate, "days");
          const within7Days = daysDiff >= -7 && daysDiff < 0;
          const isAdminAndExpired = isAdmin && type === "expired";

          return (
            <Row key={index}>
              <Col>
                <SmallText style={{ paddingLeft: size.m1 }}>
                  <Tooltip
                    title={license.id}
                    overlayInnerStyle={{ width: 290 }}
                    placement="top"
                  >
                    <SmallText strong style={{ paddingRight: size.s1 }}>
                      {license.text}
                    </SmallText>
                  </Tooltip>
                  {license.text !== "Mobitron" && (
                    <SmallText
                      title={titleText(type, license.renewAuto)}
                      type={colorType(within7Days)}
                      style={{
                        cursor: isAdminAndExpired ? "pointer" : "auto"
                      }}
                      onClick={() => {
                        if (isAdminAndExpired) {
                          dispatch(
                            addToRenewalCart({
                              licenseId: license.id,
                              licensePriceId: license.licensePriceId
                            })
                          );
                          dispatch(openRenewalModal());
                          navigate("/admin");
                        }
                      }}
                    >
                      ({dayjs(license.endDate).format("YYYY-MM-DD")})
                      {type === "active" &&
                        license.renewAuto &&
                        ` (${t("AutoRenewal")})`}
                    </SmallText>
                  )}
                </SmallText>
              </Col>
            </Row>
          );
        })}
      </>
    );
  };

  return (
    <LandingCard
      key="user"
      extra={
        <NormalButtonSmall
          icon={<SettingOutlined />}
          onClick={() => {
            dispatch(setCurrentPage("account"));
            dispatch(openSettingsModal());
          }}
        >
          {t("Settings")}
        </NormalButtonSmall>
      }
      title={
        <LargeHeaderTitle>
          <UserOutlined /> {t("Account")}
        </LargeHeaderTitle>
      }
      styles={{
        body: {
          height: 230,
          overflow: "auto",
          outline: "none",
          paddingBottom: 0
        }
      }}
    >
      <Online>
        {/* Print user information. Link to User settings */}
        <Spin spinning={userLoading || companyLoading || licenseLoading}>
          <Descriptions column={1} size="small">
            <Descriptions.Item
              label={t("Name")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.firstName} {userData?.lastName}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Email")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.email}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Phone")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {userData?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("Company")}
              labelStyle={{ fontWeight: "bold" }}
            >
              {companyData?.name}
            </Descriptions.Item>
          </Descriptions>

          {userLicenses && userLicenses.length === 0 && (
            <Alert
              style={{ textAlign: "center" }}
              message={
                <>
                  <Text strong>{t("YouDontHaveLicense")}</Text>
                  <br />
                  <Text>
                    {t("ContactYourAdminAt")}: {companyData?.adminEmail}
                  </Text>
                </>
              }
              type="error"
            />
          )}

          {expiredLicenses &&
            expiredLicenses.length > 0 &&
            licensesContent({ data: expiredLicenses, type: "expired" })}

          {activeLicenses &&
            activeLicenses.length > 0 &&
            licensesContent({ data: activeLicenses, type: "active" })}
        </Spin>
      </Online>
    </LandingCard>
  );
};

export default AccountLandingCard;
