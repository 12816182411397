import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Popover,
  Typography,
  notification,
  Select
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  appType,
  Company,
  useGetCompanyDetailsQuery,
  useRevokeAccessTokenMutation,
  useUpdateCompanyDetailsMutation
} from "../../state/cargologRestApi";
import { setCompany } from "../../state/modalsSlice";
import { PrimaryButton, NormalButton } from "../Common/CommonButtons";
import {
  setCreateCompanySuccess,
  setCreateUserAccountProgress,
  setCreateUserSuccess
} from "../../state/navigationSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { getUser, setInitalizeSwitcher } from "../../state/sessionSlice";
import { setValidSetup } from "../../state/persistantStateSlice";
import { WarningOutlined } from "@ant-design/icons";
import Online from "../MicroComponents/Online";
import { countries } from "../../constants/countries";
import { clearSessionAndData } from "../../App";
const { Text } = Typography;
const { Option } = Select;

const CreateCompany = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* Get API for Revoke Access Token */
  const [revokeAccessToken] = useRevokeAccessTokenMutation();

  let company: Company = {
    id: "",
    name: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    countryCode: "",
    orgNumber: "",
    vatNumber: "",
    invoiceEmail: "",
    invoiceAddress: "",
    invoicePostalCode: "",
    invoiceCity: "",
    parentCompanyId: ""
  };

  const countryOptions = countries.map((country) => {
    return (
      <Option key={country.code} value={country.code}>
        {country.name}
      </Option>
    );
  });
  const currentUser = useSelector(getUser);
  const getCompanyDetails = useGetCompanyDetailsQuery(
    currentUser.companyId ? { companyId: currentUser.companyId } : skipToken
  );
  const companyDetails = getCompanyDetails.data;

  if (!isUndefined(companyDetails)) {
    company = companyDetails;
  }

  /* Request for update Company Properties */
  const [updateCompanyDetails, requestStatus] =
    useUpdateCompanyDetailsMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  /** Function that handles company data update */
  const handleUpdateCompanyDetails = async (request: Company) => {
    const result: any = await updateCompanyDetails(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyCreatedUserAccount") });
      // Reset form fields
      setName(undefined);
      setAddress(undefined);
      setAddress2(undefined);
      setPostalCode(undefined);
      setCity(undefined);
      setCountryCode(undefined);
      setOrgNumber(undefined);

      setCompany(result.data);
      // Reset create user and company navigation state
      dispatch(setCreateUserSuccess(false));
      dispatch(setCreateCompanySuccess(false));
      dispatch(setCreateUserAccountProgress(0));
      dispatch(setValidSetup(true));
      dispatch(setInitalizeSwitcher("appLayout"));
    } else {
      notification.error({ message: t("UnableToCreateUserAccount") });
    }
  };

  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [orgNumber, setOrgNumber] = useState<string>();

  const handleCountryCodeChange = (value: string) => {
    setCountryCode(value);
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    const id = company.id;
    const newName = !isUndefined(name) ? name : company.name;
    const newAddress = !isUndefined(address) ? address : company.address;
    const newAddress2 = !isUndefined(address2) ? address2 : company.address2;
    const newPostalCode = !isUndefined(postalCode)
      ? postalCode
      : company.postalCode;
    const newCity = !isUndefined(city) ? city : company.city;
    const newCountryCode = !isUndefined(countryCode)
      ? countryCode
      : company.countryCode;
    const newOrgNumber = !isUndefined(orgNumber)
      ? orgNumber
      : company.orgNumber;

    if (
      !isUndefined(newName) &&
      !isUndefined(newAddress) &&
      !isUndefined(newPostalCode) &&
      !isUndefined(newCity) &&
      !isUndefined(newCountryCode)
    ) {
      const request: Company = {
        id,
        name: newName,
        address: newAddress,
        address2: newAddress2,
        postalCode: newPostalCode,
        city: newCity,
        countryCode: newCountryCode,
        orgNumber: newOrgNumber,
        vatNumber: company.vatNumber,
        invoiceEmail: company.invoiceEmail,
        invoiceAddress: company.invoiceAddress,
        invoicePostalCode: company.invoicePostalCode,
        invoiceCity: company.invoiceCity,
        parentCompanyId: company.parentCompanyId
      };
      handleUpdateCompanyDetails(request);
    }
  };
  // When user tries to finish form, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={changeFinished}
        onFinishFailed={changeFailed}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Row gutter={size.m1}>
          <Col span={24}>
            <Form.Item
              label={t("CompanyName")}
              name="name"
              rules={[{ required: true, message: t("NameIsRequired") }]}
            >
              <Input
                placeholder=""
                value={name}
                maxLength={60}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("Address")}
              name="address"
              rules={[{ required: true, message: t("AddressIsRequired") }]}
            >
              <Input
                placeholder=""
                value={address}
                maxLength={60}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("Address2")} name="address2">
              <Input
                placeholder=""
                value={address2}
                maxLength={60}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("PostalCode")}
              name="postalCode"
              rules={[{ required: true, message: t("PostalCodeIsRequired") }]}
            >
              <Input
                placeholder=""
                value={postalCode}
                maxLength={20}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("City")}
              name="city"
              rules={[{ required: true, message: t("CityIsRequired") }]}
            >
              <Input
                placeholder=""
                value={city}
                maxLength={60}
                onChange={(e) => setCity(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={size.m1}>
          <Col span={12}>
            <Form.Item
              label={t("Country")}
              name="countryCode"
              rules={[{ required: true, message: t("CountryIsRequired") }]}
            >
              <Select
                showSearch
                placeholder={t("SearchToSelect")}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option?.children) {
                    return (
                      option.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                filterSort={(optionA, optionB) => {
                  if (optionA.children && optionB.children) {
                    return optionA.children
                      .toString()
                      .toLowerCase()
                      .localeCompare(optionB.children.toString().toLowerCase());
                  }
                  return 0;
                }}
                onChange={handleCountryCodeChange}
              >
                {countryOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("OrgNumber")}
              name="orgNumber"
              rules={[
                {
                  required: countryCode === "SE",
                  message: t("OrgNumberRequiredInSweden")
                }
              ]}
            >
              <Input
                placeholder=""
                value={orgNumber}
                onChange={(e) => setOrgNumber(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingTop: size.m1 }}>
          <Popover
            content={
              <>
                <Text type="danger">
                  <WarningOutlined />
                </Text>{" "}
                {t("ThisAbortsYourRegistration")}
              </>
            }
          >
            <NormalButton
              icon={<WarningOutlined />}
              onClick={() => {
                revokeAccessToken(appType);
                clearSessionAndData(dispatch);
              }}
            >
              {t("AbortSetup")}
            </NormalButton>
          </Popover>
          <Online>
            <Form.Item style={{ marginBottom: 0 }}>
              <PrimaryButton
                style={{ height: "35px" }}
                htmlType="submit"
                loading={requestIsLoading}
                disabled={
                  isUndefined(name) ||
                  isUndefined(address) ||
                  isUndefined(postalCode) ||
                  isUndefined(city) ||
                  isUndefined(countryCode) ||
                  (countryCode === "SE" && isUndefined(orgNumber))
                }
              >
                {t("FinishSetup")}
              </PrimaryButton>
            </Form.Item>
          </Online>
        </Row>
      </Form>
    </>
  );
};

export default CreateCompany;
