import dayjs from "dayjs";
import { DictionaryTransKeys } from "../lib/i18n";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";

interface ITimeZone {
  timezoneStr: string;
  utcOffsetStr: string;
}

/** Helper function that returns recording start as string */
export const getStartDate = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
) => {
  const { startRecordingType, startTimestamp } = recordingParameters.RecParams;

  const { timezoneStr, utcOffsetStr } = timezone;

  switch (startRecordingType) {
    case "date":
      return startTimestamp
        ? dayjs
            .unix(startTimestamp!)
            .tz(timezoneStr)
            .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
        : t("NotSet");
    case "directly":
      return t("StartAfterDownload");
    case "button":
      return t("StartByButton");
    default:
      return t("somethingWentWrong");
  }
};

/** Helper function that returns recording end as string */
export const getEndDate = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
) => {
  const {
    startRecordingType,
    startTimestamp,
    stopTimestamp,
    stopAfterDuration
  } = recordingParameters.RecParams;

  const { timezoneStr, utcOffsetStr } = timezone;

  if (startRecordingType === "date" && startTimestamp) {
    return stopTimestamp
      ? dayjs
          .unix(stopTimestamp!)
          .tz(timezoneStr)
          .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
      : t("NotSet");
  } else {
    return `${t("After")} ${stopAfterDuration.days}d ${stopAfterDuration.hours}h`;
  }
};
