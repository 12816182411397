import { Row } from "antd";
import React, { createRef } from "react";

import { TransportHeaderData } from "../../helpers/pdf/pdfInterfaces";
import ReportHeader from "./ReportHeader";
import { size } from "../../helpers/pageHelper";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
const { Text } = Typography;

// Types

/** Prepfeared props for components that should be printed */
interface PrintableProps {
  width: number;
  height: number;
}

interface MultiHocProps<T> {
  printableItems: PrintableItem<T>[];
  orientation: Orientation;
  reportPrintDone: () => void;
  headers: TransportHeaderData[];
}

interface WrappedPrintableComponentProps<T>
  extends Omit<MultiHocProps<T>, "reportPrintDone"> {
  previewMode?: boolean;
  headers: TransportHeaderData[];
}

export interface PrintableItem<T> {
  Component: React.FC<T & PrintableProps>;
  props?: T;
  heightDenominator?: number;
}

/** Printed page orientation */
export type Orientation = "landscape" | "portrait";

/** Utility type: Create props without width/height. Usefull for partially
 * applying props that will be used for a printable component */
export type PartiallyAppliedPropType<T extends PrintableProps> = Omit<
  T,
  "width" | "height"
>;

// Constants

/** The longer side on an A4 in pixels */
const longSide = 1485;
/** The shorter side on an A4 in pixels */
const shortSide = 1050;

/** Total margin per axis in pixels rounded up. (7mm * 2 * ~3.8) */
const marginPerAxisInPx = 53;

/** A4 size minus side margins*/
const a4Size = {
  landscape: {
    w: longSide - marginPerAxisInPx,
    h: shortSide - marginPerAxisInPx
  },
  portrait: {
    w: shortSide - marginPerAxisInPx,
    h: longSide - marginPerAxisInPx
  }
};

// HOCs

/**
 * Wrap multible printable items to components
 * @param hocProps
 *
 * @var printableItems: This is mis-named. It should be exportableItems.
 */
export const WrapMultipleComponents = <T,>(
  hocProps: WrappedPrintableComponentProps<T>
) => {
  const {
    printableItems,
    orientation: pageOrientation,
    previewMode,
    headers
  } = hocProps;

  const headerHeight = 190;
  const itemsPerPage = 1;

  // Change orientation of content if more than one item per page
  const itemIsHorizontal =
    itemsPerPage === 1
      ? pageOrientation === "landscape"
      : pageOrientation === "portrait";
  const itemOrientation = itemIsHorizontal ? "landscape" : "portrait";

  // How much we need to scale the content to fit in the page
  const itemScale = "scale(" + 1 / itemsPerPage + ")";

  // The size of the actual content (minus header and margin)
  const contentSize = {
    w: a4Size[itemOrientation].w,
    h: a4Size[itemOrientation].h - headerHeight
  };

  // Width of the li element that contains the content
  // horizontal parent, horizontal child:  w = parent w
  // vertical parent, horizontal children: w = parent w
  // vertical parent, vertical child:      w = parent w / 1
  // horizontal parent, vertical children: w = parent w / nr of children
  const itemWidth = itemIsHorizontal
    ? a4Size[pageOrientation].w
    : a4Size[pageOrientation].w / itemsPerPage;

  // Height of the li element that contains the content
  // vertical parent, vertical child:      h = parent h
  // horizontal parent, vertical children: h = parent h
  // horizontal parent, horizontal child:  h = parent h / 1
  // vertical parent, horizontal children: h = parent h / nr of children
  const itemHeight = itemIsHorizontal
    ? a4Size[pageOrientation].h / itemsPerPage
    : a4Size[pageOrientation].h;

  // The width of the li container in percent
  const itemWidthPercent = itemIsHorizontal ? "100%" : 100 / itemsPerPage + "%";

  // The height of the li container in percent
  const itemHeightPercent = itemIsHorizontal
    ? 100 / itemsPerPage + "%"
    : "100%";

  // Contains all items to be printed
  const printUlStyle: React.CSSProperties = {
    width: a4Size[pageOrientation].w,
    listStyle: "none",
    margin: 0,
    padding: 0
  };

  // Contains one item to be printed
  const printLiStyle: React.CSSProperties = {
    width: itemWidthPercent,
    height: itemHeightPercent, // needs to be percent in order to break page
    maxHeight: itemHeight,
    maxWidth: itemWidth,
    margin: 0,
    padding: 0,
    float: "left" // puts the next item on the same page
  };

  // Div width and height before scaling
  // horizontal parent, horizontal child:  w = parent w,     h = parent h * 1
  // horizontal parent, vertical children: w = parent w,     h = parent h * n
  // vertical parent, vertical child:      w = parent w * 1, h = parent h
  // vertical parent, horizontal children: w = parent w * n, h = parent h

  const unscaledDivWidth =
    pageOrientation === "landscape"
      ? a4Size[pageOrientation].w - 10
      : (a4Size[pageOrientation].w - 10) * itemsPerPage;

  const unscaledDivHeight =
    pageOrientation === "landscape"
      ? (a4Size[pageOrientation].h - 10) * itemsPerPage
      : a4Size[pageOrientation].h - 10;

  // Scales down an item to fit in the li container
  const printDivScaleStyle: React.CSSProperties = {
    width: unscaledDivWidth,
    height: unscaledDivHeight,
    margin: 0,
    padding: 0,
    transformOrigin: "top left",
    transform: itemScale,
    maxWidth: a4Size[pageOrientation].w * itemsPerPage,
    maxHeight: a4Size[pageOrientation].h * itemsPerPage
  };

  const renderContent = () => {
    return (
      <ul style={printUlStyle}>
        {printableItems.map((item, index) => {
          const { Component, props } = item;

          return (
            <li key={index} style={printLiStyle}>
              <div style={printDivScaleStyle}>
                <div
                  style={{
                    width: "100%",
                    height: headerHeight,
                    margin: 0,
                    padding: 0
                  }}
                >
                  <ReportHeader
                    header={headers[index]}
                    isHorizontal={itemIsHorizontal}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: contentSize.h - 10,
                    padding: "0 25px",
                    margin: 0
                  }}
                >
                  <Component
                    style={{
                      margin: 0,
                      padding: 0
                    }}
                    width={contentSize.w - 50}
                    height={contentSize.h - 10}
                    {...props!}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return previewMode ? (
    <ReportInPreviewMode
      printableItems={printableItems}
      a4Size={a4Size[pageOrientation]}
      orientation={pageOrientation}
      headers={headers}
    />
  ) : (
    renderContent()
  );
};

//todo: make cleaner
const ReportInPreviewMode = (props: {
  printableItems: PrintableItem<any>[];
  a4Size: { w: number; h: number };
  headers: TransportHeaderData[];
  orientation: Orientation;
}) => {
  const { t } = useTranslation();
  const { printableItems, headers } = props;
  const previewContainerRef = createRef<HTMLUListElement>();
  const modalWidth = window.innerWidth - 48 - 25;
  const componentWidth = modalWidth > 1050 ? 1050 : modalWidth;
  return (
    <Row justify="center" style={{ width: "100%" }}>
      <ul
        ref={previewContainerRef}
        style={{
          width: "100%",
          maxWidth: "100%",
          listStyle: "none",
          margin: 0,
          padding: 0
        }}
      >
        {printableItems.map((item, iterator) => {
          const { Component, props } = item;
          const reportType = headers[iterator].reportType ?? "";
          return (
            <li
              key={iterator}
              style={{
                marginBlock: size.l1,
                marginInline: "auto",
                padding: 0,
                maxWidth: componentWidth
              }}
            >
              <div style={{ paddingBottom: size.s1 }}>
                <Text strong>{t(reportType)}</Text>
              </div>
              <Component width={componentWidth} {...props!} />
            </li>
          );
        })}
      </ul>
    </Row>
  );
};
