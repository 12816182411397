import React from "react";
import {
  Row,
  Col,
  Typography,
  DatePicker,
  InputNumber,
  Card,
  Switch,
  Space,
  Popover,
  Divider
} from "antd";
import { isNil } from "lodash-es";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRecordingParameters,
  addSchedulerBlock,
  removeSchedulerBlock,
  setSchedulerBlockDuration,
  setSchedulerFlightMode,
  setSchedulerUseLteFlightMode,
  setSchedulerUseGpsFlightMode,
  setSchedulerUseLteInterval,
  setSchedulerUseGpsInterval
} from "../../state/openParxSlice";
import { size, hideBlock } from "../../helpers/pageHelper";
import { useTranslation } from "react-i18next";
import ParamsBuilderGps from "./ParamsBuilderGps";
import ParamsBuilderLte from "./ParamsBuilderLte";
import {
  DangerButtonSmall,
  NormalButtonSmall,
  PrimaryButton
} from "../Common/CommonButtons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import mobitronColors from "../../styles/mobitronColors";
import { FlightMode } from "../../icons";
import commonColors from "../../styles/commonColors";

const { Text } = Typography;

interface AvailableFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailableFeatures;
}
const ParamsBuilderScheduler: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const recordingParameters = useSelector(selectRecordingParameters);
  const { scheduleBlocks, RecParams } = recordingParameters;

  const { startRecordingType, startTimestamp } = RecParams;
  const hasStartDate = startRecordingType === "date" && !isNil(startTimestamp);

  const { hasLte, hasGps } = props.features;

  // Adds the durations from all schedule blocks until the given index
  const timeAtIndex = (index: number) => {
    let time = 0;
    for (let i = 0; i < index; i++) {
      time += scheduleBlocks[i].blockDuration;
    }
    return time;
  };

  // Sets the new duration by adding the new days and existing hours
  const setDurationDays = (index: number, days: number) => {
    const duration = scheduleBlocks[index].blockDuration;
    const totalDuration = (duration % 24) + days * 24;
    dispatch(setSchedulerBlockDuration({ index, value: totalDuration }));
  };

  // Sets the new duration by adding the new hours and existing days
  const setDurationHours = (index: number, hours: number) => {
    const duration = scheduleBlocks[index].blockDuration;
    const totalDuration = Math.floor(duration / 24) * 24 + hours;
    dispatch(setSchedulerBlockDuration({ index, value: totalDuration }));
  };

  // Sets the new duration by subtracting the start of the block from the date
  const setDurationDate = (index: number, date: Dayjs) => {
    if (startTimestamp) {
      const newDuration =
        (date.unix() - startTimestamp) / 3600 - timeAtIndex(index);
      dispatch(setSchedulerBlockDuration({ index, value: newDuration }));
    }
  };

  return (
    <>
      {scheduleBlocks.map((block, index) => {
        const durationDays = Math.floor(block.blockDuration / 24);
        const durationHours =
          Math.round((block.blockDuration % 24) * 100) / 100;
        const { flightModeLte } = block.LteParams;
        const { flightModeGps } = block.GpsParams;
        const { flightMode } = block;

        const lteDisabled = !hasLte || flightModeLte || flightMode;
        const periodicLTE = !lteDisabled && block.LteParams.useLteInterval;
        const {
          intervalDays: LteDays,
          intervalHours: LteHours,
          intervalMinutes: LteMinutes
        } = block.LteParams;

        const gpsDisabled = !hasGps || flightModeGps || flightMode;
        const periodicGPS = !gpsDisabled && block.GpsParams.useGpsInterval;
        const {
          intervalDays: GpsDays,
          intervalHours: GpsHours,
          intervalMinutes: GpsMinutes
        } = block.GpsParams;

        const timestampFormat = "YYYY-MM-DD, HH:mm";
        const flightModeActive =
          (hasLte && hasGps && flightMode) ||
          (hasLte && flightModeLte) ||
          (hasGps && flightModeGps);
        return (
          <React.Fragment key={index}>
            <Card
              style={{
                width: "100%",
                marginBottom: size.m1,
                borderColor: mobitronColors.softDarkGreen
              }}
              styles={{ body: { padding: size.m1, textAlign: "start" } }}
            >
              <Row gutter={size.s1} align="middle" justify="space-between">
                <Col flex="200px">
                  <div style={hasStartDate ? undefined : hideBlock}>
                    <div>
                      <Text>{t("start")}: </Text>
                      <Text strong>
                        {dayjs
                          .unix(startTimestamp!)
                          .add(timeAtIndex(index), "hours")
                          .format(timestampFormat)}
                      </Text>
                    </div>
                    <Space>
                      <div>
                        <Text>{t("end")}: </Text>
                        <Text strong>
                          {dayjs
                            .unix(startTimestamp!)
                            .add(
                              timeAtIndex(index) + block.blockDuration,
                              "hours"
                            )
                            .format(timestampFormat)}
                        </Text>
                      </div>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        content={
                          <>
                            <Space size={0} direction="vertical">
                              <Text strong>{t("endsAt")}</Text>
                              <DatePicker
                                showTime
                                value={dayjs
                                  .unix(startTimestamp!)
                                  .add(
                                    timeAtIndex(index) + block.blockDuration,
                                    "hours"
                                  )}
                                onChange={(date) =>
                                  isNil(date)
                                    ? {}
                                    : setDurationDate(index, date)
                                }
                                format="YYYY-MM-DD HH:mm"
                                allowClear={false}
                                style={{ marginBottom: size.m1 }}
                              />

                              <Text strong>{t("durationLabel")}</Text>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <Text>{t("genDays")}</Text>
                                </Col>
                                <Col span={12}>
                                  <Text>{t("genHours")}</Text>
                                </Col>
                              </Row>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <InputNumber
                                    size="middle"
                                    style={{ width: "80px" }}
                                    value={durationDays}
                                    onChange={(value) =>
                                      isNil(value)
                                        ? {}
                                        : setDurationDays(index, value)
                                    }
                                    min={0}
                                  />
                                </Col>
                                <Col span={12}>
                                  <InputNumber
                                    size="middle"
                                    style={{ width: "80px" }}
                                    value={durationHours}
                                    onChange={(value) =>
                                      isNil(value)
                                        ? {}
                                        : setDurationHours(index, value)
                                    }
                                    min={0}
                                    precision={2}
                                    step={0.5}
                                  />
                                </Col>
                              </Row>
                            </Space>
                          </>
                        }
                      >
                        <NormalButtonSmall icon={<EditOutlined />} />
                      </Popover>
                    </Space>
                  </div>
                  <div style={hasStartDate ? hideBlock : undefined}>
                    <div>
                      <Text>{t("startsAfter")}: </Text>
                      <Text strong>
                        {Math.floor(timeAtIndex(index) / 24)}d{" "}
                        {Math.round((timeAtIndex(index) % 24) * 100) / 100}h
                      </Text>
                    </div>
                    <Space>
                      <div>
                        <Text>{t("durationLabel")}: </Text>
                        <Text strong>
                          {durationDays}d {durationHours}h
                        </Text>
                      </div>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        content={
                          <>
                            <Space size={0} direction="vertical">
                              <Text strong>{t("durationLabel")}</Text>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <Text>{t("genDays")}</Text>
                                </Col>
                                <Col span={12}>
                                  <Text>{t("genHours")}</Text>
                                </Col>
                              </Row>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <InputNumber
                                    size="middle"
                                    style={{ width: "80px" }}
                                    value={durationDays}
                                    onChange={(value) =>
                                      isNil(value)
                                        ? {}
                                        : setDurationDays(index, value)
                                    }
                                    min={0}
                                  />
                                </Col>
                                <Col span={12}>
                                  <InputNumber
                                    size="middle"
                                    style={{ width: "80px" }}
                                    value={durationHours}
                                    onChange={(value) =>
                                      isNil(value)
                                        ? {}
                                        : setDurationHours(index, value)
                                    }
                                    min={0}
                                    precision={2}
                                    step={0.5}
                                  />
                                </Col>
                              </Row>
                            </Space>
                          </>
                        }
                      >
                        <NormalButtonSmall icon={<EditOutlined />} />
                      </Popover>
                    </Space>
                  </div>
                </Col>

                <Col flex="120px">
                  <Row justify="space-between" align="middle">
                    <Text strong>{t("PeriodicGps")}</Text>
                    <Switch
                      size="small"
                      title={t("RequiresGPS")}
                      checked={periodicGPS}
                      disabled={gpsDisabled}
                      onChange={(value) =>
                        dispatch(setSchedulerUseGpsInterval({ index, value }))
                      }
                    />
                  </Row>
                  <Row justify="space-between" align="middle">
                    {periodicGPS
                      ? GpsDays + "d " + GpsHours + "h " + GpsMinutes + "m"
                      : t("Inactive")}
                    <Popover
                      placement="bottomRight"
                      content={
                        <ParamsBuilderGps index={index} features={{ hasGps }} />
                      }
                      trigger="click"
                    >
                      <NormalButtonSmall icon={<EditOutlined />} />
                    </Popover>
                  </Row>
                </Col>
                <Col flex="120px">
                  <Row justify="space-between" align="middle">
                    <Text strong>{t("PeriodicLTE")}</Text>
                    <Switch
                      size="small"
                      title={t("RequiresLTE")}
                      checked={periodicLTE}
                      disabled={lteDisabled}
                      onChange={(value) =>
                        dispatch(setSchedulerUseLteInterval({ index, value }))
                      }
                    />
                  </Row>
                  <Row justify="space-between" align="middle">
                    {periodicLTE
                      ? LteDays + "d " + LteHours + "h " + LteMinutes + "m"
                      : t("Inactive")}
                    <Popover
                      placement="bottomRight"
                      content={
                        <ParamsBuilderLte
                          index={index}
                          features={{ hasLte, hasGps }}
                        />
                      }
                      trigger="click"
                    >
                      <NormalButtonSmall icon={<EditOutlined />} />
                    </Popover>
                  </Row>
                </Col>
                <Col flex="125px">
                  <Space>
                    <Popover
                      placement="bottom"
                      trigger="click"
                      content={
                        <Space direction="vertical">
                          <Row justify="space-between" align="middle">
                            <Text style={{ marginRight: size.s1 }}>
                              {t("flightMode")}
                            </Text>
                            <Switch
                              checked={(hasLte || hasGps) && block.flightMode}
                              disabled={!hasLte && !hasGps}
                              onChange={(value) =>
                                dispatch(
                                  setSchedulerFlightMode({ index, value })
                                )
                              }
                              size="small"
                              title={t("DisablesAllRadio")}
                            />
                          </Row>
                          <Divider style={{ margin: 0 }} />
                          <Row justify="space-between" align="middle">
                            <Text>{t("BlockLte")}</Text>
                            <Switch
                              disabled={block.flightMode || !hasLte}
                              checked={
                                hasLte &&
                                (block.LteParams.flightModeLte ||
                                  block.flightMode)
                              }
                              onChange={(value) =>
                                dispatch(
                                  setSchedulerUseLteFlightMode({ index, value })
                                )
                              }
                              size="small"
                              title={hasLte ? t("BlocksLte") : t("RequiresLTE")}
                            />
                          </Row>
                          <Row justify="space-between" align="middle">
                            <Text>{t("BlockGps")}</Text>
                            <Switch
                              disabled={block.flightMode || !hasGps}
                              checked={
                                hasGps &&
                                (block.GpsParams.flightModeGps ||
                                  block.flightMode)
                              }
                              onChange={(value) =>
                                dispatch(
                                  setSchedulerUseGpsFlightMode({ index, value })
                                )
                              }
                              size="small"
                              title={hasGps ? t("BlocksGps") : t("RequiresGPS")}
                            />
                          </Row>
                        </Space>
                      }
                    >
                      <NormalButtonSmall
                        style={{
                          fill: flightModeActive
                            ? commonColors.menuColor
                            : commonColors.disabledText
                        }}
                      >
                        <div className="anticon">
                          <FlightMode />
                        </div>
                      </NormalButtonSmall>
                    </Popover>
                    <DangerButtonSmall
                      disabled={scheduleBlocks.length <= 1}
                      onClick={() => {
                        dispatch(removeSchedulerBlock(index));
                      }}
                    >
                      <DeleteOutlined />
                    </DangerButtonSmall>
                  </Space>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        );
      })}
      <div style={{ width: "100%", textAlign: "center" }}>
        <PrimaryButton onClick={() => dispatch(addSchedulerBlock())}>
          {t("AddRecordingBlock")}
        </PrimaryButton>
      </div>
    </>
  );
};

export default ParamsBuilderScheduler;
