import { skipToken } from "@reduxjs/toolkit/query";
import { Buffer } from "buffer";
import { isUndefined } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { impersonateBannerHeight } from "../../helpers/pageHelper";
import { useDownloadSerializedDataQuery } from "../../state/cargologRestApi";
import { unpackFile } from "../../state/compareGraphsSlice";
import {
  RecentFile,
  appendRecentOnlineDatxFile,
  selectImpersonate,
  selectRecentDatxFiles
} from "../../state/persistantStateSlice";
import { NormalSideMenuCard } from "../Common/CommonCards";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import OnlineFiles from "../MicroComponents/OnlineFiles";
import OpenCompareFiles from "../MicroComponents/OpenCompareFiles";
import OpenFileButton from "../MicroComponents/OpenFileButton";
import RecentFiles from "../MicroComponents/RecentFiles";
import { GeneralSideMenuArrow } from "../MicroComponents/GeneralSideMenuArrow";

interface IProps {
  isExpanded?: boolean;
  isFloating?: boolean;
  width?: number;
  style?: React.CSSProperties;
}

export interface IDatxToDownload {
  fileId: string;
  fileName: string;
}

const CompareGraphSideMenu: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);
  const recentFiles = useSelector(selectRecentDatxFiles);

  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();
  const [lastIdToDownload, setLastIdToDownload] = useState<string>();

  // downloads the selected file from the server
  const { data: selectedDatxFile } = useDownloadSerializedDataQuery(
    datxToDownload ? { parameterId: datxToDownload.fileId } : skipToken
  );

  // If the file is already cached
  useEffect(() => {
    if (
      !isUndefined(selectedDatxFile) &&
      !isUndefined(datxToDownload) &&
      datxToDownload.fileId === lastIdToDownload
    ) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(unpackFile({ filePath: datxToDownload.fileName, rawData }));
      setDatxToDownload(undefined);
    }
  }, [datxToDownload]);

  // If the file is newly downloaded
  useEffect(() => {
    if (!isUndefined(selectedDatxFile) && !isUndefined(datxToDownload)) {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(unpackFile({ filePath: datxToDownload.fileName, rawData }));
      setLastIdToDownload(datxToDownload.fileId);
      setDatxToDownload(undefined);
    }
  }, [selectedDatxFile]);

  const openOnlineFile = (fileId: string, fileName: string) => {
    setDatxToDownload({ fileId, fileName });
    dispatch(appendRecentOnlineDatxFile({ fileId, fileName }));
  };

  const openRecentFile = async (recentFile: RecentFile) => {
    if (recentFile.fileType === "online") {
      setDatxToDownload({
        fileId: recentFile.fileId,
        fileName: recentFile.fileName
      });
    }
  };

  const [mouseInArea, setMouseInArea] = useState(false);

  return (
    <NormalSideMenuCard
      title={mouseInArea || props.isExpanded ? t("Compare") : undefined}
      isExpanded={mouseInArea || props.isExpanded}
      isFloating={props.isFloating}
      isImpersonating={isImpersonating}
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
      style={props.style}
    >
      <div
        style={{
          overflow: "auto",
          outline: "none",
          maxHeight: `calc(100vh - (48px + 65px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`
        }}
      >
        {!mouseInArea && !props.isExpanded ? (
          <GeneralSideMenuArrow
            page="compareGraph"
            isImpersonating={isImpersonating}
          />
        ) : (
          <>
            <OpenCompareFiles />

            <RecentFiles data={recentFiles} openFile={openRecentFile} />

            <OnlineFiles openFile={openOnlineFile} />
          </>
        )}
      </div>
    </NormalSideMenuCard>
  );
};

export default CompareGraphSideMenu;
