import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Col, Row, Typography } from "antd";
import {
  CaretRightOutlined,
  DownOutlined,
  HomeTwoTone,
  PauseOutlined,
  UpOutlined,
  VerticalRightOutlined
} from "@ant-design/icons";
import L, { LatLngTuple, Map } from "leaflet";
import {
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents
} from "react-leaflet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarkedMapKeys,
  selectDatxOptionalFiltersById,
  selectProcessedGpsData
} from "../../state/openDatxSlice";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import { deviceIcon } from "../DevicesPage/DeviceMap";
import MapTable from "./MapTable";
import ReactDOMServer from "react-dom/server";
import { throttle, isUndefined } from "lodash-es";
import { SmallTitle } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";
import DashboardMap from "../../assets/img/DashboardMap.png";
import { connectionColors } from "../../constants/colors";
import "../../styles/customLeaflet.css";
import { useResizeDetector } from "react-resize-detector";

const { Text } = Typography;

export interface ReportsGpsPosData {
  key: number;
  path: [number, number];
  status?: number;
}

export interface ReportsDeviceData {
  firstPos?: FirstPos;
  lastPos?: LastPos;
  serialNumber: string;
  gpsPosData: ReportsGpsPosData[];
  alarms?: number;
  project?: {
    id: string;
    name: string;
  };
  key: string;
}

interface FirstPos {
  firstPath?: LatLngTuple;
  firstStatus?: number;
}

interface LastPos {
  lastPath: LatLngTuple;
  lastStatus?: number;
}

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}

interface IClusterData {
  position: LatLngTuple; // [latitude, longitude]
  numberOfPoints: number; // number of points in cluster
  key: number;
}

const MapDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fileId, dragHandle, closeCard } = props;

  const { width, height, ref } = useResizeDetector();

  const {
    gpsData,
    positionsData,
    outerBounds,
    firstPositionKey,
    lastPositionKey,
    markedMapKeys,
    timezone
  } = useSelector(selectProcessedGpsData(fileId));
  const filters = useSelector(selectDatxOptionalFiltersById(fileId));
  const [mapState, setMapState] = useState<Map | null>(null);
  const [clusterData, setClusterData] = useState<IClusterData[]>([]);
  const [duplicatePositionKeys, setDuplicatePositionKeys] = useState<number[]>(
    []
  );

  // Get the absolute distance between two positions in display pixels
  const getDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) => {
    const p1 = mapState?.latLngToContainerPoint([lat1, lng1]) || { x: 0, y: 0 };
    const p2 = mapState?.latLngToContainerPoint([lat2, lng2]) || { x: 0, y: 0 };
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  };

  // Get clustered data
  const getClusteredData = () => {
    const clusterDistance = 20 * 20; // Squared in order to avoid using Math.sqrt
    let clusters: IClusterData[] = [];
    let duplicatePositions: number[] = [];
    // If two or more locations are within clusterDistance px of each other, add them to the same cluster.
    positionsData.forEach((data) => {
      if (data.position?.latitude && data.position?.longitude) {
        let index = 0;
        let foundCluster = false;
        const lat1 = data.position.latitude;
        const lng1 = data.position.longitude;
        while (!foundCluster && index < clusters.length) {
          const lat2 = clusters[index].position[0];
          const lng2 = clusters[index].position[1];
          if (getDistance(lat1, lng1, lat2, lng2) < clusterDistance) {
            clusters[index].numberOfPoints++;
            duplicatePositions.push(data.key);
            foundCluster = true;
          } else {
            index++;
          }
        }
        if (!foundCluster) {
          clusters.push({
            position: [data.position.latitude, data.position.longitude],
            numberOfPoints: 1,
            key: data.key
          });
        }
      }
    });
    // Remove clusters with only one point in it
    clusters = clusters.filter((cluster) => cluster.numberOfPoints > 1);
    return { clusters, duplicatePositions };
  };

  const updateClusters = () => {
    const { clusters, duplicatePositions } = getClusteredData();
    setClusterData(clusters);
    setDuplicatePositionKeys(duplicatePositions);
  };
  const MapHook = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        updateClusters();
      },
      mousedown: (e) => {
        // Prevents CC from crashing when "click-dragging" with scroll wheel button in the map
        if (e.originalEvent.button === 1) {
          e.originalEvent.preventDefault();
          return false;
        }
      }
    });
    return null;
  };
  useEffect(() => {
    updateClusters();
  }, [positionsData]);

  // The next key that has rowType: "gpsPosition"
  const getNextKey = (currentKey: number) => {
    let nextKey = currentKey + 1;
    let foundKey = false;
    while (!foundKey && nextKey < gpsData.length) {
      if (gpsData[nextKey].rowType.includes("gpsPosition")) {
        foundKey = true;
      } else {
        nextKey++;
      }
    }
    return foundKey ? nextKey : firstPositionKey;
  };

  // The previous key that has rowType: "gpsPosition"
  const getPrevKey = (currentKey: number) => {
    let prevKey = currentKey - 1;
    let foundKey = false;
    while (!foundKey && prevKey >= 0) {
      if (gpsData[prevKey].rowType.includes("gpsPosition")) {
        foundKey = true;
      } else {
        prevKey--;
      }
    }
    return foundKey ? prevKey : lastPositionKey;
  };

  const isMarkedKey = markedMapKeys.length === 1;
  const isFirstKey = markedMapKeys[0] === firstPositionKey;
  const isLastKey = markedMapKeys[0] === lastPositionKey;

  const getFirstKeyMark = (): number[] => {
    return !isUndefined(firstPositionKey) ? [firstPositionKey] : [];
  };

  const getLastKeyMark = (): number[] => {
    return !isUndefined(lastPositionKey) ? [lastPositionKey] : [];
  };

  const prevPos = useCallback(() => {
    const prevKey = getPrevKey(markedMapKeys[0]);
    const markedKeys = prevKey !== undefined ? [prevKey] : [];
    dispatch(setMarkedMapKeys({ fileId, markedKeys }));
  }, [markedMapKeys, dispatch, fileId]);

  const nextPos = useCallback(() => {
    if (markedMapKeys.length === 0) {
      const markedKeys = getFirstKeyMark();
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    } else {
      const nextKey = getNextKey(markedMapKeys[0]);
      const markedKeys = nextKey !== undefined ? [nextKey] : [];
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    }
  }, [markedMapKeys, dispatch, fileId]);

  const lastPos = () => {
    const markedKeys = getLastKeyMark();
    dispatch(setMarkedMapKeys({ fileId, markedKeys }));
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const startRoute = () => {
    const markedKeys = getFirstKeyMark();
    dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    setIsPlaying(true);
  };

  const pauseRoute = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  /** Function attached to keyboard arrow-keys events.
   * Used to move GPS position in map. */
  const keyDownCallback = useMemo(
    () =>
      throttle((evt: KeyboardEvent) => {
        if (evt.key === "ArrowDown") {
          evt.preventDefault();
          nextPos();
        } else if (evt.key === "ArrowUp") {
          evt.preventDefault();
          prevPos();
        } else if (evt.key === "Space") {
          evt.preventDefault();
          pauseRoute();
        }
      }, 200),
    [nextPos, prevPos, pauseRoute]
  );

  useEffect(() => {
    if (!isPlaying) {
      document.addEventListener("keydown", keyDownCallback, false);
      /** clean up when component unmounts */
      return () => {
        document.removeEventListener("keydown", keyDownCallback, false);
      };
    }
  }, [keyDownCallback]);

  const curPos = positionsData.find((pos) => pos.key === markedMapKeys[0]);
  let selectedLatLong: LatLngTuple | undefined = curPos?.position
    ? [curPos.position.latitude, curPos.position.longitude]
    : undefined;

  let center: LatLngTuple = selectedLatLong ?? [14, 14];
  useEffect(() => {
    if (isPlaying === true) {
      setTimeout(() => {
        nextPos();
      }, 300);
    }
  }, [isPlaying, markedMapKeys, nextPos]);

  // Set isPlaying to false when markedMapKeys has reached the end of the array
  if (markedMapKeys[0] === positionsData.length - 1 && isPlaying === true) {
    setIsPlaying(false);
  }

  // Only re-centering map when markedMapKey is outside of map view
  useEffect(() => {
    if (
      selectedLatLong &&
      mapState?.getBounds()?.contains(selectedLatLong) === false
    ) {
      mapState?.panTo(selectedLatLong);
    }
  }, [selectedLatLong]);

  // Adjust table width to fit the table with scrollbar
  const tableWidth = width ? Math.floor(width / 2) : 0;

  const isFiltered = filters.gps.hideSensorData || filters.gps.hideScheduleData;

  /** Map has a minHeight of 200px or else the same px-height as its row */
  const mapHeight = () => {
    if (height) {
      if (height < 200) {
        return 200;
      } else {
        return height;
      }
    } else {
      return "100%";
    }
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      tooltip: !isPlaying
        ? t("PlayTheRouteOnTheMap")
        : t("PauseTheRouteOnTheMap"),
      button: "custom",
      action: !isPlaying ? startRoute : pauseRoute,
      icon: !isPlaying ? <CaretRightOutlined /> : <PauseOutlined />
    },
    {
      title: "",
      tooltip: t("SelectPreviousPosition"),
      button: "custom",
      action: prevPos,
      disabled: isFirstKey || !isMarkedKey || isPlaying,
      icon: <UpOutlined />
    },
    {
      title: "",
      tooltip: t("SelectNextPosition"),
      button: "custom",
      action: nextPos,
      disabled: isLastKey || !isMarkedKey || isPlaying,
      icon: <DownOutlined />
    },
    {
      title: "",
      tooltip: t("SelectLastPosition"),
      button: "custom",
      action: lastPos,
      disabled: isLastKey || isPlaying,
      icon: <VerticalRightOutlined rotate={270} />
    },
    {
      title: "",
      button: "close",
      action: emptyFunction
    }
  ];

  // If saved card-size is smaller than its original size the map bounds messes up. invalidateSize prevents that.
  // This code also causes the application to crash when switching between DATX files as it tries to access a map that no longer exist.
  // useEffect(() => {
  //   mapState?.invalidateSize();
  // }, [currentHeight, currentWidth]);

  const linePositions: LatLngTuple[] = positionsData
    .filter((pos) => pos.position)
    .map((pos) => {
      return [pos.position!.latitude, pos.position!.longitude];
    });

  return (
    <>
      <MoveableCard
        title={t("Map")}
        dragHandle={dragHandle}
        closeMe={closeCard}
        extraButtons={extraButtons}
        fileId={fileId}
      >
        <Row
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
          id={"current"}
          ref={ref}
        >
          <Col span={12}>
            {outerBounds.length > 0 ? (
              <MapContainer
                style={{
                  width: "100%",
                  height: mapHeight()
                }}
                keyboard={false}
                center={outerBounds.length <= 1 ? center : undefined}
                bounds={outerBounds.length > 1 ? outerBounds : undefined}
                zoom={11}
                scrollWheelZoom={true}
                ref={setMapState}
              >
                <MapHook />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxNativeZoom={19}
                  maxZoom={20}
                />

                {/* Polyline */}
                <Polyline
                  pathOptions={{ opacity: 0.3 }}
                  positions={linePositions}
                  color={"#006600"}
                  pane="overlayPane"
                />

                {/* Dots, start and stop */}
                {positionsData.length > 0 &&
                  positionsData.map((position, index) => {
                    // Start marker
                    if (position.key === firstPositionKey && !isFiltered) {
                      const startMarker = new L.DivIcon({
                        iconAnchor: [10, 10],
                        popupAnchor: [0.5, -10],
                        html: ReactDOMServer.renderToString(
                          <HomeTwoTone
                            twoToneColor="#006600"
                            style={{ fontSize: 20 }}
                          />
                        ),
                        className: "custom-icon"
                      });
                      return (
                        <Marker
                          key={index}
                          position={[
                            position.position!.latitude,
                            position.position!.longitude
                          ]}
                          icon={startMarker}
                          pane={"markerPane"}
                          eventHandlers={{
                            click: () => {
                              dispatch(
                                setMarkedMapKeys({
                                  fileId,
                                  markedKeys: getFirstKeyMark()
                                })
                              );
                            }
                          }}
                        ></Marker>
                      );
                    }
                    // End marker
                    else if (position.key === lastPositionKey && !isFiltered) {
                      return (
                        <Marker
                          key={index}
                          position={[
                            position.position!.latitude,
                            position.position!.longitude
                          ]}
                          icon={deviceIcon}
                          pane={"markerPane"}
                          // zIndexOffset={100000}
                          eventHandlers={{
                            click: () => {
                              dispatch(
                                setMarkedMapKeys({
                                  fileId,
                                  markedKeys: getLastKeyMark()
                                })
                              );
                            }
                          }}
                        ></Marker>
                      );
                    }
                    // Normal markers
                    else if (!duplicatePositionKeys.includes(position.key)) {
                      return (
                        <div style={{ zIndex: 40 }} key={index}>
                          <CircleMarker
                            key={position.key}
                            center={[
                              position.position!.latitude,
                              position.position!.longitude
                            ]}
                            radius={3}
                            color={
                              position.alarm
                                ? connectionColors.sensor
                                : "#006600"
                            }
                            eventHandlers={{
                              click: () => {
                                dispatch(
                                  setMarkedMapKeys({
                                    fileId,
                                    markedKeys: [position.key]
                                  })
                                );
                              }
                            }}
                          ></CircleMarker>
                        </div>
                      );
                    } else {
                      return <React.Fragment key={index}></React.Fragment>;
                    }
                  })}

                {/* Marked position */}
                {curPos ? (
                  <CircleMarker
                    key={curPos.key}
                    center={selectedLatLong!}
                    color={curPos.alarm ? connectionColors.sensor : "#006600"}
                    pane={"popupPane"}
                  ></CircleMarker>
                ) : null}

                {/* Cluster position */}
                {clusterData &&
                  clusterData.length > 0 &&
                  clusterData.map((cluster) => {
                    let pillW = 16;
                    if (cluster.numberOfPoints < 10) {
                    } else if (cluster.numberOfPoints < 100) {
                      pillW = 20;
                    } else if (cluster.numberOfPoints < 1000) {
                      pillW = 24;
                    } else if (cluster.numberOfPoints < 10000) {
                      pillW = 28;
                    } else {
                      pillW = 32;
                    }

                    const customMarker = new L.DivIcon({
                      className: "counter-icon",
                      iconSize: [pillW * 2, 32],
                      iconAnchor: [pillW, 16],
                      html: cluster.numberOfPoints.toString()
                    });

                    return (
                      <Marker
                        key={cluster.key}
                        opacity={0.8}
                        position={cluster.position}
                        icon={customMarker}
                        zIndexOffset={cluster.numberOfPoints}
                        eventHandlers={{
                          click: () => {
                            dispatch(
                              setMarkedMapKeys({
                                fileId,
                                markedKeys: [cluster.key]
                              })
                            );
                          }
                        }}
                      ></Marker>
                    );
                  })}
              </MapContainer>
            ) : (
              // NO GPS DATA
              <div>
                <Row
                  justify="center"
                  align="middle"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "220px",
                    zIndex: 1040
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      padding: size.m1,
                      borderRadius: size.m1,
                      boxShadow: "0px 0px 75px 10px rgba(0,0,0,0.12)"
                    }}
                  >
                    <Row justify="center">
                      <SmallTitle style={{ marginBottom: 0 }}>
                        {t("LocationsNotFound")}
                      </SmallTitle>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <Text>{t("ThereAreNoGPSPositionsInThisFile")}</Text>
                    </Row>
                  </div>
                </Row>
                <div
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "220px"
                  }}
                >
                  <img
                    src={DashboardMap}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      filter: "blur(6px)",
                      zIndex: 1035
                    }}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col span={12}>
            {height && (
              <MapTable
                fileId={fileId}
                tableData={gpsData}
                markedMapKeys={markedMapKeys}
                isPlaying={isPlaying}
                tableHeight={height}
                tableWidth={tableWidth}
                timezone={timezone}
              />
            )}
          </Col>
        </Row>
      </MoveableCard>
    </>
  );
};

export default MapDashboardCard;
