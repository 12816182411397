import React from "react";
import { isNull } from "lodash-es";
import {
  LicenseWithCost,
  ProjectInvite,
  ProjectNotification,
  ProjectWithUserRights
} from "../../state/cargologRestApi";
import { peopleTableData } from "./PeopleTab";
import { CheckProjectRightsAccess } from "../MicroComponents/LicenseAccess";

export interface ActivePeopleData {
  project: ProjectWithUserRights;
  emailNotifications?: ProjectNotification[];
  removeUser?: (projectUserId: string, id?: string) => void;
}

export const activePeopleData = (props: ActivePeopleData) => {
  const { project, removeUser, emailNotifications } = props;

  const peopleData: peopleTableData[] = project.projectUsers
    ? project.projectUsers.map((user, index) => {
        const userEmailAlert = emailNotifications?.find(
          (notification) => notification.userId === user.user.userId
        );
        return {
          key: index,
          userData: {
            name: `${user.user.firstName ?? ""} ${user.user.lastName ?? ""}`,
            email: user.user.email ?? ""
          },
          companyName: user.user.company?.name ?? "",
          licenseActive: user.user.licenseNames ?? undefined,
          notifications: {
            notificationId: userEmailAlert?.id,
            userId: user.user.userId ?? "",
            type: userEmailAlert?.type ?? 0
          },
          action: {
            disabled: CheckProjectRightsAccess(project.userRights ?? [], "ADK")
              ? false
              : true,
            removeUser: () =>
              removeUser ? removeUser(user.id, userEmailAlert?.id) : undefined
          }
        };
      })
    : [];

  return peopleData;
};

export interface InvitedPeopleData {
  projectInvites: ProjectInvite[];
  project: ProjectWithUserRights;
  allLicenses: LicenseWithCost[];
  loggedInUserId?: string;
  withdrawInvite?: (request: { projectInviteId: string }) => void;
}

export const invitedPeopleData = (props: InvitedPeopleData) => {
  const {
    projectInvites,
    project,
    allLicenses,
    loggedInUserId,
    withdrawInvite
  } = props;

  const peopleData: peopleTableData[] = projectInvites
    ?.filter(
      (invite) =>
        invite.companyId === project.companyId &&
        isNull(invite.answered) &&
        invite.projectId === project.id
    )
    .map((invite, index) => {
      const selectedLicense = allLicenses?.find(
        (license) => license.id === invite.licenseId
      );

      return {
        key: index,
        userData: {
          name: `${invite.connectedUser?.firstName ?? ""} ${invite.connectedUser?.lastName ?? ""}`,
          email: invite.email ?? ""
        },
        companyName: "",
        licenseInvited: selectedLicense?.text ? selectedLicense.text : "",
        action: {
          disabled:
            invite.createdByUserId === loggedInUserId &&
            CheckProjectRightsAccess(project.userRights ?? [], "ADK")
              ? false
              : true,
          withdrawInvite: () =>
            withdrawInvite
              ? withdrawInvite({ projectInviteId: invite.id })
              : undefined
        }
      };
    });

  return peopleData;
};
