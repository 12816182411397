import { CheckOutlined, FilterFilled } from "@ant-design/icons";
import {
  Col,
  Input,
  List,
  Row,
  Space,
  Checkbox,
  Badge,
  Typography
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDashboardFilters,
  selectDatxOptionalFiltersById,
  setDataTimezone,
  toggleHideAccDataWithinAlarmLevel,
  toggleHideAngleDataWithinAlarmLevel,
  toggleHideRhDataWithinAlarmLevel,
  toggleHideTempDataWithinAlarmLevel,
  toggleHideXAccDataWithinAlarmLevel,
  toggleHideYAccDataWithinAlarmLevel,
  toggleHideZAccDataWithinAlarmLevel,
  toggleHideGpsStatusData,
  toggleHideGpsSensorData,
  toggleHideGpsScheduleData,
  toggleHidePressureRawDataWithinAlarmLevel,
  toggleHidePressureCompDataWithinAlarmLevel,
  DataFilterStates,
  selectExternalSensorParamsById,
  toggleHideExtSensorRhWithinAlarmLevel,
  toggleHideExtSensorTempWithinAlarmLevel
} from "../../state/openDatxSlice";
import mobitronColors from "../../styles/mobitronColors";
import { NormalButton } from "../Common/CommonButtons";
import { SmallText, SmallTitle } from "../Common/CommonFonts";
import MyDashboards from "./MyDashboards";
import { formatUtcOffset, getAllTzWithOffset } from "../../helpers/dateHelper";
import DashboardHeaderRangePicker from "./DashboardHeaderRangePicker";
import { selectGlobalTimezoneToggle } from "../../state/sessionSlice";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { Help } from "../MicroComponents/Help";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import dayjs from "dayjs";
import StandardModal from "../Common/StandardModal";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import { cloneDeep } from "lodash-es";

const { Text } = Typography;

/** Pick timezone */
const TimezoneButton = (props: { fileId: string; timezone: string }) => {
  const { timezone } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const [timeZoneFilter, setTimeZoneFilter] = useState("");

  const allTimezones = getAllTzWithOffset();

  const filteredTimeZones = allTimezones.filter((x) =>
    timeZoneFilter === "" ? true : x.name.toLowerCase().includes(timeZoneFilter)
  );

  const selectTimezone = (zone: string) => {
    const { fileId } = props;

    dispatch(setDataTimezone({ fileId, zone }));
  };

  const isActiveTimezone = (zone: string) => zone === timezone;

  const activeItemStyle: React.CSSProperties = {
    color: mobitronColors.altDarkGreen
  };

  const utcOffset = (tz: string) => {
    const offset = dayjs().tz(tz).utcOffset() / 60;
    return offset >= 0 ? `+${offset}` : offset;
  };

  return (
    <>
      <StandardModal
        title={t("SelectTimezone")}
        closable={true}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        zIndex={1045}
      >
        <div>
          <Input.Search
            placeholder={t("SearchForTimezone")}
            enterButton
            size="middle"
            value={timeZoneFilter}
            onChange={(e) => setTimeZoneFilter(e.currentTarget.value)}
            autoFocus={true}
          />

          <div>
            <List
              style={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
              dataSource={filteredTimeZones}
              renderItem={(item) => (
                <List.Item
                  key={item.name}
                  onClick={() => selectTimezone(item.name)}
                  style={{ cursor: "pointer" }}
                >
                  <SmallText
                    style={isActiveTimezone(item.name) ? activeItemStyle : {}}
                  >
                    {`${item.name} (UTC ${formatUtcOffset(item.offset)})`}
                  </SmallText>
                  <div style={{ paddingRight: size.s2 }}>
                    {isActiveTimezone(item.name) ? (
                      <CheckOutlined style={activeItemStyle} />
                    ) : null}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </StandardModal>
      <NormalButton onClick={() => setModalVisible(true)}>
        <Text strong>{t("Region")}</Text>:
        <Text>{timezone + ` (UTC ${utcOffset(timezone)})`}</Text>
      </NormalButton>
    </>
  );
};

interface SettingsModalProps {
  fileId: string;
  show: boolean;
  close: () => void;
}
/** Modal content */
const SettingsModal = (props: SettingsModalProps) => {
  const filters = useSelector(selectDatxOptionalFiltersById(props.fileId));
  const extSensorParams = useSelector(
    selectExternalSensorParamsById(props.fileId)
  );
  const dispatch = useDispatch();
  const [t] = useTranslation();

  // Note: Using local state so that no changes are made until the user presses
  // apply/ok for the filters

  const [hideXAccWithinAlarm, setHideXAccWithinAlarm] = useState(
    filters.xAcc.hideDataWithinAlarmLevel
  );
  const [hideYAccWithinAlarm, setHideYAccWithinAlarm] = useState(
    filters.yAcc.hideDataWithinAlarmLevel
  );
  const [hideZAccWithinAlarm, sethideZAccWithinAlarm] = useState(
    filters.zAcc.hideDataWithinAlarmLevel
  );

  const [hideTempDataWithinAlarm, setHideTempDataWithinAlarm] = useState(
    filters.temp.hideDataWithinAlarmLevel
  );

  const [hideRhDataWithinAlarm, setHideRhDataWithinAlarm] = useState(
    filters.rh.hideDataWithinAlarmLevel
  );

  const [hidePressureRawDataWithinAlarm, setHidePressureRawDataWithinAlarm] =
    useState(filters.pressureRaw.hideDataWithinAlarmLevel);

  const [hidePressureCompDataWithinAlarm, setHidePressureCompDataWithinAlarm] =
    useState(filters.pressureComp.hideDataWithinAlarmLevel);

  const [hideAngleWithinAlarm, setHideAngleWithinAlarm] = useState(
    filters.angle.hideDataWithinAlarmLevel
  );
  const [hideGpsStatusData, setHideGpsStatusData] = useState(
    filters.gps.hideStatusData
  );
  const [hideGpsSensorData, setHideGpsSensorData] = useState(
    filters.gps.hideSensorData
  );
  const [hideGpsScheduleData, setHideGpsScheduleData] = useState(
    filters.gps.hideScheduleData
  );

  const [externalRh, setExternalRhs] = useState(filters.extRh);

  const [externalTemp, setExternalTemps] = useState(filters.extTemp);

  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const hideExternalRhWithinAlarm = (sensorId: string) => {
    const extRhs = cloneDeep(externalRh);

    extRhs[sensorId].hideDataWithinAlarmLevel =
      !extRhs[sensorId].hideDataWithinAlarmLevel;

    setExternalRhs(extRhs);
  };

  const hideExternalTempWithinAlarm = (sensorId: string) => {
    const extTemps = cloneDeep(externalTemp);

    extTemps[sensorId].hideDataWithinAlarmLevel =
      !extTemps[sensorId].hideDataWithinAlarmLevel;

    setExternalTemps(extTemps);
  };

  /** True if any changes to the state compared to the global state */
  const filtersHasChanged =
    hideXAccWithinAlarm !== filters.xAcc.hideDataWithinAlarmLevel ||
    hideYAccWithinAlarm !== filters.yAcc.hideDataWithinAlarmLevel ||
    hideZAccWithinAlarm !== filters.zAcc.hideDataWithinAlarmLevel ||
    hideTempDataWithinAlarm !== filters.temp.hideDataWithinAlarmLevel ||
    hideRhDataWithinAlarm !== filters.rh.hideDataWithinAlarmLevel ||
    hidePressureRawDataWithinAlarm !==
      filters.pressureRaw.hideDataWithinAlarmLevel ||
    hidePressureCompDataWithinAlarm !==
      filters.pressureComp.hideDataWithinAlarmLevel ||
    hideAngleWithinAlarm !== filters.angle.hideDataWithinAlarmLevel ||
    hideGpsStatusData !== filters.gps.hideStatusData ||
    hideGpsSensorData !== filters.gps.hideSensorData ||
    hideGpsScheduleData !== filters.gps.hideScheduleData ||
    externalRh !== filters.extRh ||
    externalTemp !== filters.extTemp;

  // hideExternalRhWithinAlarm !== filters.extRh.hideDataWithinAlarmLevel ||
  // hideExternalTempWithinAlarm !== filters.extTemp.hideDataWithinAlarmLevel;

  /** Apply selected filters to the graph */
  const applyFilters = () => {
    setIsApplyingFilters(true);

    hideXAccWithinAlarm !== filters.xAcc.hideDataWithinAlarmLevel &&
      dispatch(toggleHideXAccDataWithinAlarmLevel({ id: props.fileId }));
    hideYAccWithinAlarm !== filters.yAcc.hideDataWithinAlarmLevel &&
      dispatch(toggleHideYAccDataWithinAlarmLevel({ id: props.fileId }));
    hideZAccWithinAlarm !== filters.zAcc.hideDataWithinAlarmLevel &&
      dispatch(toggleHideZAccDataWithinAlarmLevel({ id: props.fileId }));

    hideTempDataWithinAlarm !== filters.temp.hideDataWithinAlarmLevel &&
      dispatch(toggleHideTempDataWithinAlarmLevel({ fileId: props.fileId }));

    hideRhDataWithinAlarm !== filters.rh.hideDataWithinAlarmLevel &&
      dispatch(toggleHideRhDataWithinAlarmLevel({ fileId: props.fileId }));

    hidePressureRawDataWithinAlarm !==
      filters.pressureRaw.hideDataWithinAlarmLevel &&
      dispatch(
        toggleHidePressureRawDataWithinAlarmLevel({ fileId: props.fileId })
      );
    hidePressureCompDataWithinAlarm !==
      filters.pressureComp.hideDataWithinAlarmLevel &&
      dispatch(
        toggleHidePressureCompDataWithinAlarmLevel({ fileId: props.fileId })
      );

    hideAngleWithinAlarm !== filters.angle.hideDataWithinAlarmLevel &&
      dispatch(toggleHideAngleDataWithinAlarmLevel({ fileId: props.fileId }));

    hideGpsStatusData !== filters.gps.hideStatusData &&
      dispatch(toggleHideGpsStatusData({ fileId: props.fileId }));
    hideGpsSensorData !== filters.gps.hideSensorData &&
      dispatch(toggleHideGpsSensorData({ fileId: props.fileId }));
    hideGpsScheduleData !== filters.gps.hideScheduleData &&
      dispatch(toggleHideGpsScheduleData({ fileId: props.fileId }));

    Object.keys(externalRh).map((sensorId) => {
      const extRh = externalRh[sensorId];
      extRh.hideDataWithinAlarmLevel !==
        filters.extRh[sensorId].hideDataWithinAlarmLevel &&
        dispatch(
          toggleHideExtSensorRhWithinAlarmLevel({
            fileId: props.fileId,
            target: sensorId
          })
        );
    });

    Object.keys(externalTemp).map((sensorId) => {
      const extTemp = externalTemp[sensorId];
      extTemp.hideDataWithinAlarmLevel !==
        filters.extTemp[sensorId].hideDataWithinAlarmLevel &&
        dispatch(
          toggleHideExtSensorTempWithinAlarmLevel({
            fileId: props.fileId,
            target: sensorId
          })
        );
    });

    props.close();
    setIsApplyingFilters(false);
  };

  const renderAccFilters = () => (
    <>
      <SmallTitle>{t("AccelerationData")}</SmallTitle>
      <Checkbox
        checked={
          hideXAccWithinAlarm && hideYAccWithinAlarm && hideZAccWithinAlarm
        }
        onChange={() => {
          setHideXAccWithinAlarm((z) => !z);
          setHideYAccWithinAlarm((z) => !z);
          sethideZAccWithinAlarm((z) => !z);
        }}
        style={{ paddingBottom: size.m1 }}
      >
        {t("HideAccelerationsWithinAlarmLimit")}
      </Checkbox>
    </>
  );

  const renderTempFilters = () => (
    <>
      <SmallTitle>{t("TemperatureData")}</SmallTitle>
      <Checkbox
        checked={hideTempDataWithinAlarm}
        onChange={() => {
          setHideTempDataWithinAlarm((z) => !z);
        }}
        style={{ paddingBottom: size.m1 }}
      >
        {t("HideTemperatureDataWithinAlarmLimit")}
      </Checkbox>
    </>
  );

  const renderRhData = () => (
    <>
      <SmallTitle>{t("HumidityData")}</SmallTitle>
      <Checkbox
        checked={hideRhDataWithinAlarm}
        onChange={() => {
          setHideRhDataWithinAlarm((z) => !z);
        }}
        style={{ paddingBottom: size.m1 }}
      >
        {t("HideHumidityDataWithinAlarmLimit")}
      </Checkbox>
    </>
  );

  const renderPressureFilters = () => (
    <>
      <SmallTitle>{t("PressureData")}</SmallTitle>
      <Checkbox
        checked={hideRhDataWithinAlarm}
        onChange={() => {
          setHidePressureRawDataWithinAlarm((z) => !z);
          setHidePressureCompDataWithinAlarm((z) => !z);
        }}
        style={{ paddingBottom: size.m1 }}
      >
        {t("HidePressureDataWithinAlarmLimit")}
      </Checkbox>
    </>
  );

  const renderAngleFilters = () => (
    <>
      <SmallTitle>{t("AngleData")}</SmallTitle>
      <Checkbox
        checked={hideAngleWithinAlarm}
        onChange={() => {
          setHideAngleWithinAlarm((z) => !z);
        }}
        style={{ paddingBottom: size.m1 }}
      >
        {t("HideAngleDataWithinAlarmLimit")}
      </Checkbox>
    </>
  );
  const renderGpsFilters = () => (
    <>
      <SmallTitle>{t("GpsData")}</SmallTitle>
      <div>
        <Checkbox
          checked={hideGpsStatusData}
          onChange={() => {
            setHideGpsStatusData((z) => !z);
          }}
          style={{ paddingBottom: size.m1 }}
        >
          {t("HideGPSStatuses")}
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={hideGpsSensorData}
          onChange={() => {
            setHideGpsSensorData((z) => !z);
          }}
          style={{ paddingBottom: size.m1 }}
        >
          {t("HideGPSPositionsTriggeredBySensor")}
        </Checkbox>
      </div>
      <div>
        <Checkbox
          checked={hideGpsScheduleData}
          onChange={() => {
            setHideGpsScheduleData((z) => !z);
          }}
          style={{ paddingBottom: size.m1 }}
        >
          {t("HideGPSPositionsTriggeredBySchedule")}
        </Checkbox>
      </div>
    </>
  );
  const renderExternalSensors = () => (
    <>
      <SmallTitle>{t("ExternalSensors")}</SmallTitle>
      {Object.keys(externalRh).map((sensorId, index) => {
        const extRh = externalRh[sensorId];
        const sensor = extSensorParams.find(
          (sensor) => sensor.params.sensorTypeId.toString() === sensorId
        );
        const sensorName =
          t("ExternalHumidity") +
          " " +
          t("NumberAbbrevation") +
          " " +
          getSensorNrFromId(Number(sensorId)) +
          (sensor ? " (" + sensor?.params.sensorName + ")" : "");

        return (
          <div key={index}>
            <Checkbox
              checked={extRh.hideDataWithinAlarmLevel}
              onChange={() => {
                hideExternalRhWithinAlarm(sensorId);
              }}
              style={{ paddingBottom: size.m1 }}
            >
              {t("HideDataWithinAlarmLimitFor")}
              <br /> {sensorName}
            </Checkbox>
          </div>
        );
      })}

      {Object.keys(externalTemp).map((sensorId, index) => {
        const extTemp = externalTemp[sensorId];
        const sensor = extSensorParams.find(
          (sensor) => sensor.params.sensorTypeId.toString() === sensorId
        );
        const sensorName =
          t("ExternalTemperature") +
          " " +
          t("NumberAbbrevation") +
          " " +
          getSensorNrFromId(Number(sensorId)) +
          (sensor ? " (" + sensor?.params.sensorName + ")" : "");

        return (
          <div key={index}>
            <Checkbox
              checked={extTemp.hideDataWithinAlarmLevel}
              onChange={() => {
                hideExternalTempWithinAlarm(sensorId);
              }}
              style={{ paddingBottom: size.m1 }}
            >
              {t("HideDataWithinAlarmLimitFor")}
              <br /> {sensorName}
            </Checkbox>
          </div>
        );
      })}
    </>
  );

  // Note: Re-syncing local component state with redux every time the modal opens
  useEffect(() => {
    if (props.show) {
      setHideXAccWithinAlarm(filters.xAcc.hideDataWithinAlarmLevel);
      setHideYAccWithinAlarm(filters.yAcc.hideDataWithinAlarmLevel);
      sethideZAccWithinAlarm(filters.zAcc.hideDataWithinAlarmLevel);

      setHideTempDataWithinAlarm(filters.temp.hideDataWithinAlarmLevel);
      setHideRhDataWithinAlarm(filters.rh.hideDataWithinAlarmLevel);
      setHidePressureRawDataWithinAlarm(
        filters.pressureRaw.hideDataWithinAlarmLevel
      );
      setHidePressureCompDataWithinAlarm(
        filters.pressureComp.hideDataWithinAlarmLevel
      );

      setHideAngleWithinAlarm(filters.angle.hideDataWithinAlarmLevel);
      setHideGpsStatusData(filters.gps.hideStatusData);
      setHideGpsSensorData(filters.gps.hideSensorData);
      setHideGpsScheduleData(filters.gps.hideScheduleData);
    }
  }, [props.show, filters]);

  return (
    <StandardModal
      title={t("DashboardFilters")}
      open={props.show}
      onOk={applyFilters}
      okButtonProps={{ disabled: !filtersHasChanged }}
      confirmLoading={isApplyingFilters}
      onCancel={props.close}
      closable={false}
      zIndex={1045}
    >
      <div>
        {(filters.xAcc.dataToggle.isUsed ||
          filters.yAcc.dataToggle.isUsed ||
          filters.zAcc.dataToggle.isUsed) &&
          renderAccFilters()}
        {filters.temp.dataToggle.isUsed && renderTempFilters()}
        {filters.rh.dataToggle.isUsed && renderRhData()}
        {(filters.pressureRaw.dataToggle.isUsed ||
          filters.pressureComp.dataToggle.isUsed) &&
          renderPressureFilters()}
        {filters.angle.dataToggle.isUsed && renderAngleFilters()}
        {filters.gps.dataToggle.isUsed && renderGpsFilters()}

        {Object.entries(filters.extRh).some(
          (extRh) => extRh[1].dataToggle.isUsed === true
        ) &&
          Object.entries(filters.extTemp).some(
            (extTemp) => extTemp[1].dataToggle.isUsed === true
          ) &&
          renderExternalSensors()}
      </div>
    </StandardModal>
  );
};

/** A small row of the applied tags */
const countFilters = (filters: DataFilterStates) => {
  let count = 0;
  // Acceleration
  count +=
    (filters.xAcc.dataToggle.isActive &&
      filters.xAcc.hideDataWithinAlarmLevel) ||
    (filters.yAcc.dataToggle.isActive &&
      filters.yAcc.hideDataWithinAlarmLevel) ||
    (filters.zAcc.dataToggle.isActive && filters.zAcc.hideDataWithinAlarmLevel)
      ? 1
      : 0;
  // Temperature
  count +=
    filters.temp.dataToggle.isActive && filters.temp.hideDataWithinAlarmLevel
      ? 1
      : 0;
  // Humidity
  count +=
    filters.rh.dataToggle.isActive && filters.rh.hideDataWithinAlarmLevel
      ? 1
      : 0;
  // Raw Pressure
  count +=
    filters.pressureRaw.dataToggle.isActive &&
    filters.pressureRaw.hideDataWithinAlarmLevel
      ? 1
      : 0;
  // Compensated Pressure
  count +=
    filters.pressureComp.dataToggle.isActive &&
    filters.pressureComp.hideDataWithinAlarmLevel
      ? 1
      : 0;
  // Angle
  count +=
    filters.angle.dataToggle.isActive && filters.angle.hideDataWithinAlarmLevel
      ? 1
      : 0;
  // GPS
  if (filters.gps.dataToggle.isActive) {
    if (filters.gps.hideStatusData) count++;
    if (filters.gps.hideSensorData) count++;
    if (filters.gps.hideScheduleData) count++;
  }
  //External Sensors
  count += Object.entries(filters.extRh).filter(
    (extRh) => extRh[1].dataToggle.isActive && extRh[1].hideDataWithinAlarmLevel
  ).length;
  count += Object.entries(filters.extTemp).filter(
    (extTemp) =>
      extTemp[1].dataToggle.isActive && extTemp[1].hideDataWithinAlarmLevel
  ).length;

  return count;
};

interface IProps {
  fileId: string;
  showLayoutDrawer: () => void;
}
/* Main exported component */
const DashboardExtraHeader: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [showSettings, setShowSettings] = useState(false);
  const { optionalFilters, timezone } = useSelector(
    selectDashboardFilters(props.fileId)
  );
  const globalTimezone = useSelector(selectGlobalTimezoneToggle);

  const filterCount = countFilters(optionalFilters);
  return (
    <>
      <Row align="middle" wrap={false}>
        <Col flex="auto">
          <Space>
            {!globalTimezone && (
              <TimezoneButton fileId={props.fileId} timezone={timezone} />
            )}
            <DashboardHeaderRangePicker fileId={props.fileId} />
            <Badge count={filterCount}>
              <NormalButton
                icon={<FilterFilled />}
                onClick={(e) => setShowSettings((z) => !z)}
              >
                {t("FilterReport")}
              </NormalButton>
            </Badge>
          </Space>
        </Col>

        {LicenseAccess(
          "ADA",
          <Col flex="none">
            {Help(
              <Space direction="horizontal" align="center">
                <SmallText>{t("ManageReport")}: </SmallText>
                <MyDashboards
                  showLayoutDrawer={props.showLayoutDrawer}
                  fileId={props.fileId}
                />
              </Space>,
              t("ClickHereToCreateACustomReport"),
              "left"
            )}
          </Col>
        )}
      </Row>
      <SettingsModal
        fileId={props.fileId}
        show={showSettings}
        close={() => setShowSettings(false)}
      />
    </>
  );
};

export default DashboardExtraHeader;
