import React from "react";
import {
  VictoryChart,
  VictoryTheme,
  VictoryBar,
  VictoryLine,
  VictoryClipContainer,
  VictoryGroup,
  VictorySelectionContainer,
  VictoryBrushContainer,
  VictoryAxis,
  VictoryLabel,
  VictoryScatter
} from "victory";
import { VictoryTickStyleObject } from "victory-core";
import { iconColors } from "../../constants/colors";
import { unix2Date } from "../../helpers/dataModelHelper";
import {
  dateAxisFormater,
  formatYData,
  generateTickValues,
  getNormalizerFunctions,
  graphAxisScaleSelector,
  toLowHighOrder,
  yAxisDefaultNormalizedValues,
  yAxisFormater
} from "../../helpers/graphHelper";
import {
  CompareStateZoomDomain,
  ICompareGraph,
  selectGlobalSelectedDomain,
  YAxisLowHigh
} from "../../state/compareGraphsSlice";
import { useSelector } from "react-redux";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import {
  selectGlobalGraphScale,
  selectGraphAxisTickCountTime
} from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle,
  selectTemperatureScale
} from "../../state/sessionSlice";
import {
  TemperatureToScale,
  TempScaleSymbol
} from "../MicroComponents/TemperatureConverter";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { Nullable } from "../../helpers/datasetHelper";
import { ceil, floor, isUndefined, maxBy, minBy } from "lodash-es";
import {
  getDataInDomain,
  IDataPoint,
  reduceDataPoints
} from "../../helpers/compareGraphsHelper";
import { guessLocalTz } from "../../helpers/dateHelper";
import { size } from "../../helpers/pageHelper";

interface IProps {
  data: ICompareGraph[];
  width: number;
  height: number;
  handleSelectedDomain: (domain: CompareStateZoomDomain | undefined) => void;
}

const PrimaryGraphsOnTop: React.FC<IProps> = (props) => {
  const { data } = props;

  const selectedDomain = useSelector(selectGlobalSelectedDomain());

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);
  const tempScale = useSelector(selectTemperatureScale);
  const globalScale = useSelector(selectGlobalGraphScale);

  const localTimezone = useSelector(guessLocalTz);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  let xOffsetYaxis = [40, 90, 140, 190];
  const getYAxisOffset = () => xOffsetYaxis.pop();

  // Calculate the new data points for the graphs
  const quickGraphData = React.useMemo(
    () =>
      data.map((graph) => {
        const channels = graph.activeChannels;
        const startTimeDiff =
          graph.startTime && graph.dataDomain
            ? graph.startTime - graph.dataDomain[0]
            : 0;

        const reducedPrimaryData = graph.compareGraphContent.data.filter(
          (dataPoint) =>
            (channels.includes("x") &&
              dataPoint.xAcc &&
              dataPoint.xAcc[1] > 0) ||
            (channels.includes("y") &&
              dataPoint.yAcc &&
              dataPoint.yAcc[1] > 0) ||
            (channels.includes("z") &&
              dataPoint.zAcc &&
              dataPoint.zAcc[1] > 0) ||
            (channels.includes("rh") && dataPoint.rh) ||
            (channels.includes("temp") && dataPoint.temp) ||
            (channels.includes("pressureRaw") && dataPoint.pressureRaw) ||
            (channels.includes("pressureComp") && dataPoint.pressureComp)
        );
        const offsetSeconds = graph.offsetMs ? graph.offsetMs / 1000 : 0;

        const xAcc: IDataPoint[] = reducedPrimaryData
          .filter((e) => e.xAcc && e.xAcc[1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.xAcc![0],
              d: e.xAcc![1]
            };
          });
        const yAcc: IDataPoint[] = reducedPrimaryData
          .filter((e) => e.yAcc && e.yAcc[1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.yAcc![0],
              d: e.yAcc![1]
            };
          });
        const zAcc: IDataPoint[] = reducedPrimaryData
          .filter((e) => e.zAcc && e.zAcc![1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.zAcc![0],
              d: e.zAcc![1]
            };
          });
        const rh = reducedPrimaryData
          .filter((e) => e.rh)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.rh
            };
          });
        const temp = reducedPrimaryData
          .filter((e) => e.temp)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.temp
            };
          });
        const pressureRaw = reducedPrimaryData
          .filter((e) => e.pressureRaw)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.pressureRaw
            };
          });
        const pressureComp = reducedPrimaryData
          .filter((e) => e.pressureComp)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.pressureComp
            };
          });

        const newGraph = {
          id: graph.id,
          primaryData: {
            xAcc,
            yAcc,
            zAcc,
            rh,
            temp,
            pressureRaw,
            pressureComp
          },
          metaData: {
            activeGraphType: graph.activeGraphType,
            activeChannels: graph.activeChannels,
            startTime: graph.startTime,
            offsetMs: graph.offsetMs,
            dataDomain: graph.dataDomain
          }
        };
        return newGraph;
      }),
    [data]
  );

  // Create performant data for primary graph
  const primaryData = React.useMemo(
    () =>
      quickGraphData.map((graph) => {
        const { primaryData } = graph;
        const trimmedData = () => {
          if (isUndefined(selectedDomain)) {
            return primaryData;
          } else {
            return {
              xAcc: getDataInDomain(primaryData.xAcc, selectedDomain.x),
              yAcc: getDataInDomain(primaryData.yAcc, selectedDomain.x),
              zAcc: getDataInDomain(primaryData.zAcc, selectedDomain.x)
            };
          }
        };

        return {
          ...graph,
          primaryData: {
            xAcc: reduceDataPoints(trimmedData().xAcc, 300),
            yAcc: reduceDataPoints(trimmedData().yAcc, 300),
            zAcc: reduceDataPoints(trimmedData().zAcc, 300),
            rh: primaryData.rh,
            temp: primaryData.temp,
            pressureRaw: primaryData.pressureRaw,
            pressureComp: primaryData.pressureComp
          }
        };
      }),
    [selectedDomain, quickGraphData]
  );

  // Create performant data for overview graph
  const overviewData = React.useMemo(
    () =>
      quickGraphData.map((graph) => {
        return {
          ...graph,
          primaryData: {
            xAcc: reduceDataPoints(graph.primaryData.xAcc, 150),
            yAcc: reduceDataPoints(graph.primaryData.yAcc, 150),
            zAcc: reduceDataPoints(graph.primaryData.zAcc, 150),
            rh: graph.primaryData.rh,
            temp: graph.primaryData.temp,
            pressureRaw: graph.primaryData.pressureRaw,
            pressureComp: graph.primaryData.pressureComp
          }
        };
      }),
    [quickGraphData]
  );

  // Find largest domain for all graphs and active channels
  let fullDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setMinMax = (timestamp: number) => {
    fullDomain[0] = Math.min(fullDomain[0], timestamp);
    fullDomain[1] = Math.max(fullDomain[1], timestamp);
  };

  let accDomain: YAxisLowHigh = [0, 0];
  const setAccMinMax = (value: number) => {
    accDomain[0] = Math.min(accDomain[0], value);
    accDomain[1] = Math.max(accDomain[1], value);
  };

  let tempDomain: YAxisLowHigh = [
    Number.MAX_SAFE_INTEGER,
    Number.MIN_SAFE_INTEGER
  ];
  const setTempMinMax = (value: number) => {
    tempDomain[0] = Math.min(tempDomain[0], value);
    tempDomain[1] = Math.max(tempDomain[1], value);
  };

  let rhDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setRhMinMax = (value: number) => {
    rhDomain[0] = Math.min(rhDomain[0], value);
    rhDomain[1] = Math.max(rhDomain[1], value);
  };

  let pressureDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setPressureMinMax = (value: number) => {
    pressureDomain[0] = Math.min(pressureDomain[0], value);
    pressureDomain[1] = Math.max(pressureDomain[1], value);
  };

  overviewData.forEach((file) => {
    // X-values
    file.primaryData.xAcc.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.yAcc.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.zAcc.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.temp.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.rh.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.pressureRaw.forEach((a) => setMinMax(a.x.getTime()));
    file.primaryData.pressureComp.forEach((a) => setMinMax(a.x.getTime()));

    // Y-values
    file.primaryData.xAcc.forEach((a) => setAccMinMax(a.y));
    file.primaryData.yAcc.forEach((a) => setAccMinMax(a.y));
    file.primaryData.zAcc.forEach((a) => setAccMinMax(a.y));
    file.primaryData.temp.forEach((a) => {
      if (a.y) {
        setTempMinMax(a.y);
      }
    });
    file.primaryData.rh.forEach((a) => {
      if (a.y) {
        setRhMinMax(a.y);
      }
    });
    file.primaryData.pressureRaw.forEach((a) => {
      if (a.y) {
        setPressureMinMax(a.y);
      }
    });
    file.primaryData.pressureComp.forEach((a) => {
      if (a.y) {
        setPressureMinMax(a.y);
      }
    });
  });

  // width of domain i milliseconds
  const fullDomainWidthMs = fullDomain[1] - fullDomain[0];

  /** Converts duration to non zoomed pixles */
  const durInPx = (ms: number) => (ms * props.width) / fullDomainWidthMs;

  /** Converts duration to zoomed (selectedDomain) pixles */
  const durInZoomPx = (ms: number) => {
    if (selectedDomain) {
      const domainWidth = selectedDomain.x[1] - selectedDomain.x[0];
      return (ms * props.width) / domainWidth;
    } else {
      return durInPx(ms);
    }
  };

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = { low: -1, high: 1 };

  // fileValue will be different in different files so it is statically set to 21
  const accTickCount = graphAxisScaleSelector(
    21,
    globalScale.acc.count,
    globalScale.acc.toggle,
    globalScale.toggle
  );
  const tempTickCount = graphAxisScaleSelector(
    21,
    globalScale.temp.count,
    globalScale.temp.toggle,
    globalScale.toggle
  );
  const rhTickCount = graphAxisScaleSelector(
    21,
    globalScale.rh.count,
    globalScale.rh.toggle,
    globalScale.toggle
  );
  const pressureTickCount = graphAxisScaleSelector(
    21,
    globalScale.pressure.count,
    globalScale.pressure.toggle,
    globalScale.toggle
  );

  /** Callback used to style y-axis grid */
  const gridStyleCallback = (tick: number) =>
    tick === 0 || tick === scale.low ? "black" : "#ECEFF1";

  /** Function that calculates the width of a bar in px. */
  const getBarWidth = (v: any) => {
    const duration = v?.datum?.d;
    if (!duration) return 0;
    const widthInPixels = durInZoomPx(duration);
    // If the width is below 1px, it will be barely visible
    if (widthInPixels < 1.5) return 1.5;
    return widthInPixels;
  };

  /** Utility function used for barwidth. Returns a width in pixels based on
   * acceleration duration */
  const getOverviewBarWidth = (v: any) => {
    const duration = v?.datum?.d;
    if (!duration) return 0;
    const widthInPixels = durInPx(duration);
    // If the width is below 1px, it will be barely visible
    if (widthInPixels < 1.5) return 1.5;
    return widthInPixels;
  };

  const handleZoomSelection = (bounds: CompareStateZoomDomain) => {
    const fixedBoundsX: [number, number] =
      bounds.x[0] < 0 ? [0, bounds.x[1]] : bounds.x;

    // If user selects from right to left, the first value will be larger than the seconds value, causing a bugged selection. {toLowHighOrder} solves that
    const newXDomain: CompareStateZoomDomain["x"] =
      toLowHighOrder(fixedBoundsX);
    const newYDomain: CompareStateZoomDomain["y"] = [-1, 1];
    props.handleSelectedDomain({ x: newXDomain, y: newYDomain });
  };

  // Finds largest domain for all graphs and active channels
  const accDomainUnit = (): YAxisLowHigh => {
    let maxAcc = 1;
    quickGraphData.forEach((file) => {
      if (file.metaData.activeChannels.includes("x")) {
        file.primaryData.xAcc.forEach((dataPoint) => {
          maxAcc = Math.max(maxAcc, Math.abs(dataPoint.y));
        });
      }
      if (file.metaData.activeChannels.includes("y")) {
        file.primaryData.yAcc.forEach((dataPoint) => {
          maxAcc = Math.max(maxAcc, Math.abs(dataPoint.y));
        });
      }
      if (file.metaData.activeChannels.includes("z")) {
        file.primaryData.zAcc.forEach((dataPoint) => {
          maxAcc = Math.max(maxAcc, Math.abs(dataPoint.y));
        });
      }
    });
    return [maxAcc * -1, maxAcc * 1];
  };

  const tempDomainUnit = () => {
    const pickTempValue = (temp: number) => temp;

    const lowestTempBlock = minBy(tempDomain, pickTempValue);
    const highestTempBlock = maxBy(tempDomain, pickTempValue);

    const tempMin = lowestTempBlock && floor(pickTempValue(lowestTempBlock)!);
    const tempMax = highestTempBlock && ceil(pickTempValue(highestTempBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        tempMin ? tempMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.temp.min,
        globalScale.temp.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        tempMax ? tempMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.temp.max,
        globalScale.temp.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const rhDomainUnit = () => {
    const pickRhValue = (rh: number) => rh;

    const lowestRhBlock = minBy(rhDomain, pickRhValue);
    const highestRhBlock = maxBy(rhDomain, pickRhValue);

    const rhMin = lowestRhBlock && floor(pickRhValue(lowestRhBlock)!);
    const rhMax = highestRhBlock && ceil(pickRhValue(highestRhBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        rhMin ? rhMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.rh.min,
        globalScale.rh.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        rhMax ? rhMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.rh.max,
        globalScale.rh.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const pressureDomainUnit = () => {
    const pickPressureValue = (pressure: number) => pressure;

    const lowestPressureBlock = minBy(pressureDomain, pickPressureValue);
    const highestPressureBlock = maxBy(pressureDomain, pickPressureValue);

    const pressureMin =
      lowestPressureBlock && floor(pickPressureValue(lowestPressureBlock)!);
    const pressureMax =
      highestPressureBlock && ceil(pickPressureValue(highestPressureBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        pressureMin ? pressureMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.pressure.min,
        globalScale.pressure.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        pressureMax ? pressureMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.pressure.max,
        globalScale.pressure.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  // Normalizer funtions is needed since this chart may contain data with
  // different scaling. Normalizing is needed to enable e.g. rh% (0 - 100) and acc
  // (-16 - 16) to be in the same chart
  const {
    normalizeValue: normalizeAccValue,
    denormalizeValue: denormalizeAccValue
  } = getNormalizerFunctions(accDomainUnit());

  const formatYValueAcc = (y: number) =>
    formatYData(y, accDomainUnit(), normalizeAccValue);

  const {
    normalizeValue: normalizeTempValue,
    denormalizeValue: denormalizeTempValue
  } = getNormalizerFunctions(tempDomainUnit(), scale);

  const formatYValueTemp = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(
          TemperatureToScale(y, tempScale),
          tempDomainUnit(),
          normalizeTempValue
        );

  const {
    normalizeValue: normalizeRhValue,
    denormalizeValue: denormalizeRhValue
  } = getNormalizerFunctions(rhDomainUnit(), scale);

  const formatYValueRh = (y: Nullable<number>) =>
    y === null ? 0 : formatYData(y, rhDomainUnit(), normalizeRhValue);

  const {
    normalizeValue: normalizePressureValue,
    denormalizeValue: denormalizePressureValue
  } = getNormalizerFunctions(pressureDomainUnit(), scale);

  const formatYValuePressure = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(y, pressureDomainUnit(), normalizePressureValue);

  // Draws the graph
  const graphContent = (overview?: boolean) => {
    const graphData = overview ? overviewData : primaryData;
    return graphData.map((file, index) => {
      /** The current graph is a mainGraph */
      const d = file.primaryData;
      return (
        <VictoryGroup key={index}>
          {/* x-axis data */}
          {file.metaData.activeChannels.includes("x") && d.xAcc.length > 0 && (
            <VictoryBar
              data={d.xAcc}
              x="x"
              y={(datum) => formatYValueAcc(datum.y)}
              style={{ data: { fill: iconColors.xAccA } }}
              alignment="start"
              barWidth={overview ? getOverviewBarWidth : getBarWidth}
              groupComponent={<VictoryClipContainer />}
            />
          )}
          {/* y-axis data */}
          {file.metaData.activeChannels.includes("y") && d.yAcc.length > 0 && (
            <VictoryBar
              data={d.yAcc}
              x="x"
              y={(datum) => formatYValueAcc(datum.y)}
              style={{ data: { fill: iconColors.yAccA } }}
              alignment="start"
              barWidth={overview ? getOverviewBarWidth : getBarWidth}
              groupComponent={<VictoryClipContainer />}
            />
          )}
          {/* z-axis data */}
          {file.metaData.activeChannels.includes("z") && d.zAcc.length > 0 && (
            <VictoryBar
              data={d.zAcc}
              x="x"
              y={(datum) => formatYValueAcc(datum.y)}
              style={{ data: { fill: iconColors.zAccA } }}
              alignment="start"
              barWidth={overview ? getOverviewBarWidth : getBarWidth}
              groupComponent={<VictoryClipContainer />}
            />
          )}

          {/* temp data */}
          {file.metaData.activeChannels.includes("temp") &&
            d.temp.length > 1 && (
              <VictoryLine
                name="Temp"
                data={d.temp ?? []}
                y={(datum: { x: Date; y: number | undefined }) =>
                  formatYValueTemp(datum.y ?? null)
                }
                style={{
                  data: {
                    stroke: iconColors.temp,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

          {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
          {d.temp.length === 1 && (
            <VictoryScatter
              name="temp1Data"
              data={d.temp ?? []}
              y={(datum) => formatYValueTemp(datum.y ?? null)}
              size={5}
              style={{ data: { fill: iconColors.temp } }}
            />
          )}

          {/* rh data */}
          {file.metaData.activeChannels.includes("rh") && d.rh.length > 0 && (
            // quickGraphData.map(e => e.primaryData.rh.length > 1) &&
            <VictoryLine
              name="RH"
              data={d.rh ?? []}
              y={(datum) => formatYValueRh(datum.y ?? null)}
              style={{
                data: {
                  stroke: iconColors.rh,
                  strokeWidth: overview ? 1 : 2
                }
              }}
            />
          )}

          {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
          {d.rh.length === 1 && file.metaData.activeChannels.includes("rh") && (
            <VictoryScatter
              name="rh1Data"
              data={d.rh ?? []}
              y={(datum) => formatYValueRh(datum.y ?? null)}
              size={5}
              style={{ data: { fill: iconColors.rh } }}
            />
          )}

          {/* pressureRaw data */}
          {file.metaData.activeChannels.includes("pressureRaw") &&
            d.pressureRaw.length > 0 && (
              <VictoryLine
                name="PressureRaw"
                data={d.pressureRaw ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                style={{
                  data: {
                    stroke: iconColors.pressureRaw,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

          {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
          {d.pressureRaw.length === 1 &&
            file.metaData.activeChannels.includes("pressureRaw") && (
              <VictoryScatter
                name="pressureRaw1Data"
                data={d.pressureRaw ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                size={5}
                style={{ data: { fill: iconColors.pressureRaw } }}
              />
            )}

          {/* pressureComp data */}
          {file.metaData.activeChannels.includes("pressureComp") &&
            d.pressureComp.length > 0 && (
              <VictoryLine
                name="PressureComp"
                data={d.pressureComp ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                style={{
                  data: {
                    stroke: iconColors.pressureComp,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

          {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
          {d.pressureComp.length === 1 &&
            file.metaData.activeChannels.includes("pressureComp") && (
              <VictoryScatter
                name="pressureComp1Data"
                data={d.pressureComp ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                size={5}
                style={{ data: { fill: iconColors.pressureComp } }}
              />
            )}
        </VictoryGroup>
      );
    });
  };

  const strokeSize = 4;
  const colorNumber = 150;
  const strokeColor = `rgb(${colorNumber}, ${colorNumber}, ${colorNumber})`;

  const customTickStyle: VictoryTickStyleObject = {
    size: strokeSize,
    stroke: strokeColor
  };

  const activeChannels = [...data.map((e) => e.activeChannels).flat()];

  /** Some temp-data comes through that are undefined which messes up VictoryAxis.
   * This filters them out. */
  const tempHasValue = [
    ...primaryData.map((file) =>
      file.primaryData.temp.filter((f) => f.y !== undefined)
    )
  ].flat();

  const rhHasValue = [
    ...primaryData.map((file) =>
      file.primaryData.rh.filter((f) => f.y !== undefined)
    )
  ].flat();

  const pressureRawHasValue = [
    ...primaryData.map((file) =>
      file.primaryData.pressureRaw.filter((f) => f.y !== undefined)
    )
  ].flat();

  const pressureCompHasValue = [
    ...primaryData.map((file) =>
      file.primaryData.pressureComp.filter((f) => f.y !== undefined)
    )
  ].flat();

  const accActive =
    activeChannels.includes("x") ||
    activeChannels.includes("y") ||
    activeChannels.includes("z");

  return (
    <>
      <VictoryChart
        name="primary"
        width={props.width}
        height={props.height}
        scale={{ x: "time" }}
        padding={{ top: size.l1, left: 190, right: 40, bottom: size.l3 }}
        domain={
          selectedDomain
            ? {
                x: selectedDomain!.x,
                y: [-1, 1]
              }
            : undefined
        }
        theme={VictoryTheme.material}
        domainPadding={{ x: [10, 10], y: 0 }}
        containerComponent={
          <VictorySelectionContainer
            selectionDimension="x"
            allowSelection
            onSelection={(points, bounds: any, props) => {
              handleZoomSelection(bounds as any);
            }}
          />
        }
      >
        {/* x-axis(based on time, show no values, based on origo) */}
        <VictoryAxis
          tickFormat={[]}
          tickCount={timeScaleSteps}
          style={{
            axis: {
              stroke: strokeColor,
              strokeWidth: accActive ? 1.5 : 0
            },
            ticks: {
              stroke: strokeColor,
              strokeWidth: accActive ? 1 : 0
            }
          }}
          axisValue={0}
        />

        {/* x-axis(based on time, show values) */}
        <VictoryAxis
          tickFormat={(t, _i, arr) =>
            dateAxisFormater(
              t,
              arr,
              timezoneSelector(localTimezone, timezoneState, timezoneToggle)
            )
          }
          fixLabelOverlap
          tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
          axisValue={yAxisDefaultNormalizedValues[0]}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: "black",
              strokeWidth: 1.5
            },
            grid: {
              stroke: strokeColor,
              strokeWidth: 0
            }
          }}
        />

        {accActive ? (
          <VictoryAxis
            dependentAxis
            offsetX={getYAxisOffset()}
            label={"g"}
            fixLabelOverlap
            style={{
              axis: { stroke: strokeColor, strokeWidth: 2 },
              ...commonAxisStyle,
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              }
            }}
            axisLabelComponent={
              <VictoryLabel y={15} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(accTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeAccValue(t), accDomainUnit())
            }
          />
        ) : undefined}

        {/* y-axis(temperature) */}
        {tempHasValue.length > 0 && activeChannels.includes("temp") && (
          <VictoryAxis
            name="tempAxis"
            dependentAxis
            offsetX={getYAxisOffset()}
            label={TempScaleSymbol(tempScale)}
            fixLabelOverlap
            style={{
              ...commonAxisStyle,
              axis: { stroke: iconColors.temp, strokeWidth: 2 },
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              },
              ticks: customTickStyle
            }}
            axisLabelComponent={
              <VictoryLabel y={15} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(tempTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeTempValue(t), tempDomainUnit())
            }
          />
        )}

        {/* y-axis(rh) */}
        {rhHasValue.length > 0 && activeChannels.includes("rh") && (
          <VictoryAxis
            name="rhAxis"
            dependentAxis
            offsetX={getYAxisOffset()}
            label="Rh(%)"
            fixLabelOverlap
            style={{
              ...commonAxisStyle,
              axis: { stroke: iconColors.rh, strokeWidth: 2 },
              grid: { stroke: (tick: any) => gridStyleCallback(tick) },
              ticks: customTickStyle
            }}
            axisLabelComponent={
              <VictoryLabel y={15} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(rhTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeRhValue(t), rhDomainUnit())
            }
          />
        )}

        {/* y-axis(pressure) */}
        {pressureRawHasValue.length > 0 &&
          pressureCompHasValue.length > 0 &&
          (activeChannels.includes("pressureRaw") ||
            activeChannels.includes("pressureComp")) && (
            <VictoryAxis
              name="pressureAxis"
              dependentAxis
              offsetX={getYAxisOffset()}
              label="mbar"
              fixLabelOverlap
              style={{
                ...commonAxisStyle,
                axis: { stroke: iconColors.pressure, strokeWidth: 2 },
                grid: { stroke: (tick: any) => gridStyleCallback(tick) },
                ticks: customTickStyle
              }}
              axisLabelComponent={
                <VictoryLabel y={15} dx={39} textAnchor="middle" />
              }
              tickValues={generateTickValues(pressureTickCount)}
              tickFormat={(t) =>
                yAxisFormater(denormalizePressureValue(t), pressureDomainUnit())
              }
            />
          )}

        {graphContent()}
      </VictoryChart>

      <VictoryChart
        name="overview"
        width={props.width}
        height={90}
        scale={{ x: "time" }}
        padding={{ top: size.l1, left: 190, right: 40, bottom: size.l1 }}
        domainPadding={{ x: [10, 10], y: 0 }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryBrushContainer
            brushDimension="x"
            brushDomain={
              selectedDomain
                ? {
                    x: selectedDomain!.x,
                    y: [-1, 1]
                  }
                : undefined
            }
            onBrushDomainChangeEnd={(bounds: any) =>
              handleZoomSelection(bounds as any)
            }
          />
        }
      >
        <VictoryAxis
          tickCount={timeScaleSteps}
          tickFormat={[]}
          axisValue={0}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: strokeColor,
              strokeWidth: accActive ? 1.5 : 0
            },
            ticks: {
              stroke: strokeColor,
              strokeWidth: accActive ? 1 : 0
            }
          }}
        />
        {graphContent(true)}
      </VictoryChart>
    </>
  );
};

export default React.memo(PrimaryGraphsOnTop);
