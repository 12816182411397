import { notification, Typography } from "antd";
import { isUndefined } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useMobitronGetCompaniesQuery,
  useRemoveOrderMutation
} from "../../state/cargologRestApi";
import { closeRemoveOrderModal, modalsState } from "../../state/modalsSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import StandardModal from "../Common/StandardModal";

const { Text } = Typography;

type RemoveOrderType = { orderId: string };

const RemoveOrderModal = () => {
  const { orderObject: selectedOrder, isRemoveOrderModalOpen } =
    useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: allCompanies } = useMobitronGetCompaniesQuery();

  let companyName = allCompanies
    ?.filter((company) => company.id === selectedOrder?.companyId)
    .map((companyName) => companyName.name);

  const [removeOrder, requestStatus] = useRemoveOrderMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRemoveOrder = async (request: RemoveOrderType) => {
    const result: any = await removeOrder(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRemoveTheInvoice"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyRemovedTheInvoice") });
      dispatch(closeRemoveOrderModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectedOrder)) {
      const request = { orderId: selectedOrder?.id };
      handleRemoveOrder(request);
    }
  };

  return (
    <>
      <StandardModal
        title={t("DeleteOrder")}
        open={isRemoveOrderModalOpen}
        onCancel={() => dispatch(closeRemoveOrderModal())}
        closable={true}
        destroyOnClose={true}
        footer={[
          <NormalButton
            key={"Cancel"}
            htmlType="submit"
            onClick={() => dispatch(closeRemoveOrderModal())}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            key={"Delete"}
            danger
            htmlType="submit"
            onClick={onFinish}
            loading={requestIsLoading}
          >
            {t("DeleteOrder")}
          </PrimaryButton>
        ]}
      >
        {`${t("AreYouSureYouWantToDelete")} ${t("theOrder")} `}
        <Text strong>{selectedOrder?.id}</Text>
        <Text>{` ${t("placedBy")} ${companyName}? `}</Text>
      </StandardModal>
    </>
  );
};

export default RemoveOrderModal;
