import React from "react";
import { Button, ButtonProps } from "antd";
import {
  FilePdfOutlined,
  MoreOutlined,
  PrinterOutlined,
  SaveOutlined
} from "@ant-design/icons";
import commonColors from "../../styles/commonColors";

/**
 * Base button style.
 * !!Important: the same style is mannually applied in "overrides.css"
 * css-selector. If you change this value, also change it over there.
 */
export const shadowLarge: React.CSSProperties = {
  boxShadow: "0 1px 6px -2px rgb(0 0 0 / 25%)"
};

export const shadowSmall: React.CSSProperties = {
  boxShadow: "0 1px 4px -2px rgb(0 0 0 / 25%)"
};

interface NormalButtonProps extends ButtonProps {
  active?: boolean;
}

/** Basic normal button that should be used for any non primary action
 * throughout the application */
export const NormalButton: React.FC<NormalButtonProps> = (props) => {
  const buttonStyle: React.CSSProperties = {
    ...shadowLarge,
    ...props.style,
    backgroundColor: props.disabled ? undefined : commonColors.buttonColor
  };
  const buttonActiveStyle: React.CSSProperties = {
    ...shadowLarge,
    color: "#3e9736",
    borderColor: "#3e9736",
    ...props.style
  };

  return (
    <Button {...props} style={props.active ? buttonActiveStyle : buttonStyle}>
      {props.children}
    </Button>
  );
};
/** Basic normal small button that should be used for any non primary action
 * throughout the application */
export const NormalButtonSmall: React.FC<ButtonProps> = (props) => {
  const buttonStyle: React.CSSProperties = {
    ...shadowSmall,
    ...props.style,
    backgroundColor: props.disabled ? undefined : commonColors.buttonColor
  };

  return (
    <Button size="small" {...props} style={buttonStyle}>
      {props.children}
    </Button>
  );
};

/** Normal dropdown button that should be used for buttons opening menus */
export const MenuButton: React.FC<ButtonProps> = (props) => {
  const buttonStyle: React.CSSProperties = {
    ...shadowLarge,
    ...props.style,
    backgroundColor: props.disabled ? undefined : commonColors.buttonColor
  };

  return (
    <Button {...props} style={buttonStyle}>
      {props.children} <MoreOutlined style={{ fontSize: 12 }} />
    </Button>
  );
};

/** Small dropdown button that should be used for any buttons opening menus */
export const MenuButtonSmall: React.FC<ButtonProps> = (props) => {
  const buttonStyle: React.CSSProperties = {
    ...shadowSmall,
    ...props.style,
    backgroundColor: props.disabled ? undefined : commonColors.buttonColor
  };

  return (
    <Button size="small" {...props} style={buttonStyle}>
      {props.children} <MoreOutlined style={{ fontSize: 10 }} />
    </Button>
  );
};

/** Basic primary action button that should be used for primary actions in the application */
export const DangerButtonSmall: React.FC<ButtonProps> = (props) => (
  <Button
    danger
    size="small"
    style={{ ...shadowSmall }}
    className={"dangerButton"}
    {...props}
  >
    {props.children}
  </Button>
);

/** Basic primary action button that should be used for primary actions in the application.
 * Only use one PrimaryButton per page.
 */
export const PrimaryButton: React.FC<ButtonProps> = (props) => (
  <Button type="primary" {...props} style={{ ...shadowLarge, ...props.style }}>
    {props.children}
  </Button>
);

/** Button that doesn't have a background or border, only use in special cases */
export const GhostButton: React.FC<ButtonProps> = (props) => (
  <Button type="text" {...props}>
    {props.children}
  </Button>
);

/** Only used in Quick Tour modal */
export const QuickTourButton: React.FC<ButtonProps> = (props) => (
  <Button
    type="text"
    {...props}
    style={{
      color: "white",
      border: "1px solid rgba(255,255,255,0.9)",
      ...props.style
    }}
  >
    {props.children}
  </Button>
);

/** Save button that should be used for save actions application wide */
export const SaveButton: React.FC<ButtonProps> = (props) => (
  <NormalButton icon={<SaveOutlined />} {...props}>
    {props.children}
  </NormalButton>
);

/** Export button that should be used for export actions application wide */
export const ExportPdfButton: React.FC<ButtonProps> = (props) => (
  <NormalButton icon={<FilePdfOutlined />} {...props}>
    {props.children}
  </NormalButton>
);
