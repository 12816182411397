import React from "react";
import { Layout, Row } from "antd";
import { useSelector } from "react-redux";
import {
  size,
  impersonateBannerHeight,
  isSmallScreen
} from "../../helpers/pageHelper";
import { selectParamsPageUserProgress } from "../../state/paramsPageSlice";
import { NormalCard, NormalHeaderCard } from "../Common/CommonCards";
import ParamsBuilderContent from "./ParamsBuilderContent";
import ParamsBuilderFooter from "./ParamsBuilderFooter";
import ParamsBuilderProgress from "./ParamsBuilderProgress";
import ParamsPageHeader from "./ParamsPageHeader";
import { selectImpersonate } from "../../state/persistantStateSlice";
import { useResizeDetector } from "react-resize-detector";

interface IProps {
  contentWidth: number;
}

const ParamsBuilder = (props: IProps) => {
  const { userProgress } = useSelector(selectParamsPageUserProgress);
  const { isImpersonating } = useSelector(selectImpersonate);
  const { contentWidth } = props;
  const { width } = useResizeDetector();

  /** Primary styling used for header and footer in this component */
  const stickyElementStyle: React.CSSProperties = {
    position: "sticky",
    zIndex: 1000,
    width: "100%",
    backgroundColor: "white",
    margin: 0,
    padding: 0
  };

  return (
    <Layout
      style={{
        padding: 0,
        margin: 0,
        width: "100%",
        maxWidth: "100%",
        minHeight: `calc(100vh ${isImpersonating ? `- 48px - ${impersonateBannerHeight}px` : "- 48px"}`,
        paddingLeft: isSmallScreen(window.innerWidth ?? width) ? size.l2 : 0
      }}
    >
      <Layout.Header
        style={{
          ...stickyElementStyle,
          top: 0,
          height: "auto"
        }}
      >
        <NormalHeaderCard
          size="default"
          title={<ParamsPageHeader contentWidth={contentWidth} />}
          styles={{
            header: { height: "65px" },
            body: {
              height: "auto",
              paddingTop: size.m1,
              paddingBottom: size.m1,
              paddingLeft: size.l2,
              paddingRight: size.l2
            }
          }}
          style={{ borderTop: 0 }}
        >
          <ParamsBuilderProgress contentWidth={contentWidth} />
        </NormalHeaderCard>
      </Layout.Header>
      <Layout.Content style={{ padding: size.m1 }}>
        <NormalCard styles={{ body: { height: "100%" } }}>
          <Row
            justify="center"
            align="top"
            style={{
              width: "100%",
              height: "100%",
              paddingBlock: size.xl1,
              paddingInline: size.l2
            }}
          >
            <ParamsBuilderContent contentWidth={contentWidth} />
          </Row>
        </NormalCard>
      </Layout.Content>
      {/* First page will not use a footer */}
      {userProgress !== 0 && (
        <Layout.Footer
          style={{
            ...stickyElementStyle,
            bottom: 0
          }}
        >
          <ParamsBuilderFooter contentWidth={contentWidth} />
        </Layout.Footer>
      )}
    </Layout>
  );
};

export default ParamsBuilder;
