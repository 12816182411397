import { CloseOutlined } from "@ant-design/icons";
import { Row, Typography, notification } from "antd";
import { Buffer } from "buffer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanParams,
  recordingParameters2ViewModel
} from "../../helpers/dataModelHelper";
import { impersonateBannerHeight, size } from "../../helpers/pageHelper";
import { validatePackedParx } from "../../helpers/paramsHelper";
import { parseParx } from "../../helpers/parsers/parseParxHelper";
import {
  isValidationObjAllValid,
  validateRecordingParameters
} from "../../helpers/validationHelper";
import { getAppVersion } from "../../helpers/versionHelper";
import { newRecordingParameters } from "../../state/openParxSlice";
import {
  defaultParametersString,
  selectParamsBasedOn,
  selectTargetDevice,
  setActiveParametersTab,
  setParamsBasedOn,
  setParamsUserProgress
} from "../../state/paramsPageSlice";
import { selectImpersonate } from "../../state/persistantStateSlice";
import { getUser } from "../../state/sessionSlice";
import { invalidRecordingParametersErrorMessage } from "../../utils/invalidRecordingParametersMessage";
import { NormalSideMenuCard } from "../Common/CommonCards";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import OpenFileButton from "../MicroComponents/OpenFileButton";
import RecentFiles from "../MicroComponents/RecentFiles";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { GeneralSideMenuArrow } from "../MicroComponents/GeneralSideMenuArrow";

const { Text } = Typography;

interface IProps {
  isExpanded?: boolean;
  isFloating?: boolean;
  width?: number;
}
const ParamsSideMenu: React.FC<IProps> = (props) => {
  const { companyId } = useSelector(getUser);
  const { isImpersonating } = useSelector(selectImpersonate);
  const targetDevice = useSelector(selectTargetDevice);
  const paramsBasedOn = useSelector(selectParamsBasedOn);
  const isBaseParams = paramsBasedOn === defaultParametersString;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  /** general function */
  const validateAndOpenParx = (parxBuf: Buffer, fileName: string) => {
    const validationRes = validatePackedParx(parxBuf);
    const isValid = validationRes.isOk;

    //tell the user that there is something wrong with parx
    if (!isValid) {
      //saveNotOkRecParamsToLog(parxBuf, validationRes);
      invalidRecordingParametersErrorMessage();
      return;
    }

    const parx = parseParx(parxBuf);
    const recordingParameters = parx.recordingParameters;
    const vmRecordingParameters =
      recordingParameters2ViewModel(recordingParameters);

    // Validate recording parameters and display error message if invalid
    const parametersValid = isValidationObjAllValid(
      validateRecordingParameters(vmRecordingParameters, targetDevice)
    );

    const neutralParams = cleanParams(
      vmRecordingParameters,
      getAppVersion(),
      companyId
    );

    dispatch(newRecordingParameters(neutralParams));
    dispatch(setParamsUserProgress(1));
    dispatch(setActiveParametersTab("freeText"));
    dispatch(setParamsBasedOn(fileName));

    if (parametersValid) {
      notification.success({ message: t("YourSelectedFileHasBeenOpened") });
    } else {
      notification.warning({ message: t("SomeParametersHaveBeenChanged") });
    }
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const data: Buffer = Buffer.from(reader.result as ArrayBuffer);
          validateAndOpenParx(data, file.name);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const [mouseInArea, setMouseInArea] = useState(false);

  return (
    <NormalSideMenuCard
      title={mouseInArea || props.isExpanded ? t("Setupdevice") : undefined}
      isExpanded={mouseInArea || props.isExpanded}
      isFloating={props.isFloating}
      isImpersonating={isImpersonating}
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
      style={{ zIndex: 1001 }}
    >
      <div
        style={{
          overflow: "auto",
          outline: "none",
          maxHeight: `calc(100vh - (48px + 65px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`
        }}
      >
        {!mouseInArea && !props.isExpanded ? (
          <GeneralSideMenuArrow
            page="params"
            isImpersonating={isImpersonating}
          />
        ) : (
          <>
            <OpenFileButton
              accept=".parx"
              onSelectFile={onSelectFile}
              buttonLabel={t("ImportFromFile")}
            />

            <Row style={{ paddingTop: size.m2, paddingInline: size.m2 }}>
              <Text strong>{t("ParametersBasedOn")}:</Text>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%", paddingInline: size.m2 }}
              wrap={false}
            >
              <Text>{paramsBasedOn}</Text>
              {!isBaseParams && (
                <NormalButtonSmall
                  icon={<CloseOutlined />}
                  onClick={() =>
                    dispatch(setParamsBasedOn(defaultParametersString))
                  }
                />
              )}
            </Row>
          </>
        )}
      </div>
    </NormalSideMenuCard>
  );
};

export default ParamsSideMenu;
