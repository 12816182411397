import dayjs, { Dayjs } from "dayjs";
import { dayjs2IDate } from "./dataModelHelper";

export const getNowDate = () => dayjs();

export const getNowUnixTimestamp = () => dayjs().unix();

/** Get current time subtracted by 1 min */
export const getSlighlyEarlierDate = () => getNowDate().subtract(1, "minute");

export const getSlighlyEarlierUnixTimestamp = () =>
  getSlighlyEarlierDate().unix();

export const getUtcOffset = (m: Dayjs) => m.utcOffset() / 60;

export const getLocalUtcOffset = () => getUtcOffset(dayjs());

export const guessLocalTz = () => dayjs.tz.guess();

export const getAllTz = () => Intl.supportedValuesOf("timeZone");

export const getAllTzWithOffset = () => {
  let mutObj = dayjs.tz();

  return getAllTz().map((x) => ({
    name: x,
    offset: getUtcOffset(mutObj.tz(x))
  }));
};

export const formatUtcOffset = (offset: number): string =>
  offset <= 0 ? `${offset}` : `+${offset}`;

export const createLocalDate = (unix: number) => dayjs.unix(unix);

export const createUtcDate = (unix: number) => createLocalDate(unix).utc();

export const createTzDate = (unix: number, tz: string) =>
  tz === "UTC" ? createUtcDate(unix) : dayjs.unix(unix).tz(tz);

export const createDateWithOffset = (unix: number, offset: number) => {
  return offset === 0 ? createUtcDate(unix) : createLocalDate(unix);
};

export const createCurrentTimeUnixTimestamp = () => dayjs().unix();

export const dateToUnix = (d: Date): number => d.getTime() / 1e3;

//todo: prob remove
export const createNormalDateWithOffset = (
  unix: number,
  offset: number
): Date => createDateWithOffset(unix, offset).toDate();

export const createNormalDateInTz = (unix: number, tz: string): Date =>
  createTzDate(unix, tz).toDate();

//not done
export const createCustomTimezoneDate = (unix: number, zone: string) =>
  createLocalDate(unix).tz(zone, false);

export const createDateTupleWithOffset = (
  unixTuple: [number, number],
  tz: string
): [Dayjs, Dayjs] => {
  const [fst, snd] = unixTuple;

  return tz === "UTC"
    ? [createUtcDate(fst), createUtcDate(snd)]
    : [createTzDate(fst, tz), createTzDate(snd, tz)];
};

export const createFormatedLocalDateFromUnix = (
  unix: number,
  timezone: string
) => createLocalDate(unix).tz(timezone).format("YYYY-MM-DD HH:mm:ss");

/** Returns a new dayjs object with time set to 00:00:00 */
export const withoutTime = (d: Dayjs) => d.startOf("day");

export const createCurrentTimeAsIDateBuffer = (): Uint8Array => {
  const utcDate = dayjs.utc();
  const { year, month, day, hour, minute, second } = dayjs2IDate(utcDate);
  const buf = new Uint8Array([year, month, day, hour, minute, second]);

  return buf;
};

// Optional date in unix timestamp
export const createUtcOffsetStr = (timezone: string, date?: number) => {
  const utcOffset = dayjs(date).tz(timezone).utcOffset() / 60;
  const utcOffsetStr =
    utcOffset >= 0 ? `(UTC+${utcOffset})` : `(UTC${utcOffset})`;
  return utcOffsetStr;
};

export const getLocalTimeAndOffset = (): string => {
  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;
  const now = dayjs().format("YYYY-MM-DD, HH:mm ");
  return now + localUtcOffsetStr;
};
