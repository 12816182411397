import React, { useState } from "react";
import { ShoppingCartOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Typography,
  Space,
  CardProps,
  Select,
  Badge,
  Popover,
  Checkbox,
  Divider
} from "antd";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { LiftedCard } from "../Common/CommonCards";
import {
  LicensePrice,
  useGetAllLicensePricesQuery
} from "../../state/cargologRestApi";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import backgroundBlue from "../../assets/Fields/blue.png";
import backgroundGold from "../../assets/Fields/gold.png";
import backgroundGray from "../../assets/Fields/gray.png";
import backgroundGreen from "../../assets/Fields/green.png";
import backgroundPurple from "../../assets/Fields/purple.png";
import backgroundRed from "../../assets/Fields/red.png";
import backgroundTeal from "../../assets/Fields/teal.png";
import backgroundWhite from "../../assets/Fields/white.png";
import { isUndefined } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartState,
  licenseStoreState,
  openCartDrawer,
  setCartState,
  setMonths,
  toggleIsProjectLicense
} from "../../state/licenseStoreSlice";
import LicenseCartDrawer from "./LicenseCartDrawer";
import CheckoutModal from "./LicenseCheckoutModal";
import { selectMyCurrency, setCurrency } from "../../state/sessionSlice";
import CurrencyAndPrice from "../MicroComponents/CurrencyAndPrice";
import {
  openLicenseDetailsModal,
  setLicensePrice
} from "../../state/modalsSlice";
import LicenseDetailsModal from "./LicenseDetailsModal";
import { Help } from "../MicroComponents/Help";
import { LargeTitle } from "../Common/CommonFonts";
import StandardModal from "../Common/StandardModal";

const { Text, Title, Paragraph, Link } = Typography;
const { Option } = Select;

export interface cartItemType {
  name: string;
  description: string;
  quantity: number;
  price: number;
  months: number;
  licensePriceId: string;
  companyDiscountPercent: number;
}

type availableBackground =
  | "blue"
  | "gold"
  | "gray"
  | "green"
  | "purple"
  | "red"
  | "teal"
  | "white";

const cardBackgrounds: Record<availableBackground, string> = {
  blue: backgroundBlue,
  gold: backgroundGold,
  gray: backgroundGray,
  green: backgroundGreen,
  purple: backgroundPurple,
  red: backgroundRed,
  teal: backgroundTeal,
  white: backgroundWhite
};

const LicenseStore = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const persistantCurrency = useSelector(selectMyCurrency);
  const { cartState, isProjectLicense, months } =
    useSelector(licenseStoreState);

  if (isUndefined(persistantCurrency)) {
    dispatch(setCurrency("USD"));
  }

  type LicenseTypes = 1 | 2 | 3;
  const [licenseTypes, setLicenseTypes] = useState<LicenseTypes[]>([1]);

  const productCardHeight = 300;

  /* Product Card that takes 1/2 of the width */
  const ProductCard = (props: CardProps) => (
    <Col xs={24} lg={12} xl={8} xxl={6}>
      <LiftedCard
        {...props}
        bordered={false}
        style={{
          ...props.style,
          height: productCardHeight,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderRadius: size.m1
        }}
      >
        {props.children}
      </LiftedCard>
    </Col>
  );

  /* Aligns item to bottom right of parent */
  const BottomRight = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        padding: size.l2
      }}
    >
      <Space align="center">{props.children}</Space>
    </div>
  );

  /* Aligns item to bottom left of parent */
  const BottomLeft = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        padding: size.l2
      }}
    >
      {props.children}
    </div>
  );

  /* Aligns item to top right of parent */
  const TopRight = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: 0,
        paddingRight: size.m1,
        fontSize: "16px",
        fontWeight: "normal"
      }}
    >
      {props.children}
    </div>
  );

  /* Request for License Prices */
  const { data: LicensePricesData } = useGetAllLicensePricesQuery();

  let productLines: LicensePrice[] = [];

  if (!isUndefined(LicensePricesData)) {
    productLines = LicensePricesData;
  }

  let filteredLicenses: LicensePrice[] = [];
  // Loop through full product list
  productLines.forEach((product) => {
    // Find selected currency and selected months in product line
    const price = product.licensePriceDefs.filter(
      (p) => p.currency === persistantCurrency && p.months === months
    );
    // If price and month found add product line to filteredLicenses
    if (price.length > 0) {
      filteredLicenses.push({
        id: product.id,
        created: product.created,
        userId: product.userId,
        name: product.name,
        description: product.description,
        deviceType: product.deviceType,
        color: product.color,
        licensePriceDefs: price, // Found price
        userRights: product.userRights,
        companyDiscountPercent: product.companyDiscountPercent
      });
    }
  });

  let cartLength: number = 0;

  cartState.forEach((item) => (cartLength += item.quantity));

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tempMonths, setTempMonth] = useState<number>(3);

  const changeMonth = (value: number) => {
    if (cartLength === 0) {
      dispatch(setMonths(value));
    } else {
      setTempMonth(value);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Layout
        style={{
          width: "100%",
          maxWidth: "100%"
        }}
      >
        <Layout.Content style={{ padding: size.m1, textAlign: "left" }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: size.l2 }}
          >
            <Col>
              <Space>
                <Title level={2} style={{ marginBlock: 0 }}>
                  {t("LicenseStore")}
                </Title>
              </Space>
            </Col>
            <Col>
              <Space>
                <Popover
                  placement="bottom"
                  trigger="click"
                  overlayInnerStyle={{
                    maxHeight: 300,
                    overflow: "auto",
                    outline: "none"
                  }}
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  content={
                    <>
                      <Checkbox.Group
                        onChange={(v) => setLicenseTypes(v as LicenseTypes[])}
                        value={licenseTypes}
                        style={{ flexDirection: "column" }}
                      >
                        <Checkbox
                          style={{ width: "100%" }}
                          defaultChecked={
                            licenseTypes.includes(1) ? true : false
                          }
                          value={1}
                          checked={licenseTypes.includes(1) ? true : false}
                        >
                          {t("cargolog3rdGen")}
                        </Checkbox>
                        <Checkbox
                          style={{ width: "100%" }}
                          defaultChecked={
                            licenseTypes.includes(2) ? true : false
                          }
                          value={2}
                          checked={licenseTypes.includes(2) ? true : false}
                        >
                          {t("cargologEasy")}
                        </Checkbox>
                      </Checkbox.Group>
                      <Divider style={{ marginBlock: size.m1 }} />
                      <Checkbox
                        style={{ width: "100%" }}
                        defaultChecked={isProjectLicense}
                        value={isProjectLicense}
                        onClick={() => dispatch(toggleIsProjectLicense())}
                      >
                        {t("OnlyProjectLicenses")}
                      </Checkbox>
                    </>
                  }
                >
                  <NormalButton
                    icon={<FilterOutlined />}
                    active={licenseTypes.length > 0 || isProjectLicense}
                  >
                    {t("LicenseTypes")}
                  </NormalButton>
                </Popover>

                <Select
                  value={months}
                  onChange={changeMonth}
                  // Keeps Dropdown in place when scrolling
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <Option value={3}>3 {t("months")}</Option>
                  <Option value={6}>6 {t("months")}</Option>
                  <Option value={12}>12 {t("months")}</Option>
                </Select>
                {Help(
                  <Badge count={cartLength}>
                    <NormalButton
                      icon={<ShoppingCartOutlined />}
                      onClick={() => dispatch(openCartDrawer())}
                    >
                      {t("btnShowCart")}
                    </NormalButton>
                  </Badge>,
                  t("ClickHereToShowTheCart"),
                  "top"
                )}
              </Space>
            </Col>
          </Row>
          <Row gutter={[size.l2, size.l2]}>
            {!isUndefined(filteredLicenses)
              ? filteredLicenses
                  .filter((license) =>
                    licenseTypes.find((e) => e === license.deviceType)
                  )
                  .filter(
                    (license) =>
                      !isProjectLicense ||
                      (isProjectLicense &&
                        (license.userRights.includes("ADK") ||
                          license.userRights.includes("ADI") ||
                          license.userRights.includes("ADL") ||
                          license.userRights.includes("ADM")))
                  )
                  .map((productLine) => {
                    const totalPrice =
                      productLine.licensePriceDefs[0].price *
                      productLine.licensePriceDefs[0].months;
                    const monthlyDiscount =
                      (productLine.licensePriceDefs[0].price *
                        productLine.companyDiscountPercent) /
                      100;
                    const totalDiscount =
                      (totalPrice * productLine.companyDiscountPercent) / 100;
                    const backgroundImage =
                      cardBackgrounds[productLine.color as availableBackground];
                    return (
                      <ProductCard
                        key={productLine.id}
                        style={{
                          backgroundImage: `url(${backgroundImage})`
                        }}
                      >
                        <TopRight>
                          <Text type="secondary">
                            {productLine.deviceType === 1
                              ? t("cargolog3rdGen")
                              : ""}
                            {productLine.deviceType === 2
                              ? t("cargologEasy")
                              : ""}
                            {productLine.deviceType === 3 ? t("vehicle") : ""}
                          </Text>
                        </TopRight>
                        <LargeTitle>{productLine.name}</LargeTitle>
                        <Paragraph style={{ maxWidth: "300px" }}>
                          {productLine.description}
                        </Paragraph>
                        <Link
                          onClick={() => {
                            dispatch(setLicensePrice(productLine));
                            dispatch(openLicenseDetailsModal());
                          }}
                        >
                          {t("genCardClickForDetails")}
                        </Link>
                        <BottomLeft>
                          {productLine.companyDiscountPercent > 0 ? (
                            <>
                              <Text
                                style={{
                                  backgroundColor: "RGBA(255,50,40,1.00)",
                                  color: "white",
                                  opacity: 0.8,
                                  fontWeight: "bolder",
                                  paddingBlock: size.s1,
                                  paddingInline: size.s1,
                                  borderRadius: "6px"
                                }}
                              >
                                {t("Discount")}: -
                                {productLine.companyDiscountPercent}%
                              </Text>
                              <br />
                              <Space style={{ paddingTop: size.s1 }}>
                                <Text
                                  strong
                                  type="secondary"
                                  style={{
                                    fontSize: 14,
                                    textDecorationLine: "line-through"
                                  }}
                                >
                                  <CurrencyAndPrice price={totalPrice} />
                                </Text>
                                <Text
                                  style={{
                                    fontSize: 12,
                                    textDecorationLine: "line-through",
                                    color: "#555"
                                  }}
                                >
                                  {" "}
                                  (
                                  <CurrencyAndPrice
                                    price={
                                      productLine.licensePriceDefs[0].price
                                    }
                                  />
                                  /{t("cardMonth")})
                                </Text>
                              </Space>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          <Space align="center">
                            <Text strong style={{ fontSize: 20 }}>
                              <CurrencyAndPrice
                                price={totalPrice - totalDiscount}
                              />
                            </Text>
                            <Text style={{ color: "#555" }}>
                              {" "}
                              (
                              <CurrencyAndPrice
                                price={
                                  productLine.licensePriceDefs[0].price -
                                  monthlyDiscount
                                }
                              />
                              /{t("cardMonth")})
                            </Text>
                          </Space>
                        </BottomLeft>
                        <BottomRight>
                          <NormalButton
                            onClick={() => {
                              dispatch(openCartDrawer());
                              dispatch(
                                addToCartState({
                                  name: productLine.name,
                                  description: productLine.description,
                                  quantity: 1,
                                  price: productLine.licensePriceDefs[0].price,
                                  months:
                                    productLine.licensePriceDefs[0].months,
                                  licensePriceId: productLine.id,
                                  companyDiscountPercent:
                                    productLine.companyDiscountPercent
                                })
                              );
                            }}
                          >
                            {t("btnAddToCart")}
                          </NormalButton>
                        </BottomRight>
                      </ProductCard>
                    );
                  })
              : ""}
          </Row>
          <CheckoutModal />
        </Layout.Content>
        <LicenseCartDrawer />
        <LicenseDetailsModal />
      </Layout>

      <StandardModal
        title={t("ChangingTheMonthValueWillEmptyYourCart")}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        closable={true}
        zIndex={1045}
        width={500}
        footer={[
          <NormalButton
            key={"Cancel"}
            htmlType="button"
            onClick={() => setIsModalOpen(false)}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            key={"Continue"}
            htmlType="button"
            onClick={() => {
              dispatch(setMonths(tempMonths));
              dispatch(setCartState([]));
              setIsModalOpen(false);
            }}
          >
            {t("Continue")}
          </PrimaryButton>
        ]}
      >
        {/* <Row style={{ marginBottom: size.m1 }}><Text strong>{("Changing the month value will empty your cart.")}</Text></Row> */}
        <Row>{t("YouCanOnlyPurchaseLicensesWith")}</Row>
      </StandardModal>
    </>
  );
};

export default LicenseStore;
