import { isEmpty, throttle } from "lodash-es";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectionHelpers,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryClipContainer,
  VictoryContainer,
  VictoryCursorContainerProps,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  createContainer
} from "victory";
import { CallbackArgs, EventPropTypeInterface, Selection } from "victory-core";
import { VictorySelectionContainerProps } from "victory-selection-container";
import {
  externalRhColors,
  externalTempColors,
  iconColors
} from "../../constants/colors";
import { Nullable } from "../../helpers/datasetHelper";
import { dateToUnix } from "../../helpers/dateHelper";
import {
  PrimaryGraphZoomDomain,
  ZoomDimension,
  commonPrimaryGraphProps,
  createPerformantPrimaryGraphData,
  dateAxisFormater,
  formatYData,
  generateTickValues,
  getCanvasOffset,
  getNormalizerFunctions,
  graphAxisScaleSelector,
  isAccFilterActive,
  isExternalRhFilterActive,
  isExternalTempFilterActive,
  toLowHighOrder,
  yAxisDefaultNormalizedValues,
  yAxisFormater,
  zoomDimensionToVictoryProp
} from "../../helpers/graphHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import {
  DataFilterStates,
  YAxisDomain,
  YAxisLowHigh,
  YAxisTickCount,
  removeNearestContextLineIfClose,
  selectGraphTools,
  setContextLineNewPos,
  setNewCursorPosition,
  setScoreValuesCursorPosToNext,
  setScoreValuesCursorPosToPrior
} from "../../state/openDatxSlice";
import {
  selectGlobalGraphScale,
  selectGraphAxisTickCountTime
} from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle,
  selectTemperatureScale
} from "../../state/sessionSlice";
import {
  commonAxisStyle,
  scoreAxisStyle
} from "../../styles/graphStylesCommon";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";

export interface AccData {
  xAcc?: GraphDataPoint[];
  yAcc?: GraphDataPoint[];
  zAcc?: GraphDataPoint[];
  accComponent?: GraphDataPoint[];
}

export interface ExternalSensorDataSeries {
  sensorId: number;
  data: NullableGraphDataPoint[];
}
export interface PrimaryGraphData {
  timezone: string;
  dataFilters: DataFilterStates;
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  recParams?: VMRecordingParameters;
  accData?: AccData;
  tempData?: NullableGraphDataPoint[];
  rhData?: NullableGraphDataPoint[];
  pressureData?: {
    pressureRaw?: NullableGraphDataPoint[];
    pressureComp?: NullableGraphDataPoint[];
  };
  externalTemp?: ExternalSensorDataSeries[];
  externalRh?: ExternalSensorDataSeries[];
}

/** Custom container component combining selection and cursor container components */
const CustomSelectionCursorContainer = createContainer(
  "selection",
  "cursor"
) as React.ComponentType<
  VictorySelectionContainerProps & VictoryCursorContainerProps
>;

/** A data point in primary graph. The 0th element in y will always hold the
 * measured value while the rest is for potential extra data */
export interface GraphDataPoint {
  x: Date;
  y: number[];
}

/** Used for line data where part of the dataset can be hidden by setting y to
 * null. This will cause a "break" in the data */
export interface NullableGraphDataPoint {
  x: Date;
  y: number[] | null;
}

/** Mini interface that can be combined with Victory component props to get
 * correct datum type */
interface TypedDatumProps {
  datum?: GraphDataPoint;
}

interface IProps {
  data: PrimaryGraphData;
  width: number;
  height: number;
  /** Disables interactivity with the component */
  previewMode?: boolean;
  zoomDomain: { x: [Date, Date]; y: [number, number] };

  zoomDimension?: ZoomDimension;
  handleZoom?: (domain: PrimaryGraphZoomDomain) => void;

  maxAccDataPoints?: number;
}
const PrimaryGraph: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  /** Data to be shown in graph. Filtered for performance reasons */

  const graphData = useMemo(
    () =>
      createPerformantPrimaryGraphData(
        props.data,
        props.zoomDomain.x,
        props.maxAccDataPoints ?? 700,
        props.width
      ),
    [props.data, props.zoomDomain.x, props.maxAccDataPoints, props.width]
  );

  const tempScale = useSelector(selectTemperatureScale);
  const globalScale = useSelector(selectGlobalGraphScale);

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);

  //todo: maybe put as props instead later
  const graphTools = useSelector(selectGraphTools);

  const { dataFilters } = props.data;
  const canvasOffset = getCanvasOffset(dataFilters);
  let xOffsetYaxis = [220, 175, 130, 85, 40];
  // TODO: Find a better way to do this
  if (canvasOffset === 91) {
    xOffsetYaxis.pop();
  }
  const getYAxisOffset = () => xOffsetYaxis.pop();

  //Variables determining which data the graph should render

  const shouldRenderXAccData =
    dataFilters.xAcc.dataToggle.isActive && !isEmpty(graphData?.accData?.xAcc);

  const shouldRenderYAccData =
    dataFilters.yAcc.dataToggle.isActive && !isEmpty(graphData?.accData?.yAcc);

  const shouldRenderZAccData =
    dataFilters.zAcc.dataToggle.isActive && !isEmpty(graphData?.accData?.zAcc);

  const shouldRenderTempDataMultiValue =
    dataFilters.temp.dataToggle.isActive && !isEmpty(graphData?.tempData);

  const shouldRenderTempDataSingleValue =
    dataFilters.temp.dataToggle.isActive && graphData?.tempData?.length === 1;

  const shouldRenderRhDataMultiValue =
    dataFilters.rh.dataToggle.isActive && !isEmpty(graphData?.rhData);

  const shouldRenderRhDataSingleValue =
    dataFilters.rh.dataToggle.isActive && graphData?.rhData?.length === 1;

  const shouldRenderPressureRawDataMultiValue =
    dataFilters.pressureRaw.dataToggle.isActive &&
    !isEmpty(graphData?.pressureData?.pressureRaw);

  const shouldRenderPressureRawDataSingleValue =
    dataFilters.pressureRaw.dataToggle.isActive &&
    graphData?.pressureData?.pressureRaw?.length === 1;

  const shouldRenderPressureCompDataMultiValue =
    dataFilters.pressureComp.dataToggle.isActive &&
    !isEmpty(graphData?.pressureData?.pressureComp);

  const shouldRenderPressureCompDataSingleValue =
    dataFilters.pressureComp.dataToggle.isActive &&
    graphData?.pressureData?.pressureComp?.length === 1;

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = {
    low: yAxisDefaultNormalizedValues[0],
    high: yAxisDefaultNormalizedValues[1]
  };

  const accTickCount = graphAxisScaleSelector(
    props.data.yAxisTickCount.accTick,
    globalScale.acc.count,
    globalScale.acc.toggle,
    globalScale.toggle
  );
  const tempTickCount = graphAxisScaleSelector(
    props.data.yAxisTickCount.tempTick,
    globalScale.temp.count,
    globalScale.temp.toggle,
    globalScale.toggle
  );
  const rhTickCount = graphAxisScaleSelector(
    props.data.yAxisTickCount.rhTick,
    globalScale.rh.count,
    globalScale.rh.toggle,
    globalScale.toggle
  );
  const pressureTickCount = graphAxisScaleSelector(
    props.data.yAxisTickCount.pressureTick,
    globalScale.pressure.count,
    globalScale.pressure.toggle,
    globalScale.toggle
  );

  /** Callback used to style y-axis grid */
  const gridStyleCallback = (tick: number) =>
    tick === 0 || tick === scale.low ? "black" : "#ECEFF1";

  const accDomainUnit = () => {
    const domain = props.data.yAxisDomain.acc ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        domain[0],
        globalScale.acc.min,
        globalScale.acc.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        domain[1],
        globalScale.acc.max,
        globalScale.acc.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const tempDomainUnit = () => {
    const domain = props.data.yAxisDomain.temp ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      TemperatureToScale(
        graphAxisScaleSelector(
          domain[0],
          globalScale.temp.min,
          globalScale.temp.toggle,
          globalScale.toggle
        ),
        tempScale
      ),
      TemperatureToScale(
        graphAxisScaleSelector(
          domain[1],
          globalScale.temp.max,
          globalScale.temp.toggle,
          globalScale.toggle
        ),
        tempScale
      )
    ];
    return newDomain;
  };

  const rhDomainUnit = () => {
    const domain = props.data.yAxisDomain.rh ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        domain[0],
        globalScale.rh.min,
        globalScale.rh.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        domain[1],
        globalScale.rh.max,
        globalScale.rh.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const pressureDomainUnit = () => {
    const domain =
      props.data.yAxisDomain.pressure ?? yAxisDefaultNormalizedValues;
    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        domain[0],
        globalScale.pressure.min,
        globalScale.pressure.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        domain[1],
        globalScale.pressure.max,
        globalScale.pressure.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  // Normalizer funtions is needed since this chart may contain data with
  // different scaling. Normalizing is needed to enable e.g. rh% (0 - 100) and acc
  // (-16 - 16) to be in the same chart
  const {
    normalizeValue: normalizeAccValue,
    denormalizeValue: denormalizeAccValue
  } = getNormalizerFunctions(accDomainUnit());

  const {
    normalizeValue: normalizeTempValue,
    denormalizeValue: denormalizeTempValue
  } = getNormalizerFunctions(tempDomainUnit(), scale);

  const {
    normalizeValue: normalizeRhValue,
    denormalizeValue: denormalizeRhValue
  } = getNormalizerFunctions(rhDomainUnit(), scale);

  const {
    normalizeValue: normalizePressureValue,
    denormalizeValue: denormalizePressureValue
  } = getNormalizerFunctions(pressureDomainUnit(), scale);

  /** Function attatched to keyboard arrow-keys events. Used to move vertical
   * line in graph */
  const keyDownCallback = useMemo(
    () =>
      throttle((evt: KeyboardEvent) => {
        if (evt?.key === "ArrowLeft") {
          dispatch<any>(setScoreValuesCursorPosToPrior());
        } else if (evt?.key === "ArrowRight") {
          dispatch<any>(setScoreValuesCursorPosToNext());
        }
      }, 200),
    [dispatch]
  );

  const chartPadding = {
    ...commonPrimaryGraphProps.chartPadding,
    left: canvasOffset
  };

  /** Describes how much a pixel is worth in ms */
  const pixelWorthMs =
    (props.width - chartPadding.left - chartPadding.right) /
    (props.zoomDomain.x[1].getTime() - props.zoomDomain.x[0].getTime());

  /** Utility function used for barwidth. Returns a width in pixels based on
   * acceleration duration */
  const getAccBarWidth = (v: Omit<CallbackArgs, "datum"> & TypedDatumProps) => {
    const duration = v?.datum?.y?.[1];
    if (!duration) return 0;
    const widthInPixels = duration * pixelWorthMs;
    // If the width is below 1px, it will be barely visible
    if (widthInPixels < 1.5) return 1.5;

    return widthInPixels;
  };

  interface IBounds {
    x: [Date, Date];
    y: [number, number];
  }
  const handleZoomSelection = (bounds: IBounds) => {
    // If the user select the area in the y-axis, we will get a x0 value outside
    // the zoom-domain. The lines below fixes that
    const lowerDomain = props.zoomDomain.x[0];
    const fixedBoundsX: [Date, Date] =
      bounds.x[0] < lowerDomain ? [lowerDomain, bounds.x[1]] : bounds.x;

    //Note: If user selects from right to left, the first value will be larger
    //than the seconds value, causing a bugged selection. {toLowHighOrder} solves that

    const newXDomain: [Date, Date] = toLowHighOrder(fixedBoundsX);
    const newYDomain: [number, number] = toLowHighOrder(bounds.y);

    props.handleZoom?.({ x: newXDomain, y: newYDomain });
  };

  /** Creates a label for a context line with all active data channels */
  const getContextLineLabel = (contextLinePos: number) => {
    let labels: string[] = [];
    const { temp, rh } = graphData.dataFilters;

    const round2Decimals = (v: number) => Math.round(v * 100) / 100;

    if (isAccFilterActive(graphData.dataFilters)) {
      const accLabel = `acc: ${round2Decimals(denormalizeAccValue(contextLinePos))}g`;
      labels.push(accLabel);
    }

    if (temp.dataToggle.isActive) {
      const tempLabel = `temp: ${round2Decimals(
        denormalizeTempValue(contextLinePos)
      )}${TempScaleSymbol(tempScale)}`;
      labels.push(tempLabel);
    }

    if (rh.dataToggle.isActive) {
      const rhLabel = `rh: ${round2Decimals(denormalizeRhValue(contextLinePos))}%`;
      labels.push(rhLabel);
    }

    const finalLabel = labels.reduce(
      (res, curr, i, arr) =>
        i === arr.length - 1 ? res.concat(curr) : res.concat(`${curr}, `),
      ""
    );

    return finalLabel;
  };

  const formatYValueAcc = (y: number) =>
    formatYData(y, accDomainUnit(), normalizeAccValue);

  const formatYValueTemp = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(
          TemperatureToScale(y, tempScale),
          tempDomainUnit(),
          normalizeTempValue
        );

  const formatYValueRh = (y: Nullable<number>) =>
    y === null ? 0 : formatYData(y, rhDomainUnit(), normalizeRhValue);

  const formatYValuePressure = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(y, pressureDomainUnit(), normalizePressureValue);

  /** Common base event when releasing a context line */
  const releaseContextLineMutation = {
    holdingContextLine: false,
    cursorValue: null
  } as any;

  /** Get x-axis acceleration data to display */
  const xAccData = graphData.accData?.xAcc ?? [];
  const xAccEdgeData = xAccData
    .filter(
      (dataPoint) => dataPoint.x.getTime() < props.zoomDomain.x[0].getTime()
    )
    .map((dataPoint) => {
      return {
        ...dataPoint,
        x: new Date(dataPoint.x.getTime() + dataPoint.y[1])
      };
    });

  /** Get y-axis acceleration data to display */
  const yAccData = graphData.accData?.yAcc ?? [];
  const yAccEdgeData = yAccData
    .filter(
      (dataPoint) => dataPoint.x.getTime() < props.zoomDomain.x[0].getTime()
    )
    .map((dataPoint) => {
      return {
        ...dataPoint,
        x: new Date(dataPoint.x.getTime() + dataPoint.y[1])
      };
    });

  /** Get z-axis acceleration data to display */
  const zAccData = graphData.accData?.zAcc ?? [];
  const zAccEdgeData = zAccData
    .filter(
      (dataPoint) => dataPoint.x.getTime() < props.zoomDomain.x[0].getTime()
    )
    .map((dataPoint) => {
      return {
        ...dataPoint,
        x: new Date(dataPoint.x.getTime() + dataPoint.y[1])
      };
    });

  /** --Simple description of graph events--
   * Left click in y-axis area: create context line(s)
   * Right click in y-axis area: remove context line if close enough to the
     cursor
   * Left click + drag in graph: select area to zoom into
   * Left click in graph (without draging): create score values cursor
  */
  const events: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onMouseDown: (evt) => ({
        target: "parent",
        mutation: (targetProps) => {
          const { x, y } = Selection.getSVGEventCoordinates(evt);

          // Create new context line
          if (x < canvasOffset) {
            const yPosInGraph: number = targetProps.scale.y.invert(y);

            // removes a context line if it's close enough. Otherwhise
            // nothing happens
            dispatch(removeNearestContextLineIfClose(yPosInGraph));

            return {
              cursorValue: { y: yPosInGraph },
              holdingContextLine: true
            };
          }

          //default container event (zoom)
          const container = SelectionHelpers.onMouseDown(
            evt,
            targetProps
          ) as any;
          if (Array.isArray(container)) {
            return container[0]?.mutation();
          }
        }
      }),
      onMouseMove: (evt) => ({
        mutation: (targetProps) => {
          // Creating new context line
          if (targetProps.holdingContextLine) {
            const { y } = Selection.getSVGEventCoordinates(evt);
            const yPosInGraph: number = targetProps.scale.y.invert(y);

            return { cursorValue: { y: yPosInGraph } };
          }

          //default container event (zoom)
          const selectionContainerDefaultMutation =
            SelectionHelpers.onMouseMove(evt, targetProps)?.mutation();

          return selectionContainerDefaultMutation;
        }
      }),
      onMouseUp: (evt) => ({
        mutation: (targetProps) => {
          const { y } = Selection.getSVGEventCoordinates(evt);
          const { holdingContextLine, onSelection, x1, x2, y1, y2, scale } =
            targetProps;

          const baseMutation = releaseContextLineMutation;

          //User has released the context line
          if (holdingContextLine) {
            const yPosInGraph: number = targetProps.scale.y.invert(y);
            dispatch(setContextLineNewPos(yPosInGraph));
            return baseMutation;
          }

          const x1PosIngraph: Date = scale?.x?.invert(x1);
          const x2PosIngraph: Date = scale?.x?.invert(x2);
          const y1PosIngraph = scale?.y?.invert(y1);
          const y2PosIngraph = scale?.y?.invert(y2);

          const selectionContainerDefaultMutation = {
            select: false,
            x1: null,
            x2: null,
            y1: null,
            y2: null
          } as any;

          //score values event. No area has been selection = counts as a
          //simple click
          if (Math.abs(x1 - x2) < 3) {
            const dateAsUnix = dateToUnix(x1PosIngraph);
            dispatch(setNewCursorPosition(dateAsUnix));

            return {
              ...baseMutation,
              ...selectionContainerDefaultMutation
            };
          }

          //default container event (zoom)
          const bounds: IBounds = {
            x: [x1PosIngraph, x2PosIngraph],
            y: [y1PosIngraph, y2PosIngraph]
          };

          onSelection([], bounds, targetProps);

          return {
            ...baseMutation,
            ...selectionContainerDefaultMutation
          };
        }
      }),
      onContextMenu: (evt) => ({
        mutation: (targetProps) => {
          const { x, y } = Selection.getSVGEventCoordinates(evt);

          //context line remove
          if (x < canvasOffset) {
            const yPosInGraph: number = targetProps.scale.y.invert(y);
            dispatch(removeNearestContextLineIfClose(yPosInGraph));
          }
        }
      }),
      // This fixes some issues that occuer when the user has their cursor in
      // the graph and then leaves the application
      onMouseLeave: (evt) => {
        return { mutation: () => ({}) };
      }
    }
  };

  useEffect(() => {
    if (graphTools?.scoreValuesIsActive) {
      document.addEventListener("keydown", keyDownCallback, false);
    }

    /** clean up when component unmounts */
    return () => {
      document.removeEventListener("keydown", keyDownCallback, false);
    };
  }, [keyDownCallback, graphTools?.scoreValuesIsActive]);

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const showTempAxis =
    dataFilters.temp.dataToggle.isActive ||
    isExternalTempFilterActive(dataFilters);
  const showRhAxis =
    dataFilters.rh.dataToggle.isActive || isExternalRhFilterActive(dataFilters);

  return (
    <div>
      <VictoryChart
        events={props.previewMode ? undefined : [events]}
        name="chartCanvas"
        width={props.width}
        height={props.height}
        standalone={true}
        domain={props.zoomDomain}
        padding={chartPadding}
        domainPadding={commonPrimaryGraphProps.domainPadding}
        scale={{ x: "time", y: "linear" }}
        containerComponent={
          props.previewMode ? (
            <VictoryContainer />
          ) : (
            <CustomSelectionCursorContainer
              selectionDimension={zoomDimensionToVictoryProp(
                props.zoomDimension
              )}
              onSelection={(points, bounds, props: any) => {
                // Note i've noticed that the bounds argument contains wierd
                // values for y+, therefore I calculate the bounds mannually
                let boundsNew = Selection.getBounds(props);
                handleZoomSelection(boundsNew as any);
              }}
              cursorDimension="y"
              allowSelection
            />
          )
        }
        theme={VictoryTheme.material}
      >
        {/* x-axis(based on time, show no values, based on origo) */}
        <VictoryAxis
          tickFormat={[]}
          tickCount={timeScaleSteps}
          style={{
            ...commonAxisStyle,
            axis: { stroke: "black" }
          }}
          axisValue={normalizeAccValue(0) ?? 0}
        />

        {/* x-axis(based on time, show values) */}
        <VictoryAxis
          tickFormat={(t, _i, arr) =>
            dateAxisFormater(
              t,
              arr,
              timezoneSelector(
                props.data.timezone,
                timezoneState,
                timezoneToggle
              )
            )
          }
          fixLabelOverlap
          tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
          axisValue={props.zoomDomain.y[0]}
          style={{
            ...commonAxisStyle,
            axis: { stroke: "black" }
          }}
        />

        {(shouldRenderXAccData ||
          shouldRenderYAccData ||
          shouldRenderZAccData) && (
          <VictoryAxis
            dependentAxis
            offsetX={getYAxisOffset()}
            label="g"
            fixLabelOverlap
            style={{
              axis: { stroke: "black" },
              ...commonAxisStyle,
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              }
            }}
            axisLabelComponent={
              <VictoryLabel y={24} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(accTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeAccValue(t), accDomainUnit())
            }
          />
        )}

        {/* y-axis(temperature) */}
        {showTempAxis && (
          <VictoryAxis
            name="tempAxis"
            dependentAxis
            offsetX={getYAxisOffset()}
            label={TempScaleSymbol(tempScale)}
            fixLabelOverlap
            style={{
              ...commonAxisStyle,
              axis: { stroke: iconColors.temp },
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              }
            }}
            axisLabelComponent={
              <VictoryLabel y={24} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(tempTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeTempValue(t), tempDomainUnit())
            }
          />
        )}

        {/* y-axis(rh) */}
        {showRhAxis && (
          <VictoryAxis
            name="rhAxis"
            dependentAxis
            offsetX={getYAxisOffset()}
            label="Rh(%)"
            fixLabelOverlap
            style={{
              ...commonAxisStyle,
              axis: { stroke: iconColors.rh },
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              }
            }}
            axisLabelComponent={
              <VictoryLabel y={24} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(rhTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizeRhValue(t), rhDomainUnit())
            }
          />
        )}

        {/* y-axis(pressure) */}
        {(dataFilters.pressureRaw.dataToggle.isActive ||
          dataFilters.pressureComp.dataToggle.isActive) && (
          <VictoryAxis
            name="pressureAxis"
            dependentAxis
            offsetX={getYAxisOffset()}
            label="mbar"
            fixLabelOverlap
            style={{
              ...commonAxisStyle,
              axis: { stroke: iconColors.pressure },
              grid: {
                stroke: (tick: any) => gridStyleCallback(tick)
              }
            }}
            axisLabelComponent={
              <VictoryLabel y={24} dx={39} textAnchor="middle" />
            }
            tickValues={generateTickValues(pressureTickCount)}
            tickFormat={(t) =>
              yAxisFormater(denormalizePressureValue(t), pressureDomainUnit())
            }
          />
        )}

        {/* data from x-acc */}
        {shouldRenderXAccData && (
          <VictoryBar
            data={xAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.xAccA } }}
            alignment="start"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}
        {/* data from x-acc */}
        {shouldRenderXAccData && xAccEdgeData.length > 0 && (
          <VictoryBar
            data={xAccEdgeData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.xAccA } }}
            alignment="end"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from y-acc */}
        {shouldRenderYAccData && (
          <VictoryBar
            data={yAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.yAccA } }}
            alignment="start"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}
        {/* data from y-acc */}
        {shouldRenderYAccData && yAccEdgeData.length > 0 && (
          <VictoryBar
            data={yAccEdgeData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.yAccA } }}
            alignment="end"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from z-acc */}
        {shouldRenderZAccData && (
          <VictoryBar
            data={zAccData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.zAccA } }}
            alignment="start"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}
        {/* data from z-acc */}
        {shouldRenderZAccData && zAccEdgeData.length > 0 && (
          <VictoryBar
            data={zAccEdgeData}
            x="x"
            y={(datum: GraphDataPoint) => formatYValueAcc(datum.y[0])}
            style={{ data: { fill: iconColors.zAccA } }}
            alignment="end"
            barWidth={getAccBarWidth}
            groupComponent={<VictoryClipContainer />}
          />
        )}

        {/* data from temperature */}
        {shouldRenderTempDataMultiValue && (
          <VictoryLine
            name="temp"
            data={graphData.tempData ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueTemp(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.temp } }}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderTempDataSingleValue && (
          <VictoryScatter
            name="temp1Data"
            data={graphData.tempData ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueTemp(datum.y?.[0] ?? null)
            }
            size={5}
            style={{ data: { fill: iconColors.temp } }}
          />
        )}

        {/* data from rh */}
        {shouldRenderRhDataMultiValue && (
          <VictoryLine
            name="rh"
            data={graphData.rhData ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueRh(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.rh } }}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderRhDataSingleValue && (
          <VictoryScatter
            name="rh1Data"
            data={graphData.rhData ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValueRh(datum.y?.[0] ?? null)
            }
            size={5}
            style={{ data: { fill: iconColors.rh } }}
          />
        )}

        {/* data from pressureRaw */}
        {shouldRenderPressureRawDataMultiValue && (
          <VictoryLine
            name="pressureRaw"
            data={graphData.pressureData?.pressureRaw ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValuePressure(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.pressureRaw } }}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderPressureRawDataSingleValue && (
          <VictoryScatter
            name="pressureRaw1Data"
            data={graphData.pressureData?.pressureRaw ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValuePressure(datum.y?.[0] ?? null)
            }
            size={5}
            style={{ data: { fill: iconColors.pressureRaw } }}
          />
        )}

        {/* data from pressureComp */}
        {shouldRenderPressureCompDataMultiValue && (
          <VictoryLine
            name="pressureComp"
            data={graphData.pressureData?.pressureComp ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValuePressure(datum.y?.[0] ?? null)
            }
            style={{ data: { stroke: iconColors.pressureComp } }}
          />
        )}

        {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
        {shouldRenderPressureCompDataSingleValue && (
          <VictoryScatter
            name="pressureComp1Data"
            data={graphData.pressureData?.pressureComp ?? []}
            y={(datum: NullableGraphDataPoint) =>
              formatYValuePressure(datum.y?.[0] ?? null)
            }
            size={5}
            style={{ data: { fill: iconColors.pressureComp } }}
          />
        )}

        {/* data from external temp */}
        {graphData.externalTemp &&
          graphData.externalTemp.map((series, index) => {
            const { sensorId, data: lineData } = series;
            const shouldRenderData =
              dataFilters.extTemp[sensorId].dataToggle.isActive &&
              !isEmpty(lineData);
            if (!shouldRenderData) return null;
            const single = lineData.length === 1;

            if (single) {
              return (
                <VictoryScatter
                  key={"extTemp-" + sensorId}
                  name={"extTemp-" + sensorId}
                  data={lineData}
                  y={(datum: NullableGraphDataPoint) =>
                    formatYValueTemp(datum.y?.[0] ?? null)
                  }
                  size={5}
                  style={{ data: { fill: externalTempColors[index % 4] } }}
                />
              );
            } else {
              return (
                <VictoryLine
                  key={"extTemp-" + sensorId}
                  name={"extTemp-" + sensorId}
                  data={lineData}
                  y={(datum: NullableGraphDataPoint) =>
                    formatYValueTemp(datum.y?.[0] ?? null)
                  }
                  style={{ data: { stroke: externalTempColors[index % 4] } }}
                />
              );
            }
          })}

        {/* data from external rh */}
        {graphData.externalRh &&
          graphData.externalRh.map((series, index) => {
            const { sensorId, data: lineData } = series;
            const shouldRenderData =
              dataFilters.extRh[sensorId].dataToggle.isActive &&
              !isEmpty(lineData);
            if (!shouldRenderData) return null;
            const single = lineData.length === 1;

            if (single) {
              return (
                <VictoryScatter
                  key={"extRh-" + sensorId}
                  name={"extRh-" + sensorId}
                  data={lineData}
                  y={(datum: NullableGraphDataPoint) =>
                    formatYValueRh(datum.y?.[0] ?? null)
                  }
                  size={5}
                  style={{ data: { fill: externalRhColors[index % 4] } }}
                />
              );
            } else {
              return (
                <VictoryLine
                  key={"extRh-" + sensorId}
                  name={"extRh-" + sensorId}
                  data={lineData}
                  y={(datum: NullableGraphDataPoint) =>
                    formatYValueRh(datum.y?.[0] ?? null)
                  }
                  style={{ data: { stroke: externalRhColors[index % 4] } }}
                />
              );
            }
          })}

        {/* score values line */}
        {graphTools?.scoreValuesCursorPos && (
          <VictoryAxis
            dependentAxis
            key={"scoreValuesCursorPos"}
            tickValues={undefined}
            style={scoreAxisStyle}
            axisValue={dayjs.unix(graphTools!.scoreValuesCursorPos)}
          />
        )}

        {/* context line in y-axis */}
        {!isEmpty(graphTools?.contextLines) &&
          graphTools?.contextLines?.map((l, i) => (
            <VictoryLine
              key={i}
              y={() => l}
              labels={[getContextLineLabel(l)]}
              labelComponent={<VictoryLabel textAnchor="start" />}
            />
          ))}
      </VictoryChart>
    </div>
  );
};

export default React.memo(PrimaryGraph);
