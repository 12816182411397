// import { ValueOf } from "../utils/utilTypes";

//========== Protocol Commands
export const PCMD_ACK = 0x0102;
export const PCMD_NACK = 0x0103;

export const PCMD_ACK1 = 0x0111;
export const PCMD_ACK2 = 0x0112;
export const PCMD_ACK3 = 0x0113;
export const PCMD_ACK4 = 0x0114;
export const PCMD_NACK1 = 0x0115;
export const PCMD_NACK2 = 0x0116;
export const PCMD_NACK3 = 0x0117;
export const PCMD_NACK4 = 0x0118;

export const PCMD_REQNEWDATA = 0x0120;
export const PCMD_REQALLDATA = 0x0121;
export const PCMD_DATA = 0x0122;
export const PCMD_ENDOFDATA = 0x012f;

export const PCMD_SYNC1 = 0x0881;
export const PCMD_SYNC2 = 0x0842;
export const PCMD_SYNC3 = 0x0824;
export const PCMD_SYNC4 = 0x0818;

export const PCMD_REQLOADRECPARAMS = 0x1000;
export const PCMD_REQLOADRECPARFMT = 0x1001; // Memfmt regardless data read
export const PCMD_LOADRECPARAMS = 0x1002;

export const PCMD_REQRECPARAMS = 0x1005;
export const PCMD_RECPARAMS = 0x1006;

export const PCMD_REQCURRSTATUS = 0x1008;
export const PCMD_CURRSTATUS = 0x1009;

export const PCMD_REQPGMINFO = 0x100a;
export const PCMD_PGMINFO = 0x100b;

export const PCMD_REQDEVCONFIG = 0x100c;
export const PCMD_DEVCONFIG = 0x100d;

export const PCMD_SETTIME = 0x1010;
export const PCMD_GETTIME = 0x1011;
export const PCMD_CURRTIME = 0x1012;

export const PCMD_REQSYSTEMINFO = 0x1015;
export const PCMD_SYSTEMINFO = 0x1016;

export const PCMD_REQBATSTAT = 0x1018; // Request current Battery status
export const PCMD_BATSTAT = 0x1019;

export const PCMD_UPDBATSTAT = 0x101a; // Update Battery status

export const PCMD_REQFORMAT = 0x1020;
export const PCMD_FORMATCOMPLETE = 0x1021;

export const PCMD_REQPWDSTATUS = 0x2600;
export const PCMD_PWDSTATUS = 0x2601;

export const PCMD_CONNECTINFO = 0x2000;
export const PCMD_DISCONNECT = 0x2001;

export const PCMD_CONNECTTIMEOUT = 0x2008; // Sent to master just before disconnecting due to inactivity timoeout.

export const PCMD_TERMINAL = 0x8000;
export const PCMD_TERMINAL_EXIT = 0x8001;

export const PCMD_MEMFMT = 0x80f1;
export const PCMD_MEMFMT_COMPLETE = 0x80f2;
export const PCMD_MEMFMT_NO_CHECK = 0x80f3; // Format Mem without checking for unread data

//----- Protocol "error cmd:s"
export const PCMD_ERROR = 0xe000; // Error mask
export const PCMD_ERR_NEWDATA_AVAIL = 0xe001; // There is unread data
export const PCMD_ERR_MEM_NOTEMPTY = 0xe002; // Memory not empty
export const PCMD_ERR_MEM_EMPTY = 0xe003; // Memory is empty, requested data not found
export const PCMD_ERR_MEM_FULL = 0xe004; // Memory is full, recording can't be completed
export const PCMD_ERR_FORMAT = 0xe005; // Error during format
export const PCMD_ERR_ACTIVERECORDING = 0xe006; // Recording has started, selected command is blocked
export const PCMD_ERR_UNSUPPORTEDPARAM = 0xe007; // Parameter(s) out of range
export const PCMD_ERR_TIMEOUT = 0xe008; // Timeout while waiting for reply from master
export const PCMD_ERR_SAVEING_PARAMS = 0xe009; // Error while saving parameters to flash or F-RAM
export const PCMD_ERR_INTERNAL = 0xe00a; // Internal Error in Slave
export const PCMD_ERR_INVALID_PWD = 0xe00b; // Incorrect password
export const PCMD_ERR_FW_ADDRESS = 0xe0f0; // Firmware address error
export const PCMD_ERR_FW_WRITE = 0xe0f1; // Firmware write error
export const PCMD_ERR_FW_READ = 0xe0f2; // Firmware read error
export const PCMD_ERR_FW_BANK = 0xe0f3; // Firmware bank error
export const PCMD_ERR_FW_FORMAT = 0xe0f4; // Firmware format error
export const PCMD_ERR_FW_SWAPREBOOT = 0xe0f5; // Firmware swap reboot error
export const PCMD_ERR_UNKNOWN = 0xe0ff; // Any other error

//----- FirmWare
export const PCMD_REQFWBANKINFO = 0xf001;
export const PCMD_FWBANKINFO = 0xf002;

export const PCMD_REQFWFLASHFORMAT = 0xf010;
export const PCMD_FWFFORMATDONE = 0xf011;

export const PCMD_REQFWFLASHWRITE = 0xf012;
export const PCMD_FWWRITEDONE = 0xf013;

export const PCMD_REQFWFLASHREAD = 0xf014;

export const PCMD_REQFWSWAPREBOOT = 0xf015;
export const PCMD_FWDATA = 0xf016;
export const PCMD_REQFWFLASHCOMPLETE = 0xf017;

//========== Protocol states
export const FPS_IDLE = 0x0000;

export const FPS_TRM_MODE_MASK = 0x0100; // trm mode mask
export const FPS_TRM_MODE_START = 0x0101; // trm mode
export const FPS_TRM_MODE_ACTIVE = 0x0102; // trm mode
export const FPS_TRM_MODE_EXIT = 0x0103; // trm mode

export const FPS_MEMFMT_MASK = 0x0200; // fmt mask
export const FPS_MEMFMT_USED = 0x0202; // fmt used recdata
export const FPS_MEMFMT_RECDATA = 0x0203; // fmt all recdata
export const FPS_MEMFMT_ACTIVE = 0x0204; // fmt is ongoing
export const FPS_MEMFMT_CMPL = 0x0205; // fmt done

export const FPS_LOADRECPAR_MASK = 0x0300; // load rec par mask
export const FPS_LOADRECPAR_WAIT = 0x0301; // load rec par

export const FPS_LOADSCHPAR_MASK = 0x0400; // load schedule par mask
export const FPS_LOADSCHPAR_WAIT = 0x0401; // load schedule par

export const FPS_READRECDATA_MASK = 0x0500; // read rec data mask
export const FPS_READREC_SYSINFO = 0x0501; // read rec system info
export const FPS_READRECDATA = 0x0502; // read rec data
export const FPS_READREC_EOD = 0x0503; // rec data end of data

export const FPS_READRECPARAM_MASK = 0x0600; // read rec params mask
export const FPS_RECPARAM_SEND = 0x0601; // rec params send
export const FPS_RECPARAM_EOD = 0x0602; // rec params end of data

export const FPS_READCURRSTATUS_MASK = 0x0700; // read current status mask
export const FPS_READCURRSTATUS_WAIT = 0x0701; // wait for ack on current status

export const FPS_READSYSTEMINFO_MASK = 0x0800; // read systeminfo mask
export const FPS_READSYSTEMINFO_WAIT = 0x0801; // wait for ack or nack

export const FPS_COM_SETTIME_MASK = 0x0b00; // set time mask
export const FPS_COM_SETTIME_CHECK = 0x0b01; // check time data

export const FPS_COM_GETTIME_MASK = 0x0c00; // get time mask
export const FPS_COM_GETTIME_WAIT = 0x0c01; // wait for ack or nack

export const FPS_READDEVCONFIG_MASK = 0x0d00; // read dev config mask
export const FPS_READDEVCONFIG_SEND = 0x0d01; // wait for ack on dev config

export const FPS_REQPGMINFO_MASK = 0x0e00; //pgm info mask
export const FPS_REQPGMINFO_SEND = 0x0e01; //

export const FPS_COM_DISCONNECT_MASK = 0x0f00; // disconnect mask

export const FPS_REQBATSTAT_MASK = 0x1200; // request Battery status mask
export const FPS_REQBATSTAT_WAIT = 0x1201; // wait for ACK or NACK

export const FPS_SEND_ERR = 0xe001; // send error msg

//---------- device config
//----- features
export const DF_ACC = 0x00000001;
export const DF_TEMP = 0x00000002;
export const DF_RH = 0x00000004;
export const DF_ANGLE = 0x00000008;
export const DF_PRESSURE = 0x00000010;
export const DF_INT_LIGHT = 0x00000020;
export const DF_GPS = 0x00000040;
export const DF_LTE = 0x00000080;
export const DF_BLUETOOTH = 0x00000100;
export const DF_EXT_INPUTS = 0x00000200; // Not used
export const DF_EXT_OUTPUTS = 0x00000400; // Not used
export const DF_RESERVED1 = 0x00000800;
export const DF_DVA = 0x00001000;
export const DF_ACCEXTRA = 0x00002000; // if using AccExtra as main acc
export const DF_ANGLE_SENSOR = 0x00004000;

export const DF_EXT_IO_MASK = 0x000f0000;
export const DF_EXT_IO_CFG1 = 0x00010000; // 1 Ext In (wake up/alarm), 1 Ext Out
export const DF_EXT_IO_CFG2 = 0x00020000; // 2 Ext In (wake up/alarm), 2 Ext Out
export const DF_EXT_IO_CFG3 = 0x00030000; // 1 Ext In (wake up), Standard CAN via CAN-transiver on IO-pcb
export const DF_EXT_IO_CFG4 = 0x00040000; // IO-pcb with I2C support for external sensors
export const DF_EXT_IO_CFG5 = 0x00050000; // Reserved 2
export const DF_EXT_IO_CFG6 = 0x00060000; // Reserved 3
export const DF_EXT_IO_CFG7 = 0x00070000; // Reserved 4
export const DF_EXT_IO_CFG8 = 0x00080000; // Reserved 5
export const DF_EXT_IO_CFG9 = 0x00090000; // Reserved 6
export const DF_EXT_IO_CFG10 = 0x000a0000; // Reserved 7
export const DF_EXT_IO_CFG11 = 0x000b0000; // Reserved 8
export const DF_EXT_IO_CFG12 = 0x000c0000; // Reserved 9
export const DF_EXT_IO_CFG13 = 0x000d0000; // Reserved 10

// todo: Which of externalIO and externalSensors should be visible by default?
export const ALL_FEATURES = 0x0004ffff; // All features, but only I2C

//----------Recording parameters
export const KIND_RECPARAMS = 0x0003;

export const CT_CfgVersion = 0x40;
export const CT_ProgInfo = 0x41;
export const CT_LicenseKey = 0x42;
export const CT_RecParams = 0x43;
export const CT_AccParams = 0x45;
export const CT_AccDVATrigLevel = 0x46;
export const CT_TempParams = 0x47;
export const CT_RhParams = 0x48;
export const CT_AngleParams = 0x49;
export const CT_PressureParams = 0x4a;
export const CT_ExternalInputs = 0x4b;
export const CT_ExternalOutputs = 0x4c;
export const CT_LightSensorParams = 0x4d;
export const CT_GPS = 0x4e;
export const CT_LTE = 0x4f;
export const CT_UserInfo = 0x50;
export const CT_Password = 0x51;
export const CT_ExternalSensor = 0x52;
export const CT_ProjectName = 0x53;
export const CT_AccFilterValues = 0x54;
export const CT_CompanyId = 0x55;
export const CT_ParameterId = 0x56;

export const REC_START_DOWNLOAD = 0x10;
export const REC_START_DATE = 0x20;
export const REC_START_ON_BUTTON = 0x40;
export const REC_STOP_DATE = 0x01;
export const REC_STOP_DURATION = 0x02;
export const REC_START_NOT_LOADED = 0x00; // when no recording parameters are loaded yet

export type ValidRecStartMode =
  | typeof REC_START_DOWNLOAD
  | typeof REC_START_DATE
  | typeof REC_START_ON_BUTTON;

export type ValidRecStopMode = typeof REC_STOP_DATE | typeof REC_STOP_DURATION;

export const CX_Schedule = 0x80; // Schedule data

//========= Schedules
//--- Schedule Sensor Id
export const SSI_Acc = 0x00; // Acc
export const SSI_Temp = 0x01; // Temp
export const SSI_RH = 0x02; // Rh
export const SSI_Angle = 0x03; // Angle
export const SSI_Pressure = 0x04; // Pressure
export const SSI_Light = 0x05; // Internal lightsensor
export const SSI_GPS = 0x06; // GPS available
export const SSI_LTE = 0x07; // LTE available
export const SSI_Bluetooth = 0x08; // Bluetooth available
export const SSI_Dev_Status = 0x09; // Running status bits
export const SSI_Dev_Health = 0x0a; // Memory, Main battery and Backup battery status
export const SSI_Ext_Inputs = 0x10; // External inputs state
export const SSI_Ext_Outputs = 0x11; // External outputs state
export const SSI_Ext_Sensor1 = 0x20; // External Sensor1
export const SSI_Ext_Sensor2 = 0x21; // External Sensor2
export const SSI_Ext_Sensor3 = 0x22; // External Sensor3
export const SSI_Ext_Sensor4 = 0x23; // External Sensor4
export const SSI_Ext_Sensor5 = 0x24; // External Sensor5
export const SSI_Ext_Sensor6 = 0x25; // External Sensor6
export const SSI_Ext_Sensor7 = 0x26; // External Sensor7
export const SSI_Ext_Sensor8 = 0x27; // External Sensor8
export const External_Sensors_SSI = [
  SSI_Ext_Sensor1,
  SSI_Ext_Sensor2,
  SSI_Ext_Sensor3,
  SSI_Ext_Sensor4,
  SSI_Ext_Sensor5,
  SSI_Ext_Sensor6,
  SSI_Ext_Sensor7,
  SSI_Ext_Sensor8
];

export const SSI_Not_Active = 0xff; // Channel not in use

export const PCMD_LOGIN = 0x2100; //
export const PCMD_ABORT_LOGIN = 0x2200; // terminate login attempt
export const PCMD_USER_LEV = 0x2300; // Indicate back to cargolog granted access level.

export const PCMD_SETPWDCMD = 0x2400; // Set password
export const PCMD_PWD_NEEDED = 0x2500; // Password is required
export const PCMD_ERR_ADM_PWD_NEEDED = 0xe020; // Login with Admin nedded
export const PCMD_ERR_READONLY_PWD_NEEDED = 0xe021; // Login with ReadOnly needed

// PWD types
export const PWD_DATX = 0x10;
export const PWD_READONLY = 0x11;
export const PWD_ADMIN = 0x12;

export const UL_NONE = 0x00;
export const UL_READONLY = 0x01;
export const UL_ADMIN = 0x03;
export const UL_MOBITRON = 0x07;

export const channelBitMasks = {
  Temp: 0x0002,
  Rh: 0x0004,
  Angle: 0x0008,
  Pressure: 0x0010,
  Light: 0x0020,
  GPS: 0x0040,
  LTE: 0x0080,
  Bluetooth: 0x0100,
  DeviceHealth: 0x8000
};

export const alarmStatusBitMasks = {
  Acceleration: 0x0003,
  Temperature: 0x000c,
  Rh: 0x0030,
  Angle: 0x00c0,
  Pressure: 0x0300,
  GPS: 0x0c00,
  LTE: 0x3000,
  Other: 0xc000,
  External: 0x30000
};

export const alarmInputBitsBitMasks = {
  input0used: 0x0001,
  input1used: 0x0002,
  input2used: 0x0004,
  input3used: 0x0008,
  input4used: 0x0010,
  input5used: 0x0020,
  input6used: 0x0040,
  input7used: 0x0080
};

export const alarmOutputBitsBitMasks = {
  output0used: 0x0001,
  output1used: 0x0002,
  output2used: 0x0004,
  output3used: 0x0008,
  output4used: 0x0010,
  output5used: 0x0020,
  output6used: 0x0040,
  output7used: 0x0080,
  output0newState: 0x0100, // 0 = off, 1 = on
  output1newState: 0x0200, // 0 = off, 1 = on
  output2newState: 0x0400, // 0 = off, 1 = on
  output3newState: 0x0800, // 0 = off, 1 = on
  output4newState: 0x1000, // 0 = off, 1 = on
  output5newState: 0x2000, // 0 = off, 1 = on
  output6newState: 0x4000, // 0 = off, 1 = on
  output7newState: 0x8000 // 0 = off, 1 = on
};

export const onChangeBits = {
  saveTrigg: 0x01, // If trigged IO should be saved to data
  alarmOnTrigg: 0x02, // If trigged IO should create alarm
  triggOnImpact: 0x04, // If IO should be triggered on impact (1)
  triggOnReset: 0x04, // If IO should be triggered on reset (0)
  triggOnAll: 0x08 // If IO should be triggered both reset and impact
};

// Bit 0-3: Error code
//   0000 No error
//   0001 Failed to init LTE module
//   0010 Failed to connect to network
//   0011 Failed to reach dataserver (timeout)
//   0100 Transmission not completed (aborted)
//   0101 No suitable power available
//   0110 No SIM found
//   1000 LTE antenna error
// Bit 4: 1 = Blocked by no GPS position (GPS
// Required active)
// Bit 5: 1 = Failed to init CoMCU
// Bit 6: 1 = Communication problems towards
// CoMCU
// Bit 8: 1 = LTE Power On
// Bit 9: 1 = LTE Power Off
// Bit 10: 1 = LTE connect trigged by sensor
// Bit 11: 1 = LTE connect trigged by schedule
// Bit 12: 1 = LTE session ended
export const lteStatusBitMasks = {
  errorCodeMask: 0b1111,
  lteFailedToInitLTEModule: 0b0001,
  lteFailedToConnectToNetwork: 0b0010,
  lteFailedToReachDataserver: 0b0011,
  lteTransmissionNotCompleted: 0b0100,
  lteNoSuitablePowerAvailable: 0b0101,
  lteNoSIMFound: 0b0110,
  lteAntennaError: 0b1000,
  lteBlockedByGPS: 16,
  lteFailedToInitCoMCU: 32,
  lteCommunicationProblemsCoMCU: 64,
  ltePowerOn: 256,
  ltePowerOff: 512,
  lteTriggeredBySensor: 1024,
  lteTriggeredBySchedule: 2048,
  lteSessionEnded: 4096
};

export const gpsStatusBitMasks = {
  gpsFailedToInitGPSModule: 1,
  gpsFailedToGetPosition: 2,
  gpsAntennaError: 8,
  gpsFailedToInitCoMCU: 16,
  gpsCommunicationProblemsCoMCU: 32,
  gpsStatusFailedToGetGPSPosition: 64,
  gpsPowerOn: 256,
  gpsPowerOff: 512,
  gpsTriggeredBySensor: 1024,
  gpsTriggeredBySchedule: 2048
};

export const protConstsMap: Map<number, string> = new Map([
  [0x0102, "PCMD_ACK"],
  [0x0103, "PCMD_NACK"],

  [0x0111, "PCMD_ACK1"],
  [0x0112, "PCMD_ACK2"],
  [0x0113, "PCMD_ACK3"],
  [0x0114, "PCMD_ACK4"],
  [0x0115, "PCMD_NACK1"],
  [0x0116, "PCMD_NACK2"],
  [0x0117, "PCMD_NACK3"],
  [0x0118, "PCMD_NACK4"],

  [0x0120, "PCMD_REQNEWDATA"],
  [0x0121, "PCMD_REQALLDATA"],
  [0x0122, "PCMD_DATA"],
  [0x012f, "PCMD_ENDOFDATA"],

  [0x0881, "PCMD_SYNC1"],
  [0x0842, "PCMD_SYNC2"],
  [0x0824, "PCMD_SYNC3"],
  [0x0818, "PCMD_SYNC4"],

  [0x1000, "PCMD_REQLOADRECPARAMS"],
  [0x1001, "PCMD_REQLOADRECPARFMT"],
  [0x1002, "PCMD_LOADRECPARAMS"],

  [0x1005, "PCMD_REQRECPARAMS"],
  [0x1006, "PCMD_RECPARAMS"],

  [0x1008, "PCMD_REQCURRSTATUS"],
  [0x1009, "PCMD_CURRSTATUS"],

  [0x100a, "PCMD_REQPGMINFO"],
  [0x100b, "PCMD_PGMINFO"],

  [0x100c, "PCMD_REQDEVCONFIG"],
  [0x100d, "PCMD_DEVCONFIG"],

  [0x1010, "PCMD_SETTIME"],
  [0x1011, "PCMD_GETTIME"],
  [0x1012, "PCMD_CURRTIME"],

  [0x1015, "PCMD_REQSYSTEMINFO"],
  [0x1016, "PCMD_SYSTEMINFO"],

  [0x1018, "PCMD_REQBATSTAT"],
  [0x1019, "PCMD_BATSTAT"],

  [0x101a, "PCMD_UPDBATSTAT"],

  [0x1020, "PCMD_REQFORMAT"],
  [0x1021, "PCMD_FORMATCOMPLETE"],

  [0x2000, "PCMD_CONNECTINFO"],
  [0x2001, "PCMD_DISCONNECT"],
  [0x2002, "PCMD_IDLETIMEOUT"],
  [0x2008, "PCMD_CONNECTTIMEOUT"],

  [0x2100, "PCMD_LOGIN"],
  [0x2200, "PCMD_ABORT_LOGIN"],
  [0x2300, "PCMD_USER_LEV"],
  [0x2400, "PCMD_SETPWDCMD"],

  [0x2500, "PCMD_PWD_NEEDED"],
  [0xe020, "PCMD_ERR_ADM_PWD_NEEDED"],
  [0xe021, "PCMD_ERR_READONLY_PWD_NEEDED"],

  [0x2600, "PCMD_REQPWDSTATUS"],
  [0x2601, "PCMD_PWDSTATUS"],

  [0x8000, "PCMD_TERMINAL"],

  [0x80f1, "PCMD_MEMFMT"],
  [0x80f2, "PCMD_MEMFMT_COMPLETE"],
  [0x80f3, "PCMD_MEMFMT_NO_CHECK"],

  [0xe000, "PCMD_ERROR"],
  [0xe001, "PCMD_ERR_NEWDATA_AVAIL"],
  [0xe002, "PCMD_ERR_MEM_NOTEMPTY"],
  [0xe003, "PCMD_ERR_MEM_EMPTY"],
  [0xe004, "PCMD_ERR_MEM_FULL"],
  [0xe005, "PCMD_ERR_NO_PARAMS"],
  [0xe006, "PCMD_ERR_ACTIVERECORDING"],

  [0xe00b, "PCMD_ERR_INVALID_PWD"],

  [0xe0f0, "PCMD_ERR_FW_ADDRESS"],
  [0xe0f1, "PCMD_ERR_FW_WRITE"],
  [0xe0f2, "PCMD_ERR_FW_READ"],

  [0xe0ff, "PCMD_ERR_UNKNOWN"],

  [0xf001, "PCMD_REQFWBANKINFO"],
  [0xf002, "PCMD_FWBANKINFO"],

  [0xf010, "PCMD_REQFWFLASHFORMAT"],
  [0xf011, "PCMD_FWFFORMATDONE"],

  [0xf012, "PCMD_REQFWFLASHWRITE"],
  [0xf013, "PCMD_FWWRITEDONE"],

  [0xf014, "PCMD_REQFWFLASHREAD"]
]);

export const cmdToSymbol = (cmd: number): string =>
  protConstsMap.get(cmd) ?? "<unknown> (0x" + cmd.toString(16) + ")";

export const encryptionKey = [0xf0, 0x48, 0x0a, 0xff];
