import React from "react";
import { ColumnsType } from "antd/lib/table";
import { MinMaxDataExtSensor, MinMaxDataObject } from "./MinMaxDashboardCard";
import { useSelector } from "react-redux";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  TemperatureToScale,
  TempScaleSymbol
} from "../MicroComponents/TemperatureConverter";
import { insertIf } from "../../utils/generalUtils";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { Table } from "antd";
import {
  selectActiveDatxFile,
  selectExternalSensorParamsById
} from "../../state/openDatxSlice";
import { getSensorNrFromId } from "../../helpers/paramsHelper";

interface ITableData {
  key: number;

  dataChannel: string;

  minValue?: string;
  minTimestamp?: string;

  maxValue: string;
  maxTimestamp: string;
}

let index = 0;
// stateless functions

//todo: move this to a general place
const formatAccValue = ([value, duration]: [number, number]): string =>
  `${value}g, ${duration}ms`;

const createAccTableData = (
  dataChannel: string,
  item: Required<MinMaxDataObject>["xAcc"]
): ITableData => {
  const maxValue = formatAccValue(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return { key: index++, dataChannel, maxValue, maxTimestamp };
};

const createTempTableData = (
  dataChannel: string,
  item: Required<MinMaxDataObject>["temp"],
  tempScale: string
): ITableData => {
  const round2Decimals = (v: number) => Math.round(v * 100) / 100;
  const toCelcius = (v: any) =>
    `${round2Decimals(TemperatureToScale(v, tempScale))}${TempScaleSymbol(tempScale)}`;

  const minValue = toCelcius(item.min.value);
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = toCelcius(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

const createRhTableData = (
  dataChannel: string,
  item: Required<MinMaxDataObject>["rh"]
): ITableData => {
  const toRh = (v: any) => `${v}% Rh`;

  const minValue = toRh(item.min.value);
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = toRh(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

const createPressureTableData = (
  dataChannel: string,
  item: Required<MinMaxDataObject>["pressureRaw" | "pressureComp"]
): ITableData => {
  const toPressure = (v: any) => `${v}mbar`;

  const minValue = toPressure(item.min.value);
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = toPressure(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

const createAngleTableData = (
  dataChannel: string,
  item: Required<MinMaxDataObject>["xAngle"]
): ITableData => {
  const minValue = item.min.value.toString();
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = item.max.value.toString();
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

const createExternalTempTableData = (
  dataChannel: string,
  item: MinMaxDataExtSensor<number>,
  tempScale: string
): ITableData => {
  const round2Decimals = (v: number) => Math.round(v * 100) / 100;
  const toCelcius = (v: any) =>
    `${round2Decimals(TemperatureToScale(v, tempScale))}${TempScaleSymbol(tempScale)}`;

  const minValue = toCelcius(item.min.value);
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = toCelcius(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

const createExternalRhTableData = (
  dataChannel: string,
  item: MinMaxDataExtSensor<number>
): ITableData => {
  const toRh = (v: any) => `${v}% Rh`;

  const minValue = toRh(item.min.value);
  const minTimestamp = item.min.timestamp.format(timestampFormat);

  const maxValue = toRh(item.max.value);
  const maxTimestamp = item.max.timestamp.format(timestampFormat);

  return {
    key: index++,
    dataChannel,
    minValue,
    minTimestamp,
    maxValue,
    maxTimestamp
  };
};

interface IProps {
  dataObject: MinMaxDataObject;
}
const MinMaxTable = (props: IProps) => {
  const { t } = useTranslation();
  const tempScale = useSelector(selectTemperatureScale);
  const activeFile = useSelector(selectActiveDatxFile);
  const extSensorParams = useSelector(
    selectExternalSensorParamsById(activeFile!.id)
  );

  const tableData: ITableData[] = [];

  const {
    xAcc,
    yAcc,
    zAcc,
    temp,
    rh,
    pressureRaw,
    pressureComp,
    xAngle,
    yAngle,
    zAngle,
    externalTemp,
    externalRh
  } = props.dataObject;

  const hasMinValues: boolean =
    !isUndefined(temp) ||
    !isUndefined(rh) ||
    !isUndefined(pressureRaw) ||
    !isUndefined(pressureComp) ||
    !isUndefined(xAngle) ||
    !isUndefined(yAngle) ||
    !isUndefined(zAngle) ||
    !isUndefined(externalTemp) ||
    !isUndefined(externalRh);

  const columns: ColumnsType<ITableData> = [
    { title: t("Channel"), dataIndex: "dataChannel", key: "dataChannel" },
    ...insertIf(hasMinValues, {
      title: t("MinValue"),
      dataIndex: "minValue",
      key: "minValue"
    }),
    ...insertIf(hasMinValues, {
      title: t("MinTimestamp"),
      dataIndex: "minTimestamp",
      key: "minTimestamp"
    }),
    { title: t("MaxValue"), dataIndex: "maxValue", key: "maxValue" },
    { title: t("MaxTimestamp"), dataIndex: "maxTimestamp", key: "maxTimestamp" }
  ];

  if (xAcc) tableData.push(createAccTableData(t("AccelerationXAxis"), xAcc));
  if (yAcc) tableData.push(createAccTableData(t("AccelerationYAxis"), yAcc));
  if (zAcc) tableData.push(createAccTableData(t("AccelerationZAxis"), zAcc));

  if (temp)
    tableData.push(createTempTableData(t("Temperature"), temp, tempScale));
  if (rh) tableData.push(createRhTableData(t("Humidity"), rh));
  if (pressureComp)
    tableData.push(
      createPressureTableData(t("CompensatedPressure"), pressureComp)
    );
  if (pressureRaw)
    tableData.push(createPressureTableData(t("RawPressure"), pressureRaw));

  if (xAngle) tableData.push(createAngleTableData(t("AngleX"), xAngle));
  if (yAngle) tableData.push(createAngleTableData(t("AngleY"), yAngle));
  if (zAngle) tableData.push(createAngleTableData(t("AngleZ"), zAngle));

  if (externalTemp) {
    externalTemp.forEach((extTemp) => {
      const sensor = extSensorParams.find(
        (extSensor) => extSensor.params.sensorTypeId === extTemp.sensorId
      );

      const externalTempTableData = createExternalTempTableData(
        sensor?.params.sensorName
          ? `${t("ExternalTemperature")} nr ${getSensorNrFromId(extTemp.sensorId)} (${sensor?.params.sensorName})`
          : `${t("ExternalTemperature")} nr ${getSensorNrFromId(extTemp.sensorId)}`,
        extTemp,
        tempScale
      );

      tableData.push(externalTempTableData);
    });
  }

  if (externalRh) {
    externalRh.forEach((extRh) => {
      const sensor = extSensorParams.find(
        (extSensor) => extSensor.params.sensorTypeId === extRh.sensorId
      );

      const externalRhTableData = createExternalRhTableData(
        sensor?.params.sensorName
          ? `${t("ExternalHumidity")} nr ${getSensorNrFromId(extRh.sensorId)} (${sensor?.params.sensorName})`
          : `${t("ExternalHumidity")} nr ${getSensorNrFromId(extRh.sensorId)}`,
        extRh
      );

      tableData.push(externalRhTableData);
    });
  }

  return <Table columns={columns} dataSource={tableData} pagination={false} />;
};

export default MinMaxTable;
