import React, { CSSProperties } from "react";
import { Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import {
  ProjectWithUserRights,
  useGetParametersQuery,
  useGetProjectGpsQuery,
  useGetProjectNotesQuery
} from "../../state/cargologRestApi";
import dayjs from "dayjs";
import { renderStatus } from "../../pages/ProjectsPage";
import { skipToken } from "@reduxjs/toolkit/query";
import { isUndefined } from "lodash-es";
import { LastPos } from "../DevicesPage/DeviceMap";
import { useSelector } from "react-redux";
import { projectsState } from "../../state/projectSlice";
import { renderPositionStatus } from "../../helpers/dataModelHelper";

const { Text, Title, Paragraph } = Typography;

interface GpsData {
  parameterId: string;
  lastPos: LastPos;
}

interface INotesData {
  id: string;
  text: string;
  created: string;
  lastEdit: string;
  user: IUserData;
}

interface IUserData {
  id: string;
  name: string;
  email: string;
}

interface IProps {
  project?: ProjectWithUserRights;
}

const ProjectExport = (props: IProps) => {
  const { t } = useTranslation();
  const { selectedProjectId } = useSelector(projectsState);

  const { project } = props;

  const activePeople = project?.projectUsers
    ? project.projectUsers.map((user) => {
        return {
          userData: {
            name: `${user.user.firstName} ${user.user.lastName}`,
            email: user.user.email
          },
          companyName: user.user.company?.name ?? "",
          licenseActive: user.user.licenseNames ?? undefined
        };
      })
    : [];

  const { data: parameters } = useGetParametersQuery(
    selectedProjectId ? { projectId: selectedProjectId } : skipToken
  );
  const { data: gpsPos } = useGetProjectGpsQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );
  const { data: notes } = useGetProjectNotesQuery(
    selectedProjectId ? selectedProjectId : skipToken
  );

  const timezoneOffset = new Date().getTimezoneOffset();
  const startTime = dayjs
    .utc(project?.start)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD, HH:mm:ss");
  const endTime = dayjs
    .utc(project?.end)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD, HH:mm:ss");

  const gpsData: GpsData[] = gpsPos
    ? gpsPos.map((gps) => {
        const dateTime = gps.gpsData?.[0].dateTime;
        const lastDateTime = dayjs
          .utc(dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = gps.gpsData?.[0].velocity;
        const lastStatus = gps.gpsData?.[0].status ?? 100;

        const lastLat = gps.gpsData?.[0].lat ?? 57.8563;
        const lastLon = gps.gpsData?.[0].lon ?? 14.30782;

        const lastPos: LastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        return {
          parameterId: gps.parameterId ?? "",
          lastPos: lastPos
        };
      })
    : [];

  const tableCellStyle: CSSProperties = {
    textAlign: "start",
    borderLeft: "solid 1px rgb(217, 217, 217)",
    padding: size.m1
  };

  let notesData: INotesData[] | undefined = undefined;

  if (!isUndefined(notes)) {
    notesData = notes.map((note) => {
      let row: INotesData = {
        id: note.id,
        text: note.text,
        created: note.created,
        lastEdit: note.lastEdit ?? "",
        user: {
          id: note.user.userId,
          name: `${note.user.firstName} ${note.user.lastName}`,
          email: note.user.email
        }
      };
      return row;
    });
  }

  return (
    <>
      <div style={{ paddingInline: size.m1 }}>
        <div
          style={{
            marginBottom: size.xl1,
            paddingLeft: 0
          }}
        >
          <Row>
            <Title level={5}>
              {t("Project")}: {project?.title}
            </Title>
          </Row>
          <Row>
            <Text italic style={{ whiteSpace: "pre-wrap" }}>
              {project?.description}
            </Text>
          </Row>
          <Row style={{ marginTop: size.m1 }}>
            {startTime !== "Invalid date" ? (
              <Col span={8}>
                <Text>
                  {"Start"}: {startTime}
                </Text>
              </Col>
            ) : (
              <></>
            )}
            {endTime !== "Invalid date" ? (
              <Col>
                <Text>
                  {"End"}: {endTime}
                </Text>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </div>

        {/* ALARMS */}
        <Title
          level={5}
          style={{
            paddingLeft: size.m1,
            marginTop: 0,
            marginBottom: size.m1
          }}
        >
          {t("Alarms")}
        </Title>
        {project?.alarms && project.alarms.length > 0 ? (
          <table style={{ width: "100%", marginBottom: size.l2 }}>
            <tbody>
              <tr
                style={{
                  backgroundColor: "#fafafa",
                  border: "solid 1px rgb(217, 217, 217)"
                }}
              >
                <th style={{ ...tableCellStyle }}>{t("DeviceName")}</th>
                <th style={{ ...tableCellStyle }}>{t("Sensor")}</th>
                <th style={{ ...tableCellStyle, width: 250 }}>
                  {t("Timestamp")}
                </th>
              </tr>
              {project?.alarms &&
                project?.alarms.map((alarm, index) => {
                  const timezoneOffset = new Date().getTimezoneOffset();
                  const date = dayjs
                    .utc(alarm.dateTime)
                    .subtract(timezoneOffset, "minutes")
                    .format("YYYY-MM-DD, HH:mm:ss");
                  const device = parameters?.find(
                    (parameter) => parameter.id === alarm.parameterId
                  );

                  return (
                    <tr
                      key={index}
                      style={{ border: "solid 1px rgb(217, 217, 217)" }}
                    >
                      <td style={{ ...tableCellStyle }}>
                        {device?.deviceName}
                      </td>
                      <td style={{ ...tableCellStyle }}>{alarm.alarms}</td>
                      <td style={{ ...tableCellStyle }}>{date}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <Row justify="center" style={{ marginBottom: size.m1 }}>
            <Title type="secondary" level={5}>
              {t("NoAlarmsInProject")}
            </Title>
          </Row>
        )}

        {/* DATX-FILES */}
        <Title
          level={5}
          style={{
            paddingTop: size.m1,
            paddingLeft: size.m1,
            marginBottom: size.m1
          }}
        >
          {t("DatxFiles")}
        </Title>
        {project?.datXFiles && project?.datXFiles?.length > 0 ? (
          <table style={{ width: "100%", marginBottom: size.l2 }}>
            <tbody>
              <tr
                style={{
                  backgroundColor: "#fafafa",
                  border: "solid 1px rgb(217, 217, 217)"
                }}
              >
                <th style={{ ...tableCellStyle }}>{t("Timestamp")}</th>
                <th style={{ ...tableCellStyle }}>{t("FileSize")}</th>
              </tr>
              {project?.datXFiles &&
                project?.datXFiles.map((file, index) => {
                  const timezoneOffset = new Date().getTimezoneOffset();
                  const created = dayjs
                    .utc(file.created)
                    .subtract(timezoneOffset, "minutes")
                    .format("YYYY-MM-DD, HH:mm:ss");

                  return (
                    <tr
                      key={index}
                      style={{ border: "solid 1px rgb(217, 217, 217)" }}
                    >
                      <td style={{ ...tableCellStyle }}>{created}</td>
                      <td style={{ ...tableCellStyle }}>
                        {parseFloat(
                          (file.length / 1000).toFixed(0)
                        ).toLocaleString("sv")}{" "}
                        KB
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <Row justify="center" style={{ marginBottom: size.l2 }}>
            <Title type="secondary" level={5}>
              {t("NoFilesInProject")}
            </Title>
          </Row>
        )}

        {/* DEVICES */}
        <Title
          level={5}
          style={{
            paddingTop: size.m1,
            paddingLeft: size.m1,
            marginBottom: size.m1
          }}
        >
          {t("Devices")}
        </Title>
        {parameters && parameters.length > 0 ? (
          <table style={{ width: "100%", marginBottom: size.l2 }}>
            <tbody>
              <tr
                style={{
                  border: "solid 1px rgb(217, 217, 217)",
                  backgroundColor: "#fafafa"
                }}
              >
                <th style={{ ...tableCellStyle }}>{t("DeviceName")}</th>
                <th style={{ ...tableCellStyle }}>{t("DeviceDescription")}</th>
                <th style={{ ...tableCellStyle }}>{t("LastPosition")}</th>
              </tr>
              {parameters.map((param, index) => {
                const gps = gpsData.find((gps) => gps.parameterId === param.id);

                return (
                  <tr
                    key={index}
                    style={{ border: "solid 1px rgb(217, 217, 217)" }}
                  >
                    <td style={{ ...tableCellStyle }}>{param.deviceName}</td>
                    <td style={{ ...tableCellStyle }}>
                      {param.deviceDescription}
                    </td>
                    <td style={{ ...tableCellStyle }}>
                      <Text type="secondary">{gps?.lastPos.lastDateTime}</Text>{" "}
                      <br />
                      {gps
                        ? gps?.lastPos.lastLat + ", " + gps?.lastPos.lastLon
                        : ""}
                      <br />
                      {renderPositionStatus(t, gps?.lastPos.lastStatus)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Row justify="center" style={{ marginBottom: size.l2 }}>
            <Title type="secondary" level={5}>
              {t("NoDevicesInProject")}
            </Title>
          </Row>
        )}

        {/* MEMBERS IN PROJECT */}
        <Title
          level={5}
          style={{
            paddingTop: size.m1,
            paddingLeft: size.m1,
            marginBottom: size.m1
          }}
        >
          {t("MembersInProject")}
        </Title>
        {activePeople.length > 0 ? (
          <table style={{ width: "100%", marginBottom: size.l2 }}>
            <tbody>
              <tr
                style={{
                  border: "solid 1px rgb(217, 217, 217)",
                  backgroundColor: "#fafafa"
                }}
              >
                <th style={{ ...tableCellStyle }}>{t("Name")}</th>
                <th style={{ ...tableCellStyle }}>{t("Email")}</th>
                <th style={{ ...tableCellStyle }}>{t("Company")}</th>
                <th style={{ ...tableCellStyle }}>{t("Licenses")}</th>
              </tr>
              {activePeople.length > 0 &&
                activePeople.map((user, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ border: "solid 1px rgb(217, 217, 217)" }}
                    >
                      <td style={{ ...tableCellStyle }}>
                        {user.userData.name}
                      </td>
                      <td style={{ ...tableCellStyle }}>
                        {user.userData.email}
                      </td>
                      <td style={{ ...tableCellStyle }}>{user.companyName}</td>
                      <td style={{ ...tableCellStyle }}>
                        {user.licenseActive?.map((license, index) => (
                          <React.Fragment key={index}>
                            {license} <br />
                          </React.Fragment>
                        ))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <Row justify="center" style={{ marginBottom: size.l2 }}>
            <Title type="secondary" level={5}>
              {t("NoMembersInProject")}
            </Title>
          </Row>
        )}

        {/* STATUS */}
        <Title
          level={5}
          style={{
            paddingTop: size.m1,
            paddingLeft: size.m1,
            marginBottom: size.m1
          }}
        >
          {t("ProjectStatus")}
        </Title>
        <table style={{ width: "40%", marginBottom: size.l2 }}>
          <tbody>
            <tr
              style={{
                backgroundColor: "#fafafa",
                border: "solid 1px rgb(217, 217, 217)"
              }}
            >
              <th style={{ ...tableCellStyle }}>{t("ProjectStatus")}</th>
            </tr>
            <tr style={{ border: "solid 1px rgb(217, 217, 217)" }}>
              <td style={{ ...tableCellStyle }}>
                {renderStatus(t, project?.status).string}{" "}
                <Text type="secondary">({t("current")})</Text>
              </td>
            </tr>
          </tbody>
        </table>

        {/* NOTES */}
        <Title
          level={5}
          style={{
            paddingTop: size.m1,
            paddingLeft: size.m1,
            marginBottom: size.m1
          }}
        >
          {t("Notes")}
        </Title>

        {notesData?.reverse().map((note, index) => {
          const timezoneOffset = new Date().getTimezoneOffset();
          const created = dayjs
            .utc(note.created)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD, HH:mm");
          const lastEdit = dayjs
            .utc(note.lastEdit)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD, HH:mm");

          return (
            <React.Fragment key={index}>
              <div
                style={{
                  marginBottom: size.m1,
                  border: "solid 1px rgb(217, 217, 217)"
                }}
              >
                <div style={{ padding: size.l2 }}>
                  <Row style={{ fontSize: 12 }}>
                    <Text
                      style={{ paddingRight: size.m1 }}
                      strong
                      type="secondary"
                    >
                      {note.user?.name}
                    </Text>
                    <Text type="secondary" style={{ paddingRight: size.m1 }}>
                      {created}
                    </Text>
                    {lastEdit !== "Invalid date" ? (
                      <Text type="secondary">
                        ({t("Edited")}: {lastEdit})
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Row>

                  <Row style={{}}>
                    <Paragraph
                      ellipsis={{
                        rows: 3,
                        expandable: true,
                        symbol: t("more")
                      }}
                      style={{
                        width: "100%",
                        marginBottom: 0,
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {note.text}
                    </Paragraph>
                  </Row>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default ProjectExport;
