import { head, last, maxBy, minBy, take, takeRight, uniq } from "lodash-es";
import { ChannelTypes, GraphTypes } from "../state/compareGraphsSlice";
import { dateToUnix } from "./dateHelper";

export interface IDataPoint {
  x: Date;
  y: number;
  d: number;
}

export interface IDataPoint2 {
  x: Date;
  y?: number;
}

export interface IPrimaryData {
  xAcc: IDataPoint[];
  yAcc: IDataPoint[];
  zAcc: IDataPoint[];
  rh: IDataPoint2[];
  temp: IDataPoint2[];
  pressureRaw: IDataPoint2[];
  pressureComp: IDataPoint2[];
}

export interface IDvaDataPoint {
  xAlarm: number;
  yAlarm: number;
  zAlarm: number;
  time: Date | number;
}

interface IDvaData {
  start: number;
  end: number;
  data: IDvaDataPoint[];
}

export interface IMetaData {
  activeGraphType: GraphTypes;
  activeChannels: ChannelTypes[];
  activeDvaGraph?: number;
  dvaFrequency?: number;
  startTime?: number;
  offsetMs?: number;
  dataDomain?: [number, number];
}

export interface IGraphData {
  id: string;
  primaryData: IPrimaryData;
  overviewData: IPrimaryData;
  dvaData: IDvaData[];
  metaData: IMetaData;
}

/**
 * Returns the data in the given domain. If the data is of size 0 an empty array
 * is returned
 * @param data
 * @param domain
 * @returns new dataset (might be empty)
 */
export const getDataInDomain = <T extends { x: Date }>(
  data: T[],
  domain: [number, number]
) => data.filter((d) => isItemWithinDomain(d, domain));

/**
 * Returns true if {item} is within {domain}
 * @param item
 * @param domain
 */
export const isItemWithinDomain = <T extends { x: Date }>(
  item: T,
  domain: [number, number]
) => item.x.getTime() >= domain[0] && item.x.getTime() <= domain[1];

/**
 * Takes an array of graph-ready data and reduces it down to approximately
 * targetLength in length. It is not unusual for the returned array length to be
 * slighy shorter/longer due to performance reasons. The functions prioritezes
 * keeping the overall shape of the dataset by picking the absolute-highest
 * datapoints that is as far away from eachother as possible.
 * Note: This function is optimized for discrete data and should probably not be
 * used with continuous data.
 * @param data
 * @param targetLength
 * @param graphWidthInPx
 */
export const reduceDataPoints = (
  data: IDataPoint[],
  targetLength: number,
  //this parameter is not used yet.
  graphWidthInPx = 1000
): IDataPoint[] => {
  //No need for any reducing
  if (data.length <= targetLength) {
    return data;
  }

  const firstElem = head(data)!;
  const lastElem = last(data)!;

  /** Total number of seconds covered by {data} */
  const numberOfSecondsInTimespan =
    dateToUnix(lastElem.x) - dateToUnix(firstElem.x);

  /** Describes average number of datapoints per second in the dataset */
  const dataDensity = data.length / numberOfSecondsInTimespan;

  /** Todo: examine this further. What I know at the moment is that the
   * algorithm performs poorly when data-density is very low or very high. */
  const secSpanNumeratorMultiplier =
    dataDensity < 0.3 ? 0.3 : dataDensity > 0.6 ? 0.6 : dataDensity;

  /** The span of seconds that is allowed to hold 1 or 2 data points */
  const secSpan =
    (numberOfSecondsInTimespan * secSpanNumeratorMultiplier) /
    (targetLength / 2);

  /** Selector function: Get y-value from DataPoint */
  const yValueSelect = (v: IDataPoint) => v.y;

  /** Selector function: Get absolute y-value from DataPoint */
  const yValueAbsSelect = (v: IDataPoint) => Math.abs(v.y);

  /** Array to be returned */
  let responseArray: IDataPoint[] = [];

  //loop variables

  /** DataPoints in current chunk */
  let dataPointsInChunk: IDataPoint[] = [];
  /** Total number of seconds in current chunk */
  let secCounter = 0;

  //Always include the first element
  responseArray.push(firstElem);

  for (let i = 1; i < data.length; i++) {
    const dataPoint = data[i];
    const lastDataPoint = i === 1 ? dataPoint : data[i - 1];

    const currTimestamp = dateToUnix(dataPoint.x);
    const lastTimestamp = dateToUnix(lastDataPoint.x);

    secCounter += currTimestamp - lastTimestamp;

    dataPointsInChunk.push(dataPoint);

    // Special case: If chunks covers a large number of seconds, push 2 highest
    // 2 lowest, and current + prior data points. This ussually happens when 2
    // DataPoints are far away from each other
    if (secCounter >= secSpan * 2) {
      const lowestAndHighest4 = takeNMostExtremePoints(dataPointsInChunk, 4);

      const mostImportantDataPoints = uniq([
        //to not miss out when a chunk of acc begin
        dataPoint,
        //to not miss out when a chunk of acc end
        lastDataPoint,
        //to not loose the overall shape of the chunk
        ...lowestAndHighest4
      ]);

      responseArray.push(...mostImportantDataPoints);

      //reseting loop variables
      secCounter = 0;
      dataPointsInChunk = [];
    }
    // Normal scenario: When a chunk covers the chunk seconds span, push 1-2
    // values from the chunk to the response array
    else if (secCounter >= secSpan) {
      const maxDataPoint = maxBy(dataPointsInChunk, yValueSelect)!;
      const minDataPoint = minBy(dataPointsInChunk, yValueSelect)!;

      const absMaxDataPoint = maxBy(
        [maxDataPoint, minDataPoint],
        yValueAbsSelect
      )!;

      //if both negative and positive values in chunk push both.
      if (maxDataPoint.y > 0 && minDataPoint.y < 0) {
        responseArray.push(maxDataPoint, minDataPoint);
      } else {
        responseArray.push(absMaxDataPoint);
      }

      //reseting loop variables
      secCounter = 0;
      dataPointsInChunk = [];
    }
  }

  // Make sure the last element(s) is included
  if (last(responseArray) !== lastElem) {
    const lowestAndHighest4 = takeNMostExtremePoints(dataPointsInChunk, 4);
    responseArray.push(...lowestAndHighest4);
  }

  return responseArray;
};

/**
 * Return n/2 highest and n/2 lowest datapoints from data, where n must be a
 * positive integear. If n is less or equal than length the original data will
 * be returned.
 * @param data
 * @param n a positive integear
 */
const takeNMostExtremePoints = (data: IDataPoint[], n: number) => {
  if (data.length <= n) {
    return data;
  }

  data.sort((a, b) => a.y - b.y);

  const highest = take(data, n / 2);
  const lowest = takeRight(data, n / 2);

  const sortedByDate = [...highest, ...lowest].sort(
    (a, b) => a.x.getTime() - b.x.getTime()
  );

  return sortedByDate;
};
