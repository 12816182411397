import { ceil, floor, isUndefined, maxBy, minBy } from "lodash-es";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryClipContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictorySelectionContainer,
  VictoryTheme
} from "victory";
import { VictoryTickStyleObject } from "victory-core";
import { iconColors } from "../../constants/colors";
import {
  IDataPoint,
  IDvaDataPoint,
  IGraphData,
  getDataInDomain,
  reduceDataPoints
} from "../../helpers/compareGraphsHelper";
import { VMDvaData, unix2Date } from "../../helpers/dataModelHelper";
import { Nullable } from "../../helpers/datasetHelper";
import { guessLocalTz } from "../../helpers/dateHelper";
import {
  dateAxisFormater,
  formatYData,
  generateTickValues,
  getNormalizerFunctions,
  graphAxisScaleSelector,
  toLowHighOrder,
  yAxisDefaultNormalizedValues,
  yAxisFormater
} from "../../helpers/graphHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import {
  CompareStateZoomDomain,
  ICompareGraph,
  YAxisLowHigh,
  selectGlobalSelectedDomain
} from "../../state/compareGraphsSlice";
import {
  selectGlobalGraphScale,
  selectGraphAxisTickCountTime
} from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle,
  selectTemperatureScale
} from "../../state/sessionSlice";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import { GraphEmpty } from "../MicroComponents/GraphEmpty";
import {
  TempScaleSymbol,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { getDvaContent } from "./DvaGraphContent";
import { size } from "../../helpers/pageHelper";
import { Row, Spin } from "antd";

interface IProps {
  data: ICompareGraph[];
  width: number;
  height: number;
  handleSelectedDomain: (domain: CompareStateZoomDomain | undefined) => void;
}

const CombinedGraph: React.FC<IProps> = (props) => {
  const { data } = props;

  const selectedDomain = useSelector(selectGlobalSelectedDomain());

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);
  const tempScale = useSelector(selectTemperatureScale);
  const globalScale = useSelector(selectGlobalGraphScale);

  const localTimezone = useSelector(guessLocalTz);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  let xOffsetYaxis = [40, 90, 140, 190];
  const getYAxisOffset = () => xOffsetYaxis.pop();

  // Calculate the new data points for the graphs
  const quickGraphData = React.useMemo(
    () =>
      data.map((graph) => {
        const channels = graph.activeChannels;
        const startTimeDiff =
          graph.startTime && graph.dataDomain
            ? graph.startTime - graph.dataDomain[0]
            : 0;

        // Filter out data points outside active channels or have no duration
        const filterPrimaryData = graph.compareGraphContent.data.filter(
          (dataPoint) =>
            (channels.includes("x") &&
              dataPoint.xAcc &&
              dataPoint.xAcc[1] > 0) ||
            (channels.includes("y") &&
              dataPoint.yAcc &&
              dataPoint.yAcc[1] > 0) ||
            (channels.includes("z") &&
              dataPoint.zAcc &&
              dataPoint.zAcc[1] > 0) ||
            (channels.includes("rh") && dataPoint.rh) ||
            (channels.includes("temp") && dataPoint.temp) ||
            (channels.includes("pressureRaw") && dataPoint.pressureRaw) ||
            (channels.includes("pressureComp") && dataPoint.pressureComp)
        );
        const offsetSeconds = graph.offsetMs ? graph.offsetMs / 1000 : 0;

        // Only X accelerations
        const xAcc: IDataPoint[] = filterPrimaryData
          .filter((e) => e.xAcc && e.xAcc[1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.xAcc![0],
              d: e.xAcc![1]
            };
          });
        // Only Y accelerations
        const yAcc: IDataPoint[] = filterPrimaryData
          .filter((e) => e.yAcc && e.yAcc[1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.yAcc![0],
              d: e.yAcc![1]
            };
          });
        // Only Z accelerations
        const zAcc: IDataPoint[] = filterPrimaryData
          .filter((e) => e.zAcc && e.zAcc![1] > 0)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.zAcc![0],
              d: e.zAcc![1]
            };
          });
        // Only RH
        const rh = filterPrimaryData
          .filter((e) => e.rh)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.rh
            };
          });
        // Only Temperature
        const temp = filterPrimaryData
          .filter((e) => e.temp)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.temp
            };
          });
        // Only PressureRaw
        const pressureRaw = filterPrimaryData
          .filter((e) => e.pressureRaw)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.pressureRaw
            };
          });
        // Only PressureComp
        const pressureComp = filterPrimaryData
          .filter((e) => e.pressureComp)
          .map((e) => {
            return {
              x: unix2Date(e.timestamp + offsetSeconds + startTimeDiff),
              y: e.pressureComp
            };
          });

        // Trims data outside zoom domain if present
        const trimmedAccData = {
          xAcc: selectedDomain ? getDataInDomain(xAcc, selectedDomain.x) : xAcc,
          yAcc: selectedDomain ? getDataInDomain(yAcc, selectedDomain.x) : yAcc,
          zAcc: selectedDomain ? getDataInDomain(zAcc, selectedDomain.x) : zAcc
        };

        // Limits the number of points in each channel to 300
        const reducedData = {
          primary: {
            xAcc: reduceDataPoints(trimmedAccData.xAcc, 300),
            yAcc: reduceDataPoints(trimmedAccData.yAcc, 300),
            zAcc: reduceDataPoints(trimmedAccData.zAcc, 300),
            rh: rh,
            temp: temp,
            pressureRaw: pressureRaw,
            pressureComp: pressureComp
          },
          overview: {
            xAcc: reduceDataPoints(xAcc, 150),
            yAcc: reduceDataPoints(yAcc, 150),
            zAcc: reduceDataPoints(zAcc, 150),
            rh: rh,
            temp: temp,
            pressureRaw: pressureRaw,
            pressureComp: pressureComp
          }
        };

        // Calculates the DVA content for the graph
        const reducedDvaGraphs = graph.compareGraphContent.dvaData.map(
          (dvaGraph) => ({
            start: graph.startTime ?? dvaGraph.start,
            end: graph.startTime
              ? graph.startTime + dvaGraph.end - dvaGraph.start
              : dvaGraph.end,
            data: dvaGraph.data
              .filter(
                (dataPoint) =>
                  (channels.includes("x") && dataPoint.xAlarm) ||
                  (channels.includes("y") && dataPoint.yAlarm) ||
                  (channels.includes("z") && dataPoint.zAlarm)
              )
              .map((dataPoint, index) => {
                const offsetSeconds = graph.offsetMs
                  ? graph.offsetMs / 1000
                  : 0;
                const dataStart = graph.startTime ?? dvaGraph.start;
                const dvaFrequency = graph.dvaFrequency ?? 1000;
                return {
                  xAlarm: dataPoint.xAlarm,
                  yAlarm: dataPoint.yAlarm,
                  zAlarm: dataPoint.zAlarm,
                  time: dataStart + offsetSeconds + index / dvaFrequency
                };
              })
          })
        );

        // New graph object
        const newGraph: IGraphData = {
          id: graph.id,
          primaryData: {
            xAcc: reducedData.primary.xAcc,
            yAcc: reducedData.primary.yAcc,
            zAcc: reducedData.primary.zAcc,
            rh: reducedData.primary.rh,
            temp: reducedData.primary.temp,
            pressureRaw: reducedData.primary.pressureRaw,
            pressureComp: reducedData.primary.pressureComp
          },
          overviewData: {
            xAcc: reducedData.overview.xAcc,
            yAcc: reducedData.overview.yAcc,
            zAcc: reducedData.overview.zAcc,
            rh: reducedData.overview.rh,
            temp: reducedData.overview.temp,
            pressureRaw: reducedData.overview.pressureRaw,
            pressureComp: reducedData.overview.pressureComp
          },
          dvaData: reducedDvaGraphs,
          metaData: {
            activeGraphType: graph.activeGraphType,
            activeChannels: graph.activeChannels,
            activeDvaGraph: graph.activeDvaGraph,
            dvaFrequency: graph.dvaFrequency,
            startTime: graph.startTime,
            offsetMs: graph.offsetMs,
            dataDomain: graph.dataDomain
          }
        };
        return newGraph;
      }),
    [data, selectedDomain]
  );

  // Contains largest domains for all graphs and active channels
  let fullDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setMinMax = (timestamp: number) => {
    fullDomain[0] = Math.min(fullDomain[0], timestamp);
    fullDomain[1] = Math.max(fullDomain[1], timestamp);
  };

  let accDomain: YAxisLowHigh = [0, 0];
  const setAccMinMax = (value: number) => {
    accDomain[0] = Math.min(accDomain[0], value);
    accDomain[1] = Math.max(accDomain[1], value);
  };

  let tempDomain: YAxisLowHigh = [
    Number.MAX_SAFE_INTEGER,
    Number.MIN_SAFE_INTEGER
  ];
  const setTempMinMax = (value: number) => {
    tempDomain[0] = Math.min(tempDomain[0], value);
    tempDomain[1] = Math.max(tempDomain[1], value);
  };

  let rhDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setRhMinMax = (value: number) => {
    rhDomain[0] = Math.min(rhDomain[0], value);
    rhDomain[1] = Math.max(rhDomain[1], value);
  };

  let pressureDomain: YAxisLowHigh = [Number.MAX_SAFE_INTEGER, 0];
  const setPressureMinMax = (value: number) => {
    pressureDomain[0] = Math.min(pressureDomain[0], value);
    pressureDomain[1] = Math.max(pressureDomain[1], value);
  };

  // Loop through all graphs and set domains
  quickGraphData.forEach((file) => {
    if (file.metaData.activeGraphType === "mainGraph") {
      file.overviewData.xAcc.forEach((a) => {
        setMinMax(a.x.getTime());
        setAccMinMax(a.y);
      });
      file.overviewData.yAcc.forEach((a) => {
        setMinMax(a.x.getTime());
        setAccMinMax(a.y);
      });
      file.overviewData.zAcc.forEach((a) => {
        setMinMax(a.x.getTime());
        setAccMinMax(a.y);
      });
      file.overviewData.temp.forEach((a) => {
        setMinMax(a.x.getTime());
        if (a.y) setTempMinMax(a.y);
      });
      file.overviewData.rh.forEach((a) => {
        setMinMax(a.x.getTime());
        if (a.y) {
          setRhMinMax(a.y);
        }
      });
      file.overviewData.pressureRaw.forEach((a) => {
        setMinMax(a.x.getTime());
        if (a.y) {
          setPressureMinMax(a.y);
        }
      });
      file.overviewData.pressureComp.forEach((a) => {
        setMinMax(a.x.getTime());
        if (a.y) {
          setPressureMinMax(a.y);
        }
      });
    } else if (
      file.metaData.activeGraphType === "dvaGraph" &&
      file.metaData.activeDvaGraph
    ) {
      const activeDvaGraph = file.dvaData.find(
        (e) =>
          e.start.toString() + e.end.toString() ===
          file.metaData.activeDvaGraph!.toString()
      );
      if (activeDvaGraph) {
        // X-values
        setMinMax(activeDvaGraph.start);
        setMinMax(activeDvaGraph.end);
        // Y-values
        activeDvaGraph.data.forEach((a) => {
          setAccMinMax(a.xAlarm);
          setAccMinMax(a.yAlarm);
          setAccMinMax(a.zAlarm);
        });
      }
    }
  });

  // Width of full domain in milliseconds
  const fullDomainWidthMs = fullDomain[1] - fullDomain[0];

  /** Converts duration to non zoomed pixles */
  const durInPx = (ms: number) => (ms * props.width) / fullDomainWidthMs;

  /** Converts duration to zoomed (selectedDomain) pixles */
  const durInZoomPx = (ms: number) => {
    if (selectedDomain) {
      const domainWidth = selectedDomain.x[1] - selectedDomain.x[0];
      return (ms * props.width) / domainWidth;
    } else {
      return durInPx(ms);
    }
  };

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = { low: -1, high: 1 };

  // fileValue will be different in different files so it is statically set to 21
  const accTickCount = graphAxisScaleSelector(
    21,
    globalScale.acc.count,
    globalScale.acc.toggle,
    globalScale.toggle
  );
  const tempTickCount = graphAxisScaleSelector(
    21,
    globalScale.temp.count,
    globalScale.temp.toggle,
    globalScale.toggle
  );
  const rhTickCount = graphAxisScaleSelector(
    21,
    globalScale.rh.count,
    globalScale.rh.toggle,
    globalScale.toggle
  );
  const pressureTickCount = graphAxisScaleSelector(
    21,
    globalScale.pressure.count,
    globalScale.pressure.toggle,
    globalScale.toggle
  );

  /** Callback used to style y-axis grid */
  const gridStyleCallback = (tick: number) =>
    tick === 0 || tick === scale.low ? "black" : "#ECEFF1";

  /** Function that calculates the width of a bar in px. */
  const getBarWidth = (v: any) => {
    const duration = v?.datum?.d;
    if (!duration) return 0;
    const widthInPixels = durInZoomPx(duration);
    // If the width is below 1px, it will not be visible
    if (widthInPixels < 1.5) return 1.5;
    return widthInPixels;
  };

  /** Utility function used for barwidth. Returns a width in pixels based on
   * acceleration duration */
  const getOverviewBarWidth = (v: any) => {
    const duration = v?.datum?.d;
    if (!duration) return 0;
    const widthInPixels = durInPx(duration);
    // If the width is below 1px, it will not be visible
    if (widthInPixels < 1.5) return 1.5;
    return widthInPixels;
  };

  const handleZoomSelection = (bounds: CompareStateZoomDomain) => {
    const fixedBoundsX: [number, number] =
      bounds.x[0] < 0 ? [0, bounds.x[1]] : bounds.x;

    // If user selects from right to left, the first value will be larger than the seconds value, causing a bugged selection. {toLowHighOrder} solves that
    const newXDomain: CompareStateZoomDomain["x"] =
      toLowHighOrder(fixedBoundsX);
    const newYDomain: CompareStateZoomDomain["y"] = [-1, 1];
    props.handleSelectedDomain({ x: newXDomain, y: newYDomain });
  };

  const accDomainUnit = (quickGraphData: IGraphData[]): YAxisLowHigh => {
    let maxAcc = 1;
    quickGraphData.forEach((file) => {
      if (file.metaData.activeDvaGraph) {
        const activeDvaGraph = file.dvaData.find(
          (e) =>
            e.start.toString() + e.end.toString() ===
            file.metaData.activeDvaGraph!.toString()
        );
        if (activeDvaGraph) {
          activeDvaGraph.data.forEach((a) => {
            maxAcc = Math.max(maxAcc, Math.abs(a.xAlarm));
            maxAcc = Math.max(maxAcc, Math.abs(a.yAlarm));
            maxAcc = Math.max(maxAcc, Math.abs(a.zAlarm));
          });
        }
      }
    });
    return [maxAcc * -1, maxAcc * 1];
  };

  const accDomainUnitMemo: YAxisLowHigh = useMemo(
    () => accDomainUnit(quickGraphData),
    [quickGraphData]
  );

  const tempDomainUnit = () => {
    const pickTempValue = (temp: number) => temp;

    const lowestTempBlock = minBy(tempDomain, pickTempValue);
    const highestTempBlock = maxBy(tempDomain, pickTempValue);

    const tempMin = lowestTempBlock && floor(pickTempValue(lowestTempBlock)!);
    const tempMax = highestTempBlock && ceil(pickTempValue(highestTempBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        tempMin ? tempMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.temp.min,
        globalScale.temp.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        tempMax ? tempMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.temp.max,
        globalScale.temp.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const rhDomainUnit = () => {
    const pickRhValue = (rh: number) => rh;

    const lowestRhBlock = minBy(rhDomain, pickRhValue);
    const highestRhBlock = maxBy(rhDomain, pickRhValue);

    const rhMin = lowestRhBlock && floor(pickRhValue(lowestRhBlock)!);
    const rhMax = highestRhBlock && ceil(pickRhValue(highestRhBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        rhMin ? rhMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.rh.min,
        globalScale.rh.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        rhMax ? rhMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.rh.max,
        globalScale.rh.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  const pressureDomainUnit = () => {
    const pickPressureValue = (pressure: number) => pressure;

    const lowestPressureBlock = minBy(pressureDomain, pickPressureValue);
    const highestPressureBlock = maxBy(pressureDomain, pickPressureValue);

    const pressureMin =
      lowestPressureBlock && floor(pickPressureValue(lowestPressureBlock)!);
    const pressureMax =
      highestPressureBlock && ceil(pickPressureValue(highestPressureBlock)!);

    const newDomain: YAxisLowHigh = [
      graphAxisScaleSelector(
        pressureMin ? pressureMin - 1 : yAxisDefaultNormalizedValues[0],
        globalScale.pressure.min,
        globalScale.pressure.toggle,
        globalScale.toggle
      ),
      graphAxisScaleSelector(
        pressureMax ? pressureMax + 1 : yAxisDefaultNormalizedValues[1],
        globalScale.pressure.max,
        globalScale.pressure.toggle,
        globalScale.toggle
      )
    ];
    return newDomain;
  };

  // Normalizer funtions is needed since this chart may contain data with
  // different scaling. Normalizing is needed to enable e.g. rh% (0 - 100) and acc
  // (-16 - 16) to be in the same chart
  const {
    normalizeValue: normalizeAccValue,
    denormalizeValue: denormalizeAccValue
  } = getNormalizerFunctions(accDomainUnitMemo);

  const formatYValueAcc = (y: number) =>
    formatYData(y, accDomainUnitMemo, normalizeAccValue);

  const {
    normalizeValue: normalizeTempValue,
    denormalizeValue: denormalizeTempValue
  } = getNormalizerFunctions(tempDomainUnit(), scale);

  const formatYValueTemp = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(
          TemperatureToScale(y, tempScale),
          tempDomainUnit(),
          normalizeTempValue
        );

  const {
    normalizeValue: normalizeRhValue,
    denormalizeValue: denormalizeRhValue
  } = getNormalizerFunctions(rhDomainUnit(), scale);

  const formatYValueRh = (y: Nullable<number>) =>
    y === null ? 0 : formatYData(y, rhDomainUnit(), normalizeRhValue);

  const {
    normalizeValue: normalizePressureValue,
    denormalizeValue: denormalizePressureValue
  } = getNormalizerFunctions(pressureDomainUnit(), scale);

  const formatYValuePressure = (y: Nullable<number>) =>
    y === null
      ? 0
      : formatYData(y, pressureDomainUnit(), normalizePressureValue);

  // If any graph is selected
  const hasGraph =
    quickGraphData.filter(
      (graph) =>
        graph.metaData.activeGraphType === "mainGraph" ||
        (graph.metaData.activeGraphType === "dvaGraph" &&
          graph.metaData.activeDvaGraph)
    ).length > 0;

  const graphData = React.useMemo(
    () =>
      quickGraphData.map((file) => {
        /**  The current graph is a dvaGraph */
        if (
          file.metaData.activeGraphType === "dvaGraph" &&
          file.metaData.activeDvaGraph
        ) {
          const dvaDataBlock: VMDvaData | undefined = file.dvaData.find((e) => {
            return (
              e.start.toString() + e.end.toString() ===
              file.metaData.activeDvaGraph!.toString()
            );
          });

          if (!isUndefined(dvaDataBlock)) {
            const dvaFrequency = file.metaData.dvaFrequency ?? 700;
            const dvaData: IDvaDataPoint[] = dvaDataBlock.data.map(
              (e, index) => {
                const offsetSeconds = file.metaData.offsetMs
                  ? file.metaData.offsetMs / 1000
                  : 0;
                const dataStart =
                  file.metaData.startTime ?? dvaDataBlock!.start;
                return {
                  xAlarm: formatYValueAcc(e.xAlarm),
                  yAlarm: formatYValueAcc(e.yAlarm),
                  zAlarm: formatYValueAcc(e.zAlarm),
                  time: unix2Date(
                    dataStart + offsetSeconds + index / dvaFrequency
                  )
                };
              }
            );
            return { ...file, dvaData: dvaData };
          }
        }
        return { ...file, dvaData: [] };
      }),
    [quickGraphData, accDomainUnitMemo]
  );

  // Draws the graph
  const graphContent = (overview?: boolean) =>
    graphData.map((file, index) => {
      // Draws the DVA graph
      if (
        file.metaData.activeGraphType === "dvaGraph" &&
        file.metaData.activeDvaGraph
      ) {
        return file.dvaData.length > 0
          ? getDvaContent(file.dvaData, file.metaData, index)
          : null;
      }

      if (file.metaData.activeGraphType === "mainGraph") {
        /** The current graph is a mainGraph */
        const d = overview ? file.overviewData : file.primaryData;
        return (
          <VictoryGroup key={index}>
            {/* x-axis data */}
            {file.metaData.activeChannels.includes("x") &&
              d.xAcc.length > 0 && (
                <VictoryBar
                  data={d.xAcc}
                  x="x"
                  y={(datum) => formatYValueAcc(datum.y)}
                  style={{ data: { fill: iconColors.xAccA } }}
                  alignment="start"
                  barWidth={overview ? getOverviewBarWidth : getBarWidth}
                  groupComponent={<VictoryClipContainer />}
                />
              )}
            {/* y-axis data */}
            {file.metaData.activeChannels.includes("y") &&
              d.yAcc.length > 0 && (
                <VictoryBar
                  data={d.yAcc}
                  x="x"
                  y={(datum) => formatYValueAcc(datum.y)}
                  style={{ data: { fill: iconColors.yAccA } }}
                  alignment="start"
                  barWidth={overview ? getOverviewBarWidth : getBarWidth}
                  groupComponent={<VictoryClipContainer />}
                />
              )}
            {/* z-axis data */}
            {file.metaData.activeChannels.includes("z") &&
              d.zAcc.length > 0 && (
                <VictoryBar
                  data={d.zAcc}
                  x="x"
                  y={(datum) => formatYValueAcc(datum.y)}
                  style={{ data: { fill: iconColors.zAccA } }}
                  alignment="start"
                  barWidth={overview ? getOverviewBarWidth : getBarWidth}
                  groupComponent={<VictoryClipContainer />}
                />
              )}

            {/* temp data */}
            {file.metaData.activeChannels.includes("temp") &&
              d.temp.length > 1 && (
                <VictoryLine
                  name="Temp"
                  data={d.temp ?? []}
                  y={(datum) => formatYValueTemp(datum.y ?? null)}
                  style={{
                    data: {
                      stroke: iconColors.temp,
                      strokeWidth: overview ? 1 : 2
                    }
                  }}
                />
              )}

            {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
            {d.temp.length === 1 && (
              <VictoryScatter
                name="temp1Data"
                data={d.temp ?? []}
                y={(datum) => formatYValueTemp(datum.y ?? null)}
                size={5}
                style={{ data: { fill: iconColors.temp } }}
              />
            )}

            {/* rh data */}
            {file.metaData.activeChannels.includes("rh") && (
              // quickGraphData.map(e => e.primaryData.rh.length > 1) &&
              <VictoryLine
                name="RH"
                data={d.rh ?? []}
                y={(datum) => formatYValueRh(datum.y ?? null)}
                style={{
                  data: {
                    stroke: iconColors.rh,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

            {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
            {d.rh.length === 1 &&
              file.metaData.activeChannels.includes("rh") && (
                <VictoryScatter
                  name="rh1Data"
                  data={d.rh ?? []}
                  y={(datum) => formatYValueRh(datum.y ?? null)}
                  size={5}
                  style={{ data: { fill: iconColors.rh } }}
                />
              )}

            {/* pressureRaw data */}
            {file.metaData.activeChannels.includes("pressureRaw") && (
              <VictoryLine
                name="PressureRaw"
                data={d.pressureRaw ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                style={{
                  data: {
                    stroke: iconColors.pressureRaw,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

            {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
            {d.pressureRaw.length === 1 &&
              file.metaData.activeChannels.includes("pressureRaw") && (
                <VictoryScatter
                  name="pressureRaw1Data"
                  data={d.pressureRaw ?? []}
                  y={(datum) => formatYValuePressure(datum.y ?? null)}
                  size={5}
                  style={{ data: { fill: iconColors.pressureRaw } }}
                />
              )}

            {/* pressureComp data */}
            {file.metaData.activeChannels.includes("pressureComp") && (
              <VictoryLine
                name="PressureComp"
                data={d.pressureComp ?? []}
                y={(datum) => formatYValuePressure(datum.y ?? null)}
                style={{
                  data: {
                    stroke: iconColors.pressureComp,
                    strokeWidth: overview ? 1 : 2
                  }
                }}
              />
            )}

            {/* special case: when length is one, VictoryLine won't do the job. Then this scatter-graph will be rendered instead */}
            {d.pressureComp.length === 1 &&
              file.metaData.activeChannels.includes("pressureComp") && (
                <VictoryScatter
                  name="pressureComp1Data"
                  data={d.pressureComp ?? []}
                  y={(datum) => formatYValuePressure(datum.y ?? null)}
                  size={5}
                  style={{ data: { fill: iconColors.pressureComp } }}
                />
              )}
          </VictoryGroup>
        );
      } else {
        return null;
      }
    });

  const strokeSize = 4;
  const colorNumber = 150;
  const strokeColor = `rgb(${colorNumber}, ${colorNumber}, ${colorNumber})`;

  const customTickStyle: VictoryTickStyleObject = {
    size: strokeSize,
    stroke: strokeColor
  };

  const activeChannels = [...data.map((e) => e.activeChannels).flat()];

  /** Some temp-data comes through that are undefined which messes up VictoryAxis.
   * This filters them out. */
  const tempHasValue = [
    ...quickGraphData.map((file) =>
      file.primaryData.temp.filter((f) => f.y !== undefined)
    )
  ].flat();

  const rhHasValue = [
    ...quickGraphData.map((file) =>
      file.primaryData.rh.filter((f) => f.y !== undefined)
    )
  ].flat();

  const pressureRawHasValue = [
    ...quickGraphData.map((file) =>
      file.primaryData.pressureRaw.filter((f) => f.y !== undefined)
    )
  ].flat();

  const pressureCompHasValue = [
    ...quickGraphData.map((file) =>
      file.primaryData.pressureComp.filter((f) => f.y !== undefined)
    )
  ].flat();

  const accActive =
    activeChannels.includes("x") ||
    activeChannels.includes("y") ||
    activeChannels.includes("z");

  return hasGraph ? (
    <>
      {isUndefined(accDomainUnitMemo) ? (
        <Row
          justify="center"
          align="middle"
          // Setting height to the same as the main graph
          style={{ height: props.height }}
        >
          <Spin />
        </Row>
      ) : (
        <>
          <VictoryChart
            name="primary"
            width={props.width}
            height={props.height}
            scale={{ x: "time" }}
            padding={{ top: size.l1, left: 190, right: 40, bottom: size.l3 }}
            domain={selectedDomain}
            theme={VictoryTheme.material}
            domainPadding={{ x: [10, 10], y: 0 }}
            containerComponent={
              <VictorySelectionContainer
                selectionDimension="x"
                allowSelection
                onSelection={(points, bounds: any, props) => {
                  handleZoomSelection(bounds as any);
                }}
              />
            }
          >
            {/* x-axis(based on time, show no values, based on origo) */}
            <VictoryAxis
              tickFormat={[]}
              tickCount={timeScaleSteps}
              style={{
                axis: {
                  stroke: strokeColor,
                  strokeWidth: accActive ? 1.5 : 0
                },
                ticks: {
                  stroke: strokeColor,
                  strokeWidth: accActive ? 1 : 0
                }
              }}
              axisValue={0}
            />

            {/* x-axis(based on time, show values) */}
            <VictoryAxis
              tickFormat={(t, _i, arr) =>
                dateAxisFormater(
                  t,
                  arr,
                  timezoneSelector(localTimezone, timezoneState, timezoneToggle)
                )
              }
              fixLabelOverlap
              tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
              axisValue={yAxisDefaultNormalizedValues[0]}
              style={{
                ...commonAxisStyle,
                axis: {
                  stroke: "black",
                  strokeWidth: 1.5
                },
                grid: {
                  stroke: strokeColor,
                  strokeWidth: 0
                }
              }}
            />

            {accActive ? (
              <VictoryAxis
                dependentAxis
                offsetX={getYAxisOffset()}
                label={"g"}
                fixLabelOverlap
                style={{
                  axis: { stroke: strokeColor, strokeWidth: 2 },
                  ...commonAxisStyle,
                  grid: {
                    stroke: (tick: any) => gridStyleCallback(tick)
                  }
                }}
                axisLabelComponent={
                  <VictoryLabel y={15} dx={39} textAnchor="middle" />
                }
                tickValues={generateTickValues(accTickCount)}
                tickFormat={(t) =>
                  yAxisFormater(denormalizeAccValue(t), accDomainUnitMemo)
                }
              />
            ) : undefined}

            {/* y-axis(temperature) */}
            {tempHasValue.length > 0 && activeChannels.includes("temp") && (
              <VictoryAxis
                name="tempAxis"
                dependentAxis
                offsetX={getYAxisOffset()}
                label={TempScaleSymbol(tempScale)}
                fixLabelOverlap
                style={{
                  ...commonAxisStyle,
                  axis: { stroke: iconColors.temp, strokeWidth: 2 },
                  grid: { stroke: (tick: any) => gridStyleCallback(tick) },
                  ticks: customTickStyle
                }}
                axisLabelComponent={
                  <VictoryLabel y={15} dx={39} textAnchor="middle" />
                }
                tickValues={generateTickValues(tempTickCount)}
                tickFormat={(t) =>
                  yAxisFormater(denormalizeTempValue(t), tempDomainUnit())
                }
              />
            )}

            {/* y-axis(rh) */}
            {rhHasValue.length > 0 && activeChannels.includes("rh") && (
              <VictoryAxis
                name="rhAxis"
                dependentAxis
                offsetX={getYAxisOffset()}
                label="Rh(%)"
                fixLabelOverlap
                style={{
                  ...commonAxisStyle,
                  axis: { stroke: iconColors.rh, strokeWidth: 2 },
                  grid: { stroke: (tick: any) => gridStyleCallback(tick) },
                  ticks: customTickStyle
                }}
                axisLabelComponent={
                  <VictoryLabel y={15} dx={39} textAnchor="middle" />
                }
                tickValues={generateTickValues(rhTickCount)}
                tickFormat={(t) =>
                  yAxisFormater(denormalizeRhValue(t), rhDomainUnit())
                }
              />
            )}

            {/* y-axis(pressure) */}
            {(pressureRawHasValue.length > 0 ||
              pressureCompHasValue.length > 0) &&
              (activeChannels.includes("pressureRaw") ||
                activeChannels.includes("pressureComp")) && (
                <VictoryAxis
                  name="pressureAxis"
                  dependentAxis
                  offsetX={getYAxisOffset()}
                  label="mbar"
                  fixLabelOverlap
                  style={{
                    ...commonAxisStyle,
                    axis: { stroke: iconColors.pressure, strokeWidth: 2 },
                    grid: { stroke: (tick: any) => gridStyleCallback(tick) },
                    ticks: customTickStyle
                  }}
                  axisLabelComponent={
                    <VictoryLabel y={15} dx={39} textAnchor="middle" />
                  }
                  tickValues={generateTickValues(pressureTickCount)}
                  tickFormat={(t) =>
                    yAxisFormater(
                      denormalizePressureValue(t),
                      pressureDomainUnit()
                    )
                  }
                />
              )}

            {graphContent()}
          </VictoryChart>

          <VictoryChart
            name="overview"
            width={props.width}
            height={90}
            scale={{ x: "time" }}
            padding={{ top: size.l1, left: 190, right: 40, bottom: size.l1 }}
            domainPadding={{ x: [10, 10], y: 0 }}
            theme={VictoryTheme.material}
            containerComponent={
              <VictoryBrushContainer
                brushDimension="x"
                brushDomain={
                  selectedDomain
                    ? {
                        x: selectedDomain!.x,
                        y: [-1, 1]
                      }
                    : undefined
                }
                onBrushDomainChangeEnd={(bounds: any) =>
                  handleZoomSelection(bounds as any)
                }
              />
            }
          >
            <VictoryAxis
              tickCount={timeScaleSteps}
              tickFormat={[]}
              axisValue={0}
              style={{
                ...commonAxisStyle,
                axis: {
                  stroke: strokeColor,
                  strokeWidth: accActive ? 1.5 : 0
                },
                ticks: {
                  stroke: strokeColor,
                  strokeWidth: accActive ? 1 : 0
                }
              }}
            />
            {graphContent(true)}
          </VictoryChart>
        </>
      )}
    </>
  ) : (
    <GraphEmpty />
  );
};

export default React.memo(CombinedGraph);
