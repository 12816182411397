import * as cheerio from "cheerio";
import { TableType } from "./pdfInterfaces";
import { parseBackground } from "./pdfHelpers";
import { TableCell } from "pdfmake/interfaces";

/** Parses out all HTML tables with headers and rows
 *  Colspan is supported but rowspan is not
 */
export const parseHtmlTables = (html: HTMLElement): TableType[] => {
  const tables: TableType[] = [];
  const parser = cheerio.load(html.outerHTML);
  // Remove all SVG elements
  parser("svg").remove();
  parser("table").each((index, element) => {
    const table: TableType = { headers: [], rows: [] };
    // Extract headers from the table
    parser(element)
      .find("thead tr")
      .each((index, element) => {
        const cells: TableCell[] = [];
        parser(element)
          .find("th")
          .each((index, element) => {
            const text = parser(element).text();
            const colspan = parser(element).attr("colspan");
            if (colspan) {
              const span = parseInt(colspan);
              cells.push({
                text: text,
                bold: true,
                colSpan: span
              });
              // Add empty cells for the colspan
              for (let i = 1; i < span; i++) {
                cells.push({});
              }
            } else {
              cells.push({
                text: text,
                bold: true
              });
            }
          });
        if (cells.length > 0) {
          table.headers.push(cells);
        }
      });

    // Extract rows from the table
    parser(element)
      .find("tbody tr")
      .each((index, element) => {
        const cells: TableCell[] = [];
        parser(element)
          .find("td")
          .each((index, element) => {
            // Find if any of the children have a background-color
            let backgroundColor: string | undefined = undefined;
            parser(element)
              .children()
              .each((index, element) => {
                backgroundColor = parseBackground(
                  parser(element).css("background-color")
                );
              });
            const text = parser(element).text().replace(/\n/g, "").trim();
            const colspan = parser(element).attr("colspan");
            if (colspan) {
              const span = parseInt(colspan);
              cells.push({
                text: text,
                background: backgroundColor,
                colSpan: span,
                bold: true
              });
              // Add empty cells for the colspan
              for (let i = 1; i < span; i++) {
                cells.push({});
              }
            } else {
              cells.push({
                text: text,
                background: backgroundColor
              });
            }
          });
        if (cells.length > 0) {
          table.rows.push(cells);
        }
      });
    if (table.headers.length > 0 || table.rows.length > 0) {
      tables.push(table);
    }
  });
  return tables;
};
