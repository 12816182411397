import React from "react";
import { Row } from "antd";
import mobitronColors from "../../styles/mobitronColors";
import { RightOutlined } from "@ant-design/icons";
import { impersonateBannerHeight } from "../../helpers/pageHelper";

interface IProps {
  page: "reports" | "compareGraph" | "params";
  isImpersonating: boolean;
}
export const GeneralSideMenuArrow = (props: IProps) => {
  const { page, isImpersonating } = props;

  const selectPosition = () => {
    switch (page) {
      case "reports":
        return !isImpersonating ? 85 : 85 - impersonateBannerHeight;
      case "compareGraph":
        return !isImpersonating ? 145 : 145 - impersonateBannerHeight;
      case "params":
        return !isImpersonating ? 204 : 204 - impersonateBannerHeight;
      default:
        return 0;
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        marginTop: selectPosition()
      }}
    >
      <Row justify={"center"}>
        <RightOutlined style={{ color: mobitronColors.softDarkGreen }} />
      </Row>
    </div>
  );
};
