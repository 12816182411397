import { notification } from "antd";

// General functions that can be used to give the user feedback

/** Displays an error notification at the top right of the screen */
export const displayErrorMessage = (content: string) => {
  notification.error({ message: content });
};

export const notImplementedMessage = () => {
  notification.info({ message: "This feature is not yet implemented, sorry!" });
};
