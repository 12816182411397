import * as cheerio from "cheerio";
import { Content, TableCell } from "pdfmake/interfaces";
import { TableType } from "./pdfInterfaces";

/** Parses an Order header and returns it as stacks in columns */
export const parseOrderHeader = (html: HTMLElement): Content => {
  const parser = cheerio.load(html.outerHTML);
  // Find the element with the class "order-header"
  const invoiceHeader = parser(".order-header");
  // Make one column for each child in the invoice header
  const columns = invoiceHeader.children().map((index, element) => {
    // Put the text ofall children split by br tags in a stack
    const html = parser(element).html();
    if (!html) return null;
    const stack = html.split("<br>").map((row) => {
      const parsedRow = parser(row);
      const text = parsedRow.text();
      // Find out if the row has italic content
      const italic = parsedRow.find("i").length > 0;
      return { text: text, italics: italic };
    });
    return {
      stack: stack
    };
  });
  const header: Content = {
    columns: columns.toArray(),
    margin: [0, 10, 0, 20]
  };
  return header;
};

export const parseOrderTables = (html: HTMLElement): TableType[] => {
  const tables: TableType[] = [];
  const parser = cheerio.load(html.outerHTML);
  parser("table").each((index, element) => {
    const table: TableType = { headers: [], rows: [] };
    // Extract headers from the table
    parser(element)
      .find("thead tr")
      .each((index, element) => {
        const cells: TableCell[] = [];
        parser(element)
          .find("th")
          .each((index, element) => {
            const text = parser(element).text();
            cells.push({
              text: text,
              bold: true
            });
          });
        if (cells.length > 0) {
          table.headers.push(cells);
        }
      });

    // Loop through all rows in the table
    parser(element)
      .find("tbody tr")
      .each((index, element) => {
        const cells: TableCell[] = [];
        const fontWeight = parser(element).css("font-weight");
        // Loop through all cells in the row
        parser(element)
          .find("td")
          .each((index, element) => {
            const stack: Content[] = [];
            // Loop through all items in the cell
            parser(element)
              .children()
              .each((index, element) => {
                const parsed = parser(element);
                const text = parsed.text();
                // If element has classname line-through, add a muted style with a line through the text
                if (parsed.hasClass("line-through")) {
                  stack.push({
                    text: text,
                    decoration: "lineThrough",
                    color: "#888888",
                    fontSize: 8
                  });
                  return;
                } else {
                  stack.push({
                    text: text,
                    bold: fontWeight === "bold"
                  });
                }
              });
            // If there are no children, add the text of the element
            if (stack.length === 0) {
              cells.push({
                text: parser(element).text(),
                bold: fontWeight === "bold"
              });
            } else {
              cells.push({ stack: stack });
            }
          });
        if (cells.length > 0) {
          table.rows.push(cells);
        }
      });
    if (table.headers.length > 0 || table.rows.length > 0) {
      tables.push(table);
    }
  });
  return tables;
};

/** Parses an Order footer and returns it as stacks in columns */
export const parseOrderFooter = (html: HTMLElement): Content => {
  const parser = cheerio.load(html.outerHTML);
  // Find the element with the class "order-footer"
  const invoiceFooter = parser(".order-footer");
  // Make one column for each child in the invoice footer
  const columns = invoiceFooter.children().map((index, element) => {
    // Put the text of all children in a stack
    const stack = parser(element)
      .children()
      .map((index, child) => {
        const parsed = parser(child);
        const text = parsed.text();
        // Find out if the row has italic content
        const italic = parsed.find("i").length;
        if (italic > 0) {
          return { text: text, italics: true };
        } else {
          return { text: text };
        }
      });
    return {
      stack: stack.toArray(),
      width: "auto"
    };
  });
  const footer: Content = {
    columns: columns.toArray(),
    columnGap: 10,
    margin: [20, 0, 20, 20]
  };
  return footer;
};
