import React from "react";
import { Typography, Table } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import { useGetAllOrdersQuery } from "../../state/cargologRestApi";
import { isNull } from "lodash-es";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { useDispatch } from "react-redux";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import Online from "../MicroComponents/Online";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

/** All the fields for table used in this component */
interface ITableData {
  key: string;
  nr: string;
  invoicePrice: {
    price: number;
    currency: string;
  };
  status: string;
}

const InvoicesLandingCard = () => {
  const { t } = useTranslation();

  const { data: orderData, isLoading } = useGetAllOrdersQuery();

  const tableData: ITableData[] = orderData
    ? orderData.slice(0, 3).map((order) => {
        const status = !isNull(order.paid) ? t("paid") : t("pending");
        const row = {
          key: order.id,
          nr: order.invoiceNumber,
          invoicePrice: {
            price: order.priceTotal,
            currency: order.currency
          },
          status: status
        };
        return row;
      })
    : [];

  const columns: ColumnsType<ITableData> = [
    {
      title: t("tableNr"),
      dataIndex: "nr",
      key: "nr",
      width: 10,
      render: (nr) => <Text strong>{nr}</Text>
    },
    {
      title: t("tableAmount"),
      dataIndex: "invoicePrice",
      key: "invoicePrice",
      align: "right",
      width: 10,
      render: (data) => (
        <>
          <Text strong>{data.price}</Text> <Text>{data.currency}</Text>
        </>
      )
    },
    {
      title: t("tableStatus"),
      dataIndex: "status",
      key: "status",
      width: 10,
      render: (status) => {
        let statusColor = "#000";
        if (status === "paid") {
          statusColor = "green";
        }
        return (
          <Text style={{ color: statusColor, textTransform: "capitalize" }}>
            {status}
          </Text>
        );
      }
    }
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {LicenseAccess(
        "AFB",
        <LandingCard
          key="invoices"
          title={
            <LargeHeaderTitle>
              <CreditCardOutlined /> {t("Invoices")}
            </LargeHeaderTitle>
          }
          extra={
            <NormalButtonSmall
              onClick={() => {
                dispatch(setCurrentAdminTab("invoices"));
                navigate("/admin");
              }}
            >
              {t("Manage")}
            </NormalButtonSmall>
          }
        >
          <Online>
            {/* List latest 3 orders and link to orders.*/}
            <Table
              columns={columns}
              dataSource={tableData}
              style={{ width: "100%" }}
              size="small"
              pagination={{ hideOnSinglePage: true }}
              loading={isLoading}
            />
          </Online>
        </LandingCard>
      )}
    </>
  );
};

export default InvoicesLandingCard;
