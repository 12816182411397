import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Company,
  CompanyDiscount,
  Invoice,
  LicensePrice,
  LicenseWithCost,
  ParameterForAlteration,
  User,
  Order
} from "./cargologRestApi";
import { StoreApi } from "./store";

export interface EditCompanyMobitronModal {
  isOpen: boolean;
  companyId?: string;
  companyName?: string;
  isDeletable: boolean;
}

/** Main interface for this state-slice */
export interface ModalState {
  isAboutModalOpen: boolean;
  isHelpModalOpen: boolean;
  isCreateUserModalOpen: boolean;
  isUserRequestModalOpen: boolean;
  isUserRequestAdminModalOpen: boolean;
  isEditUserCompanyModalOpen: boolean;
  isEditUserNameModalOpen: boolean;
  isEditUserPhoneModalOpen: boolean;
  isEditCompanyModalOpen: boolean;
  isEditInvoiceInfoModalOpen: boolean;
  invoiceObject?: Invoice;
  isRemoveOrderModalOpen: boolean;
  orderObject?: Order;
  companyObject?: Company;
  editCompanyMobitronModal: EditCompanyMobitronModal;
  isRemoveCompanyMobitronModalOpen: boolean;
  companyIdObject?: string;
  isChangePasswordModalOpen: boolean;
  isCreateLicenseModalOpen: boolean;
  isRemoveLicenseModalOpen: boolean;
  licenseObject?: LicenseWithCost;
  isRemoveLicensePriceModalOpen: boolean;
  isEditLicensePriceModalOpen: boolean;
  isChangeEmailModalOpen: boolean;
  isEditUserModalOpen: boolean;
  userObject?: User;
  isRemoveUserModalOpen: boolean;
  isLicenseDetailsModalOpen: boolean;
  setLicensePrice?: LicensePrice;
  setUserToRemove?: User;
  isAddDiscountModalOpen: boolean;
  invoiceObjectId?: number;
  isOrderDetailsModalOpen: boolean;
  isRemoveLicenseDiscountModalOpen: boolean;
  licenseDiscountObject?: CompanyDiscount;
  isGraphAxisScaleModalOpen: boolean;
  isTimezoneModalOpen: boolean;
  isFilePasswordModalOpen: boolean;
  passwordFileId?: string;
  isSystemInfoModalOpen: boolean;
  isParamsPreviewModalOpen: boolean;
  isPositionsModalOpen: boolean;
  isFinishTransportModalOpen: boolean;
  transportToFinish?: ParameterForAlteration;
  isReviewDatxUploadModalOpen: boolean;
  isRenewLicenseModalOpen: boolean;
}

/** Initial state for this slice */
const initialState: ModalState = {
  isAboutModalOpen: false,
  isHelpModalOpen: false,
  isCreateUserModalOpen: false,
  isUserRequestModalOpen: false,
  isUserRequestAdminModalOpen: false,
  isEditUserCompanyModalOpen: false,
  isEditUserNameModalOpen: false,
  isEditUserPhoneModalOpen: false,
  isEditCompanyModalOpen: false,
  isEditInvoiceInfoModalOpen: false,
  isRemoveOrderModalOpen: false,
  invoiceObject: undefined,
  companyObject: undefined,
  editCompanyMobitronModal: {
    isOpen: false,
    isDeletable: false
  },
  isRemoveCompanyMobitronModalOpen: false,
  companyIdObject: undefined,
  isChangePasswordModalOpen: false,
  isCreateLicenseModalOpen: false,
  isRemoveLicenseModalOpen: false,
  licenseObject: undefined,
  isRemoveLicensePriceModalOpen: false,
  isEditLicensePriceModalOpen: false,
  isChangeEmailModalOpen: false,
  isEditUserModalOpen: false,
  userObject: undefined,
  isRemoveUserModalOpen: false,
  setUserToRemove: undefined,
  isAddDiscountModalOpen: false,
  isLicenseDetailsModalOpen: false,
  setLicensePrice: undefined,
  isOrderDetailsModalOpen: false,
  invoiceObjectId: undefined,
  isRemoveLicenseDiscountModalOpen: false,
  licenseDiscountObject: undefined,
  isGraphAxisScaleModalOpen: false,
  isTimezoneModalOpen: false,
  isFilePasswordModalOpen: false,
  passwordFileId: undefined,
  isSystemInfoModalOpen: false,
  isParamsPreviewModalOpen: false,
  isPositionsModalOpen: false,
  isFinishTransportModalOpen: false,
  isReviewDatxUploadModalOpen: false,
  isRenewLicenseModalOpen: false
};

export const slice = createSlice({
  name: "Modals",
  initialState,
  reducers: {
    closeAllModals: (state) => {
      state.isAboutModalOpen = false;
      state.isHelpModalOpen = false;
      state.isCreateUserModalOpen = false;
      state.isUserRequestModalOpen = false;
      state.isUserRequestAdminModalOpen = false;
      state.isEditUserCompanyModalOpen = false;
      state.isEditUserNameModalOpen = false;
      state.isEditUserPhoneModalOpen = false;
      state.isEditCompanyModalOpen = false;
      state.isEditInvoiceInfoModalOpen = false;
      state.isRemoveOrderModalOpen = false;
      state.editCompanyMobitronModal.isOpen = false;
      state.isRemoveCompanyMobitronModalOpen = false;
      state.isChangePasswordModalOpen = false;
      state.isCreateLicenseModalOpen = false;
      state.isRemoveLicenseModalOpen = false;
      state.isRemoveLicensePriceModalOpen = false;
      state.isEditLicensePriceModalOpen = false;
      state.isChangeEmailModalOpen = false;
      state.isEditUserModalOpen = false;
      state.isRemoveUserModalOpen = false;
      state.isLicenseDetailsModalOpen = false;
      state.isAddDiscountModalOpen = false;
      state.isOrderDetailsModalOpen = false;
      state.isRemoveLicenseDiscountModalOpen = false;
      state.isGraphAxisScaleModalOpen = false;
      state.isTimezoneModalOpen = false;
      state.isFilePasswordModalOpen = false;
      state.isSystemInfoModalOpen = false;
      state.isParamsPreviewModalOpen = false;
      state.isPositionsModalOpen = false;
      state.isFinishTransportModalOpen = false;
      state.isReviewDatxUploadModalOpen = false;
      state.isRenewLicenseModalOpen = false;
    },

    openAboutModal: (state) => {
      state.isAboutModalOpen = true;
    },
    closeAboutModal: (state) => {
      state.isAboutModalOpen = false;
    },

    openHelpModal: (state) => {
      state.isHelpModalOpen = true;
    },
    closeHelpModal: (state) => {
      state.isHelpModalOpen = false;
    },

    openCreateUserModal: (state) => {
      state.isCreateUserModalOpen = true;
    },
    closeCreateUserModal: (state) => {
      state.isCreateUserModalOpen = false;
    },

    openUserRequestModal: (state) => {
      state.isUserRequestModalOpen = true;
    },
    closeUserRequestModal: (state) => {
      state.isUserRequestModalOpen = false;
    },

    openUserRequestAdminModal: (state) => {
      state.isUserRequestAdminModalOpen = true;
    },
    closeUserRequestAdminModal: (state) => {
      state.isUserRequestAdminModalOpen = false;
    },

    openEditUserCompanyModal: (state) => {
      state.isEditUserCompanyModalOpen = true;
    },
    closeEditUserCompanyModal: (state) => {
      state.isEditUserCompanyModalOpen = false;
    },

    openEditUserNameModal: (state) => {
      state.isEditUserNameModalOpen = true;
    },
    closeEditUserNameModal: (state) => {
      state.isEditUserNameModalOpen = false;
    },

    openEditUserPhoneModal: (state) => {
      state.isEditUserPhoneModalOpen = true;
    },
    closeEditUserPhoneModal: (state) => {
      state.isEditUserPhoneModalOpen = false;
    },

    openEditCompanyModal: (state) => {
      state.isEditCompanyModalOpen = true;
    },
    closeEditCompanyModal: (state) => {
      state.isEditCompanyModalOpen = false;
    },
    openEditInvoiceInfoModal: (state) => {
      state.isEditInvoiceInfoModalOpen = true;
    },
    closeEditInvoiceInfoModal: (state) => {
      state.isEditInvoiceInfoModalOpen = false;
    },
    setCompany: (state, action: PayloadAction<Company | undefined>) => {
      state.companyObject = action.payload;
    },

    openRemoveOrderModal: (state) => {
      state.isRemoveOrderModalOpen = true;
    },
    closeRemoveOrderModal: (state) => {
      state.isRemoveOrderModalOpen = false;
      state.orderObject = undefined;
    },
    setOrderToRemove: (state, action: PayloadAction<Order>) => {
      state.orderObject = action.payload;
    },

    openEditCompanyMobitronModal: (state) => {
      state.editCompanyMobitronModal.isOpen = true;
    },
    closeEditCompanyMobitronModal: (state) => {
      state.editCompanyMobitronModal.isOpen = false;
    },
    setCompanyId: (state, action: PayloadAction<string | undefined>) => {
      state.editCompanyMobitronModal.companyId = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string | undefined>) => {
      state.editCompanyMobitronModal.companyName = action.payload;
    },
    setCompanyDeletable: (state, action: PayloadAction<boolean>) => {
      state.editCompanyMobitronModal.isDeletable = action.payload;
    },
    openRemoveCompanyMobitronModal: (state) => {
      state.isRemoveCompanyMobitronModalOpen = true;
    },
    closeRemoveCompanyMobitronModal: (state) => {
      state.isRemoveCompanyMobitronModalOpen = false;
    },
    openChangePasswordModal: (state) => {
      state.isChangePasswordModalOpen = true;
    },
    closeChangePasswordModal: (state) => {
      state.isChangePasswordModalOpen = false;
    },

    openCreateLicenseModal: (state) => {
      state.isCreateLicenseModalOpen = true;
    },
    closeCreateLicenseModal: (state) => {
      state.isCreateLicenseModalOpen = false;
    },

    openRemoveLicenseModal: (state) => {
      state.isRemoveLicenseModalOpen = true;
    },
    closeRemoveLicenseModal: (state) => {
      state.isRemoveLicenseModalOpen = false;
    },
    setLicenseToRemove: (state, action: PayloadAction<LicenseWithCost>) => {
      state.licenseObject = action.payload;
    },

    openRemoveLicensePriceModal: (state) => {
      state.isRemoveLicensePriceModalOpen = true;
    },

    closeRemoveLicensePriceModal: (state) => {
      state.isRemoveLicensePriceModalOpen = false;
    },

    openEditLicensePriceModal: (state) => {
      state.isEditLicensePriceModalOpen = true;
    },

    closeEditLicensePriceModal: (state) => {
      state.isEditLicensePriceModalOpen = false;
    },

    openChangeEmailModal: (state) => {
      state.isChangeEmailModalOpen = true;
    },
    closeChangeEmailModal: (state) => {
      state.isChangeEmailModalOpen = false;
    },

    openEditUserModal: (state) => {
      state.isEditUserModalOpen = true;
    },
    closeEditUserModal: (state) => {
      state.isEditUserModalOpen = false;
      state.userObject = undefined;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.userObject = action.payload;
    },

    openRemoveUserModal: (state) => {
      state.isRemoveUserModalOpen = true;
    },
    closeRemoveUserModal: (state) => {
      state.isRemoveUserModalOpen = false;
    },
    setUserToRemove: (state, action: PayloadAction<User>) => {
      state.setUserToRemove = action.payload;
    },
    openLicenseDetailsModal: (state) => {
      state.isLicenseDetailsModalOpen = true;
    },
    closeLicenseDetailsModal: (state) => {
      state.isLicenseDetailsModalOpen = false;
      state.setLicensePrice = undefined;
    },
    setLicensePrice: (state, action: PayloadAction<LicensePrice>) => {
      state.setLicensePrice = action.payload;
    },
    resetModalsState: (state) => {
      Object.assign(state, initialState);
    },

    openAddDiscountModal: (state) => {
      state.isAddDiscountModalOpen = true;
    },
    closeAddDiscountModal: (state) => {
      state.isAddDiscountModalOpen = false;
    },

    setInvoiceId: (state, action: PayloadAction<number | undefined>) => {
      state.invoiceObjectId = action.payload;
    },
    openOrderDetailsModal: (state) => {
      state.isOrderDetailsModalOpen = true;
    },
    closeOrderDetailsModal: (state) => {
      state.isOrderDetailsModalOpen = false;
      state.invoiceObjectId = undefined;
    },
    openRemoveLicenseDiscountModal: (state) => {
      state.isRemoveLicenseDiscountModalOpen = true;
    },
    closeRemoveLicenseDiscountModal: (state) => {
      state.isRemoveLicenseDiscountModalOpen = false;
    },
    setLicenseDiscountToRemove: (
      state,
      action: PayloadAction<CompanyDiscount>
    ) => {
      state.licenseDiscountObject = action.payload;
    },
    openGraphAxisScaleModal: (state) => {
      state.isGraphAxisScaleModalOpen = true;
    },
    closeGraphAxisScaleModal: (state) => {
      state.isGraphAxisScaleModalOpen = false;
    },
    openTimezoneModal: (state) => {
      state.isTimezoneModalOpen = true;
    },
    closeTimezoneModal: (state) => {
      state.isTimezoneModalOpen = false;
    },
    openFilePasswordModal: (state) => {
      state.isFilePasswordModalOpen = true;
    },
    closeFilePasswordModal: (state) => {
      state.isFilePasswordModalOpen = false;
    },
    setPasswordFileId: (state, action: PayloadAction<string>) => {
      state.passwordFileId = action.payload;
    },
    openSystemInfoModal: (state) => {
      state.isSystemInfoModalOpen = true;
    },
    closeSystemInfoModal: (state) => {
      state.isSystemInfoModalOpen = false;
    },
    openParamsPreviewModal: (state) => {
      state.isParamsPreviewModalOpen = true;
    },
    closeParamsPreviewModal: (state) => {
      state.isParamsPreviewModalOpen = false;
    },
    openPositionsModal: (state) => {
      state.isPositionsModalOpen = true;
    },
    closePositionsModal: (state) => {
      state.isPositionsModalOpen = false;
    },

    openFinishTransportModal: (state) => {
      state.isFinishTransportModalOpen = true;
    },
    closeFinishTransportModal: (state) => {
      state.isFinishTransportModalOpen = false;
    },
    setTransportToFinish: (
      state,
      action: PayloadAction<ParameterForAlteration>
    ) => {
      state.transportToFinish = action.payload;
    },
    openReviewDatxUploadModal: (state) => {
      state.isReviewDatxUploadModalOpen = true;
    },
    closeReviewDatxUploadModal: (state) => {
      state.isReviewDatxUploadModalOpen = false;
    },
    openRenewLicenseModal: (state) => {
      state.isRenewLicenseModalOpen = true;
    },
    closeRenewLicenseModal: (state) => {
      state.isRenewLicenseModalOpen = false;
    }
  }
});

export const {
  closeAllModals,

  openAboutModal,
  closeAboutModal,

  openHelpModal,
  closeHelpModal,

  openCreateUserModal,
  closeCreateUserModal,

  openUserRequestModal,
  closeUserRequestModal,

  openUserRequestAdminModal,
  closeUserRequestAdminModal,

  openEditUserCompanyModal,
  closeEditUserCompanyModal,

  openEditUserNameModal,
  closeEditUserNameModal,

  openEditUserPhoneModal,
  closeEditUserPhoneModal,

  openEditCompanyModal,
  closeEditCompanyModal,

  openEditInvoiceInfoModal,
  closeEditInvoiceInfoModal,
  setCompany,

  openRemoveOrderModal,
  closeRemoveOrderModal,
  setOrderToRemove,

  openEditCompanyMobitronModal,
  closeEditCompanyMobitronModal,
  setCompanyId,
  setCompanyName,
  setCompanyDeletable,

  openRemoveCompanyMobitronModal,
  closeRemoveCompanyMobitronModal,

  openChangePasswordModal,
  closeChangePasswordModal,

  openCreateLicenseModal,
  closeCreateLicenseModal,

  openRemoveLicenseModal,
  closeRemoveLicenseModal,
  setLicenseToRemove,

  openRemoveLicensePriceModal,
  closeRemoveLicensePriceModal,

  openEditLicensePriceModal,
  closeEditLicensePriceModal,

  openChangeEmailModal,
  closeChangeEmailModal,

  openEditUserModal,
  closeEditUserModal,
  setUser,

  openRemoveUserModal,
  closeRemoveUserModal,
  setUserToRemove,

  openAddDiscountModal,
  closeAddDiscountModal,
  openRemoveLicenseDiscountModal,
  closeRemoveLicenseDiscountModal,
  setLicenseDiscountToRemove,

  openLicenseDetailsModal,
  closeLicenseDetailsModal,

  setLicensePrice,

  resetModalsState,

  openOrderDetailsModal,
  closeOrderDetailsModal,
  setInvoiceId,

  openGraphAxisScaleModal,
  closeGraphAxisScaleModal,

  openTimezoneModal,
  closeTimezoneModal,

  openFilePasswordModal,
  closeFilePasswordModal,
  setPasswordFileId,

  openSystemInfoModal,
  closeSystemInfoModal,

  openParamsPreviewModal,
  closeParamsPreviewModal,

  openPositionsModal,
  closePositionsModal,

  openFinishTransportModal,
  closeFinishTransportModal,
  setTransportToFinish,

  openReviewDatxUploadModal,
  closeReviewDatxUploadModal,

  openRenewLicenseModal,
  closeRenewLicenseModal
} = slice.actions;

export const modalsState = (state: StoreApi) => state.modals;

export default slice.reducer;
