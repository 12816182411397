import React, { useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  notification,
  Typography,
  Space,
  Divider
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeInviteExternalUserModal,
  closeProjectModal,
  openAddInternalUserModal,
  projectsState,
  resetProjectModalTab
} from "../../state/projectSlice";
import { NormalButtonSmall, PrimaryButton } from "../Common/CommonButtons";
import { isNull, isUndefined } from "lodash-es";
import {
  ProjectInviteForCreation,
  useCreateProjectInviteMutation,
  useGetAllLicensePricesQuery,
  useGetLicensesQuery,
  useGetProjectInvitesQuery,
  useGetUserRightsQuery,
  useGetUsersQuery
} from "../../state/cargologRestApi";
import { size } from "../../helpers/pageHelper";
import { useNavigate } from "react-router";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import StandardModal from "../Common/StandardModal";

const { Text, Link } = Typography;
const { Option } = Select;


const InviteExternalUserModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isInviteExternalUserModalOpen, selectedProjectId } =
    useSelector(projectsState);

  const { data: companyUsers } = useGetUsersQuery();
  const { data: inviteData } = useGetProjectInvitesQuery();
  const { data: userRightsData } = useGetUserRightsQuery();

  const [createProjectInvite, requestStatus] = useCreateProjectInviteMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [email, setEmail] = useState<string>("");
  const [license, setLicense] = useState<string>();

  const handleAddPerson = async (request: ProjectInviteForCreation) => {
    const result: any = await createProjectInvite(request);
    if (result.data) {
      dispatch(closeInviteExternalUserModal());
      setEmail("");
    } else {
      notification.error({
        message: t("FailedToSendTheRequest"),
        style: { zIndex: 1050 }
      });
    }
  };

  const userExistInCompany = companyUsers
    ?.map((user) => user.email)
    .find((companyEmail) => email === companyEmail);

  const onFinish = () => {
    if (
      !isUndefined(email) &&
      !isUndefined(license) &&
      !isUndefined(selectedProjectId)
    ) {
      const request: ProjectInviteForCreation = {
        email: email,
        licenseId: license,
        projectId: selectedProjectId
      };
      handleAddPerson(request);
    }
  };

  const { data: invites } = useGetProjectInvitesQuery();
  /** A list of licenseIds used in project invites that are not yet answered. */
  const pendingInvites = invites
    ?.filter((i) => isNull(i.accepted))
    .map((i) => i.licenseId);

  const { data: LicensePricesData } = useGetAllLicensePricesQuery();
  /** Shows only licenses with userRights that affect projects */
  const filteredLicenses = LicensePricesData?.filter(
    (e) =>
      e.userRights.includes("ADK") ||
      e.userRights.includes("ADI") ||
      e.userRights.includes("ADL") ||
      e.userRights.includes("ADM")
  ).map((license) => license.name);

  const { data: companyLicenses } = useGetLicensesQuery({});
  /** Filter out not activated licenses and licenses used in pending invites */
  const availableLicenses = companyLicenses?.filter(
    (license) =>
      !license.userId &&
      license.isActivated &&
      pendingInvites?.find((e) => e === license.id) !== license.id &&
      filteredLicenses?.includes(license.text)
  );

  const licenseOptions = availableLicenses?.map((license) => {
    return (
      <Option key={license.id} value={license.id}>
        {license.text}{" "}
        <Text style={{ fontSize: 12 }} type="secondary">
          ({license.id})
        </Text>
      </Option>
    );
  });

  const isAlreadyInvited = inviteData?.filter(
    (invite) =>
      invite.projectId === selectedProjectId &&
      isNull(invite.accepted) &&
      invite.email === email
  );

  const onCancel = () => {
    dispatch(closeInviteExternalUserModal());
    setEmail("");
  };

  const isDisabled =
    userExistInCompany || (isAlreadyInvited && isAlreadyInvited.length > 0);

  return (
    <StandardModal
      title={t("InviteUser")}
      open={isInviteExternalUserModalOpen}
      closable={true}
      destroyOnClose={true}
      zIndex={1045}
      footer={null}
      width={400}
      onCancel={onCancel}
    >
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[
                { required: true, message: t("EmailIsRequired") },
                { type: "email", message: t("EmailIsNotValid") }
              ]}
            >
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {userExistInCompany && (
          <Space
            direction="vertical"
            style={{ width: "100%", paddingBottom: size.l2 }}
          >
            <Row justify="center">
              <Text>{t("ThisUserIsInTheSameCompanyAsYou")}</Text>
            </Row>
            <Row justify="center">
              <NormalButtonSmall
                onClick={() => {
                  dispatch(closeInviteExternalUserModal());
                  dispatch(openAddInternalUserModal());
                  setEmail("");
                }}
              >
                <Link>{t("AddUserHere")}</Link>
              </NormalButtonSmall>
            </Row>
          </Space>
        )}
        {isAlreadyInvited && isAlreadyInvited?.length > 0 && (
          <Space
            direction="vertical"
            style={{ width: "100%", paddingBottom: size.l2 }}
          >
            <Row justify="center">
              <Text>{t("YouHaveAlreadySentAnInviteToThisEmail")}</Text>
            </Row>
          </Space>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                isDisabled ? (
                  <Text type="secondary">{t("License")}</Text>
                ) : (
                  <Text>{t("License")}</Text>
                )
              }
              name="license"
            >
              <Select
                placeholder={t("AssignLicense")}
                value={license}
                onChange={(value: string) => setLicense(value)}
                disabled={isDisabled ? true : false}
                notFoundContent={
                  <>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row justify="center">
                        <Text strong type="secondary">
                          {t("NoLicensesFound")}
                        </Text>
                      </Row>
                      {!userRightsData?.includes("AFB") && (
                        <Row justify="center">
                          <Text>
                            {t("ContactYourCompanyAdminToGetMoreLicenses")}
                          </Text>
                        </Row>
                      )}
                    </Space>
                  </>
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        marginTop: size.s1,
                        marginInline: 0,
                        marginBottom: size.m1
                      }}
                    />
                    <Row>
                      {LicenseAccess(
                        "AFB",
                        <Row
                          justify="center"
                          style={{
                            width: "100%",
                            marginBottom: size.m1
                          }}
                        >
                          <NormalButtonSmall
                            onClick={() => {
                              dispatch(closeInviteExternalUserModal());
                              dispatch(closeProjectModal());
                              dispatch(resetProjectModalTab());
                              dispatch(setCurrentAdminTab("store"));
                              navigate("/admin");
                            }}
                          >
                            {t("BuyProjectLicenses")}
                          </NormalButtonSmall>
                        </Row>
                      )}
                    </Row>
                  </>
                )}
              >
                {licenseOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
            disabled={isDisabled ? true : false}
          >
            {t("AddPerson")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default InviteExternalUserModal;
