import React from "react";
import { Form, notification, Tabs } from "antd";
import {
  modalsState,
  closeEditLicensePriceModal
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import {
  NewLicensePrice,
  useUpdateLicensePriceMutation,
  ILicensePriceDefs
} from "../../state/cargologRestApi";
import EditLicensePriceModalStage1 from "./EditLicensePriceModalStage1";
import EditLicensePriceModalStage2 from "./EditLicensePriceModalStage2";
import EditLicensePriceModalStage3 from "./EditLicensePriceModalStage3";
import {
  selectLicensePriceState,
  emptyLicensePriceState
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash-es";
import StandardModal from "../Common/StandardModal";

interface IProps {}
const EditLicensePriceModal: React.FC<IProps> = (props) => {
  const licenseInfo = useSelector(selectLicensePriceState);
  const licenseToEdit = licenseInfo.licensePriceToEdit;

  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { t } = useTranslation();

  const [updateLicensePrice, requestStatus] = useUpdateLicensePriceMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleUpdateLicensePrice = async (request: NewLicensePrice) => {
    const result: any = await updateLicensePrice(request);
    if (result.data) {
      notification.success({ message: t("LicenseSuccessfullyUpdated") });
      dispatch(emptyLicensePriceState());
      dispatch(closeEditLicensePriceModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("FailedToUpdateLicense"),
        description: errorDescription
      });
    }
  };

  const genLicensePrice = (price: number, currency: string, months: number) => {
    return { price, currency, months };
  };

  const getLicensePrices = (
    licensePrices: ILicensePriceDefs[],
    currency: string,
    months: number
  ) => {
    const price =
      licensePrices.filter(
        (licensePrice) =>
          licensePrice.currency === currency && licensePrice.months === months
      )[0]?.price ?? 0;
    return { price, currency, months };
  };

  const onFinish = () => {
    //No validation done yet. Will be doing here.

    if (licenseToEdit) {
      const userRights = licenseInfo.userRights
        ? licenseInfo.userRights
        : licenseToEdit.userRights;
      const request: NewLicensePrice = {
        id: licenseToEdit.id,
        created: licenseToEdit.created,
        userId: licenseToEdit.userId,
        name: licenseInfo.name !== "" ? licenseInfo.name : licenseToEdit.name,
        description:
          licenseInfo.description !== ""
            ? licenseInfo.description
            : licenseToEdit.description,
        deviceType: licenseToEdit.deviceType ?? 1,
        color: licenseInfo.color ?? licenseToEdit.color,
        licensePriceDefs: [
          !isUndefined(licenseInfo.prices.sek3)
            ? genLicensePrice(licenseInfo.prices.sek3, "SEK", 3)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "SEK", 3),
          !isUndefined(licenseInfo.prices.sek6)
            ? genLicensePrice(licenseInfo.prices.sek6, "SEK", 6)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "SEK", 6),
          !isUndefined(licenseInfo.prices.sek12)
            ? genLicensePrice(licenseInfo.prices.sek12, "SEK", 12)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "SEK", 12),
          !isUndefined(licenseInfo.prices.eur3)
            ? genLicensePrice(licenseInfo.prices.eur3, "EUR", 3)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "EUR", 3),
          !isUndefined(licenseInfo.prices.eur6)
            ? genLicensePrice(licenseInfo.prices.eur6, "EUR", 6)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "EUR", 6),
          !isUndefined(licenseInfo.prices.eur12)
            ? genLicensePrice(licenseInfo.prices.eur12, "EUR", 12)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "EUR", 12),
          !isUndefined(licenseInfo.prices.usd3)
            ? genLicensePrice(licenseInfo.prices.usd3, "USD", 3)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "USD", 3),
          !isUndefined(licenseInfo.prices.usd6)
            ? genLicensePrice(licenseInfo.prices.usd6, "USD", 6)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "USD", 6),
          !isUndefined(licenseInfo.prices.usd12)
            ? genLicensePrice(licenseInfo.prices.usd12, "USD", 12)
            : getLicensePrices(licenseToEdit.licensePriceDefs, "USD", 12)
        ],
        userRights: userRights
      };

      if (request?.userRights) {
        handleUpdateLicensePrice(request);
      } else {
        notification.error({
          message: t("LicenseRequiresAtLeastOneUserRight")
        });
      }
    }
  };

  const closeModal = () => {
    dispatch(emptyLicensePriceState());
    dispatch(closeEditLicensePriceModal());
  };

  return (
    <>
      <StandardModal
        title={t("licensePriceEditLicense")}
        open={modals.isEditLicensePriceModalOpen}
        onCancel={() => closeModal()}
        zIndex={1045}
        closable={true}
        footer={[
          <NormalButton key={"Cancel"} onClick={() => closeModal()}>
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            key={"genSave"}
            onClick={onFinish}
            loading={requestIsLoading}
          >
            {t("genSave")}
          </PrimaryButton>
        ]}
        destroyOnClose={true}
        width={500}
        style={{ height: "600px" }}
      >
        <Form
          name={"basic"}
          initialValues={{ remember: true }}
          layout={"vertical"}
          style={{ width: "100%" }}
        >
          <Tabs type={"card"}>
            <TabPane tab={t("licensePriceNameAndDescription")} key={"0"}>
              <EditLicensePriceModalStage1 />
            </TabPane>
            <TabPane tab={t("licensePricePrices")} key={"1"}>
              <EditLicensePriceModalStage2 />
            </TabPane>

            <TabPane tab={t("licensePriceUserRights")} key={"2"}>
              <EditLicensePriceModalStage3 />
            </TabPane>
          </Tabs>
        </Form>
      </StandardModal>
    </>
  );
};

export default EditLicensePriceModal;
