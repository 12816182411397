import React from "react";
import { Table } from "antd";
import { ProfileOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import dayjs from "dayjs";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import { useGetRecentDeviceQuery } from "../../state/cargologRestApi";

interface ITableData {
  serialNum: string;
  lastSeen: number;
}

const DevicesLandingCard = () => {
  const { t } = useTranslation();
  const { data: myDevices, isLoading } = useGetRecentDeviceQuery();

  const columns: ColumnsType<ITableData> = [
    {
      title: "Serial number",
      dataIndex: "serialNum",
      key: "serialNum",
      width: "40%"
    },
    {
      title: "Last seen",
      dataIndex: "lastSeen",
      key: "lastSeen",
      width: "60%",
      render: (value: number) => dayjs.unix(value).format("MMMM Do YYYY")
    }
  ];

  const tableData = myDevices?.slice(0, 3).map((item, index) => ({
    key: index,
    serialNum: item.systemInfo.serial.toString(),
    lastSeen: item.lastInteraction
  }));

  const navigate = useNavigate();

  return (
    <>
      {LicenseAccess(
        "ABA",
        <LandingCard
          key="devices"
          title={
            <LargeHeaderTitle>
              <ProfileOutlined /> {t("Devices")}
            </LargeHeaderTitle>
          }
          extra={
            <NormalButtonSmall
              onClick={() => {
                navigate("/devices");
              }}
            >
              {t("Manage")}
            </NormalButtonSmall>
          }
        >
          {/* List recent devices if any and link to My Devices and Setup Device */}
          <Table
            columns={columns}
            dataSource={tableData}
            loading={isLoading}
            style={{ width: "100%" }}
            size="small"
            pagination={{ hideOnSinglePage: true }}
          />
        </LandingCard>
      )}
    </>
  );
};

export default DevicesLandingCard;
