import { CheckOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { Buffer } from "buffer";
import { isUndefined } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useUploadDatxMutation } from "../../state/cargologRestApi";
import { selectRawDataById } from "../../state/openDatxSlice";
import { PrimaryButton } from "../Common/CommonButtons";

interface IProps {
  fileId: string;
  isOnlineFile?: boolean;
}
const UploadDatxButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const windowWidth = window.innerWidth;

  const rawData = useSelector(selectRawDataById(props.fileId));
  const [uploadDatx, requestStatus] = useUploadDatxMutation();

  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);

  const handleUploadFileToCloud = async (request: Buffer) => {
    const result: any = await uploadDatx(request);
    if (result.data) {
      setUploadSuccessful(true);
    } else if (result.error) {
      notification.error({ message: t("FailedToUploadDatxFile") });
      setUploadSuccessful(false);
    }
  };

  const { isLoading: requestIsLoading } = requestStatus;
  const uploadFileToCloud = () => {
    if (!isUndefined(rawData)) {
      const dataBuffer = Buffer.from(rawData);
      handleUploadFileToCloud(dataBuffer);
    }
  };

  return (
    <>
      <PrimaryButton
        icon={uploadSuccessful ? <CheckOutlined /> : <CloudUploadOutlined />}
        onClick={() => uploadFileToCloud()}
        loading={requestIsLoading}
        disabled={isUndefined(rawData) || props.isOnlineFile}
      >
        {windowWidth > 990 ? t("UploadFileToCloud") : undefined}
      </PrimaryButton>
    </>
  );
};

export default UploadDatxButton;
