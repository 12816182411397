import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "./vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export const createPdf = (
  documentDefinition: TDocumentDefinitions,
  fileName: string
) => {
  const fonts = {
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Medium.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-MediumItalic.ttf"
    }
  };

  var tableLayouts = {
    antdLayout: {
      fillColor: function (i, node) {
        return i < node.table.headerRows ? "#fafafa" : null;
      },
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return i < node.table.headerRows ? 0 : 1;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i, node) {
        return i === node.table.headerRows ? "#f0f0f0" : "#fafafa";
      },
      paddingLeft: function (i) {
        return 8;
      },
      paddingRight: function (i, node) {
        return 8;
      },
      paddingTop: function (i) {
        return 10;
      },
      paddingBottom: function (i) {
        return 7;
      }
    },
    transportHeaderLayout: {
      fillColor: function (i, node) {
        return i < node.table.headerRows ? "#fafafa" : null;
      },
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 1;
        }
        return 0;
      },
      vLineWidth: function (i, node) {
        if (i === 0 || i === node.table.widths.length) {
          return 1;
        }
        return 0;
      },
      hLineColor: function (i) {
        return "#fafafa";
      },
      vLineColor: function (i) {
        return "#fafafa";
      },
      paddingLeft: function (i) {
        return 8;
      },
      paddingRight: function (i) {
        return 8;
      },
      paddingTop: function (i) {
        return 5;
      },
      paddingBottom: function (i) {
        return 4;
      }
    },
    noBorderLayout: {
      fillColor: function (i, node) {
        return null;
      },
      hLineWidth: function (i, node) {
        return 0;
      },
      vLineWidth: function (i) {
        return 0;
      },
      paddingLeft: function (i) {
        return 8;
      },
      paddingRight: function (i, node) {
        return 8;
      },
      paddingTop: function (i) {
        return 5;
      },
      paddingBottom: function (i) {
        return 4;
      }
    }
  };

  pdfMake
    .createPdf(documentDefinition, tableLayouts, fonts, vfsFonts)
    .download(fileName);
};
