import * as cheerio from "cheerio";
import { TableType } from "./pdfInterfaces";
import { parseBackground } from "./pdfHelpers";
import { TableCell } from "pdfmake/interfaces";

/** Parses a Quick Report table with headers and rows */
export const parseQuickReportTable = (html: HTMLElement): TableType => {
  const parser = cheerio.load(html.outerHTML);
  // Remove all SVG elements
  parser("svg").remove();
  const htmlTable = parser("table").first();
  const table: TableType = { headers: [], rows: [] };
  // Extract headers and rows from the table
  htmlTable.find("tr").each((index, element) => {
    const headerCells: TableCell[] = [];
    parser(element)
      .find("th")
      .each((index, element) => {
        const text = parser(element).text();
        headerCells.push({
          text: text,
          bold: true
        });
      });
    if (headerCells.length > 0) {
      table.headers.push(headerCells);
    }
    const contentCells: TableCell[] = [];
    parser(element)
      .find("td")
      .each((index, element) => {
        // Find if any of the children have a background-color
        let backgroundColor: string | undefined = undefined;
        parser(element)
          .children()
          .each((index, element) => {
            backgroundColor = parseBackground(
              parser(element).css("background-color")
            );
          });
        const text = parser(element).text().replace(/\n/g, "").trim();
        contentCells.push({
          text: text,
          background: backgroundColor
        });
      });
    if (contentCells.length > 0) {
      table.rows.push(contentCells);
    }
  });

  return table;
};
