import i18n from "i18next";
import { initReactI18next } from "react-i18next";
export const resources = {
  sv: {
    translations: {
      Start: "Hem",
      Other: "Övrigt",
      DataVisualisation: "Datavisualisering",
      Data: "Data",
      generalTitleSettings: "Inställningar",
      closeFileTip: "Stäng flik",
      titleMyDevicesSm: "Mina enheter",
      titleGraphSm: "Rapport",
      noOpenFileMsg: "Tryck 'Öppna fil' för att öppna en graf",
      openFileBtn: "Öppna fil",
      openFilesGraph: "Öppna",
      recentFilesGraph: "Senaste",
      graphSubMenuTitle: "Mätdata",
      usersMainMenuTitle: "Användare",
      licensesMainMenuTitle: "Licenser till försäljning",
      storeMainMenuTitle: "Butik",

      btnExport: "Exportera",
      btnExportGraphSvg: "Exportera som SVG",
      btnExportGraphPng: "Exportera som PNG",
      btnExportGraphPDF: "Exportera som PDF",
      saveAsBtn: "Spara som",
      paramsBtnStart: "Startdatum",
      paramsBtnStop: "Slutdatum",
      paramsAltDate: "Datum",
      paramsAltBtn: "Vid knapptryck",
      paramsAltDirectly: "Direkt",
      paramsAltAfterDuration: "Efter varaktighet",
      paramsAltBtnMessage:
        "Inspelning kommer starta efter att du trycker på KNAPPEN på din enhet.",
      paramTitleAcc: "Parametrar för acceleration",
      paramTitleTemp: "Parametrar för temperatur",
      paramTitleHumidity: "Parametrar för fukt",
      loadRecParamsModalTitle: "Laddar parametrar",
      warningTitleErrNewDataAvil: "Varning, Ny data tillgänglig",
      warningTextErrNewDataAvil:
        "Du har ny oläst data tillgänglig. Vill du formatera?",
      warningTitleErrMemNotEmpty: "Varning, minnet är inte tomt",
      warningTextErrMemNotEmpty: "platshållare",
      scoreValuesTitle: "Detaljerad mätdata",
      scoreValuesCurrentPos: "Nuvarande position",
      generalAccTitle: "Acceleration",
      generalTempTitle: "Temperatur",
      generalRhTitle: "Fukt",
      generalAngleTitle: "Vinkel",
      generalDeviceHealthTitle: "Enhetens hälsa",
      generalMinTitle: "Min",
      generalMaxTitle: "Max",
      openFileTitle: "Öppna filer",
      serialIdTitle: "Serienummer",
      projectNameTitle: "Projektnamn",
      exportReportTitle: "Exportera rapport",
      exportDatxAsCsv: "Exportera CSV",
      myDevicesTableSerialNumberTitle: "Serienummer",
      myDevicesTableLastConnectedTitle: "Senast ansluten",
      myDevicesTableNextCalibrationTitle: "Nästa kalibrering",
      recInfoViewDeviceInfo: "Senast sedd",
      recInfoViewParams: "Visa parametrar",
      sysInfoPreviewTitle: "Systeminformation",
      sysInfoPreviewArtNum: "Artikelnummer",
      sysInfoPreviewFw: "Firmware",
      sysInfoPreviewMaxG: "Max g",
      sysInfoPreviewSupParx: "Stödd PARX version",
      sysInfoPreviewSupDatx: "Stödd DATX version",
      sysInfoPreviewCalExp: "Kalibrering utgår",
      sysInfoPreviewSectionAvilFeatures: "Funktioner",
      sysInfoPreviewSubTitleBattery: "Batteriinformation",
      sysInfoPreviewMainBatterySource: "Huvudbatteri",
      sysInfoPreviewMainBatteryLevel: "Huvudbatterinivå",
      sysInfoPreviewBackupBatteryLevel: "Backup batterinivå",
      sysInfoPreviewMemInfo: "Minnesinformation",
      sysInfoPreviewCapacity: "Kapacitet",
      sysInfoPreviewAvil: "Tillgänglig",
      // userAvatar
      Account: "Konto",
      ChangeLanguage: "Byt språk",
      Swedish: "Svenska",
      English: "Engelska",
      About: "Om",
      SignOut: "Logga ut",
      // --- SIDEBAR ---
      sbGraphMainMenuTitle: "Rapporter",
      sbCompareMainMenuTitle: "Jämför",
      sbParamsTitle: "Konfigurera enhet",
      sbDevicesTitle: "Mina enheter",
      sbAdmin: "Admin",
      sbMobitron: "Mobitron",
      sbNotConnected: "Inte ansluten",
      sbConnecting: "Ansluter...",
      sbConnected: "Ansluten",
      sbNotIdentified: "Ej identifierad",
      sbSettingsMainMenuTitle: "Inställningar",
      sbContact: "Kontakt",
      sbHelp: "Hjälp",
      // General form labels/inputs/titles etc.
      genName: "Namn",
      genFullName: "Fullständigt namn",
      genCompanies: "Företag",
      // General buttons
      genEdit: "Redigera",
      genDelete: "Radera",
      genSend: "Skicka",
      genClose: "Stäng",
      genUpdate: "Uppdatera",
      // Table content
      tableNr: "nr",
      tableAmount: "Belopp",
      tableStatus: "Status",
      tableInvoiceDate: "Fakturadatum",
      tableDueDate: "Förfallodatum",
      tablePaidOn: "Betaldatum",
      tableType: "Typ",
      tableActions: "Redigera",
      tableResetPasssword: "Återställ lösenord",
      tableMarkAsPaid: "Markera som betald",
      tableMarkedAsPaid: "Markerad som betald",
      tableReleaseLicenses: "Släpp licens",
      tableLicensesReleased: "Licens släppt",
      tableRenewNow: "Förnya",
      tableSetToAuto: "Autoförnya",
      tableDates: "Datum",
      tablePrice: "Pris",
      tableRenewAuto: "Autoförnya",
      tableRenewManually: "Förnya manuellt",
      tableRenews: "Förnyas",
      tableExpires: "Går ut",
      tableActivate: "Aktivera",
      tableName: "Namn",
      tablePaid: "betald",
      // --- Modals ---
      // General Required-messages
      genResetCodeRequired: "Återställningskod krävs",
      // EditUserModal
      EditUserAccount: "Redigera Användarkonto",
      // EditUserNameModal
      UpdateName: "Uppdatera Namn",
      // EditUserNameModal
      UpdatePhoneNumber: "Uppdatera Telefonnummer",
      // ChangeEmailModal
      ResetEmail: "Återställ e-post",
      NewEmail: "Ny e-post",
      SendResetCode: "Skicka återställningskod",
      ResetCode: "Återställningskod",
      btnSetNewEmail: "Ställ in ny e-post",
      // ChangePasswordModal
      ResetPassword: "Återställ lösenord",
      NewPassword: "Nytt lösenord",
      PasswordRequired: "Lösenord krävs",
      btnSetNewPassword: "Ställ in nytt lösenord",
      // --- Admin Pages ---
      // AdminPage
      Users: "Användare",
      Licenses: "Licenser",
      Invoices: "Fakturor",
      BuyLicenses: "Köp licens",
      // AdminUsers
      UsersAdmin: "Användare administratör",
      CompanyUsers: "Företagsanvändare",
      FilterUsers: "Filtrera användare",
      btnAddALicense: "Lägg till licens",
      AllUsers: "Alla användare",
      createNewUserTitle: "Skapa ny användare",
      createNewUserBtn: "Skapa ny användare",
      createUserBtn: "Skapa användare",
      // AdminLicenses
      licensesAdmin: "Licenser Admin",
      AllLicenses: "Alla licenser",
      tableTitleMyPlans: "Licensplan",
      filterPlans: "Filtrera",
      //LicensePrice
      tableLicensePriceCreateNewLicense: "Skapa nya licenser",
      licensePriceColor: "Färg",
      licensePriceNameAndDescription: "Namn och beskrivning",
      licensePricePrices: "Priser",
      licensePriceUserRights: "Användarrättigheter",
      licensePriceEditLicense: "Editera licenser",
      licensePriceMonths: "Månader",
      licensePriceFilterUserRights: "Filtrera användarrättigheter",
      // AdminInvoice
      tableTitleMyInvoices: "Mina fakturor",
      FilterInvoices: "Filtrera fakturor",
      // License store
      LicenseStore: "Licensbutik",
      btnShowCart: "Visa varukorg",
      btnAddToCart: "Lägg till i varukorg",
      // Cards
      cargolog3rdGen: "Cargolog 3rd Gen",
      cardMonth: "månad",
      genCardClickForDetails: "Klicka för detaljer",
      cargolog3rdGenLicenseDetails: "Cargolog 3rd Gen Licensdetaljer",
      Checkout: "Checka ut",
      // LicenseCartDrawer
      titleLicenseCart: "Varukorg",
      cartMonths: "månader",
      cartTotalPrice: "Totalt pris",
      btnProceedToCheckout: "Gå till kassa",
      // LicenseCheckoutModal
      CreditCard: "Kreditkort",
      Invoice: "Faktura",
      btnSendOrder: "Skicka order",
      NameOnCreditCard: "Namn på kreditkort",
      OrderHasBeenSent: "Din order har skickats till Mobitron",
      CheckoutMessage:
        "De beställda licenserna kommer att levereras när fakturan är fullt betald. Vänligen kontakta Mobitron för manuell hantering om du behöver dem tidigare.",
      YourOrder: "Din order",
      CreditCardNumber: "Kreditkortsnummer",
      ExpirationDate: "Utgångsdatum",
      Cvv: "CVV",
      // DetailsModal
      // Table Titles
      tableFunction: "Funktion",
      genFree: "Gratis",
      genProgramActivate: "Programmera & Aktivera",
      genDesktop: "Skrivbord",
      genPremium: "Premium",
      // Table content
      QuickReport: "Snabbrapport",
      ExportAndShareData: "Skriv ut",
      PrintData: "Skriv ut data",
      DetailedVibrationAnalysis: "Detaljerad vibrations-analys",
      CompareAndAnalyseData: "Jämför och analysera data",
      StandardDashboard: "Standard instrumentpanel",
      CustomDashboard: "Anpassad instrumentpanel",
      Reports: "Rapporter",
      FleetOverview: "Flotta Översikt",
      LoadEditAndViewParams: "Ladda, Redigera och Se Parametrar",
      SetPasswordLevel: "Sätt lösenordsnivå",
      CreateProjects: "Skapa projekt",
      EndProjects: "Avsluta projekt",
      UploadDataToCloud: "Ladda upp data till molnet",
      LiveNotificationsAndAlarms: "Live Aviseringar och Larm",
      OnlineAccessToAccount: "Online Tillgång till Konto",
      TransportMonitoring: "Transportövervakning",
      CreateTeams: "Skapa team",
      ShareDataToExternalUsers: "Dela data till externa användare",
      UpdateFirmware: "Uppdatera firmware",
      // --- Mobitron pages ---
      // MobitronAdminPage
      StoreAdmin: "Butik admin",
      Discounts: "Rabatter",
      Companies: "Företag",
      HelpInbox: "Hjälpinkorg",
      Dev: "Dev",
      // MobitronAdminOrders
      AllInvoices: "Alla Ordar",
      // StoreAdmin
      tableStoreAdminName: "Namn",
      tableStoreAdminDesc: "Beskrivning",
      tableStoreAdminCurrency: "Valuta",
      tableStoreAdminNewLicenseBtn: "Ny licens",
      FilterLicenses: "Filtrera licenser",
      tableStoreAdminCreateNewLicense: "Skapa ny licens",
      // MobitronAdminCompanies
      mobCompanyTree: "Företags träd",
      mobNoName: "Inget namn",
      // EditCompanyMobitronModal
      mobUpdateCompany: "Uppdatera företag",
      mobPostalCodeRequired: "Postnummer krävs",
      mobCityRequired: "Ort krävs",
      mobParentCompany: "Moderföretag",
      mobSaveChanges: "Spara ändringar",
      // MobitronAdminHelp
      SupportTickets: "Supportärenden",
      FilterTickets: "Filtrera ärenden",
      tableMessage: "Meddelanden",
      tableCreated: "Skapad",
      tableUserId: "Användar-ID",
      tableVersionInfo: "APP version",
      tableOsVersion: "OS-version",
      // MobitronAdminDev
      DevelopmentUtilities: "Utvecklingsverktyg",
      btnCreateDtAccCsvFromDatx: "Skapa raw dt-ACC CSV från DATX",
      // --- Settings ---
      // General settings
      Settings: "Inställningar",
      GeneralSettings: "Allmänna inställningar",
      SelectYourCurrency: "Välj din valuta",
      // Account settings
      AccountSettings: "Kontoinställningar",
      SettingsForUserAccount: "Inställningar för användarkontot",
      // Graph settings
      GraphSettings: "Grafinställningar",
      // CompanySettings
      CompanySettings: "Företagsinställningar",
      SettingsForCompany: "Inställningar för företaget",
      // ContactModal
      Contact: "Kontakt",
      Close: "Stäng",
      YourMessageHasBeenSent: "Ditt meddelande har skickats till Mobitron",
      ContactPage: "Kontaktsida",
      Sweden: "Sverige",
      ContactUs: "Kontakta oss",
      contactFormInfo:
        "Tveka inte att kontakta oss via formuläret nedan. Vi är alltid glada att få dela med oss av våra erfarenheter och hjälpa dig hitta den bästa lösningen.",
      Send: "Skicka",
      TechnicalSupport: "Teknisk support",
      ReportAProblem: "Anmäl ett problem",
      helpPageInfo:
        "Om du har stött på ett problem i Cargolog Connect kontakta Mobitron. Fyll i formuläret nedan så återkommer vi till dig.",
      DataForTroubleShooting: "Data för Felsökning",
      OSVersion: "OS-version",
      ScreenResolution: "Skärmupplösning",
      AppVersion: "APP version",
      DescribeYourProblem: "Beskriv ditt problem",
      aMessageIsRequired: "Ett meddelande krävs",
      Submit: "Skicka",
      Message: "Meddelande",
      // --- FAT 100 License Details ---
      // Device Page
      tableTitleMyDevices: "Mina enheter",
      tableCardTitle: "Senaste enheter",
      filterMyDevices: "Filtrera enheter",
      //general titles for common things
      genXAxis: "X-axel",
      genYAxis: "Y-axel",
      genZAxis: "Z-axel",
      genDays: "Dagar",
      genHours: "Timmar",
      genMinutes: "Minuter",
      genRepatNTimes: "Gånger",
      genAuto: "Automatisk",
      genManual: "Manuell",
      genCancel: "Avbryt",
      genYes: "Ja",
      genSave: "Spara",
      genPrint: "Skriv ut",
      genExportCsv: "Exportera som CSV",
      genExportPdf: "Exportera som PDF",
      genShare: "Dela",
      // SystemInfo feature names
      DF_ACC: "Acceleration ±16g",
      DF_TEMP: "Temperatur",
      DF_RH: "Fukt",
      DF_ANGLE: "Vinkel",
      DF_PRESSURE: "Tryck",
      DF_INT_LIGHT: "Ljus",
      DF_GPS: "GPS",
      DF_LTE: "LTE",
      DF_BLUETOOTH: "Bluetooth",
      DF_EXT_INPUTS: "Externa ingångar",
      DF_EXT_OUTPUTS: "Externa utgångar",
      DF_RESERVED1: "**inget namn**",
      DF_DVA: "DVA",
      DF_ACCEXTRA: "Acceleration ±200g",
      //dashboard layout ids to titles/names
      mainGraph: "Graf",
      dvaGraph: "Detaljerad vibrations-analys",
      scoreValues: "Detaljerad data",
      minMax: "Min-/maxvärden",
      topAccTable: "10 intensivaste stötarna",
      deviceInfo: "Inspelningsinformation",
      accHistogram: "Histogram",
      quickReport: "Snabbrapport",
      mainGraphDesc: "Studera all inspelad data i en graf.",
      dvaGraphDesc:
        "Detaljerad studie av stötens natur och dess underliggande vibrationer.",
      scoreValuesDesc: "N/A",
      minMaxDesc: "N/A",
      topAccTableDesc:
        "Se de 10 mest högsta stötarna i en tabell med g-kraft och varaktighet.",
      deviceInfoDesc:
        "Visa parametrarna inställda i enheten, visa registreringsnivå, larmnivå och intervall för varje mätkanal.",
      accHistogramDesc:
        "Grafisk visning av inspelad accelerationsdata i staplar. Varje stapel grupperas i intervaller.",
      // Create parameters Card content = (cp + kind of parameter + desccription)
      cpProgressWaiting: "Väntar",
      cpInProgress: "Arbetar",
      cpValidated: "Klar",
      //Acceleration
      cpAccMainToggleTitle: "Aktivera inspelning av acceleration",
      cpAccRegLevelsTitle: "Registreringsnivå",
      cpAccRegLevelsContent:
        "Ställ in den lägsta registreringsnivån för att mäta och registrera acceleration. Ställ in larmnivån och dess varaktighet för att indikera överskridet gränsvärde",
      cpAccRegLevelsInpRegLevel: "Registreringsnivå (g)",
      cpAccRegLevelsInpAlarmLevel: "Larmnivå (g)",
      cpAccRegLevelsInpDurationLevel: "Varaktighet (ms.)",
      cpAccStoreAngleTitle: "Spara vinkel",
      cpAccStoreAngleContent:
        "Mät vinkel vid överskriden larmnivå för acceleration",
      cpAccStoreGpsPosTitle: "Spara GPS-position",
      cpAccStoreGpsPosContent:
        "Registrera GPS position vid överskriden larmnivå för acceleration",
      cpAccLowPassFilterTitle: "Lågpassfilter",
      cpAccLowPassFilterContent:
        "Välj det lågpassfilter som ska användas vid registrering av acceleration",
      cpAccDvaTitle: "Detaljerad Vibrations Analys",
      cpAccDvaContent:
        "Aktivera och registrera vågformen av ovan inställda värden för acceleration.",
      cpAccSendGsmAlarmTitle: "Skicka LTE larm",
      cpAccSendGsmAlarmContent:
        "Skicka inspelad data med LTE-kommunikation vid överskriden larmnivå för acceleration.",
      cpAccIgnoreAlarmsTitle: "Ignorera larm",
      cpAccIgnoreAlarmsContent:
        "Spara ström och ställ in hur många gånger alarmnivå ska ignoreras.",
      //temperature
      cpTempMainToggleTitle: "Aktivera inspelning av temperatur",
      cpTempLogIntervalTitle: "Loggningsintervall",
      cpTempLogIntervalContent:
        "Ställ in loggningsintervall för samplingsdata.",
      cpTempStoreGpsPosTitle: "Spara GPS-position",
      cpTempStoreGpsPosContent:
        "Registrera GPS position vid överskriden larmnivå för temperatur.",
      cpTempAlarmLevelTitle: "Larmnivå",
      cpTempAlarmLevelContent:
        "Ställ in larmnivån för att indikera nedre och övre överskridande gränsnivå",
      cpTempSendGsmAlarmTitle: "Skicka LTE larm",
      cpTempSendGsmAlarmContent:
        "Skicka inspelad data med LTE-kommunikation vid överskriden larmnivå för temperatur.",
      cpTempIgnoreAlarmTitle: "Ignorera larm",
      cpTempIgnoreAlarmContent:
        "Spara ström och ställ in hur många gånger larmnivå ska ignoreras.",
      //humidity
      cpRhMainToggleTitle: "Aktivera inspelning av luftfuktighet",
      cpRhLogIntervalTitle: "Loggningsintervall",
      cpRhLogIntervalContent: "Ställ in loggningsintervall för samplingsdata.",
      cpRhStoreGpsPosTitle: "Spara GPS-position",
      cpRhStoreGpsPosContent:
        "Registrera GPS position vid överskriden larmnivå för luftfuktighet.",
      cpRhAlarmLevelTitle: "Larmnivå",
      cpRhAlarmLevelContent:
        "Ställ in larmnivån för att indikera nedre och övre överskridande gränsnivå",
      cpRhSendGsmAlarmTitle: "Skicka LTE larm",
      cpRhSendGsmAlarmContent:
        "Skicka inspelad data med LTE-kommunikation vid överskriden larmnivå för luftfuktighet.",
      cpRhIgnoreAlarmTitle: "Ignorera larm",
      cpRhIgnoreAlarmContent:
        "Spara ström och ställ in hur många gånger larmnivå ska ignoreras.",
      //angle
      cpAngleMainToggleTitle: "Aktivera inspelning av vinkel",
      cpAngleAlarmLevelTitle: "Larmnivå",
      cpAngleAlarmLevelSubTitle: "Larmnivå (°)",
      cpAngleAlarmLevelContent:
        "Ställ in larmnivå för att indikera överskriden gränsnivå.",
      cpAngleStoreGpsPosTitle: "Spara GPS-position",
      cpAngleStoreGpsPosContent:
        "Registrera GPS position vid överskriden larmnivå för vinkel.",
      cpAngleRefAngleTitle: "Referensvinkel",
      cpAngleRefAngleContent:
        "Ställ in referensvinkeln för monteringsriktningen om annat än plant läge. Referensvinkeln kommer att ställas in i början av mätperioden. Enheten måste monteras innan mätperioden påbörjas.",
      cpAngleSendGsmAlarmTitle: "Skicka LTE larm",
      cpAngleSendGsmAlarmContent:
        "Skicka inspelad data med LTE-kommunikation vid överskriden larmnivå för vinkel.",
      cpAngleIgnoreAlarmsTitle: "Ignorera larm",
      cpAngleIgnoreAlarmsContent:
        "Spara ström och ställ in hur många gånger larmnivå ska ignoreras.",
      // parameters preview
      cpRegLvlTitle: "Registreringsnivå",
      cpAlarmLimit: "Larmnivå",
      cpConfigTitle: "Konfigurera",
      cpGenLow: "Låg",
      cpGenHigh: "Hög",
      cpGenStoreGps: "Spara GPS",
      cpGenSendGpsAlarm: "Skicka GPS-larm",
      cpGenSendLteAlarm: "Skicka LTE-larm",
      cpGenIgnoreAlarms: "Ignorera återupprepade larm (antal)",
      genSampleInterval: "Loggningsintervall",
      // User information
      cpUserInfoPaneTitle: "Beskrivning",
      cpUserInfoFreeTextDesc:
        "Använd fritext för att beskriva din transport, produkt och monteringssätt.",
      // --- Warning texts ---
      warnDatxEmptyText:
        "Kan ej läsa data. Enheten har ingen data. Ingen fil har sparats.",
      // ChangeEmailModal
      emailModalResetCodeErrorMessage:
        "Det gick inte att skicka återställningskoden",
      emailModalResetCodeSuccess: "Återställningskoden har skickats till ",
      ChangeEmailErrorMessage: "Det gick inte att byta epost",
      ChangeEmailSuccessMessage: "Ny e-postadress är ",
      //ChangePasswordModal
      passwordModalResetCodeErrorMessage: "Kan ej Skicka Återställningskod",
      PasswordModalResetCodeSuccess: "Återställningskoden har Skickats till ",
      ChangePasswordErrorMessage: "Det gick inte att sätta nytt lösenord",
      ChangePasswordSuccessMessage: "Nytt lösenord är satt",
      //EditCompanyModal
      EditCompanyErrorMessage: "Det gick inte att redigera företaget",
      EditCompanySuccessMessage: "Företagets uppgifter är ändrade",
      //EditCompanyMobitrinModal
      EditCompanyMobitronErrorMessage: "Det gick inte att redigera företaget",
      EditCompanyMobitronSuccessMessage: "Företagets uppgifter är ändrade",
      EditParentCompanyMobitronErrorMsg: "Det gick inte att ändra moderföretag",
      EditParentCompanyMobitronSuccessMsg: "Moderföretag är ändrat",
      //CreateUserModal
      FailedToCreateNewUser: "Det gick inte att skapa ny användare",
      SuccessfullyCreateNewUser: "Användaren är skapad",
      //EditUserNameModal
      FailedtoUpdateUserName: "Det gick inte att ändra användarens namn",
      SuccessfullyUpdatedUserName: "Användarens namn är ändrat",
      //EditUserPhoneModal
      FailedtoUpdateUserPhone:
        "Det gick inte att ändra användarens telefonnummer",
      SuccessfullyUpdatedUserPhone: "Användarens telefonnummer är ändrat",
      //GeneralUserFeedback
      // FeatureNotImplementedMessage: "This feature is not yet implemented, sorry!",
      //HelpModal
      FailedToSendSupportTicket: "Det gick inte att skicka supportärendet",
      SuccessfullySentSupportTicket: "Supportärendet är skickat",
      //RemoveUsermodal
      SuccessfullyRemovedUser: "Raderade användaren ",
      //LoginPage
      UnabletologinError: "Det gick inte att logga in",
      // ContactModal
      FailedToSendContactForm: "Det gick inte att skicka meddelande",
      // LicenseCheckoutModal
      UnableToSendOrder: "Det gick inte att skicka order",
      // CreateAccountPage
      SuccessfullyCreatedUserAccount: "Ditt användarkonto har skapats!",
      UnableToCreateUserAccount: "Det gick inte att skapa användarkonto.",
      //openDatxSlice
      //InvalidDatxFileError: "Invalid datx-file",
      // invalid recording parameters message
      // InvalidRecParamsError: "Invalid RecParams. Check error log.",
      GeneralSettingsDescription:
        "Inställningar för språk, valuta och uppdateringar",
      GraphSettingsDescription: "Inställningar för grafer",
      Language: "Språk",
      SelectLanguageTooltip: "Välj ditt språk",
      Currency: "Valuta",
      Updates: "Uppdateringar",
      CurrentVersion: "Nuvarande version",
      LatestCheck: "Senaste koll",
      CheckForUpdates: "Leta efter uppdatering",
      DownloadingUpdate: "Laddar ner uppdatering",
      RestartAndUpdate: "Starta om och uppdatera",
      NoUpdatesInstalledYet: "Aldrig installerat någon uppdatering",
      // Report opening statuses
      openFileStatusInactive: "Inaktiv",
      openFileStatusParsing: "Packar upp DATX-fil",
      openFileStatusRendering: "Ritar upp DATX-fil",
      EditCompanyDetails: "Redigera företagsuppgifter",
      NameIsRequired: "Namn krävs",
      AddressIsRequired: "Adress krävs",
      PostalCodeIsRequired: "Postnummer krävs",
      CityIsRequired: "Ort krävs",
      CountryIsRequired: "Land krävs",
      Country: "Land",
      SearchToSelect: "Sök för att välja",
      OrgNumberRequiredInSweden: "Organisationsnummer krävs i Sverige",
      SaveChanges: "Spara ändringar",
      ScaleSteps: "Skalsteg",
      SetGlobalScale: "Ställ in den globala skalan",
      UserId: "Användar-ID",
      ActiveLicenses: "Aktiv licens",
      NoActiveLicenses: "Inga aktiva licenser",
      UpgradeLicenseToHandleDevice:
        "Du måste uppgradera din licens för att kunna hantera Cargolog-enheter.",
      HeadToLicenseStore: "Gå till webbshopen för licenser",
      CompanyId: "Företags-id",
      InvoiceEmail: "Faktura e-post",
      InvoiceAddress: "Faktura Adress",
      InvoicePostalCode: "Faktura Postnummer",
      InvoiceCity: "Faktura Stad",
      VATNumber: "VAT nummer",
      General: "Generellt",
      Graph: "Graf",
      Company: "Företag",
      NearValues: "Närliggande Värden",
      ShowNearValues: "Visa Närliggande Värden",
      HideNearValues: "Dölj Närliggande Värden",
      DescriptionGlobalNearValuesLine1:
        "När denna funktion är aktiv tillåter den här inställningen att nära värden (som inte har exakt samma tidsstämpel som den aktuella positionen på poängvärdekortet) visas.",
      DescriptionGlobalNearValuesLine2:
        "Avaktivera för att endast visa värden som matchar den aktuella positionen.",
      Temperature: "Temperatur",
      SelectTemperatureScale: "Välj temperaturskala",
      TimeScaleAxisSteps: "Tidskala Axel Steg",
      SetXAxisScale: "Ställ in X-axel skala",
      Auto: "Auto",
      Default: "Standard",
      Sparse: "Gles",
      Medium: "Medium",
      Dense: "Tät",
      CSVExport: "CSV Export",
      CSVFormatStandard: "CSV format standard",
      SwedishStandard: "Svenskt format",
      EnglishStandard: "Engelskt format",
      DecimalSeparator: "Decimalavgränsare",
      CellSeparator: "Cell separator",
      GlobalGraphScale: "Global Graf Skala",
      SetScale: "Ställ in skala",
      DescriptionGlobalScaleSettings:
        "Vid aktiv, åsidosätter den här inställningen inställningarna för graf i Primär Graf för alla öppna filer. För att manuellt ställa in skalningsalternativ per fil måste du först avaktivera denna globala inställning.",
      GlobalTimeZone: "Global Tidszon",
      DescriptionGlobalTimezone:
        "Vid aktiv, åsidosätter den här inställningen både automatiska tidszoninställningar och manuell (per fil) tidszoninställning i Analysera data. För att manuellt ställa in tidszonen per fil måste du först avaktivera denna globala inställning.",
      SelectGlobalTimezone: "Välj global tidszon",
      SearchForTimezone: "Sök tidszon",
      Dashboard: "Instrumentpanel",
      ClickForDetails: "Klicka för detaljer",
      paid: "Betald",
      overdue: "Försenad",
      pending: "Pendlar",
      Created: "Skapad",
      Status: "Status",
      WaitingForPayment: "Väntar på betalning",
      Renews: "Förnya",
      Expires: "Utgår",
      NoAvailableLicenses: "Det finns inga tillgängliga licenser",
      Remove: "Ta bort",
      UserRequests: "Användarförfrågningar",
      ClickAddALicenseToAssign:
        "Klicka på 'Lägg till licens' för att tilldela en licens till en användare.",
      Discount: "Rabatt",
      Price: "Pris",
      ExclVAT: "Exkl. VAT",
      VAT: "VAT",
      AssignLicenses: "Tilldela licenser",
      AssignTheLicenseToAUser: "Tilldela licens till en användare",
      CardPaymentIsntAvailable:
        "Kortbetalning är inte tillgängligt på gratisartiklar, skicka din beställning som en faktura istället!",
      Required: "Nödvändig",
      InvoiceDetails: "Fakturadetaljer",
      ClickToEditInvoiceDetails: "Klicka här för att redigera Fakturadetaljer",
      EditDetails: "Redigera detaljer",
      PurchaseNotAvailable: "Köp är inte tillgängligt utan fakturadetaljer",
      PurchaseOrderNumber: "Beställningsnummer",
      ClickHereToSendYourOrder: "Klicka här för att skicka din beställning",
      SendOrder: "Skicka Beställning",
      months: "månader",
      PhoneApp: "Telefon APP",
      PcApp: "PC APP",
      Cloud: "Moln",
      Bluetooth: "Bluetooth",
      SeeMyUnits: "Se mina enheter",
      AccessAllUnitData: "Få tillgång till all enhetsdata",
      ReadData: "Läs data",
      ViewParams: "Se parametrar",
      ExportData: "Exportera data",
      CreateEditAndShareTemplates: "Skapa, redigera och dela mallar",
      ViewTemplates: "Se mallar",
      Debug: "Felsökning",
      CreateEditLoadandViewParams: "Skapa, redigera och se Parametrar",
      SetPasswordLevel3: "Ställ in lösenordsnivå 3",
      SetPasswordLevel2: "Ställ in lösenordsnivå 2",
      SetPasswordLevel1: "Ställ in lösenordsnivå 1",
      ViewDataForSharedProject: "Se data för delade projekt",
      ViewDataFromLTEUnit: "Se Data från LTE enheter",
      CreateEditAndShareProjectsAndTeams:
        "Skapa, redigera och dela projekt och grupper",
      MarkLoggerAsFinishedTransport: "Markera enhet som avslutad transport",
      AutoSyncOfDataToCloud: "Automatisk synkronisering av data till molnet",
      HandleAllUsers: "Hantera alla användare",
      HandleAllCompanyLicenses: "Hantera alla företagslicenser",
      HandleCompanyDetails: "Hantera företagsinformation",
      myDevicesTableLastUserTitle: "Senaste användaren",
      NFCCommunication: "NFC kommunikation",
      ReadDataFromLabel: "Läs av data från Cargolog Easy",
      ViewQuickDataAndComment: "Visa sammanfattning och kommentar",
      PrintQuickReportPDF: "Skriv ut snabbrapport (PDF)",
      ViewParameters: "Visa inställningar",
      ShareDataSendFileToOtherUser:
        "Dela datafil (skicka fil till annan användare)",
      ViewAllMeasuringData: "Se alla mätdata",
      ExportAllDataCSV: "Exportera all data (CSV)",
      PrintDataAllData: "Skriv ut värden (alla data)",
      SaveDataLocally: "Spara data lokalt",
      OpenSavedData: "Öppna sparad fil",
      EditParameters: "Redigera parametrar",
      ActivateDVAData: "Aktivera DVA-data",
      UseTemplates: "Använd mallar",
      UploadDataToCloudOnSetup: "Ladda upp data till molnet vid aktivering",
      MarkLoggerAsTinishedTransport: "Markera transporten som klar",
      UploadDataToCloudOnReadOut: "Ladda upp data till molnet vid utläsning",
      ManualUploadOfSavedDataToloud:
        "Manuell uppladdning av sparad data till molnet",
      SetStatusTestRepair: "Sätt status test/reparation",
      TerminalMode: "Terminalläge",
      SendMessageToUnit: "Skicka meddelande till enheten",
      SetNewParameterOnline: "Ställ in ny parameter online",
      AndroidApp: "Android APP",
      iOSApp: "iOS APP",
      Compare: "Jämför",
      toOtherLicenses: "till andra licenser",
      ClickHereToShowTheCart: "Klicka här för att visa varukorgen",
      cargologEasy: "Cargolog Easy",
      vehicle: "Fordon",
      User: "Användare",
      Requested: "Begärda",
      AcceptUser: "Acceptera användare",
      RejectUser: "Neka användare",
      FailedToCreateTransfer: "Det gick inte att skapa överföringen",
      SuccessfullyCreatedTransferRequest: "Överföringsbegäran har skapats",
      RequestTransfer: "Begär överföring",
      EnterTheTargetCompanyId:
        "Ange företagets id i boxen nedan för att begära en flytt till detta företag. Be administratören på företaget om företagets id, det kan ses under företagsinställningarna. Överföringen måste accepteras av mottagande företag och dess administratör. Alla dina licenser kommer gå förlorade vid en överföring.",
      CompanyIdIsRequired: "Företags-ID måste anges",
      SendTransferRequest: "Skicka begäran om överföring",
      Ok: "Ok",
      ExportPDF: "Exportera PDF",
      ExportCSV: "Exportera CSV",
      asPDF: "som PDF",
      asCSV: "som CSV",
      Export: "Export",
      Print: "Skriv ut",
      Channels: "Kanaler",
      ResetZoomLevel: "Återställ zoomnivån",
      RemoveFromComparison: "Ta bort från jämförelsen",
      PrimaryGraphs: "Huvudgraf",
      DvaGraphs: "DVA-grafer",
      CompareGraphs: "Jämför grafer",
      UserDetails: "Användarinformation",
      CompanyDetails: "Företagsinformation",
      CompanyName: "Företagsnamn",
      Address: "Adress",
      Address2: "Adress 2",
      PostalCode: "Postnummer",
      City: "Ort",
      OrgNumber: "Org. Nummer",
      AbortSetup: "Avbryt installationen",
      ThisAbortsYourRegistration:
        "Detta avbryter din registrering. För att fortsätta, logga in med dina användaruppgifter.",
      FinishSetup: "Slutför installationen",
      FirstNameIsRequired: "Förnamn obligatoriskt",
      FirstName: "Förnamn",
      LastNameIsRequired: "Efternamn obligatoriskt",
      LastName: "Efternamn",
      EmailIsRequired: "E-mail obligatoriskt",
      Email: "E-mail",
      PhoneNumber: "Telefonnummer",
      Password: "Lösenord",
      PasswordMustBeAtLeast:
        "Lösenordet måste vara minst 6 tecken långt och innehålla minst en siffra",
      MustBeAtLeast6CharactersLong: "Måste vara minst 6 tecken långt",
      MustContainAtLeastOneNumber: "Måste innehålla minst en siffra",
      BackToLogin: "Tillbaka till login",
      Next: "Nästa",
      FailedToRemoveRecentDevice:
        "Det gick inte att ta bort den senaste enheten",
      SuccessfullyRemovedRecentDevice: "Den senaste enheten är borttagen",
      DeviceInfo: "Enhetsinformation",
      RemoveDevice: "Ta bort enhet",
      FilterByUser: "Filtrera efter användare",
      UseForNewParameters: "Använd för nya parametrar",
      Cancel: "Avbryt",
      TryAgain: "Försök igen",
      Features: "Funktioner",
      Manage: "Hantera",
      NoDeviceConnected: "Ingen enhet är ansluten",
      ConnectDeviceToStartCommunication:
        "Anslut enhet för att starta kommunikation",
      USB: "USB",
      LoadingDevice: "Överför till enhet",
      SeeFirmwareFeaturesForYourDevice: "Se firmware-funktioner för din enhet.",
      SystemInfo: "System information",
      ReadParametersSetInYourDevice:
        "Läs av parametrar som ställts in i din enhet",
      Parameters: "Parametrar",
      DownloadNewParametersAndActivateTheDevice:
        "Ladda ner nya parametrar och aktivera enheten",
      Setupdevice: "Konfigurera enheten",
      Upgradefirmware: "Uppdatera firmware",
      Upgrade: "Uppdatera",
      ActiveParameters: "Aktiva parametrar",
      Connected: "Ansluten",
      DeviceNotSupportAllFeatures:
        "Din enhet stöder inte alla funktioner i parameterfilen. För att fortsätta kommer följande funktioner inte att användas",
      SettingDevicePasswords: "Ställa in enhetslösenord",
      FormattingDevice: "Formaterar enhet",
      SettingDeviceInternalClock: "Ställer in enhetens datum och tid ",
      DownloadingNewRecordingParameters: "Ladda ner nya inspelningsparametrar",
      ValidatingDownload: "Kontrollerar nedladdning",
      DownloadingParemetersFinished:
        "Nedladdning av parametrar klar! Du kan nu säkert koppla bort enheten.",
      newRecordingParametersDownloadedShortly:
        "De nya parametrarna kommer att laddas inom kort",
      FailedToFormatDevice: "Det gick inte att formatera enheten",
      FailedToSetDeviceInternalClock:
        "Det gick inte att ställa in enhetens interna klocka",
      FailedToSetPassword: "Det gick inte att ställa in lösenordet",
      FailedToUpdateBatteryStatus: "Det gick inte att uppdatera batteristatus",
      FailedToDownloadRecParamsToDevice:
        "Det gick inte att ladda ned nya parametrar till enheten",
      SomethingWentWrongWhileDownloading:
        "Något gick fel när nya parametrar skulle laddades ned till enheten",
      Continue: "Fortsätt",
      Format: "Formatera",
      Back: "Tillbaka",
      BatteriesNotChanged: "Byte av batterier ej utfört",
      UseSelectedBattery: "Använd valt batteri",
      Done: "Klar",
      FailedReadDataDueToInternalError:
        "Det gick inte att läsa ut data på grund av ett internt fel i enheten",
      SuccessfullyReadUutData: "Utläsning av data klar!",
      ReadingRecordingData: "Läser in inspelningsdata",
      NewFirmwareTransferredSuccessfully: "Överföring av ny firmware klar!",
      RebootingDevice: "Omstart av enhet",
      PreaparingToRebootDevice: "Förbereder omstart av enhet",
      TransferingNewFirmwareToDevice: "Överför ny firmware till enhet",
      Preparing: "Förbereder",
      InternalDeviceError: "Internt fel i enhet",
      timeout: "timeout",
      UpgradeFailedDidntGoThrough:
        "Uppdatering misslyckades, uppdatering ej genomförd",
      ErrorCantUpgradeInsufficent:
        "Felmeddelande: Kan ej uppdatera på grund av otillräcklig firmware version. Minsta firmware version som krävs för uppdatering",
      unknown: "Okänd",
      actualFirmwareVersionForDevice:
        "Faktisk firmware version för denna enhet",
      ConnectDevice: "Anslut enhet",
      ActiveRecordingParameters: "Aktiva inspelningsparameterar",
      SetupDevice: "Sätt upp enhet",
      Download: "Ladda ner",
      ReadOutData: "Läser ut data",
      UpgradeFirmware: "Uppdatera firmware",
      ClickHereToReadDataFromDevice: "Klicka här för att läsa ut data",
      ReadNextDevice: "Läs nästa enhet",
      Openfile: "Öppna fil",
      SaveAs: "Spara som",
      Select: "Välj",
      DevicePasswordRequired: "Lösenord för enhet krävs",
      DeviceProtectedPleaseEnter:
        "Enheten är skyddad. Vänligen ange lösenord för att fortsätta",
      ReportDataIsFiltered: "Data i rapport är filtrerad",
      ReportDataIsNotFiltered: "Data i rapport är inte filtrerad",
      Report: "Rapport",
      Exported: "Exporterad",
      HaveYouChangedToNewBatteries: "Har du bytt batteri?",
      Batteries: "Batterier",
      AddBattery: "Lägg till batteri",
      FilterBatteries: "Filtrera batterier",
      SelectedBattery: "Välj batteri",
      Name: "Namn",
      FormFactor: "Formfaktor",
      VoltageV: "Volt (V)",
      CapacitymAh: "Kapacitet (mAh)",
      Type: "Typ",
      WithoutNewBatteries:
        "Utan nya batterier finns risk att kapaciteten inte räcker för hela transporten och tar inga garantier för förlust av data",
      Form: "Formulär",
      Alkaline: "Alkaliska",
      Lithium: "Litium",
      ConfigPassword: "Konfigurations-lösenord",
      RestrictsChangesToDeviceConfig:
        "Begränsa ändringar till enhetskonfigurationen",
      UseConfigPasswordIfYouWant:
        "Använd konfigureringslösenord om du vill skydda enhet från att om konfigureras utan rättighet.",
      KeepExisting: "Behåll existerande",
      Disabled: "Inaktivera",
      ReadPassword: "Läs-lösenord",
      RestrictsReadingFromTheDevice: "Skyddar från att läsa från enheten",
      UseReadPasswordIfYouWant:
        "Använd Läs lösenord om du vill skydda enheten från att läsas utan rättigheter.",
      characters: "Bokstäver",
      and: "och",
      DataPassword: "Data-lösenord",
      RestrictsOpeningDataFiles: "Skyddar från att öppna en datafil",
      UseDataPasswordIfYouWant:
        "Använd Data lösenord om du vill skydda en datafil (DATX) från att öppnas utan rättigheter.",
      RevertChanges: "Ångra ändringar",
      AccelerationHistogram: "Acceleration Histogram",
      HistogramSettings: "Histogram inställningar",
      DataBins: "Datainslag",
      ConfigureTheSizesOfTheDataBins: "Konfigurera storleken på datainslag",
      XAxis: "X-axel",
      YAxis: "Y-axel",
      ZAxis: "Z-axel",
      Shock: "Chock",
      Occurrences: "Förekomster",
      FailedToExportCsvDataNoDataAvailable:
        "Misslyckades att exportera csv data. Ingen data tillgänglig",
      Fullscreen: "Fullskärm",
      PrimaryGraph: "Huvudgraf",
      ResetToDefault: "Återställ till standard",
      Reset: "Återställ",
      RemoveCard: "Ta bort kort",
      SelectTimezone: "Välj tidszon",
      Region: "Region",
      HideAccelerationsWithinAlarmLimit:
        "Dölj accelerationer inom gränsvärden för alarm",
      TemperatureData: "Temperaturdata",
      HideTemperatureDataWithinAlarmLimit:
        "Dölj temperatur-data inom gränsvärden för alarm",
      HumidityData: "Luftfuktighetdata",
      HideHumidityDataWithinAlarmLimit:
        "Dölj luftfuktighet-data inom gränsvärden för alarm",
      AngleData: "Vinkel data",
      HideAngleDataWithinAlarmLimit:
        "Dölj vinkel-data inom gränsvärden för alarm",
      DashboardFilters: "Filtrera instrumentpanel",
      FilterReport: "Filtrera Rapport",
      ManageReport: "Hantera Rapport",
      ClickHereToCreateACustomReport: "Klicka här för att skapa egen rapport",
      SerialNumber: "Serienummer",
      FileName: "Filnamn",
      PreviewPrint: "Förhandsgranska utskrift",
      DVAGraph: "DVA graf",
      CloseTheViewOfTheSelectedGraph: "Stäng visningen av vald graf",
      FailedToExportDvaData: "Misslyckades att exportera data",
      View: "Se",
      StartTime: "Starttidpunkt",
      EndTime: "Stopptidpunkt",
      NoDvaEventsSelected: "Inga DVA händelser valda",
      selected: "Valda",
      ExportReport: "Exportera rapport",
      ClickHereToExportTheReport: "Klicka här för att exportera rapport",
      UnlockFile: "Lås upp fil",
      PasswordIsRequiredToUnlockThisFile: "Lösenord krävs för att låsa upp fil",
      RecordingInformation: "Information om inspelning",
      DeactivateTheGlobalScaleSettingIn:
        "Avaktivera globala skal-inställningar i",
      GlobalGraphScaleToEdit: "Global grafisk skala för att editera",
      scaleSettings: "Skal-inställningar",
      CouldntLocateFileAt: "Kan ej lokalisera fil",
      TryOpeningItFromTheNormalFileSystem:
        "Försök att öppna den från det normala filsystemet.",
      SwitchZoomMode: "Byt zoomläge",
      GoToPriorZoomLevel: "Gå till föregående zoomnivå",
      RemoveCursorPosition: "Ta bort markörens position",
      ShowInReport: "Visa i rapport",
      AddCards: "Lägg till kort",
      Search: "Sök",
      AvailableCardsGivenSearchPlaced:
        "Alla tillgängliga kort enligt din sökning är redan placerade i din rapport, ha kul!",
      AvailableCardsAlreadyPlaced:
        "Alla tillgängliga kort är redan placerade i din rapport, ha kul!",
      Channel: "Kanal",
      MinValue: "Min värde",
      MinTimestamp: "Min tidsstämpel",
      MaxValue: "Max värde",
      MaxTimestamp: "Max tidsstämpel",
      AccelerationXAxis: "Acceleration x-axlar",
      AccelerationYAxis: "Acceleration y-axlar",
      AccelerationZAxis: "Acceleration z-axlar",
      AngleX: "Vinkel x",
      AngleY: "Vinkel y",
      AngleZ: "Vinkel z",
      ReportName: "Rapportnamn",
      NewReport: "Ny rapport",
      AddCardsDisabledInStandard: "Lägg till inaktiverade kort i 'Standard'",
      AddCardsTo: "Lägg till kort till",
      NotSet: "Ej inställd",
      StartAfterDownload: "Starta efter nedladdning",
      StartByButton: "Starta med knapp",
      somethingWentWrong: "något gick fel",
      After: "Efter",
      RecordingParameters: "Inspelningsparametrar",
      Landscape: "Landskap",
      Portrait: "Porträtt",
      PDFOnly: "Enbart PDF",
      SelectedCards: "Välj kort",
      CardOrder: "Ordning av kort",
      Letter: "bokstav",
      format: "format",
      Card: "Kort",
      Page: "Sida",
      DVADataAvailable: "DVA data tillgänglig",
      MemUsed: "Använt minne",
      MainBat: "Huvudbatteri",
      BackupBat: "Backupbatteri",
      MoveScoreValuesToThe: "Flytta Data värden till",
      ScoreValues: "Data värden",
      left: "vänster",
      right: "höger",
      side: "sida",
      CloseScoreValues: "Stäng Data värden",
      CurrentPosition: "Nuvarande position",
      MemoryUsed: "Minne använt",
      MainBattery: "Huvudbatteri",
      BackupBattery: "Backupbatteri",
      Top10Accelerations: "Topp 10 Accelerationer",
      Timestamp: "Tidsstämpel",
      Resultant: "Resultat",
      Duration: "Längd",
      EditPARX: "Editera PARX",
      CloseWindow: "Stäng fönster",
      ViewStatus: "Se status",
      ReadDataFromCargologDevice: "Läs data från Cargolog enhet",
      LoadParameterIntoCargologDevice: "Ladda parametrar till Cargolog enhet",
      PrintExport: "Skriv ut/Exportera",
      MyDevices: "Mina enheter",
      Admin: "Admin",
      ConnectionCenter: "Kommunikationscenter",
      QuickTour: "Rundtur",
      QuickTourIsAnInteractiveGuide:
        "Vår rundtur är en interaktiv guide som visar dig de viktigaste funktionerna i denna applikation. Klicka på knappen för att starta.",
      StartQuickTour: "Starta rundturen",
      ShowQuickTourAtStartup: "Visa rundturen vid start",
      KeyboardShortcuts: "Snabbknappar",
      HelpCenter: "Hjälpcenter",
      TheFilterIsUsingAbsoluteValues:
        "Filtret använder absolutvärden vilket betyder att positiva värden kommer filtrera både positiva och negativa accelerationer i grafen.",
      duration: "Varighet",
      Filter: "Filter",
      RegistrationLimit: "Registreringsnivå",
      AlarmLevel: "Alarmnivå",
      ACCELERATION: "ACCELERATION",
      TEMPERATURE: "TEMPERATUR",
      HUMIDITY: "FUKTIGHET",
      ANGLE: "VINKEL",
      NoGraphSelectedSelectADva:
        "Ingen graf vald. Välj en DVA graf för att jämföra",
      ContentNotAvailableInOfflineMode:
        "Innehåll är inte tillgängligt i offlineläge",
      Acceleration: "Acceleration",
      Humidity: "Fuktighet",
      Angle: "Vinkel",
      XAlarm: "X-alarm",
      YAlarm: "Y-alarm",
      ZAlarm: "Z-alarm",
      HighAlarm: "Höga alarm",
      LowAlarm: "Låga alarm",
      Measurements: "Mätvärden",
      SuccessfullyAddedA: "Tillagd en",
      discount: "rabatt",
      FailedToAddDiscount: "Fel uppstod skapandet av rabatt",
      SelectCompany: "Välj företag",
      SelectLicense: "Välj licens",
      AddDiscountToACompany: "Lägg till rabatt till företag",
      License: "Licens",
      DiscountPercent: "Rabatt %",
      AddDiscount: "Lägg till rabatt",
      LicenseCreatedSuccessfully: "Licens skapades ",
      FailedToCreateNewLicense: "Fel vid skapande av ny licens",
      LicenseRequiresAtLeastOneUserRight:
        "Licens kräver minst en användarrättighet",
      NoName: "Inget namn",
      SelectParentCompany: "Välj moderbolag",
      LicenseSuccessfullyUpdated: "Licens uppdaterad",
      FailedToUpdateLicense: "Fel vid uppdatering av licens ",
      LicenseType: "Licenstyper",
      AddNewDiscount: "Lägg till ny rabatt",
      FilterDiscounts: "Filter Rabatt",
      LastSeen: "Senast sedd",
      Pressure: "Tryck",
      ParametersPreview: "Förhandsgranskning parametrar",
      StartOnDate: "Starta på datum",
      RecordingPeriodWillBeginSelectedDate:
        "Mätperioden kommer starta på vald datum och tid",
      RecordingPeriodWillBeginHardwareBtn:
        "Mätperioden kommer starta vid knapptryckning på hårdvara",
      StartDirectly: "Starta direkt",
      RecordingPeriodWillBeginDirectly:
        "Mätperioden kommer starta direkt när parametrar laddas ner",
      EndRecording: "Stoppa mätning",
      EndOnDate: "Stoppa på datum",
      RecordingPeriodWillEndSelectedDate:
        "Mätperioden kommer stoppa vid vald datum och tid",
      EndAfterDuration: "Stoppa efter varaktighet",
      RecordingPeriodWillEndSelected:
        "Mätperioden kommer stoppa efter vald varaktighet",
      LoadParameters: "Ladda parametrar",
      SomeParametersAreNotSupportedByYourDevice:
        "Några parametrar stöds inte av vald enhet",
      InvalidParameters: "Ogiltiga parametrar",
      Load: "Ladda",
      Previous: "Föregående",
      ClickToDownloadSelectedParameters:
        "Klicka här för att ladda valda parametrar till din enhet",
      ClickHereToGoToTheNextStep: "Klicka här för att gå till nästa steg",
      ProjectName: "Projektnamn",
      DeviceDescription: "Enhetsbeskrivning",
      SelectDevice: "Enhet",
      ScheduleRecording: "Inspelningsschema",
      AddParameters: "Parametrar",
      TransferPrint: "Överför",
      Serialnumber: "Serienummer",
      AddNewDevice: "Lägg till ny enhet",
      AddCargologDevice: "Lägg till Cargolog enhet",
      SaveParametersAsPARXFile: "Spara parametrar som PARX fil",
      ClickHereToSaveParamsAsAFile:
        "Klicka här för att spara parametrar som fil",
      none: "ingen",
      Files: "Filer",
      RecordStart: "Starta inspelning",
      RecordEnd: "Stoppa inspelning",
      ReportStart: "Start Rapport",
      ReportEnd: "Stopp Rapport",
      ExportedBy: "Exporterad av",
      Project: "Projekt",
      DeviceId: "Enhets ID",
      MustHaveAtLeast6Characters: "Måste ha minst 6 bokstäver och ett nummer",
      EditInvoiceDetails: "Editera fakturadetaljer",
      InvoiceEmailIsRequired: "Faktura Email krävs",
      SelectNewCompany: "Välj nytt företag",
      FailedToUpdateUserCompany: "Misslyckades att uppdatera användarföretag",
      SuccessfullyUpdatedUserCompany: "Användarföretag uppdaterat",
      ChangeUserCompany: "Ändra användarföretag",
      UserCompany: "Användarföretag",
      MoveUser: "Flytta användare",
      InvoiceIsDue: "Faktura är förfallen",
      InvoiceIsOverdueSince: "Faktura är förfallen sedan",
      DefaultInterestRateOf8: "Dröjsmålsränta på 8% kommer utfalla",
      QTY: "Antal",
      Products: "Produkter",
      UnitPrice: "Enhets Pris",
      CompanyAddress: "Företagsadress",
      InvoiceNumber: "Vårt ordernummer",
      InvoiceDate: "Order datum",
      TermsOfPayment: "Betalningsvillkor",
      days: "dagar",
      YourOrderNo: "Ditt ordernummer",
      DateDue: "Förfallodatum",
      PenaltyInterest: "Dröjsmålsränta",
      OurReference: "Vår referens",
      TotalAmountExclVAT: "Summa exkl. moms",
      VATAmount: "Moms",
      TotalAmountInclVAT: "Summa inkl. moms",
      ThisInvoiceWasPaidOn: "Denna faktura betalades den",
      Phone: "Telefon",
      eMail: "e-mail",
      VATNo: "VAT nr.",
      CorporateIdentityNo: "Organisationsnummer",
      InvoiceNo: "Ordernummer",
      ExportAsPDF: "Exportera som PDF",
      WelcomeToTheQuickTour: "Välkommen till vår snabb guide",
      FollowTheStepsOfTheQuickTour:
        "Följ stegen i vår snabb guide och bekanta dig med de viktigaste funktionerna i denna applikation. Notera att fönstret för snabb guiden kan flyttas om den är i vägen.",
      InTheLicenseStoreYouCan:
        "I licensbutiken kan du köpa licenser för dina användare. Lägg till licenstypen 'Free' till varukorgen och fortsätt till kassan.",
      HereYouCanAdministrateConnected:
        "Här kan du administrera ansluten enhet och läsa ut data. Anslut en enhet för att se alla val.",
      ReportsPage: "Rapporter",
      TheReportsPageLetsYou:
        "Under rapporter kan du öppna datafiler (DATX). Börja med att öppna en fil från sidomenyn i Filer.",
      InTheSetupYouCreate:
        "Under Sätt upp enhet kan du skapa inspelningsparametrar och ladda ner dem till en ansluten enhet. Välj en enhet för att fortsätta.",
      Congratulations: "Gratulera!",
      YouHaveFinishedTheQuickTour: "Du har slutfört vår snabb guide!",
      FailedToRemoveTheInvoice: "Misslyckades att ta bort faktura",
      SuccessfullyRemovedTheInvoice: "Faktura borttagen",
      DeleteInvoice: "Radera faktura",
      AreYouSureYouWantToDelete: "Är du säker på att du vill radera",
      setFor: "inställd för",
      FailedToRemoveDiscount: "Misslyckades att ta bort rabatt",
      SuccessfullyRemovedDiscount: "Rabatt borttagen",
      DeleteDiscount: "Ta bort rabatt",
      discountFor: "rabatt för",
      on: "på",
      FailedToRemoveLicense: "Misslyckades att ta bort licens",
      SuccessfullyRemovedLicense: "Licens borttagen",
      DeleteLicense: "Radera licens",
      FailedToRemoveLicensePrice: "Misslyckades att ta bort pris för licens",
      SuccessfullyRemovedLicensePrice: "Pris för licens borttagen",
      Delete: "Ta bort",
      AreYouSureYouWantToRemoveThe: "Är du säker att du vill ta bort",
      fromTheStore: "från butiken",
      FailedToRemoveUser: "Fel vid borttagning av användare",
      DeleteUser: "Radera användare",
      timestamp: "tidsstämpel",
      Humid: "Fuktig",
      DuplicateFile: "Duplicera fil",
      Duplicate: "Duplicera",
      DVAFrequency: "DVA frekvens",
      StartGraphAt: "Starta graf på",
      OffsetInMs: "Förskjutning i ms.",
      MainGraph: "Huvudgraf",
      Comparing: "Jämför",
      graph: "graf",
      graphs: "grafer",
      TypeOfComparison: "Typ av jämförelse",
      AboveAndBelow: "Över och under",
      OnTop: "Ovanpå",
      Combined: "Kombinerat",
      SyncGraphs: "Anpassa start",
      OpenANewFile: "Öppna en ny fil",
      DVAAndPrimaryGraphs: "DVA och Huvudgraf",
      NoGraphsToCompare: "Inga filer att jämföra",
      SelectDVAGraph: "Välj DVA graf",
      GraphType: "Graf typ",
      ChannelType: "Kanaltyp",
      SyncAllGraphs: "Anpassa start av alla öppna filer",
      orSelectARecentFile: "eller välj senaste fil",
      CreateAccount: "Skapa konto",
      TheSelectedFileIsEmpty: "Vald fil är tom",
      TheSelectedFileIsPasswordProtected: "Vald fil är lösenordskyddad",
      Welcome: "Välkommen",
      FailedToSignIn: "Inloggning misslyckades",
      SignIn: "Logga in",
      PleaseEnterYourEmail: "Vänligen ange din email",
      PleaseEnterYourPassword: "Vänligen ange ditt lösenord",
      ForgotPassword: "Glöm lösenord?",
      DontHaveALicenseYet: "Har du ingen licens ännu?",
      toBuyOne: "köp en",
      NoNetworkConnectionSomeFeatures:
        "Ingen nätverksanslutning. Vissa funktioner kräver nätverksanslutning.",
      UpdateReadyToInstall: "Uppdatera Redo att Installera",
      CargologHasDownloadedANewUpdate:
        "Cargolog har laddat ner en ny uppdatering. Vill du installera och starta om?",
      RestartNow: "Starta om nu",
      Later: "Senare",
      UpdateAvailable: "Uppdatering tillgänglig",
      CargologHasFoundANewUpdate:
        "Cargolog har hittat en ny uppdatering och kommer att ladda ner den i bakgrunden.",
      NoUpdatesAvailable: "Inga uppdateringar tillgängliga",
      YouAreUsingLatestVersionOfCargolog:
        "Du använder den senaste stabila versionen av Cargolog.",
      FailedToOpenFile: "Misslyckades med att öppna fil",
      ToggleHelpMode: "Växla hjälpläge",
      dur: "varaktighet",
      sample: "exempel",
      shockSpan: "chock-intervall",
      amount: "belopp",
      accelerations: "accelerationer",
      BtnPress: "Knapptryck",
      MinMaxValues: "Min/Max-värden",
      theInvoice: "fakturan",
      theLicense: "licensen",
      theUser: "användaren",
      UnableToUploadDatx: "Misslyckades att ladda upp DATX fil",
      OnlineFiles: "Online filer",
      LastInteraction: "Senaste interaktion",
      UploadFileToCloud: "Ladda upp filer till molnet",
      Cards: "Kort",
      UploadFilesToCloud: "Ladda upp filer till molnet",
      DatxFiles: "DATX-filer",
      Automatic: "Ladda upp automatiskt",
      Manual: "Ladda upp manuellt",
      flightMode: "Flygplansläge",
      EnableLte: "Aktivera LTE",
      EnableGps: "Aktivera GPS",
      PeriodicGps: "Periodisk GPS",
      cpGpsMainToggleTitle: "Aktivera Periodisk GPS inspelning",
      cpGpsLogIntervalTitle: "Mätintervall",
      cpGpsLogIntervalContent: "Sätt mätintervall för GPS inspelning",
      cpGpsStoreSpeedTitle: "GPS - inkludera hastighet",
      cpGpsStoreSpeedContent: "Inkludera hastighet vid GPS inspelning",
      cpGpsNeverSleepTitle: "Gå aldrig i sovläge",
      cpGpsNeverSleepContent: "Hindrar GPS från sovläge när mätning ej utförs",
      cpGpsNeverSleepWarning:
        "Varning! Ska enbart användas vid extern strömförsörjning.",
      PeriodicLTE: "Periodisk LTE synkning",
      cpLteMainToggleTitle: "Aktivera Periodisk LTE synkning",
      cpLteLogIntervalTitle: "Synknings intervall",
      cpLteLogIntervalContent: "Sätt LTE synkningsintervall",
      cpLteGpsRequiredTitle: "GPS krävs",
      cpLteGpsRequiredContent:
        "Synkronisera enbart om GPS position är inhämtad",
      cpLteBandSelectorTitle: "LTE - Välj band",
      cpLteBandSelectorContent:
        "Välj vilket LTE band som ska användas för synkning.",
      advanced: "Avancerat",
      transferAlarms: "Alarm",
      selectAlarmsToTransfer:
        "Välj vilka larm som ska överföras för detta block",
      sendAccAlarm: "ACC",
      sendTempAlarm: "Temp",
      sendRhAlarm: "Fukt",
      sendAngleAlarm: "Vinkel",
      lteAlarms: "LTE alarm",
      Connection: "Anslutning",
      time: "Tid",
      startsAfter: "Startar efter",
      endsAt: "Stoppar efter",
      start: "Start",
      end: "Stop",
      durationLabel: "Varaktighet",
      StartRecording: "Starta mätning",
      AddRecordingBlock: "Lägg till inspelningsblock",
      CreateProject: "Skapa projekt",
      ProjectTitleIsRequired: "Projektnamn krävs",
      ProjectTitle: "Projekttitel",
      ProjectDescription: "Projektbeskrivning",
      EditProject: "Redigera Projekt",
      Grid: "Rutnät",
      Table: "Tabell",
      CreateNewProject: "Skapa nytt projekt",
      Time: "Tid",
      Projects: "Projekt",
      Alarms: "Alarm",
      People: "Personer",
      Devices: "Enheter",
      AddPerson: "Lägg till person",
      EditProjectDetails: "Redigera Projektbeskrivning",
      UploadFile: "Ladda upp fil",
      AddDevice: "Lägg till enhet",
      Overview: "Översikt",
      Coordinates: "Koordinater",
      Role: "Roll",
      FileSize: "Filstorlek",
      Sensor: "Sensor",
      EmptyProjects: "Tomt projekt",
      ActiveProjects: "Aktiva projekt",
      InactiveProjects: "Inaktiva projekt",
      ArchivedProjects: "Arkiverade projekt",
      EmptyProject: "Tomma projekt",
      ActiveProject: "Aktivt projekt",
      InactiveProject: "Inaktivt projekt",
      ArchivedProject: "Arkiverat projekt",
      Empty: "Tomt",
      Active: "Aktivt",
      Inactive: "Inaktivt",
      Archived: "Arkiverat",
      SuccessfullyCreatedProject: "Projekt skapat",
      UnableToCreateProject: "Kan ej skapa projekt",
      NoAlarmsInProject: "Inga alarm i projektet",
      NoPeopleInProject: "Inga personer i projektet",
      NoUnitsInProject: "Inga enheter i projektet",
      NoFilesInProject: "Inga filer i projektet",
      Notes: "Anteckningar",
      NoNotesInProject: "Inga anteckningar i projektet",
      FilterProjects: "Filtrera projekt",
      AddANote: "Lägg till anteckning",
      Warning: "Varning",
      ArchiveProject: "Arkiverade projekt",
      ArchiveProjectWarningExplanation:
        "Varning! Arkivera projekt kommer också ta bort alla enheter och licenser. Inbjuda användare kan inte längre se data.",
      FailedToRecoverProject: "Misslyckades att återskapa projekt",
      SuccessfullyRecoveredProject: "Projekt återskapat",
      RecoverProject: "Återskapa projekt",
      ConfirmRecovery: "Bekräfta återskapa",
      ConfirmRecoveryOfProject: "Bekräfta återskapa projekt",
      AreYouSureYouWantToArchiveTheProject:
        "Är du säker att du vill arkivera projekt",
      FailedToArchiveProject: "Misslyckades att arkivera projekt",
      SuccessfullyArchivedProject: "Projekt arkiverat",
      SuccessfullyUpdatedProject: "Projekt uppdaterat",
      UnableToUpdateProject: "Kan ej uppdatera projekt",
      CurrentProjectStatus: "Nuvarande projektstatus",
      SelectiveFlightMode: "Selektiv flygplansläge",
      EnablesGPS: "Aktivera GPS",
      EnablesLTE: "Aktivera LTE",
      RequiresGPS: "Kräver enhet med GPS",
      RequiresLTE: "Kräver enhet med LTE",
      DisablesAllRadio: "Avaktivera alla radio enheter",
      NoActiveAlarms: "Inga aktiva alarm på denna kanal",
      RemindMeLater: "Påminn mig senare",
      DontShowAgain: "Visa ej igen",
      Description: "Beskrivning",
      RenewSession: "Förnya session",
      SessionExpiredExplanation:
        "Din session har avslutats. Vänligen ange ditt lösenord igen för att logga in.",
      AcceptInvite: "Acceptera inbjudan",
      RejectInvite: "Avböj inbjudan",
      WithdrawInvite: "Dra tillbaka inbjudan",
      Invited: "Inbjuden",
      ProjectInvites: "Projekt inbjudan",
      AlarmEmail: "Alarm email",
      DataEmail: "Data email",
      AddInternalUser: "Bjud in interna användare",
      InviteExternalUser: "Bjud in externa användare",
      PendingInvitations: "Väntande inbjudningar",
      MembersInProject: "Medlemmar i projektet",
      SuccessfullySentTheRequest: "Begäran skickad",
      FailedToSendTheRequest: "Misslyckades att skicka inbjudan",
      InviteUser: "Bjud in användare",
      ThisUserIsInTheSameCompanyAsYou:
        "Denna användare tillhör samma företag som du.",
      AddUserHere: "Lägg till användare här",
      AssignLicense: "Tilldela licens",
      AddUser: "Lägg till användare",
      UserIsRequired: "Användare krävs",
      SearchForUser: "Sök användare",
      ThereAreNoAvailableLicensesForThisCompany:
        "Inga tillgängliga licenser för detta företag",
      CreateMobitronLicense: "Skapa Mobitron licens",
      NotActivated: "Ej aktiverad",
      FailedToAddUser: "Misslyckades att lägga till användare",
      FailedToWithdrawInvite: "Misslyckades att återkalla inbjudan",
      UserAddedSuccessfully: "Användare tillagd",
      EmailIsNotValid: "Email är inte giltig",
      InviteAcceptedSuccessfully: "Inbjudan accepterad",
      FailedToAcceptTheInvitation: "Misslyckades att acceptera inbjudan",
      InviteHasBeenRejected: "Inbjudan är avvisad",
      FailedToRejectTheInvitation: "Misslyckades att avvisa inbjudan",
      YouHaveAlreadySentAnInviteToThisEmail:
        "Du har redan skickat en inbjudan till denna email",
      SearchForProject: "Sök projekt",
      MaxLengthOfTheTitleIs50Characters: "Max längd är 50 tecken",
      AddANewProjectNamed: "Lägg till nytt projekt med namn",
      NoExistingProjectNamed: "Inget projekt med namn",
      TextMustNotBeEmpty: "Text måste vara tom",
      FailedToEditTheNote: "Misslyckades att redigera anteckning",
      FailedToAddNote: "Misslyckades att lägga till anteckning",
      AddNote: "Lägg till anteckning",
      Edited: "Redigera",
      more: "mer",
      RecordingBlock: "Inspelnings block",
      RecordingPeriod: "Inspelnings period",
      LTEinterval: "LTE intervall",
      GPSinterval: "GPS intervall",
      RadioDevicesDisabled: "Radio enheter avaktiverade",
      LTEdisabled: "LTE avaktiverad",
      GPSdisabled: "GPS avaktiverad",
      GPSPower: "GPS ström",
      GPSNeverSleeps: "GPS sover aldrig",
      RequireGPS: "Kräver GPS",
      OnlySyncWithPosition: "Synkronisera endast med position",
      YourCompanyIsStillMissingSomeInvoiceDetails:
        "Ditt företag saknar fortfarande fakturadetaljer",
      OpenCompanySettings: "Öppna företagsinställningar",
      YouDontHaveAnyNewUnusedLicenses: "Du har inga oanvända licenser.",
      NewLicenses: "Nya licenser",
      RecentReports: "Senaste rapporter",
      Analyze: "Analysera",
      ActiveUsers: "Aktiva användare",
      YouHave: "Du har",
      userRequestToReplyTo: "Begäran från användare att svara på.",
      userRequestsToReplyTo: "Begäran från användare att svara på.",
      OpenUserRequests: "Öppna Begäran från användare",
      Create: "Skapa",
      SelectProject: "Välj Projekt",
      ProjectNameAlreadyExists: "Projektnamn finns redan.",
      NoLicensesFound: "Inga licenser funna",
      ContactYourCompanyAdminToGetMoreLicenses:
        "Kontakta administratör på företag för mer licenser",
      ProjectNameIsTooLong: "Projektnamn är för långt",
      Latitude: "Latitud",
      Longitude: "Longitud",
      Speed: "Hastighet",
      GPStime: "GPS tid",
      Location: "Plats",
      NoContent: "Inget innehåll",
      WelcomeToTheProjectsPage: "Välkommen till Projektsidan",
      ThereAreCurrentlyNoProjectsToShow:
        "För tillfället finns inga projekt att visa, men här kommer du kunna se alla dina aktiva och arkiverade projekt.",
      YouCanManageYourInvitesByPressingThe:
        "Du kan hantera dina inbjudningar genom att trycka på",
      buttonAbove: "knappen ovan.",
      OpenInReports: "Öppna i Rapporter",
      OpenInCompare: "Öppna i Jämförelse",
      AccessToProjectsPage: "Tillgång till projekt-sidan",
      OpenReportDataFromProject: "Öppna rapportdata från projekt",
      EditProjectDetailsInvitesNotifications:
        "Redigera projektdetaljer, inbjudningar och notifikationer",
      ArchiveAndRecoverProjects: "Arkivera och återskapa projekt",
      cpExternalSensorRhAlarmLevelTitle: "Larmnivå fukt",
      cpExternalSensorTempAlarmLevelTitle: "Larmnivå temperatur"
    }
  },
  en: {
    translations: {
      Start: "Start",
      Other: "Other",
      DataVisualisation: "Data Visualisation",
      Data: "Data",
      generalTitleSettings: "Settings",
      closeFileTip: "Close file",
      titleMyDevicesSm: "My devices",
      titleGraphSm: "Files",
      noOpenFileMsg: "Press 'Open file' to open a graph.",
      openFileBtn: "Open file",
      openFilesGraph: "Open",
      recentFilesGraph: "Recent",
      graphSubMenuTitle: "Data",
      usersMainMenuTitle: "Users",
      licensesMainMenuTitle: "Licenses for sale",
      storeMainMenuTitle: "Store",

      btnExport: "Export",
      btnExportGraphSvg: "Export as SVG",
      btnExportGraphPng: "Export as PNG",
      btnExportGraphPDF: "Export as PDF",
      saveAsBtn: "Save as",
      paramsBtnStart: "Start date",
      paramsBtnStop: "Stop date",
      paramsAltDate: "Date",
      paramsAltBtn: "By button press",
      paramsAltDirectly: "Directly",
      paramsAltAfterDuration: "After duration",
      paramsAltBtnMessage:
        "The recording will start after you press THE BUTTON on your device.",
      paramTitleAcc: "Acceleration parameters",
      paramTitleTemp: "Temperature parameters",
      paramTitleHumidity: "Humidity parameters",
      loadRecParamsModalTitle: "Loading Parameters",
      warningTitleErrNewDataAvil: "Warning, you have new data available",
      warningTextErrNewDataAvil:
        "You have new unread data available. Would you like to format?",
      warningTitleErrMemNotEmpty: "Warning, mem not empty",
      warningTextErrMemNotEmpty: "placeholder",
      scoreValuesTitle: "Score Values",
      scoreValuesCurrentPos: "Current position",
      generalAccTitle: "Acceleration",
      generalTempTitle: "Temperature",
      generalRhTitle: "Humidity",
      generalAngleTitle: "Angle",
      generalDeviceHealthTitle: "Device health",
      generalMinTitle: "Min",
      generalMaxTitle: "Max",
      openFileTitle: "Open File",
      serialIdTitle: "Serial Number",
      projectNameTitle: "Project Name",
      exportReportTitle: "Export Report",
      exportDatxAsCsv: "Export CSV",
      myDevicesTableSerialNumberTitle: "Serial number",
      myDevicesTableLastConnectedTitle: "Last connected",
      myDevicesTableNextCalibrationTitle: "Next calibration",
      recInfoViewDeviceInfo: "View device information",
      recInfoViewParams: "View parameters",
      sysInfoPreviewTitle: "System information",
      sysInfoPreviewArtNum: "Article number",
      sysInfoPreviewFw: "Firmware",
      sysInfoPreviewMaxG: "Max g",
      sysInfoPreviewSupParx: "Supported PARX version",
      sysInfoPreviewSupDatx: "Supported DATX version",
      sysInfoPreviewCalExp: "Calibration expires",
      sysInfoPreviewSectionAvilFeatures: "Features",
      sysInfoPreviewSubTitleBattery: "Battery information",
      sysInfoPreviewMainBatterySource: "Main battery source",
      sysInfoPreviewMainBatteryLevel: "Main battery level",
      sysInfoPreviewBackupBatteryLevel: "Backup battery level",
      sysInfoPreviewMemInfo: "Memory information",
      sysInfoPreviewCapacity: "Capacity",
      sysInfoPreviewAvil: "Available ",
      // userAvatar
      Account: "Account",
      ChangeLanguage: "Change language",
      Swedish: "Swedish",
      English: "English",
      About: "About",
      SignOut: "Sign out",
      // --- SIDEBAR ---
      sbGraphMainMenuTitle: "Reports",
      sbCompareMainMenuTitle: "Compare",
      sbParamsTitle: "Setup device",
      sbDevicesTitle: "My devices",
      sbAdmin: "Admin",
      sbMobitron: "Mobitron",
      sbNotConnected: "Not connected",
      sbConnecting: "Connecting..",
      sbConnected: "Connected",
      sbNotIdentified: "Not identified",
      sbSettingsMainMenuTitle: "Settings",
      sbContact: "Contact",
      sbHelp: "Help",
      // General form labels/inputs/titles etc.
      genName: "Name",
      genFullName: "Full Name",
      genCompanies: "Companies",
      // General buttons
      genEdit: "Edit",
      genDelete: "Delete",
      genSend: "Send",
      genClose: "Close",
      genUpdate: "Update",
      // Table content
      tableNr: "nr",
      tableAmount: "Amount",
      tableStatus: "Status",
      tableInvoiceDate: "Order Date",
      tableDueDate: "Due Date",
      tablePaidOn: "Paid On",
      tableType: "Type",
      tableActions: "Actions",
      tableResetPasssword: "Reset Password",
      tableMarkAsPaid: "Mark as Paid",
      tableMarkedAsPaid: "Marked as Paid",
      tableReleaseLicenses: "Release Licenses",
      tableLicensesReleased: "Licenses Released",
      tableRenewNow: "Renew Now",
      tableSetToAuto: "Set to Auto",
      tableDates: "Dates",
      tablePrice: "Price",
      tableRenewAuto: "Renew Auto",
      tableRenewManually: "Renew Manually",
      tableRenews: "Renews",
      tableExpires: "Expires",
      tableActivate: "Activate",
      tableName: "Name",
      tablePaid: "paid",
      // --- Modals ---
      // General Required-messages
      genResetCodeRequired: "Reset Code is required",
      // EditUserModal
      EditUserAccount: "Edit User Account",
      // EditUserNameModal
      UpdateName: "Update Name",
      // EditUserNameModal
      UpdatePhoneNumber: "Update Phone Number",
      // ChangeEmailModal
      ResetEmail: "Reset Email",
      NewEmail: "New Email",
      SendResetCode: "Send Reset Code",
      ResetCode: "Reset Code",
      btnSetNewEmail: "Set New Email",
      // ChangePasswordModal
      ResetPassword: "Reset Password",
      NewPassword: "New Password",
      PasswordRequired: "Password is required",
      btnSetNewPassword: "Set New Password",
      // --- Admin Pages ---
      // AdminPage
      Users: "Users",
      Licenses: "Licenses",
      Invoices: "Orders",
      BuyLicenses: "Buy Licenses",
      // AdminUsers
      UsersAdmin: "Users Admin",
      CompanyUsers: "Company Users",
      FilterUsers: "Filter Users",
      btnAddALicense: "Add a License",
      AllUsers: "All Users",
      createNewUserTitle: "Create New User",
      createNewUserBtn: "Create New User",
      createUserBtn: "Create User",
      // AdminLicenses
      licensesAdmin: "Licenses Admin",
      AllLicenses: "All Licenses",
      tableTitleMyPlans: "License Plans",
      filterPlans: "Filter Plans",
      //LicensePrice
      tableLicensePriceCreateNewLicense: "Create New License",
      licensePriceColor: "Color",
      licensePriceNameAndDescription: "Name and description",
      licensePricePrices: "Prices",
      licensePriceUserRights: "User Rights",
      licensePriceEditLicense: "Edit License",
      licensePriceMonths: "Months",
      licensePriceFilterUserRights: "Filter User Rights",
      // AdminInvoice
      tableTitleMyInvoices: "My Orders",
      FilterInvoices: "Filter Orders",
      // License store
      LicenseStore: "License Store",
      btnShowCart: "Show Cart",
      btnAddToCart: "Add to Cart",
      // Cards
      cargolog3rdGen: "Cargolog 3rdGen",
      cardMonth: "month",
      genCardClickForDetails: "Click for details",
      cargolog3rdGenLicenseDetails: "Cargolog 3rdGen License Details",
      Checkout: "Checkout",
      // LicenseCartDrawer
      titleLicenseCart: "License Cart",
      cartMonths: "months",
      cartTotalPrice: "Total price",
      btnProceedToCheckout: "Proceed to Checkout",
      // LicenseCheckoutModal
      CreditCard: "Credit Card",
      Invoice: "Invoice",
      btnSendOrder: "Send Order",
      NameOnCreditCard: "Name on Credit Card",
      OrderHasBeenSent: "Your order has been sent to Mobitron",
      CheckoutMessage:
        "The ordered Licenses will be delivered once the order is fully paid. Please contact Mobitron for manual release if you need them earlier.",
      YourOrder: "Your Order",
      CreditCardNumber: "Credit Card Number",
      ExpirationDate: "Expiration Date",
      Cvv: "CVV",
      // DetailsModal
      // Table Titles
      tableFunction: "Function",
      genFree: "Free",
      genProgramActivate: "Program & Activate",
      genDesktop: "Desktop",
      genPremium: "Premium",
      // Table content
      QuickReport: "Quick report",
      ExportAndShareData: "Export and Share Data",
      PrintData: "Print Data",
      DetailedVibrationAnalysis: "Detailed Vibration Analysis",
      CompareAndAnalyseData: "Compare and Analyse Data",
      StandardDashboard: "Standard Dashboard",
      CustomDashboard: "Custom Dashboard",
      Reports: "Reports",
      FleetOverview: "Fleet Overview",
      LoadEditAndViewParams: "Load, Edit and View Params",
      SetPasswordLevel: "Set Password Level",
      CreateProjects: "Create Projects",
      EndProjects: "End Projects",
      UploadDataToCloud: "Upload Data to Cloud",
      LiveNotificationsAndAlarms: "Live Notifications and Alarms",
      OnlineAccessToAccount: "Online Access to Account",
      TransportMonitoring: "Transport monitoring",
      CreateTeams: "Create teams",
      ShareDataToExternalUsers: "Share Data to External Users",
      UpdateFirmware: "Update Firmware",
      // --- Mobitron pages ---
      // MobitronAdminPage
      StoreAdmin: "Store Admin",
      Discounts: "Discounts",
      Companies: "Companies",
      HelpInbox: "Help Inbox",
      Dev: "Dev",
      // MobitronAdminOrders
      AllInvoices: "All Orders",
      // StoreAdmin
      tableStoreAdminName: "Name",
      tableStoreAdminDesc: "Description",
      tableStoreAdminCurrency: "Currency",
      tableStoreAdminNewLicenseBtn: "New License",
      FilterLicenses: "Filter Licenses",
      tableStoreAdminCreateNewLicense: "Create new License",
      // MobitronAdminCompanies
      mobCompanyTree: "Company Tree",
      mobNoName: "No name",
      // EditCompanyMobitronModal
      mobUpdateCompany: "Update Company",
      mobPostalCodeRequired: "Postal code is required",
      mobCityRequired: "City is required",
      mobParentCompany: "Parent Company",
      mobSaveChanges: "Save Changes",
      // MobitronAdminHelp
      SupportTickets: "Support Tickets",
      FilterTickets: "Filter Tickets",
      tableMessage: "Messages",
      tableCreated: "Created",
      tableUserId: "User ID",
      tableVersionInfo: "App Version",
      tableOsVersion: "OS Version",
      // MobitronAdminDev
      DevelopmentUtilities: "Development utilities",
      btnCreateDtAccCsvFromDatx: "Create raw dt-acc CSV from DATX",
      // --- Settings ---
      // General settings
      Settings: "Settings",
      GeneralSettings: "General Settings",
      SelectYourCurrency: "Select your currency",
      // Account settings
      AccountSettings: "Account Settings",
      SettingsForUserAccount: "Settings for user account",
      // Graph settings
      GraphSettings: "Graph Settings",
      // CompanySettings
      CompanySettings: "Company Settings",
      SettingsForCompany: "Settings for company",
      // ContactModal
      Contact: "Contact",
      Close: "Close",
      YourMessageHasBeenSent: "Your message has been sent to Mobitron",
      ContactPage: "Contact  Page",
      Sweden: "Sweden",
      ContactUs: "Contact Us",
      contactFormInfo:
        "Don't hesitate to contact us via the form below. We are always happy to share our experiences and help you find the best solution.",
      Send: "Send",
      TechnicalSupport: "Technical Support",
      ReportAProblem: "Report a problem",
      helpPageInfo:
        "If you have encountered a problem on Cargolog or with your account we'd love to hear from you. Please fill in this form and we'll get back to you.",
      DataForTroubleShooting: "Data for troubleshooting",
      OSVersion: "OS Version",
      ScreenResolution: "Screen Resolution",
      AppVersion: "App Version",
      DescribeYourProblem: "Describe your problem",
      aMessageIsRequired: "A message is required",
      Submit: "Submit",
      Message: "Message",
      // --- FAT 100 License Details ---
      // Device Page
      tableTitleMyDevices: "My Devices",
      tableCardTitle: "Recent Devices",
      filterMyDevices: "Filter Devices",
      //general titles for common things
      genXAxis: "X-axis",
      genYAxis: "Y-axis",
      genZAxis: "Z-axis",
      genDays: "Days",
      genHours: "Hours",
      genMinutes: "Minutes",
      genRepatNTimes: "Times",
      genAuto: "Automatic",
      genManual: "Manual",
      genCancel: "Cancel",
      genYes: "Yes",
      genSave: "Save",
      genPrint: "Print",
      genExportCsv: "Export as CSV",
      genExportPdf: "Export as PDF",
      genShare: "Share",
      // SystemInfo feature names
      DF_ACC: "Acceleration ±16g",
      DF_TEMP: "Temperature",
      DF_RH: "Humidity",
      DF_ANGLE: "Angle",
      DF_PRESSURE: "Pressure",
      DF_INT_LIGHT: "Light",
      DF_GPS: "GPS",
      DF_LTE: "LTE",
      DF_BLUETOOTH: "Bluetooth",
      DF_EXT_INPUTS: "External inputs",
      DF_EXT_OUTPUTS: "External outputs",
      DF_RESERVED1: "**no name**",
      DF_DVA: "DVA",
      DF_ACCEXTRA: "Acceleration ±200g",
      //dashboard layout ids to titles/names
      mainGraph: "Graph",
      dvaGraph: "Detailed Vibration Analysis",
      scoreValues: "Score values",
      minMax: "Min/Max values",
      topAccTable: "Top 10 most intensive shocks",
      deviceInfo: "Recording information",
      accHistogram: "Histogram",
      quickReport: "Quick Report",
      mainGraphDesc: "Main data recording graph",
      dvaGraphDesc: "Detailed shock data graphs",
      scoreValuesDesc: "Score values",
      minMaxDesc: "Minimum and maximum values for each measuring channel",
      topAccTableDesc: "10 most intensive shocks",
      deviceInfoDesc:
        "Device parameters such as registration level, alarm level and measuring intervals",
      accHistogramDesc: "Grouped acceleration data",
      // Create parameters Card content = (cp + kind of parameter + desccription)
      cpProgressWaiting: "Waiting",
      cpInProgress: "In progress",
      cpValidated: "Finished",
      //Acceleration
      cpAccMainToggleTitle: "Activate acceleration recording",
      cpAccRegLevelsTitle: "Registration levels",
      cpAccRegLevelsContent:
        "Set the minimum registration level to measure and register acceleration. Set the alarm level and its duration to indicate exceeded limit value",
      cpAccRegLevelsInpRegLevel: "Registration level (g)",
      cpAccRegLevelsInpAlarmLevel: "Alarm level (g)",
      cpAccRegLevelsInpDurationLevel: "Duration (ms)",
      cpAccStoreAngleTitle: "Store angle",
      cpAccStoreAngleContent:
        "Record angle/leaning when alarm level exceeded for acceleration",
      cpAccStoreGpsPosTitle: "Store GPS position",
      cpAccStoreGpsPosContent:
        "Record the GPS position when alarm level exceeded for acceleration",
      cpAccLowPassFilterTitle: "Low pass filter",
      cpAccLowPassFilterContent:
        "Select the low pass filter to be applied for the acceleration range",
      cpAccDvaTitle: "Detailed Vibration Analysis",
      cpAccDvaContent:
        "Activate and record the waveform of the acceleration above set values.",
      cpAccSendGsmAlarmTitle: "Send LTE alarm",
      cpAccSendGsmAlarmContent:
        "Send recorded data with LTE communication at exceeded alarm level for acceleration.",
      cpAccIgnoreAlarmsTitle: "Ignore alarm",
      cpAccIgnoreAlarmsContent:
        "Save power by ignoring a number of repeated alarms. The first alarm is always sent.",
      //temperature
      cpTempMainToggleTitle: "Activate temperature recording",
      cpTempLogIntervalTitle: "Logging interval",
      cpTempLogIntervalContent: "Set the logging intervall for sampling data.",
      cpTempStoreGpsPosTitle: "Store GPS position",
      cpTempStoreGpsPosContent:
        "Record the GPS position when alarm level exceeded for temperature.",
      cpTempAlarmLevelTitle: "Alarm level",
      cpTempAlarmLevelContent:
        "Set the alarm level to indicate lower and upper exceeded limit value",
      cpTempSendGsmAlarmTitle: "Send LTE alarm",
      cpTempSendGsmAlarmContent:
        "Send recorded data with LTE communication at exceeded alarm level for temperature.",
      cpTempIgnoreAlarmTitle: "Ignore alarm",
      cpTempIgnoreAlarmContent:
        "Save energy and set how often alarm levels should be ignored.",
      //humidity
      cpRhMainToggleTitle: "Activate humidity recording",
      cpRhLogIntervalTitle: "Logging interval",
      cpRhLogIntervalContent: "Set the logging intervall for sampling data.",
      cpRhStoreGpsPosTitle: "Store GPS position",
      cpRhStoreGpsPosContent:
        "Record the GPS position when alarm level exceeded for humidity.",
      cpRhAlarmLevelTitle: "Alarm level",
      cpRhAlarmLevelContent:
        "Set the alarm level to indicate lower and upper exceeded limit value",
      cpRhSendGsmAlarmTitle: "Send LTE alarm",
      cpRhSendGsmAlarmContent:
        "Send recorded data with LTE communication at exceeded alarm level for humidity.",
      cpRhIgnoreAlarmTitle: "Ignore alarm",
      cpRhIgnoreAlarmContent:
        "Save energy and set how often alarm levels should be ignored.",
      //angle
      cpAngleMainToggleTitle: "Activate angle recording",
      cpAngleAlarmLevelTitle: "Alarm level",
      cpAngleAlarmLevelSubTitle: "Alarm level (°)",
      cpAngleAlarmLevelContent:
        "Set the alarm level to indicate exceeded limit value.",
      cpAngleStoreGpsPosTitle: "Store GPS position",
      cpAngleStoreGpsPosContent:
        "Record the GPS position when alarm level exceeded for angle.",
      cpAngleRefAngleTitle: "Reference angle",
      cpAngleRefAngleContent:
        "Set the reference angle for mounting direction if other than flat position. The reference angle will be set at the start of the measurement period. Before starting the measurement period, it requires mounting the device.",
      cpAngleSendGsmAlarmTitle: "Send LTE alarm",
      cpAngleSendGsmAlarmContent:
        "Send recorded data with LTE communication at exceeded alarm level for angle.",
      cpAngleIgnoreAlarmsTitle: "Ignore alarms",
      cpAngleIgnoreAlarmsContent:
        "Save energy and set how often alarm levels should be ignored.",
      // parameters preview
      cpRegLvlTitle: "Registration limit",
      cpAlarmLimit: "Alarm limit",
      cpConfigTitle: "Configuration",
      cpGenLow: "Low",
      cpGenHigh: "High",
      cpGenStoreGps: "Store GPS",
      cpGenSendGpsAlarm: "Send GPS alarm",
      cpGenSendLteAlarm: "Send LTE alarm",
      cpGenIgnoreAlarms: "Ignore repeated alarms (time)",
      genSampleInterval: "Sample interval",
      // User information
      cpUserInfoPaneTitle: "Project",
      cpUserInfoFreeTextDesc:
        "Use free text to describe your transport, product, and mounting of the Cargolog® device.",
      // --- Warning texts ---
      warnDatxEmptyText:
        "Unable to read device. The device has no data. No file has been saved.",
      // ChangeEmailModal
      emailModalResetCodeErrorMessage: "Failed to Send Reset Code",
      emailModalResetCodeSuccess: "Reset code sent to ",
      ChangeEmailErrorMessage: "Failed to set new email",
      ChangeEmailSuccessMessage: "New email is set to",
      //ChangePasswordModal
      passwordModalResetCodeErrorMessage: "Failed to Send Reset Code",
      PasswordModalResetCodeSuccess: "Reset code sent to ",
      ChangePasswordErrorMessage: "Failed to set new password",
      ChangePasswordSuccessMessage: "New Password is set",
      //EditCompanyModal
      EditCompanyErrorMessage: "Failed to edit company",
      EditCompanySuccessMessage: "Company edited successfully",
      //EditCompanyMobitrinModal
      EditCompanyMobitronErrorMessage: "Failed to edit company",
      EditCompanyMobitronSuccessMessage: "Company edited successfully",
      EditParentCompanyMobitronErrorMsg: "Failed to edit Parent Company",
      EditParentCompanyMobitronSuccessMsg: "Parent Company edited successfully",
      //CreateUserModal
      FailedToCreateNewUser: "Failed to create new user",
      SuccessfullyCreateNewUser: "User created successfully",
      //EditUserNameModal
      FailedtoUpdateUserName: "Failed to update name",
      SuccessfullyUpdatedUserName: "Name updated successfully",
      //EditUserPhoneModal
      FailedtoUpdateUserPhone: "Failed to update phone number",
      SuccessfullyUpdatedUserPhone: "Phone number updated successfully",
      //GeneralUserFeedback
      // FeatureNotImplementedMessage: "This feature is not yet implemented, sorry!",
      //HelpModal
      FailedToSendSupportTicket: "Failed to send support ticket",
      SuccessfullySentSupportTicket: "Support ticket sent successfully",
      //RemoveUsermodal
      SuccessfullyRemovedUser: "Successfully removed user ",
      //LoginPage
      UnabletologinError: "Unable to login",
      // ContactModal
      FailedToSendContactForm: "Failed to send message",
      // LicenseCheckoutModal
      UnableToSendOrder: "Unable to send order",
      // CreateAccountPage
      SuccessfullyCreatedUserAccount: "Your user account has been created!",
      UnableToCreateUserAccount: "Failed to create user account",
      //openDatxSlice
      //InvalidDatxFileError: "Invalid datx-file",
      // invalid recording parameters message
      // InvalidRecParamsError: "Invalid RecParams. Check error log.",
      GeneralSettingsDescription: "Settings for language, currency and updates",
      GraphSettingsDescription: "Settings for graph",
      Language: "Language",
      SelectLanguageTooltip: "Select your language",
      Currency: "Currency",
      Updates: "Updates",
      CurrentVersion: "Current version",
      LatestCheck: "Latest check",
      CheckForUpdates: "Check for Updates",
      DownloadingUpdate: "Downloading Update",
      RestartAndUpdate: "Restart and Update",
      NoUpdatesInstalledYet: "No updates installed yet",
      // Report opening statuses
      openFileStatusInactive: "Inactive",
      openFileStatusParsing: "Unpacking DATX file",
      openFileStatusRendering: "Rendering DATX file",
      EditCompanyDetails: "Edit Company Details",
      NameIsRequired: "Name is required",
      AddressIsRequired: "Address is required",
      PostalCodeIsRequired: "Postal code is required",
      CityIsRequired: "City is required",
      CountryIsRequired: "Country is required",
      Country: "Country",
      SearchToSelect: "Search to select",
      OrgNumberRequiredInSweden: "Org. number is required in Sweden",
      SaveChanges: "Save Changes",
      ScaleSteps: "Scale steps",
      SetGlobalScale: "Set Global Scale",
      UserId: "User ID",
      ActiveLicenses: "Active Licenses",
      NoActiveLicenses: "No Active Licenses",
      UpgradeLicenseToHandleDevice:
        "You need to upgrade your license in order to handle Cargolog devices.",
      HeadToLicenseStore: "Head over to the license store",
      CompanyId: "Company ID",
      InvoiceEmail: "Invoice Email",
      InvoiceAddress: "Invoice Address",
      InvoicePostalCode: "Invoice Postal Code",
      InvoiceCity: "Invoice City",
      VATNumber: "VAT Number",
      General: "General",
      Graph: "Graph",
      Company: "Company",
      NearValues: "Near Values",
      ShowNearValues: "Show Near Values",
      HideNearValues: "Hide Near Values",
      DescriptionGlobalNearValuesLine1:
        "When active, this setting allow near values (that does not have the exact same timestamp as the current position in the Score Values card) to be shown.",
      DescriptionGlobalNearValuesLine2:
        "Deactivate to only show values matching the current position.",
      Temperature: "Temperature",
      SelectTemperatureScale: "Select temperature scale",
      TimeScaleAxisSteps: "Time Scale Axis Steps",
      SetXAxisScale: "Set x-axis scale",
      Auto: "Auto",
      Default: "Default",
      Sparse: "Sparse",
      Medium: "Medium",
      Dense: "Dense",
      CSVExport: "CSV Export",
      CSVFormatStandard: "CSV format standard",
      SwedishStandard: "Swedish standard",
      EnglishStandard: "English standard",
      DecimalSeparator: "Decimal separator",
      CellSeparator: "Cell separator",
      GlobalGraphScale: "Global Graph Scale",
      SetScale: "Set scale",
      DescriptionGlobalScaleSettings:
        "When active, this setting overrides the graph scale settings in Primary Graph in all open files. To manually set the scale options per file, you need to deactivate this global setting first.",
      GlobalTimeZone: "Global Time Zone",
      DescriptionGlobalTimezone:
        "When active, this setting overrides both automatic time zone settings and manual (per file) time zone setting in Analyze data. To manually set the time zone per file, you need to deactivate this global setting first.",
      SelectGlobalTimezone: "Select global timezone",
      SearchForTimezone: "Search for timezone",
      Dashboard: "Dashboard",
      ClickForDetails: "Click for details",
      paid: "paid",
      overdue: "overdue",
      pending: "pending",
      Created: "Created",
      Status: "Status",
      WaitingForPayment: "Waiting for payment",
      Renews: "Renews",
      Expires: "Expires",
      NoAvailableLicenses: "There are no available licenses",
      Remove: "Remove",
      UserRequests: "User Requests",
      ClickAddALicenseToAssign:
        "Click 'Add a license' to assign a license to one of your users.",
      Discount: "Discount",
      Price: "Price",
      ExclVAT: "Excl. VAT",
      VAT: "VAT",
      AssignLicenses: "Assign licenses",
      AssignTheLicenseToAUser: "Assign the license to a user",
      CardPaymentIsntAvailable:
        "Card payment isn't available on free items, please send your order as an invoice instead!",
      Required: "Required",
      InvoiceDetails: "Invoice Details",
      ClickToEditInvoiceDetails: "Click here to edit Invoice Details",
      EditDetails: "Edit Details",
      PurchaseNotAvailable:
        "Purchase is not available without required invoice details",
      PurchaseOrderNumber: "Purchase Order Number",
      ClickHereToSendYourOrder: "Click here to send your order",
      SendOrder: "Send Order",
      months: "months",
      PhoneApp: "Phone App",
      PcApp: "PC App",
      Cloud: "Cloud",
      Bluetooth: "Bluetooth",
      SeeMyUnits: "See My Units",
      AccessAllUnitData: "Access All Unit Data",
      ReadData: "Read Data",
      ViewParams: "View Parameters",
      ExportData: "Export Data",
      CreateEditAndShareTemplates: "Create, Edit and Share Templates",
      ViewTemplates: "View Templates",
      Debug: "Debug",
      CreateEditLoadandViewParams: "Create, Edit, Load and View Parameters",
      SetPasswordLevel3: "Set Password level 3",
      SetPasswordLevel2: "Set Password level 2",
      SetPasswordLevel1: "Set Password level 1",
      ViewDataForSharedProject: "View data for shared project",
      ViewDataFromLTEUnit: "View Data from LTE unit",
      CreateEditAndShareProjectsAndTeams:
        "Create, Edit and Share Projects and Teams",
      MarkLoggerAsFinishedTransport: "Mark Logger as Finished Transport",
      AutoSyncOfDataToCloud: "Auto Sync of Data to Cloud",
      HandleAllUsers: "Handle All Users",
      HandleAllCompanyLicenses: "Handle All Company Licenses",
      HandleCompanyDetails: "Handle Company Details",
      myDevicesTableLastUserTitle: "Latest user",
      NFCCommunication: "NFC communication",
      ReadDataFromLabel: "Read Data from label",
      ViewQuickDataAndComment: "View quick data and comment",
      PrintQuickReportPDF: "Print quick report (PDF)",
      ViewParameters: "View Parameters",
      ShareDataSendFileToOtherUser: "ShareData (send file to other user)",
      ViewAllMeasuringData: "View all measuring data",
      ExportAllDataCSV: "Export all Data (CSV)",
      PrintDataAllData: "PrintData (all data)",
      SaveDataLocally: "Save data locally",
      OpenSavedData: "Open saved data",
      EditParameters: "Edit parameters",
      ActivateDVAData: "Activate DVA data",
      UseTemplates: "Use Templates",
      UploadDataToCloudOnSetup: "Upload Data to Cloud on Setup",
      MarkLoggerAsTinishedTransport: "Mark Logger as Finished Transport",
      UploadDataToCloudOnReadOut: "Upload data to cloud on read out",
      ManualUploadOfSavedDataToloud: "Manual upload of saved data to cloud",
      SetStatusTestRepair: "Set Status test/repair",
      TerminalMode: "Terminal Mode",
      SendMessageToUnit: "Send message to unit",
      SetNewParameterOnline: "Set new parameter online",
      AndroidApp: "Android App",
      iOSApp: "iOS App",
      Compare: "Compare",
      toOtherLicenses: "to other licenses",
      ClickHereToShowTheCart: "Click here to show the cart",
      cargologEasy: "Cargolog Easy",
      vehicle: "vehicle",
      User: "User",
      Requested: "Requested",
      AcceptUser: "Accept user",
      RejectUser: "Reject user",
      FailedToCreateTransfer: "Failed to create transfer",
      SuccessfullyCreatedTransferRequest:
        "Successfully created transfer request",
      RequestTransfer: "Request transfer",
      EnterTheTargetCompanyId:
        "Enter the target company id in the box below to request a transfer to this company. Ask the admin for the company id, it can be seen in company settings. The transfer has to be accepted by the target company admin. All your licenses will be lost in the transfer.",
      CompanyIdIsRequired: "Company ID is required",
      SendTransferRequest: "Send transfer request",
      Ok: "Ok",
      ExportPDF: "Export PDF",
      ExportCSV: "Export CSV",
      asPDF: "as PDF",
      asCSV: "as CSV",
      Export: "Export",
      Print: "Print",
      Channels: "Channels",
      ResetZoomLevel: "Reset zoom level",
      RemoveFromComparison: "Remove from comparison",
      PrimaryGraphs: "Primary Graphs",
      DvaGraphs: "Dva Graphs",
      CompareGraphs: "Compare Graphs",
      UserDetails: "User Details",
      CompanyDetails: "Company Details",
      CompanyName: "Company Name",
      Address: "Address",
      Address2: "Address 2",
      PostalCode: "Postal Code",
      City: "City",
      OrgNumber: "Org. Number",
      AbortSetup: "Abort Setup",
      ThisAbortsYourRegistration:
        "This aborts your registration. To continue the setup, log in with your user account details.",
      FinishSetup: "Finish Setup",
      FirstNameIsRequired: "First name is required",
      FirstName: "First Name",
      LastNameIsRequired: "Last name is required",
      LastName: "Last Name",
      EmailIsRequired: "Email is required",
      Email: "Email",
      PhoneNumber: "Phone Number",
      Password: "Password",
      PasswordMustBeAtLeast:
        "Password must be at least 6 characters long and contain at least one number",
      MustBeAtLeast6CharactersLong: "Must be at least 6 characters long",
      MustContainAtLeastOneNumber: "Must contain at least one number",
      BackToLogin: "Back to login",
      Next: "Next",
      FailedToRemoveRecentDevice: "Failed to remove recent device",
      SuccessfullyRemovedRecentDevice: "Successfully removed recent device",
      DeviceInfo: "Device info",
      RemoveDevice: "Remove device",
      FilterByUser: "Filter by user",
      UseForNewParameters: "Use for new parameters",
      Cancel: "Cancel",
      TryAgain: "Try again",
      Features: "Features",
      Manage: "Manage",
      NoDeviceConnected: "No device connected",
      ConnectDeviceToStartCommunication:
        "Connect Device to start communication",
      USB: "USB",
      LoadingDevice: "Loading Device",
      SeeFirmwareFeaturesForYourDevice:
        "See firmware features for your device.",
      SystemInfo: "System Info",
      ReadParametersSetInYourDevice: "Read parameters set in your device",
      Parameters: "Parameters",
      DownloadNewParametersAndActivateTheDevice:
        "Download new parameters and activate the device",
      Setupdevice: "Setup device",
      Upgradefirmware: "Upgrade firmware",
      Upgrade: "Upgrade",
      ActiveParameters: "Active Parameters",
      Connected: "Connected",
      DeviceNotSupportAllFeatures:
        "Your device does not support all features in the parameter file. In order to proceed, the following features will not be used",
      SettingDevicePasswords: "Setting device passwords",
      FormattingDevice: "Formatting device",
      SettingDeviceInternalClock: "Setting device internal clock",
      DownloadingNewRecordingParameters: "Downloading new recording parameters",
      ValidatingDownload: "Validating download",
      DownloadingParemetersFinished:
        "Downloading recording parameters finished! You can now safely disconnect the device.",
      newRecordingParametersDownloadedShortly:
        "Your new recording parameters will be downloaded shortly",
      FailedToFormatDevice: "Failed to format device",
      FailedToSetDeviceInternalClock: "Failed to set device internal clock",
      FailedToSetPassword: "Failed to set password",
      FailedToUpdateBatteryStatus: "Failed to update battery status",
      FailedToDownloadRecParamsToDevice:
        "Failed to download new recording parameters to device",
      SomethingWentWrongWhileDownloading:
        "Something went wrong while download new recording parameters to device",
      Continue: "Continue",
      Format: "Format",
      Back: "Back",
      BatteriesNotChanged: "Batteries Not Changed",
      UseSelectedBattery: "Use Selected Battery",
      Done: "Done",
      FailedReadDataDueToInternalError:
        "Failed to read out data due to an internal device error",
      SuccessfullyReadUutData: "Successfully read out data",
      ReadingRecordingData: "Reading recording data",
      NewFirmwareTransferredSuccessfully:
        "New firmware transferred successfully",
      RebootingDevice: "Rebooting device",
      PreaparingToRebootDevice: "Preaparing to reboot device",
      TransferingNewFirmwareToDevice: "Transfering new firmware to device",
      Preparing: "Preparing",
      InternalDeviceError: "Internal device error",
      timeout: "timeout",
      UpgradeFailedDidntGoThrough:
        "Upgrade failed, the upgrade didn't go through.",
      ErrorCantUpgradeInsufficent:
        "Error: Can't upgrade device due to insufficient firmware version. Minimum firmware version needed to upgrade",
      unknown: "unknown",
      actualFirmwareVersionForDevice: "actual firmware version for device",
      ConnectDevice: "Connect Device",
      ActiveRecordingParameters: "Active Recording Parameters",
      SetupDevice: "Setup Device",
      Download: "Download",
      ReadOutData: "Read out data",
      UpgradeFirmware: "Upgrade firmware",
      ClickHereToReadDataFromDevice: "Click here to read data from device",
      ReadNextDevice: "Read next device",
      Openfile: "Open file",
      SaveAs: "Save as",
      Select: "Select",
      DevicePasswordRequired: "Device Password Required",
      DeviceProtectedPleaseEnter:
        "The device is protected. Please enter the password to proceed.",
      ReportDataIsFiltered: "Report data is filtered",
      ReportDataIsNotFiltered: "Report data is not filtered",
      Report: "Report",
      Exported: "Exported",
      HaveYouChangedToNewBatteries: "Have you changed to new batteries?",
      Batteries: "Batteries",
      AddBattery: "Add Battery",
      FilterBatteries: "Filter Batteries",
      SelectedBattery: "Selected Battery",
      Name: "Name",
      FormFactor: "Form factor",
      VoltageV: "Voltage (V)",
      CapacitymAh: "Capacity (mAh)",
      Type: "Type",
      WithoutNewBatteries:
        "Without new batteries we can't guarantee energy capacity for the entire freight and take no responsibility for loss of data.",
      Form: "Form",
      Alkaline: "Alkaline",
      Lithium: "Lithium",
      ConfigPassword: "Config Password",
      RestrictsChangesToDeviceConfig: "Restricts changes to device config",
      UseConfigPasswordIfYouWant:
        "Use config password if you want to protect the device from being reconfigured without authorization.",
      KeepExisting: "Keep existing",
      Disabled: "Disabled",
      ReadPassword: "Read Password",
      RestrictsReadingFromTheDevice: "Restricts reading from the device",
      UseReadPasswordIfYouWant:
        "Use read password if you want to protect the device from being read without authorization.",
      characters: "characters",
      and: "and",
      DataPassword: "Data Password",
      RestrictsOpeningDataFiles: "Restricts opening data files",
      UseDataPasswordIfYouWant:
        "Use data password if you want to protect the data files (DATX) from being opened without authorization.",
      RevertChanges: "Revert changes",
      AccelerationHistogram: "Acceleration Histogram",
      HistogramSettings: "Histogram settings",
      DataBins: "Data bins",
      ConfigureTheSizesOfTheDataBins: "Configure the sizes of the data bins",
      XAxis: "X-axis",
      YAxis: "Y-axis",
      ZAxis: "Z-axis",
      Shock: "Shock",
      Occurrences: "Occurrences",
      FailedToExportCsvDataNoDataAvailable:
        "Failed to export csv data. No data available",
      Fullscreen: "Fullscreen",
      PrimaryGraph: "Primary Graph",
      ResetToDefault: "Reset to default",
      Reset: "Reset",
      RemoveCard: "Remove card",
      SelectTimezone: "Select timezone",
      Region: "Region",
      HideAccelerationsWithinAlarmLimit:
        "Hide accelerations within alarm limit",
      TemperatureData: "Temperature data",
      HideTemperatureDataWithinAlarmLimit:
        "Hide temperature data within alarm limit",
      HumidityData: "Humidity data",
      HideHumidityDataWithinAlarmLimit: "Hide humidity data within alarm limit",
      AngleData: "Angle data",
      HideAngleDataWithinAlarmLimit: "Hide angle data within alarm limit",
      DashboardFilters: "Report filters",
      FilterReport: "Filter Report",
      ManageReport: "Manage Report",
      ClickHereToCreateACustomReport: "Click here to create a custom report",
      SerialNumber: "Serial Number",
      FileName: "File Name",
      DVAGraph: "DVA Graph",
      CloseTheViewOfTheSelectedGraph: "Close the view of the selected graph",
      FailedToExportDvaData: "Failed to export dva data",
      View: "View",
      StartTime: "Start time",
      EndTime: "End time",
      NoDvaEventsSelected: "No dva events selected",
      selected: "selected",
      ExportReport: "Export Report",
      ClickHereToExportTheReport: "Click here to export the report",
      UnlockFile: "Unlock File",
      PasswordIsRequiredToUnlockThisFile:
        "Password is required to unlock this file.",
      RecordingInformation: "Recording Information",
      DeactivateTheGlobalScaleSettingIn:
        "Deactivate the global scale setting in",
      GlobalGraphScaleToEdit: "Global Graph Scale to edit",
      scaleSettings: "scale settings",
      CouldntLocateFileAt: "Couldn't locate file at",
      TryOpeningItFromTheNormalFileSystem:
        "Try opening it from the normal file system.",
      SwitchZoomMode: "Switch zoom mode",
      GoToPriorZoomLevel: "Go to prior zoom level",
      RemoveCursorPosition: "Remove cursor position",
      ShowInReport: "Show in report",
      AddCards: "Add Cards",
      Search: "Search",
      AvailableCardsGivenSearchPlaced:
        "All available cards given your search query is already placed in your report, have fun!",
      AvailableCardsAlreadyPlaced:
        "All available cards is already placed in your report, have fun!",
      Channel: "Channel",
      MinValue: "Min value",
      MinTimestamp: "Min timestamp",
      MaxValue: "Max value",
      MaxTimestamp: "Max timestamp",
      AccelerationXAxis: "Acceleration x-axis",
      AccelerationYAxis: "Acceleration y-axis",
      AccelerationZAxis: "Acceleration z-axis",
      AngleX: "Angle x",
      AngleY: "Angle y",
      AngleZ: "Angle z",
      ReportName: "Report name",
      NewReport: "New Report",
      AddCardsDisabledInStandard: "Add Cards disabled in 'Standard'",
      AddCardsTo: "Add Cards to",
      NotSet: "Not set",
      StartAfterDownload: "Start after download",
      StartByButton: "Start by button",
      somethingWentWrong: "something went wrong",
      After: "After",
      RecordingParameters: "Recording Parameters",
      Landscape: "Landscape",
      Portrait: "Portrait",
      PDFOnly: "PDF Only",
      SelectedCards: "Selected Cards",
      CardOrder: "Card Order",
      Letter: "Letter",
      format: "format",
      Card: "Card",
      Page: "Page",
      DVADataAvailable: "DVA data available",
      MemUsed: "Mem. used",
      MainBat: "Main bat.",
      BackupBat: "Backup bat.",
      MoveScoreValuesToThe: "Move Score Values to the",
      ScoreValues: "Score Values",
      left: "left",
      right: "right",
      side: "side",
      CloseScoreValues: "Close Score Values",
      CurrentPosition: "Current position",
      MemoryUsed: "Memory used",
      MainBattery: "Main Battery",
      BackupBattery: "Backup Battery",
      Top10Accelerations: "Top 10 Accelerations",
      Timestamp: "Timestamp",
      Resultant: "Resultant",
      Duration: "Duration",
      EditPARX: "Edit PARX",
      CloseWindow: "Close window",
      ViewStatus: "View status",
      ReadDataFromCargologDevice: "Read data from Cargolog device",
      LoadParameterIntoCargologDevice: "Load parameter into Cargolog device",
      PrintExport: "Print/Export",
      MyDevices: "My devices",
      Admin: "Admin",
      ConnectionCenter: "Connection Center",
      QuickTour: "Quick Tour",
      QuickTourIsAnInteractiveGuide:
        "Quick Tour is an interactive guide that takes you through the most important functions of the application. Click on the button to start.",
      StartQuickTour: "Start Quick Tour",
      ShowQuickTourAtStartup: "Show Quick Tour at startup.",
      KeyboardShortcuts: "Keyboard Shortcuts",
      HelpCenter: "Help Center",
      TheFilterIsUsingAbsoluteValues:
        "The filter is using absolute values which means that positive inputs will filter both positive and negative accelerations in the graph.",
      duration: "duration",
      Filter: "Filter",
      RegistrationLimit: "Registration limit",
      AlarmLevel: "Alarm level",
      ACCELERATION: "ACCELERATION",
      TEMPERATURE: "TEMPERATURE",
      HUMIDITY: "HUMIDITY",
      ANGLE: "ANGLE",
      NoGraphSelectedSelectADva:
        "No graph selected. Select a DVA graph to compare.",
      ContentNotAvailableInOfflineMode: "Content not available in offline mode",
      Acceleration: "Acceleration",
      Humidity: "Humidity",
      Angle: "Angle",
      XAlarm: "X-alarm",
      YAlarm: "Y-alarm",
      ZAlarm: "Z-alarm",
      HighAlarm: "High alarm",
      LowAlarm: "Low alarm",
      Measurements: "Measurements",
      SuccessfullyAddedA: "Successfully added a",
      discount: "discount",
      FailedToAddDiscount: "Failed to add discount",
      SelectCompany: "Select company",
      SelectLicense: "Select License",
      AddDiscountToACompany: "Add discount to a company",
      License: "License",
      DiscountPercent: "Discount Percent",
      AddDiscount: "Add Discount",
      LicenseCreatedSuccessfully: "License created successfully",
      FailedToCreateNewLicense: "Failed to create new license",
      LicenseRequiresAtLeastOneUserRight:
        "License requires at least one user right",
      NoName: "No name",
      SelectParentCompany: "Select parent company",
      LicenseSuccessfullyUpdated: "License successfully updated",
      FailedToUpdateLicense: "Failed to update license",
      LicenseType: "License type",
      AddNewDiscount: "Add new discount",
      FilterDiscounts: "Filter Discounts",
      LastSeen: "Last seen",
      Pressure: "Pressure",
      ParametersPreview: "Parameters Preview",
      StartOnDate: "Start on date",
      RecordingPeriodWillBeginSelectedDate:
        "The recording period will begin on the selected date and time",
      RecordingPeriodWillBeginHardwareBtn:
        "The recording period will begin on hardware button press",
      StartDirectly: "Start directly",
      RecordingPeriodWillBeginDirectly:
        "The recording period will begin directly on parameter download",
      EndRecording: "End recording",
      EndOnDate: "End on date",
      RecordingPeriodWillEndSelectedDate:
        "The recording period will end on the selected date and time",
      EndAfterDuration: "End after duration",
      RecordingPeriodWillEndSelected:
        "The recording period will end after the selected duration",
      LoadParameters: "Load parameters",
      SomeParametersAreNotSupportedByYourDevice:
        "Some parameters are not supported by your device",
      InvalidParameters: "Invalid parameters",
      Load: "Load",
      Previous: "Previous",
      ClickToDownloadSelectedParameters:
        "Click here to download selected parameters into your device",
      ClickHereToGoToTheNextStep: "Click here to go to the next step",
      ProjectName: "Project Name",
      DeviceDescription: "Device Description",
      SelectDevice: "Device",
      ScheduleRecording: "Recording Schedule",
      AddParameters: "Parameters",
      TransferPrint: "Transfer",
      Serialnumber: "Serial Number",
      AddNewDevice: "Add new device",
      AddCargologDevice: "Add Cargolog Device",
      SaveParametersAsPARXFile: "Save parameters as PARX file",
      ClickHereToSaveParamsAsAFile: "Click here to save params as a file",
      none: "none",
      Files: "Files",
      RecordStart: "Record Start",
      RecordEnd: "Record End",
      ReportStart: "Report Start",
      ReportEnd: "Report End",
      ExportedBy: "Exported by",
      Project: "Project",
      DeviceId: "Device ID",
      MustHaveAtLeast6Characters:
        "Must have at least 6 characters and one number",
      EditInvoiceDetails: "Edit Invoice Details",
      InvoiceEmailIsRequired: "Invoice Email is required",
      SelectNewCompany: "Select new company",
      FailedToUpdateUserCompany: "Failed to update user company",
      SuccessfullyUpdatedUserCompany: "Successfully updated user company",
      ChangeUserCompany: "Change user company",
      UserCompany: "User company",
      MoveUser: "Move user",
      InvoiceIsDue: "Invoice is due",
      InvoiceIsOverdueSince: "Invoice is overdue since",
      DefaultInterestRateOf8: "Default interest rate of 8% will be fined.",
      QTY: "QTY",
      Products: "Products",
      UnitPrice: "Unit Price",
      CompanyAddress: "Company Address",
      InvoiceNumber: "Our Order Number",
      InvoiceDate: "Order date",
      TermsOfPayment: "Terms of payment",
      days: "days",
      YourOrderNo: "Your Order No",
      DateDue: "Date due",
      PenaltyInterest: "Penalty interest",
      OurReference: "Our reference",
      TotalAmountExclVAT: "Total amount excl. VAT",
      VATAmount: "VAT amount",
      TotalAmountInclVAT: "Total amount incl. VAT",
      ThisInvoiceWasPaidOn: "This invoice was paid on",
      Phone: "Phone",
      eMail: "e-mail",
      VATNo: "VAT no.",
      CorporateIdentityNo: "Corporate identity No",
      InvoiceNo: "Order no.",
      ExportAsPDF: "Export as PDF",
      WelcomeToTheQuickTour: "Welcome to the Quick Tour",
      FollowTheStepsOfTheQuickTour:
        "Follow the steps of the Quick Tour to get familiar with the most important functions of the application. Note that the Quick Tour window can be dragged if it's in the way.",
      InTheLicenseStoreYouCan:
        "In the License Store you can buy licenses for your users. Add the 'Free' license to the Cart and then proceed to Checkout.",
      HereYouCanAdministrateConnected:
        "Here you can administrate connected devices and read out their data. Connect a device to see all device options.",
      ReportsPage: "Reports Page",
      TheReportsPageLetsYou:
        "The Reports Page lets you open recording data files (DATX) and create reports. Start by opening a file from the 'Reports' sidebar.",
      InTheSetupYouCreate:
        "In Setup Device you create recording configurations and download them to a connected device. Select a device to continue.",
      Congratulations: "Congratulations!",
      YouHaveFinishedTheQuickTour: "You have finished the quick tour!",
      FailedToRemoveTheInvoice: "Failed to remove the invoice",
      SuccessfullyRemovedTheInvoice: "Successfully removed the order",
      DeleteInvoice: "Delete Invoice",
      AreYouSureYouWantToDelete: "Are you sure you want to delete",
      setFor: "set for",
      FailedToRemoveDiscount: "Failed to remove discount",
      SuccessfullyRemovedDiscount: "Successfully removed discount",
      DeleteDiscount: "Delete Discount",
      discountFor: "discount for",
      on: "on",
      FailedToRemoveLicense: "Failed to remove license",
      SuccessfullyRemovedLicense: "Successfully removed license",
      DeleteLicense: "Delete License",
      FailedToRemoveLicensePrice: "Failed to remove license price",
      SuccessfullyRemovedLicensePrice: "Successfully removed license price",
      Delete: "Delete",
      AreYouSureYouWantToRemoveThe: "Are you sure you want to remove the",
      fromTheStore: "from the store",
      FailedToRemoveUser: "Failed to remove user",
      DeleteUser: "Delete user",
      timestamp: "timestamp",
      Humid: "Humid",
      DuplicateFile: "Duplicate file",
      Duplicate: "Duplicate",
      DVAFrequency: "DVA Frequency",
      StartGraphAt: "Start graph at",
      OffsetInMs: "Offset in ms",
      MainGraph: "Main Graph",
      Comparing: "Comparing",
      graph: "graph",
      graphs: "graphs",
      TypeOfComparison: "Type of comparison",
      AboveAndBelow: "Above and below",
      OnTop: "On top",
      Combined: "Combined",
      SyncGraphs: "Align start",
      OpenANewFile: "Open a new file",
      DVAAndPrimaryGraphs: "DVA and Primary Graphs",
      NoGraphsToCompare: "No graphs to compare",
      SelectDVAGraph: "Select DVA graph",
      GraphType: "Graph type",
      ChannelType: "Channel type",
      SyncAllGraphs: "Align start of all open graphs",
      orSelectARecentFile: "or select a recent file",
      CreateAccount: "Create Account",
      TheSelectedFileIsEmpty: "The selected file is empty.",
      TheSelectedFileIsPasswordProtected:
        "The selected file is password protected.",
      Welcome: "Welcome",
      FailedToSignIn: "Failed to sign in",
      SignIn: "Sign in",
      PleaseEnterYourEmail: "Please enter your email",
      PleaseEnterYourPassword: "Please enter your password",
      ForgotPassword: "Forgot password?",
      DontHaveALicenseYet: "Don't have a license yet?",
      toBuyOne: "to buy one",
      NoNetworkConnectionSomeFeatures:
        "No network connection. Some features need a connection to the Internet in order to work.",
      UpdateReadyToInstall: "Update Ready To Install",
      CargologHasDownloadedANewUpdate:
        "Cargolog has downloaded a new update. Would you like to restart Cargolog and install it?",
      RestartNow: "Restart Now",
      Later: "Later",
      UpdateAvailable: "Update Available",
      CargologHasFoundANewUpdate:
        "Cargolog has found a new update. Would you like do download it now? You can continue working while the update is downloading.",
      NoUpdatesAvailable: "No Updates Available",
      YouAreUsingLatestVersionOfCargolog:
        "You are using the latest stable version of Cargolog.",
      FailedToOpenFile: "Failed to open file",
      ToggleHelpMode: "Toggle help mode",
      dur: "dur",
      sample: "sample",
      shockSpan: "Shock-span",
      amount: "amount",
      accelerations: "accelerations",
      BtnPress: "Btn Press",
      MinMaxValues: "Min/Max Values",
      theInvoice: "the invoice",
      theLicense: "the license",
      theUser: "the user",
      UnableToUploadDatx: "Unable to upload DATX file",
      OnlineFiles: "Online files",
      LastInteraction: "Last interaction",
      UploadFileToCloud: "Upload file to cloud",
      Cards: "Cards",
      UploadFilesToCloud: "Upload Files To Cloud",
      DatxFiles: "DATX-files",
      AutomaticUpload: "Automatic Upload",
      ManualUpload: "Manual Upload",
      flightMode: "Flightmode",
      EnableLte: "Enable LTE",
      EnableGps: "Enable GPS",
      PeriodicGps: "Periodic GPS",
      cpGpsMainToggleTitle: "Activate Periodic GPS Recording",
      cpGpsLogIntervalTitle: "Logging interval",
      cpGpsLogIntervalContent:
        "Set the logging intervall for sampling GPS data.",
      cpGpsStoreSpeedTitle: "GPS - Include Speed",
      cpGpsStoreSpeedContent: "Include speed when recording GPS position data.",
      cpGpsNeverSleepTitle: "Never sleep",
      cpGpsNeverSleepContent:
        "Prevents GPS from sleeping when not measuring data.",
      cpGpsNeverSleepWarning:
        "Warning! Only use this option with an external power source.",
      PeriodicLTE: "Periodic LTE",
      cpLteMainToggleTitle: "Activate Periodic LTE Sync",
      cpLteLogIntervalTitle: "Sync interval",
      cpLteLogIntervalContent: "Set the LTE sync intervall.",
      cpLteGpsRequiredTitle: "Require GPS",
      cpLteGpsRequiredContent: "Only sync if GPS position is acquired.",
      cpLteBandSelectorTitle: "LTE - Select Bands",
      cpLteBandSelectorContent:
        "Select which LTE bands the device will use when syncing.",
      advanced: "Advanced",
      transferAlarms: "Alarms",
      selectAlarmsToTransfer:
        "Select which alarms to transfer during this block",
      sendAccAlarm: "Acc",
      sendTempAlarm: "Temp",
      sendRhAlarm: "Humid",
      sendAngleAlarm: "Angle",
      lteAlarms: "LTE Alarms",
      Connection: "Connection",
      time: "Time",
      startsAfter: "Starts after",
      endsAt: "Ends at",
      start: "Start",
      end: "End",
      durationLabel: "Duration",
      StartRecording: "Start Recording",
      AddRecordingBlock: "Add Recording Block",
      CreateProject: "Create Project",
      ProjectTitleIsRequired: "Project name is required",
      ProjectTitle: "Project title",
      ProjectDescription: "Project description",
      EditProject: "Edit Project",
      Grid: "Grid",
      Table: "Table",
      CreateNewProject: "Create New Project",
      Time: "Time",
      Projects: "Projects",
      Alarms: "Alarms",
      People: "People",
      Devices: "Devices",
      AddPerson: "Add Person",
      EditProjectDetails: "Edit Project Details",
      UploadFile: "Upload File",
      AddDevice: "Add Device",
      Overview: "Overview",
      Coordinates: "Coordinates",
      Role: "Role",
      FileSize: "File Size",
      Sensor: "Sensor",
      EmptyProjects: "Empty projects",
      ActiveProjects: "Active projects",
      InactiveProjects: "Inactive projects",
      ArchivedProjects: "Archived projects",
      EmptyProject: "Empty project",
      ActiveProject: "Active project",
      InactiveProject: "Inactive project",
      ArchivedProject: "Archived project",
      Empty: "Empty",
      Active: "Active",
      Inactive: "Inactive",
      Archived: "Archived",
      SuccessfullyCreatedProject: "Successfully created project",
      UnableToCreateProject: "Unable to create project",
      NoAlarmsInProject: "No alarms in project",
      NoPeopleInProject: "No people in project",
      NoUnitsInProject: "No units in project",
      NoFilesInProject: "No files in project",
      Notes: "Notes",
      NoNotesInProject: "No notes in project",
      FilterProjects: "Filter projects",
      AddANote: "Add a Note",
      Warning: "Warning",
      ArchiveProject: "Archive Project",
      ArchiveProjectWarningExplanation:
        "Warning! Archiving a project will also remove all loggers and licenses from it. Invited users can no longer access the data.",
      FailedToRecoverProject: "Failed to recover project",
      SuccessfullyRecoveredProject: "Successfully recovered project",
      RecoverProject: "Recover Project",
      ConfirmRecovery: "Confirm recovery",
      ConfirmRecoveryOfProject: "Confirm recovery of project",
      AreYouSureYouWantToArchiveTheProject:
        "Are you sure you want to archive the project",
      FailedToArchiveProject: "Failed to archive project",
      SuccessfullyArchivedProject: "Successfully archived project",
      SuccessfullyUpdatedProject: "Successfully updated project",
      UnableToUpdateProject: "Unable to update project",
      CurrentProjectStatus: "Current project status",
      SelectiveFlightMode: "Selective Flight Mode",
      EnablesGPS: "Enables GPS",
      EnablesLTE: "Enables LTE",
      RequiresGPS: "Requires device with GPS",
      RequiresLTE: "Requires device with LTE",
      DisablesAllRadio: "Disables all radio devices",
      NoActiveAlarms: "No active alarms on this channel",
      RemindMeLater: "Remind me later",
      DontShowAgain: "Don't show again",
      Description: "Description",
      RenewSession: "Renew Session",
      SessionExpiredExplanation:
        "Your session has expired. Please enter your password to sign in again.",
      AcceptInvite: "Accept invite",
      RejectInvite: "Reject invite",
      WithdrawInvite: "Withdraw Invite",
      Invited: "Invited",
      ProjectInvites: "Project Invites",
      AlarmEmail: "Alarm Email",
      DataEmail: "Data Email",
      AddInternalUser: "Add Internal User",
      InviteExternalUser: "Invite External User",
      PendingInvitations: "Pending Invitations",
      MembersInProject: "Members In Project",
      SuccessfullySentTheRequest: "Successfully sent the request",
      FailedToSendTheRequest: "Failed to send the request",
      InviteUser: "Invite User",
      ThisUserIsInTheSameCompanyAsYou:
        "This user is in the same company as you.",
      AddUserHere: "Add User Here",
      AssignLicense: "Assign license",
      AddUser: "Add User",
      UserIsRequired: "User is required",
      SearchForUser: "Search for user",
      ThereAreNoAvailableLicensesForThisCompany:
        "There are no available licenses for this company",
      CreateMobitronLicense: "Create Mobitron License",
      NotActivated: "Not Activated",
      FailedToAddUser: "Failed to add user",
      FailedToWithdrawInvite: "Failed to withdraw invite",
      UserAddedSuccessfully: "User added successfully",
      EmailIsNotValid: "Email is not valid",
      InviteAcceptedSuccessfully: "Invite accepted successfully",
      FailedToAcceptTheInvitation: "Failed to accept the invitation",
      InviteHasBeenRejected: "Invite has been rejected",
      FailedToRejectTheInvitation: "Failed to reject the invitation",
      YouHaveAlreadySentAnInviteToThisEmail:
        "You have already sent an invite to this email",
      SearchForProject: "Search for project",
      MaxLengthOfTheTitleIs50Characters:
        "Max length of the title is 50 characters.",
      AddANewProjectNamed: "Add a new project named",
      NoExistingProjectNamed: "No existing project named",
      TextMustNotBeEmpty: "Text must not be empty",
      FailedToEditTheNote: "Failed to edit the note",
      FailedToAddNote: "Failed to add note",
      AddNote: "Add Note",
      Edited: "Edited",
      more: "more...",
      RecordingBlock: "Recording Block",
      RecordingPeriod: "Recording period",
      LTEinterval: "LTE interval",
      GPSinterval: "GPS interval",
      RadioDevicesDisabled: "Radio devices disabled",
      LTEdisabled: "LTE disabled",
      GPSdisabled: "GPS disabled",
      GPSPower: "GPS power",
      GPSNeverSleeps: "GPS never sleeps",
      RequireGPS: "Require GPS",
      OnlySyncWithPosition: "Only sync with position",
      YourCompanyIsStillMissingSomeInvoiceDetails:
        "Your company is still missing some invoice details.",
      OpenCompanySettings: "Open Company Settings",
      YouDontHaveAnyNewUnusedLicenses:
        "You don't have any new unused licenses.",
      NewLicenses: "New Licenses",
      RecentReports: "Recent reports",
      Analyze: "Analyze",
      ActiveUsers: "Active users",
      YouHave: "You have",
      userRequestToReplyTo: "user request to reply to.",
      userRequestsToReplyTo: "user requests to reply to.",
      OpenUserRequests: "Open User Requests",
      Create: "Create",
      SelectProject: "Select Project",
      ProjectNameAlreadyExists: "Project name already exists.",
      NoLicensesFound: "No licenses found",
      ContactYourCompanyAdminToGetMoreLicenses:
        "Contact your company admin to get more licenses",
      ProjectNameIsTooLong: "Project name is too long.",
      Latitude: "Latitude",
      Longitude: "Longitude",
      Speed: "Speed",
      GPStime: "GPS time",
      Location: "Location",
      NoContent: "No content",
      WelcomeToTheProjectsPage: "Welcome to the Projects page",
      ThereAreCurrentlyNoProjectsToShow:
        "There are currently no projects to show, but this is where you will see all your active and archived projects.",
      YouCanManageYourInvitesByPressingThe:
        "You can manage your invites by pressing the",
      buttonAbove: "button above.",
      OpenInReports: "Open in Reports",
      OpenInCompare: "Open in Compare",
      Notifications: "Notifications",
      DeviceName: "Device Name",
      VelocityActive: "Velocity active",
      AlarmWithVelocity: "Alarm with velocity",
      Alarm: "Alarm",
      Periodic: "Periodic",
      NoActiveLoggers: "No active loggers",
      AProjectIsRequired: "A project is required",
      MoveDeviceToAnotherProject: "Move device to another project",
      FailedToMoveTheDevice: "Failed to move the device",
      NoDevicesInProject: "No devices in project",
      TheDeviceHasBeenMovedToProject: "The device has been moved to project",
      NoData: "No data",
      OpenLocationInGoogleMaps: "Open location in Google Maps",
      Move: "Move",
      GoogleMaps: "Google Maps",
      ProjectReport: "Project Report",
      current: "current",
      NoMembersInProject: "No members in project",
      LastPosition: "Last Position",
      LastStatus: "Last Status",
      DeviceLines: "Device lines",
      ShowAlarms: "Show Alarms",
      NoAlarms: "No alarms",
      DevicesWithGpsData: "Devices with GPS data",
      RecentDevices: "Recent devices",
      ChangeProject: "Change Project",
      Positions: "Positions",
      NoPositions: "No positions",
      Velocity: "Velocity",
      PositionsForDevice: "Positions for device",
      RecentOnlineFiles: "Recent online files",
      FailedToUploadDatxFile: "Failed to upload DATX-file",
      DeletingThisOnlineFileWillRemoveItCompletely:
        "Deleting this online file will remove it completely. You will not be able to access the file again.",
      AreYouSureYouWantToDeleteTheFile:
        "Are you sure you want to delete the file",
      DeleteOnlineFile: "Delete online file",
      DeleteFileFromCloud: "Delete file from cloud",
      FailedToDeleteOnlineFile: "Failed to delete online file",
      SuccessfullyDeletedTheOnlineFile: "Successfully deleted the online file",
      SelectTheGraphsYouWantToCompareFromTheSideMenu:
        "Select the graphs you want to compare from the side menu.",
      OneOrMoreSelectedGraphsArePasswordProtected:
        "One or more selected graphs are password protected.",
      PleaseUnlockOrCloseTheFileToSee:
        "Please unlock or close the file to see your selected graphs.",
      FailedToUnlockTheFile: "Failed to unlock the file",
      StartPosition: "Start position",
      RecentFiles: "Recent files",
      Map: "Map",
      WarningTypes: "Warning types",
      YourSelectedFileHasBeenOpened: "Your selected file has been opened",
      LTEConnectionCanBeAlteredInScheduler:
        "LTE connection can be altered in scheduler.",
      LocationsNotFound: "Locations not found",
      ThereAreCurrentlyNoActiveDevicesWithGPSData:
        "There are currently no active devices with GPS data.",
      ProjectStatus: "Project status",
      ExportCard: "Export Card",
      ExportTable: "Export Table",
      ExportGraph: "Export Graph",
      OpenProject: "Open Project",
      kmph: "km/h",
      mph: "miles/h",
      SelectYourSpeedUnit: "Select your speed unit",
      map: "Map",
      mapDesc: "GPS positions and statuses",
      SelectPreviousPosition: "Select previous position",
      SelectNextPosition: "Select next position",
      PlayTheRouteOnTheMap: "Play the route on the map",
      PauseTheRouteOnTheMap: "Pause the route on the map",
      ThereAreNoGPSPositionsInThisFile:
        "There are no GPS positions in this file",
      OpenLocationInOpenStreetMap: "Open location in OpenStreetMap",
      ExternalMapLinks: "External Map Links",
      OpenExternalLinksIn: "Open external map links in",
      OpenStreetMap: "OpenStreetMap",
      Filename: "File name",
      BackToLastProject: "Back to last project",
      LicenseTypes: "License types",
      OnlyProjectLicenses: "Only project licenses",
      BuyProjectLicenses: "Buy Project Licenses",
      NoProject: "No project",
      FormatDevice: "Format device",
      DoneFormattingDevice: "Done formatting device",
      GetTime: "Get time",
      GettingTime: "Getting time",
      FailedToGetTime: "Failed to get time",
      Waiting: "Waiting",
      lteLog: "LTE log",
      Event: "Event",
      FailedToInitLTEModule: "Failed to init LTE module",
      FailedToConnectToNetwork: "Failed to connect to network",
      FailedToReachDataserver: "Failed to reach dataserver (timeout)",
      TransmissionNotCompleted: "Transmission not completed (aborted)",
      LTEAntennaError: "LTE antenna error",
      BlockedByGPS: "Blocked by no GPS position (GPS Required active)",
      FailedToInitCoMCU: "Failed to init CoMCU",
      CommunicationProblemsCoMCU: "Communication problems towards CoMCU",
      LTEPowerOn: "LTE Power On",
      LTEPowerOff: "LTE Power Off",
      LTETriggeredBySensor: "LTE connect triggered by sensor",
      LTETriggeredBySchedule: "LTE connect triggered by schedule",
      gpsStatusFailedToInitGPSModule: "Failed to init GPS module",
      gpsStatusFailedToGetPosition: "Failed to get position (timeout)",
      antennaError: "Antenna error",
      gpsStatusFailedToInitCoMCU: "Failed to init CoMCU",
      gpsStatusCommunicationProblemsTowardsCoMCU:
        "Communication problems towards CoMCU",
      gpsStatusFailedToGetGPSPosition:
        "Failed to get GPS position (error response)",
      gpsStatusGPSPowerOn: "GPS Power On",
      gpsStatusGPSPowerOff: "GPS Power Off",
      TriggeredBySensor: "Triggered by sensor",
      TriggeredBySchedule: "Triggered by schedule",
      AccSensor: "Acc",
      TempSensor: "Temp",
      RhSensor: "Humidity",
      AngleSensor: "Angle",
      SensorConnectionMissing: "Sensor connection missing",
      lte: "LTE",
      BlockLte: "Block LTE",
      BlockGps: "Block GPS",
      BlocksLte: "Blocks LTE",
      BlocksGps: "Blocks GPS",
      FailedToSyncRecentDeviceWithServer:
        "Failed to sync recent device with server",
      GpsData: "GPS data",
      HideGPSStatuses: "Hide GPS statuses",
      HideGPSPositionsTriggeredBySensor:
        "Hide GPS positions triggered by sensor",
      HideGPSPositionsTriggeredBySchedule:
        "Hide GPS positions triggered by schedule",
      RecordingBlocks: "Recording Blocks",
      CancelSubscription: "Cancel Subscription",
      RenewLicense: "Renew License",
      DeactivateLicense: "Deactivate License",
      SummaryOfMeasurementPeriod: "Summary of the measurement period",
      NoDeviceSelected: "No device selected",
      openFileStatusDownload: "Downloading DATX file",
      Stocked: "Stocked",
      FinishTransport: "Finish transport",
      FailedToFinishTransport: "Failed to finish transport",
      FinishedTransport: "Finished transport",
      FinishTransportWarningText:
        "This finishes the current transport and the device will be available for other transports. Are you sure you want to finish the transport?",
      DeviceStatus: "Device status",
      StatusActive: "Active",
      StatusFinished: "Finished",
      FailedToDownloadDatxFile: "Failed to download DATX file",
      lteDesc: "Table of LTE statuses",
      quickReportDesc: "Simple report of alarms in the recording",
      cpLteNeverSleepTitle: "Never sleep",
      cpLteNeverSleepContent: "Prevents LTE from sleeping when not in use.",
      cpLteNeverSleepWarning:
        "Warning! Only use this option with an external power source.",
      LTEPower: "LTE power",
      LTENeverSleeps: "LTE never sleeps",
      RenewalCheckout: "License Renewal Checkout",
      theOrder: "the order",
      placedBy: "placed by",
      DeleteOrder: "Delete Order",
      OrderRecieved: "Order Recieved",
      CouldNotCreatePayPalPlan: "Could not create PayPal plan",
      PaymentType: "Payment Type",
      Order: "Order",
      PayPalPaymentCancelled: "PayPal payment cancelled.",
      SomethingWentWrongWithYourPayment:
        "Something went wrong with your payment.",
      ProjectCreatedBy: "Project created by",
      ExternalPowerSourceRequired: "External power source required",
      NextCalibrationWithin3Months: "Calibration within 3 months",
      NoCalibrationsNeeded: "No calibrations needed",
      OpeningFile: "Opening file...",
      LTESessionEnded: "LTE session ended",
      Impersonate: "Impersonate",
      Exit: "Exit",
      ChangingTheMonthValueWillEmptyYourCart:
        "Changing the month value will empty your cart",
      YouCanOnlyPurchaseLicensesWith:
        "You can only purchase licenses with the same subscription length per order. If you want different lengths you have to put them in seperate orders.",
      Impersonating: "Impersonating",
      ImpersonateSessionHasEnded:
        "Impersonate session has ended. Please sign in and start a new impersonation session.",
      SelectiveFlightmode: "Selective flightmode",
      UnreadDataAvailable: "Unread Data Available",
      Loading: "Loading",
      PasswordTypeNeeded: "Password type needed",
      ReadOrConfigPassword: "Read or config password",
      PasswordIncorrect:
        "The currently used device password is incorrect or lacks the rights to perform this action. Please enter the correct password to proceed.",
      FailedToReadRecordingParameters: "Failed to read recording parameters",
      IfDeviceWasRecentlyFormattedItMightNotHaveAnyParameters:
        "If the device was recently formatted it might not have any parameters",
      ButtonPressed: "Button Pressed",
      Recordings: "Recordings",
      NoDescription: "No description",
      NewRecording: "New Recording",
      NoDataUploaded: "No data uploaded",
      RecordingActions: "Recording Actions",
      RecordingDescription: "Recording Description",
      Device: "Device",
      None: "None",
      OnData: "On new data",
      OnAlarm: "On data with alarm",
      YouHavePendingFileUploads: "You have pending file uploads",
      Review: "Review",
      PendingFileUploads: "Pending file uploads",
      RemoveFirstFileAndTryAgain: "Remove first file and try again",
      AdminEmail: "Admin Email",
      YouDontHaveLicense: "You don't have a license assigned.",
      ContactYourAdminAt: "Contact your admin at",
      Expired: "Expired",
      ExternalTimer: "External Timer",
      ExternalInput: "External Input",
      ExternalOutput: "External Output",
      extTimer: "External Timer",
      extTimerDesc: "Table of external timers",
      extIO: "External Triggers",
      extIODesc: "Table of external inputs and outputs",
      ChangedTo: "Changed to",
      generalExtIOTitle: "External IO",
      generalTriggersTitle: "Triggers",
      ExternalInputs: "External inputs",
      ExternalOutputs: "External outputs",
      UseInput: "Use Input",
      NameOfInput: "Name of Input",
      SaveChangesToData: "Save Changes to Data",
      NoExternalInputsAvailable: "No external inputs available",
      UseOutput: "Use Output",
      NameOfOutput: "Name of Output",
      StartAs: "Start As",
      NoExternalOutputsAvailable: "No external outputs available",
      TriggOn: "Trigg On",
      DF_EXT_IO: "External IO",
      SomeParametersHaveBeenChanged:
        "Some parameters have been altered for PARX to be compatible with selected device",
      DownloadUpdate: "Download Update",
      DeviceReset: "Device Reset",
      TamperingDetected: "Tampering Detected",
      RecordingStarted: "Recording Started",
      RecordingEnded: "Recording Ended",
      MemoryFull: "Memory Full",
      WriteError: "Write Error",
      RecordingStatus: "Recording Status",
      Never: "Never",
      genNo: "No",
      Impact: "Impact",
      On: "On",
      Off: "Off",
      HorizonXAxis: "Horizon x-axis",
      HorizonYAxis: "Horizon y-axis",
      SelectedDevice: "Selected Device",
      Output: "Output",
      Input: "Input",
      TriggerChannel: "Trigger Channel",
      PressureSensor: "Pressure Sensor",
      PressureData: "Pressure data",
      HidePressureDataWithinAlarmLimit: "Hide pressure data within alarm limit",
      TempComp: "Temp. comp.",
      RawPress: "Raw press.",
      RawPressure: "Raw Pressure",
      CompensatedPressure: "Compensated Pressure",
      ShowMap: "Show Map",
      ImportFromFile: "Import from file",
      ParametersBasedOn: "Parameters based on",
      //temperature
      cpPressureMainToggleTitle: "Activate pressure recording",
      cpPressureLogIntervalTitle: "Logging interval",
      cpPressureLogIntervalContent:
        "Set the logging intervall for sampling data.",
      cpPressureStoreGpsPosTitle: "Store GPS position",
      cpPressureStoreGpsPosContent:
        "Record the GPS position when alarm level exceeded for pressure.",
      cpPressureAlarmLevelTitle: "Alarm level",
      cpPressureAlarmLevelContent:
        "Set the alarm level to indicate lower and upper exceeded limit value",
      cpPressureSendGsmAlarmTitle: "Send LTE alarm",
      cpPressureSendGsmAlarmContent:
        "Send recorded data with LTE communication at exceeded alarm level for pressure.",
      cpPressureIgnoreAlarmTitle: "Ignore alarm",
      cpPressureIgnoreAlarmContent:
        "Save energy and set how often alarm levels should be ignored.",
      generalPressureTitle: "Pressure",
      defaultBattery: "Default battery",
      selectDefaultBattery: "Select default battery",
      Slope: "Slope",
      Temp: "Temp",
      TempCompensation: "Temp compensation",
      TempCompensationExplanation:
        "Compensate pressure alarms to take temperature gas expanssion into account.",
      SlopeCalculator: "Slope Calculator",
      ApplySlope: "Apply Slope",
      SlopeCalculationExplanation:
        "Enter two known temperature and pressure values to calculate the slope.",
      Temp1: "Temp 1",
      Temp2: "Temp 2",
      Pressure1: "Pressure 1",
      Pressure2: "Pressure 2",
      CalculatedSlope: "Calculated Slope",
      CompensationSlope: "Temperature Compensation Slope",
      AlarmLevelOrigo: "Alarm level origo",
      DF_ANGLE_SENSOR: "Angle sensor",
      xAcc_tooltip: "X Acceleration",
      yAcc_tooltip: "Y Acceleration",
      zAcc_tooltip: "Z Acceleration",
      temp_tooltip: "Temperature",
      rh_tooltip: "Humidity",
      pressureRaw_tooltip: "Pressure (Raw)",
      pressureComp_tooltip: "Pressure (Compensated)",
      angle_tooltip: "Angle",
      extInput_tooltip: "External Input",
      extOutput_tooltip: "External Output",
      extTimer_tooltip: "External Timer",
      cpExternalSensorRhAlarmLevelTitle: "Humidity alarm level",
      cpExternalSensorTempAlarmLevelTitle: "Temperature alarm level",
      generalExtSensorsTitle: "External Sensors",
      angle: "Angle",
      angleDesc: "Table of angle data",
      RollAngle: "Roll angle (X)",
      NickAngle: "Nick angle (Y)",
      YawAngle: "Yaw angle (Z)",
      DeleteCompany: "Delete Company",
      AccessToProjectsPage: "Access to Projects Page",
      OpenReportDataFromProject: "Open Report Data from Project",
      EditProjectDetailsInvitesNotifications:
        "Edit Project Details, Invites and Notifications",
      ArchiveAndRecoverProjects: "Archive and Recover Projects",
      AssignNameToSensorNr: "Assign name to sensor nr ",
      ExternalSensorsInfoText:
        "External sensors can be dynamically added and configured. Select a sensor in the dropdown to add it to the configuration.",
      RecordingCreatedDetails: "Recording created details",
      LatestUploadDetails: "Latest upload details",
      CreatedBy: "Created by",
      LatestUploadBy: "Latest upload by",
      ExternalUser: "External User",
      LatestUpload: "Latest upload",
      RecordingStart: "Recording start",
      PdfExportPreview: "PDF Export Preview",
      FailedToDeleteCompany: "Failed to delete company",
      SuccessfullyDeletedCompany: "Successfully deleted company",
      TheCompany: "the company",
      AssignANameToTheSensor: "Assign a name to the sensor",
      ExternalTemperature: "External Temperature",
      ExternalHumidity: "External Humidity",
      RhTempSensor: "RH/Temp Sensor",
      NoSuitablePowerAvailable: "No suitable power available",
      NoSIMFound: "No SIM found",
      ExtSensMsg: "External sensor message",
      AccelerationData: "Acceleration data",
      SelectLastPosition: "Select last position",
      TransportReport: "Transport Report",
      HideDataWithinAlarmLimitFor: "Hide data within alarm limit for",
      NumberAbbrevation: "No.",
      ExternalSensors: "External Sensors",
      OpenLicenses: "Open Licenses",
      ExpiredLicenses: "Expired Licenses",
      TheFollowingLicensesHaveExpired:
        "The following licenses connected to your account have expired.",
      RenewLicenseToContinueUsing:
        "Please renew them to continue using the features or remove them from your account.",
      AutoRenewal: "Auto renewal",
      ToManageYourLicenses: "To manage your licenses, contact your admin at",
      YouDontHaveAdminRights: "You don't have admin rights on your account.",
      UseParameterAlarms: "Use parameter alarms",
      ResetValues: "Reset values",
      TimeoutInCommunicationWithDevice: "Timeout in communication with device",
      PulseCapability: "Pulse capability (mA)"
    }
  }
} as const;

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
type DictionaryType = typeof resources.en.translations;
export type DictionaryTransKeys = keyof DictionaryType;
export const defaultNS = "translations";
i18n.use(initReactI18next).init({
  resources,
  returnNull: false,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  debug: false,
  ns: ["translations"],
  defaultNS
});
export default i18n;
