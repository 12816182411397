import React, { useState } from "react";
import { Form, Row, Col, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  Company,
  useUpdateCompanyDetailsMutation
} from "../../state/cargologRestApi";
import { closeEditCompanyModal, modalsState } from "../../state/modalsSlice";
import { countries } from "../../constants/countries";
import StandardModal from "../Common/StandardModal";
const { Option } = Select;

// Main component
const EditCompanyModal = () => {
  //edit to companySlice State
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modals = useSelector(modalsState);
  const usersCompany = modals.companyObject;

  const countryOptions = countries.map((country) => {
    return (
      <Option key={country.code} value={country.code}>
        {country.name}
      </Option>
    );
  });

  let company: Company = {
    id: "",
    name: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    countryCode: "",
    orgNumber: "",
    vatNumber: "",
    invoiceEmail: "",
    invoiceAddress: "",
    invoicePostalCode: "",
    invoiceCity: "",
    parentCompanyId: "",
    adminEmail: ""
  };

  if (!isUndefined(usersCompany)) {
    company = usersCompany;
  }

  /* Request for update Company Properties */
  const [updateCompanyDetails, requestStatus] =
    useUpdateCompanyDetailsMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleUpdateCompanyDetails = async (request: Company) => {
    const result: any = await updateCompanyDetails(request);
    if (result.data) {
      notification.success({ message: t("EditCompanySuccessMessage") });
      setName(undefined);
      setAddress(undefined);
      setAddress2(undefined);
      setPostalCode(undefined);
      setCity(undefined);
      setCountryCode(undefined);
      setOrgNumber(undefined);
      setAdminEmail(undefined);
      dispatch(closeEditCompanyModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("EditCompanyErrorMessage"),
        description: errorDescription
      });
    }
  };

  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [address2, setAddress2] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [city, setCity] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [orgNumber, setOrgNumber] = useState<string>();
  const [adminEmail, setAdminEmail] = useState<string>();

  const handleCountryCodeChange = (value: string) => {
    setCountryCode(value);
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    const id = company.id;
    const newName = !isUndefined(name) ? name : company.name;
    const newAddress = !isUndefined(address) ? address : company.address;
    const newAddress2 = !isUndefined(address2) ? address2 : company.address2;
    const newPostalCode = !isUndefined(postalCode)
      ? postalCode
      : company.postalCode;
    const newCity = !isUndefined(city) ? city : company.city;
    const newCountryCode = !isUndefined(countryCode)
      ? countryCode
      : company.countryCode;
    const newOrgNumber = !isUndefined(orgNumber)
      ? orgNumber
      : company.orgNumber;
    const newAdminEmail = !isUndefined(adminEmail)
      ? adminEmail
      : company.adminEmail;

    if (
      !isUndefined(newName) &&
      !isUndefined(newAddress) &&
      !isUndefined(newPostalCode) &&
      !isUndefined(newCity) &&
      !isUndefined(newCountryCode)
    ) {
      const request: Company = {
        id,
        name: newName,
        address: newAddress,
        address2: newAddress2,
        postalCode: newPostalCode,
        city: newCity,
        countryCode: newCountryCode,
        orgNumber: newOrgNumber,
        adminEmail: newAdminEmail,
        vatNumber: company.vatNumber,
        invoiceEmail: company.invoiceEmail,
        invoiceAddress: company.invoiceAddress,
        invoicePostalCode: company.invoicePostalCode,
        invoiceCity: company.invoiceCity,
        parentCompanyId: company.parentCompanyId
      };
      handleUpdateCompanyDetails(request);
    }
  };
  // When user tries to finish form, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      {/* Update company modal */}
      <StandardModal
        title={t("EditCompanyDetails")}
        zIndex={1045}
        open={modals.isEditCompanyModalOpen}
        onCancel={() => dispatch(closeEditCompanyModal())}
        closable={true}
        footer={null}
        width={400}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={changeFinished}
          onFinishFailed={changeFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("genName")}
                name="name"
                rules={[{ required: true, message: t("NameIsRequired") }]}
                initialValue={!isUndefined(company.name) ? company.name : ""}
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={name}
                  maxLength={60}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("Address")}
                name="address"
                rules={[{ required: true, message: t("AddressIsRequired") }]}
                initialValue={
                  !isUndefined(company.address) ? company.address : ""
                }
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={address}
                  maxLength={60}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("Address2")}
                name="address2"
                initialValue={
                  !isUndefined(company.address2) ? company.address2 : ""
                }
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={address2}
                  maxLength={60}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("PostalCode")}
                name="postalCode"
                rules={[{ required: true, message: t("PostalCodeIsRequired") }]}
                initialValue={
                  !isUndefined(company.postalCode) ? company.postalCode : ""
                }
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={postalCode}
                  maxLength={20}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("City")}
                name="city"
                rules={[{ required: true, message: t("CityIsRequired") }]}
                initialValue={!isUndefined(company.city) ? company.city : ""}
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={city}
                  maxLength={60}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("Country")}
                name="countryCode"
                initialValue={
                  !isUndefined(company.countryCode) ? company.countryCode : ""
                }
                rules={[{ required: true, message: t("CountryIsRequired") }]}
                style={{ marginBottom: size.s1 }}
              >
                <Select
                  showSearch
                  placeholder={t("SearchToSelect")}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option?.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                    return false;
                  }}
                  filterSort={(optionA, optionB) => {
                    if (optionA.children && optionB.children) {
                      return optionA.children
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          optionB.children.toString().toLowerCase()
                        );
                    }
                    return 0;
                  }}
                  onChange={handleCountryCodeChange}
                >
                  {countryOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("OrgNumber")}
                name="orgNumber"
                initialValue={
                  !isUndefined(company.orgNumber) ? company.orgNumber : ""
                }
                rules={[
                  {
                    required: countryCode === "SE",
                    message: t("OrgNumberRequiredInSweden")
                  }
                ]}
                style={{ marginBottom: size.s1 }}
              >
                <Input
                  placeholder=""
                  value={orgNumber}
                  onChange={(e) => setOrgNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={size.m1}>
            <Col span={24}>
              <Form.Item
                label={t("AdminEmail")}
                name="adminEmail"
                initialValue={
                  !isUndefined(company.adminEmail) ? company.adminEmail : ""
                }
              >
                <Input
                  placeholder=""
                  value={adminEmail}
                  onChange={(e) => setAdminEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("SaveChanges")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default EditCompanyModal;
