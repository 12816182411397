import {
  ceil,
  find,
  findLast,
  floor,
  head,
  isNil,
  last,
  min,
  max,
  maxBy,
  mean,
  minBy,
  range,
  round,
  uniq,
  isUndefined,
  sortBy
} from "lodash-es";
import dayjs, { Dayjs, unix } from "dayjs";
import {
  ExternalSensorDataSeries,
  GraphDataPoint,
  NullableGraphDataPoint,
  PrimaryGraphData
} from "../components/GraphPage/PrimaryGraph";
import { VMAccParams } from "../models/ViewModelRecordingParameters/VMAccParams";
import VMAngleParams from "../models/ViewModelRecordingParameters/VMAngleParams";
import { VMRecParams } from "../models/ViewModelRecordingParameters/VMRecParams";
import { VMRhParams } from "../models/ViewModelRecordingParameters/VMRhParams";
import { VMTempParams } from "../models/ViewModelRecordingParameters/VMTempParams";
import {
  CommonDataFilter,
  DataFilterStates,
  IDatxContent,
  ItemsForGraph,
  YAxisDomain,
  YAxisLowHigh,
  YAxisTickCount
} from "../state/openDatxSlice";
import { Optional } from "../utils/utilTypes";
import { VMDvaData } from "./dataModelHelper";
import {
  DtChannel,
  RecordingDataBlock,
  RecordingDataBlockFiltered,
  RecordingDataBlockUnique
} from "./datasetHelper";
import { createNormalDateInTz, createTzDate, dateToUnix } from "./dateHelper";
import { IDtDva } from "./parsers/parseDvaHelper";
import { size } from "./pageHelper";
import { VMPressureParams } from "../models/ViewModelRecordingParameters/VMPressureParams";
import { dtHasEsti } from "../models/DTTypes";

/** Available axies for accelerations */
export type AccelerationAxis = "x" | "y" | "z";

/** Zoom domain type used for most victory graphs */
export type PrimaryGraphZoomDomain = {
  x: [Date, Date];
  y: [number, number];
};

/** Available zoom dimension. Zoom in x-axis or both x/y-axis */
export type ZoomDimension = "x" | "xy";

/** Converts ZoomDimension type to its equivalent victory chart prop */
export const zoomDimensionToVictoryProp = (dim?: ZoomDimension) =>
  dim === "x" ? "x" : undefined;

/** Type used for functions searching for the closest value to timestamp
 * (unix) for the channel given a dataset */
type ScoreValuesTarget = {
  channel: DtChannel;
  timestamp: number;
  dataset: RecordingDataBlockFiltered[];
  esti?: number;
};

/** The default y-span when using normalized values */
export const yAxisDefaultNormalizedValues: [number, number] = [-1, 1];

/** Common props for victory charts */
export const commonPrimaryGraphProps = {
  chartPadding: {
    top: size.l3,
    right: size.m1,
    bottom: size.l3
  },
  overviewPadding: {
    top: 15,
    right: size.m1,
    bottom: 25
  },
  // Setting domain padding for x to prevent data to be cut off at the end
  domainPadding: { x: 5, y: 0 }
};

// Dataset that is friendly for victory graphs

/**
 * Creates a dataset for acceleration data that can be shown in the graph.
 * Accelerations with a duration of 0 will not be included in the final
 * dataset. If the datachannel is toggled of, an empty array will be returned
 * @param dataChannel
 * @param dataset
 * @param filter
 * @param timezone
 */
const createAccDataForGraph = (
  dataChannel: keyof Pick<
    RecordingDataBlockFiltered,
    "xAcc" | "yAcc" | "zAcc" | "accComponent"
  >,
  dataset: RecordingDataBlockFiltered[],
  filter: CommonDataFilter,
  timezone: string
): GraphDataPoint[] => {
  const { isActive } = filter.dataToggle;

  if (!isActive) {
    return [];
  }

  // The timezone calculation is heavy, so we only want to do it once
  const baseTimestamp = dataset[0].timestamp;
  const baseDate = createTzDate(baseTimestamp, timezone);
  return dataset.reduce((arr: GraphDataPoint[], item) => {
    const accValue = item[dataChannel];

    //Note: If duration is 0, the value should not be shown in the graph. This
    //is what we call a "snapshot value"
    if (accValue === undefined || accValue?.[1] === 0) {
      return arr;
    }

    const timestampDiff = item.timestamp - baseTimestamp;
    const x = baseDate.add(timestampDiff, "seconds").toDate();
    const y = accValue;

    arr.push({ x, y });

    return arr;
  }, []);
};

/**
 * Returns a dataset of type NullableGraphDataPoint. If the channel isn't
 * active, an empty array will be returned
 * @param dataset
 * @param filter
 * @param timezone
 */
const createTempDataForGraph = (
  dataset: RecordingDataBlockFiltered[],
  filter: CommonDataFilter,
  timezone: string
) => createNullableGraphPointSet("temp", dataset, filter, timezone);

/**
 * Returns a dataset of type NullableGraphDataPoint. If the channel isn't
 * active, a empty array will be returned
 * @param dataset
 * @param filter
 * @param timezone
 */
const createRhDataForGraph = (
  dataset: RecordingDataBlockFiltered[],
  filter: CommonDataFilter,
  timezone: string
) => createNullableGraphPointSet("rh", dataset, filter, timezone);

/**
 * Returns a dataset of type NullableGraphDataPoint. If the channel isn't
 * active, a empty array will be returned
 * @param dataset
 * @param filter
 * @param timezone
 */
const createPressureDataForGraph = (
  dataChannel: keyof Pick<
    RecordingDataBlockFiltered,
    "pressureRaw" | "pressureComp"
  >,
  dataset: RecordingDataBlockFiltered[],
  filter: CommonDataFilter,
  timezone: string
) => createNullableGraphPointSet(dataChannel, dataset, filter, timezone);

// Exported for testing
export const createExternalSensorDataForGraph = (
  dataChannel: keyof Pick<
    RecordingDataBlockFiltered,
    "externalRh" | "externalTemp"
  >,
  dataset: RecordingDataBlockFiltered[],
  filter: Record<string, CommonDataFilter>,
  timezone: string
): ExternalSensorDataSeries[] | undefined => {
  let externalSensors: ExternalSensorDataSeries[] = [];
  dataset.forEach((item) => {
    const { timestamp } = item;
    const itemData = item[dataChannel];
    if (isNil(itemData)) return;

    let yValue: number | null = null;
    if (dataChannel === "externalRh" && item.externalRh) {
      yValue = item.externalRh.rh;
    } else if (dataChannel === "externalTemp" && item.externalTemp) {
      yValue = item.externalTemp.temp;
    }
    const dataPoint = {
      x: createNormalDateInTz(timestamp, timezone),
      y: isNil(yValue) ? null : [yValue]
    };

    const { sensorId } = itemData;

    // If the sensor is not toggled on, skip the data
    const sensorFilter = filter[sensorId];
    if (!sensorFilter.dataToggle.isActive) return;

    // Split the data by sensorId
    const existingData = externalSensors.find((s) => s.sensorId === sensorId);
    if (existingData) {
      existingData.data.push(dataPoint);
    } else {
      externalSensors.push({
        sensorId,
        data: [dataPoint]
      });
    }
  });
  return externalSensors;
};

/**
 * Returns a dataset of type NullableGraphDataPoint. If the channel isn't
 * active, a empty array will be returned
 * @param dataChannel
 * @param dataset
 * @param filter
 * @param timezone
 */
const createNullableGraphPointSet = (
  dataChannel: keyof Pick<
    RecordingDataBlockFiltered,
    "temp" | "rh" | "pressureRaw" | "pressureComp"
  >,
  dataset: RecordingDataBlockFiltered[],
  filter: CommonDataFilter,
  timezone: string
): NullableGraphDataPoint[] => {
  const { isActive } = filter.dataToggle;

  if (!isActive) {
    return [];
  }

  const baseTimestamp = dataset[0].timestamp;
  const baseDate = createTzDate(baseTimestamp, timezone);
  return dataset.reduce((arr: NullableGraphDataPoint[], item) => {
    const dataValue = item[dataChannel];

    if (dataValue === undefined) {
      return arr;
    }

    const timestampDiff = item.timestamp - baseTimestamp;
    const x = baseDate.add(timestampDiff, "seconds").toDate();
    const y = dataValue === null ? null : [dataValue];

    arr.push({ x, y });

    return arr;
  }, []);
};

/**
 * Returns a tuple based on the argument tuple where the fst value is less than
 * snd value.
 * E.g. [3, 6] => [3, 6]
 *      [7, 4] => [4, 7]
 * @param param0 A tuple of 2 values
 */
export const toLowHighOrder = <T>([fst, snd]: [T, T]): [T, T] =>
  fst > snd ? [snd, fst] : [fst, snd];

/**
 * Returns the entire measure interval programmed by the user as a Dayjs tuple
 * @param recParams
 */
export const getEntireDomainDayjsTuple = (
  recParams: Required<VMRecParams>
): [Dayjs, Dayjs] => {
  const startDate = dayjs.unix(recParams.startTimestamp);
  const stopDate = dayjs.unix(recParams.stopTimestamp);
  return [startDate, stopDate];
};

/**
 * Returns the entire measure interval programmed by the user as a date tuple
 * @param recParams
 */
export const getEntireDomainDateTuple = (
  recParams: Required<VMRecParams>
): [Date, Date] => {
  const entireDomain = getEntireDomainDayjsTuple(recParams);
  return [entireDomain[0].toDate(), entireDomain[1].toDate()];
};

/**
 * Returns the domain that contains data. If there is no data [0, 0] is
 * returned. If the difference between start and end is below 6 seconds, 3
 * seconds of padding is added to each side resulting in a domain which is above
 * 6 seconds.
 * @param data an already sorted data-set
 */
export const getDomainWithData = (
  data: RecordingDataBlockUnique[]
): [number, number] => {
  //empty data found
  if (data.length === 0) return [0, 0];

  const startTimestamp = data[0].timestamp;
  const endTimestamp = data[data.length - 1].timestamp;

  // if the difference between start and end is below 6 secs, some extra
  // seconds to each side is added to the domain
  if (endTimestamp - startTimestamp < 6) {
    return [startTimestamp - 3, endTimestamp + 3];
  }

  return [startTimestamp, endTimestamp];
};

/**
 * Returns the domain that contains data. If there is no data [0, 0] is
 * returned. If the difference between start and end is below 6 seconds, 3
 * seconds of padding is added to each side resulting in a domain which is above
 * 6 seconds.
 * @param data an already sorted data-set
 */
export const getCompareDomainWithData = (
  data: RecordingDataBlockUnique[]
): [Date, Date] => {
  //empty data found
  if (data.length === 0) return [unix(0).toDate(), unix(0).toDate()];

  const startTimestamp = data[0].timestamp;
  const endTimestamp = data[data.length - 1].timestamp;

  // if the difference between start and end is below 6 secs, some extra
  // seconds to each side is added to the domain
  if (endTimestamp - startTimestamp < 6) {
    return [unix(startTimestamp - 3).toDate(), unix(endTimestamp + 3).toDate()];
  }

  return [unix(startTimestamp).toDate(), unix(endTimestamp).toDate()];
};

/**
 * Create a primary-graph-data object that can be shown in a graph
 * @param param0
 */
export const createPrimaryGraphData = ({
  filters,
  yAxisDomain,
  yAxisTickCount,
  datxContent,
  timezone
}: ItemsForGraph): PrimaryGraphData => {
  const { recordingParameters } = datxContent;

  const xAcc = createAccDataForGraph(
    "xAcc",
    datxContent.data,
    filters.xAcc,
    timezone
  );

  const yAcc = createAccDataForGraph(
    "yAcc",
    datxContent.data,
    filters.yAcc,
    timezone
  );

  const zAcc = createAccDataForGraph(
    "zAcc",
    datxContent.data,
    filters.zAcc,
    timezone
  );

  const componentFilters = {
    hideDataWithinAlarmLevel: true,
    dataToggle: { isActive: true, isUsed: true }
  };
  const accComponent = createAccDataForGraph(
    "accComponent",
    datxContent.data,
    componentFilters,
    timezone
  );

  const accData =
    xAcc.length + yAcc.length + zAcc.length === 0
      ? undefined
      : { xAcc, yAcc, zAcc, accComponent };

  const tempData = createTempDataForGraph(
    datxContent.data,
    filters.temp,
    timezone
  );

  const rhData = createRhDataForGraph(datxContent.data, filters.rh, timezone);

  const pressureRaw = createPressureDataForGraph(
    "pressureRaw",
    datxContent.data,
    filters.pressureRaw,
    timezone
  );

  const pressureComp = createPressureDataForGraph(
    "pressureComp",
    datxContent.data,
    filters.pressureComp,
    timezone
  );

  const pressureData =
    pressureRaw.length + pressureComp.length === 0
      ? undefined
      : { pressureRaw, pressureComp };

  const externalTemp: ExternalSensorDataSeries[] | undefined =
    createExternalSensorDataForGraph(
      "externalTemp",
      datxContent.data,
      filters.extTemp,
      timezone
    );

  const externalRh: ExternalSensorDataSeries[] | undefined =
    createExternalSensorDataForGraph(
      "externalRh",
      datxContent.data,
      filters.extRh,
      timezone
    );

  return {
    timezone,
    dataFilters: filters,
    yAxisDomain,
    yAxisTickCount,
    recParams: recordingParameters,
    accData,
    tempData,
    rhData,
    pressureData,
    externalTemp,
    externalRh
  };
};

/**
 * Return data filter states based on available data in the dataset. If a data
 * channel is present in the dataset, the toggle will be on.
 * @param dataset
 */
export const createDatxFiltersBasedOnData = (
  dataset: RecordingDataBlockUnique[]
): DataFilterStates => {
  /** Predicate to check if a {key} is in a recording block */
  const hasKey =
    (
      key: keyof Pick<
        RecordingDataBlockUnique,
        | "temp"
        | "rh"
        | "pressureRaw"
        | "pressureComp"
        | "angle"
        | "gps"
        | "gpsStatus"
        | "externalInputs"
        | "externalOutputs"
        | "externalTimers"
        | "externalRh"
        | "externalTemp"
      >
    ) =>
    (item: RecordingDataBlockUnique) =>
      item[key];

  /** Predicate to check if a {accKey} is in a recording block and has a duration */
  const hasAccWithDur =
    (accKey: keyof Pick<RecordingDataBlockUnique, "xAcc" | "yAcc" | "zAcc">) =>
    (item: RecordingDataBlockUnique) =>
      item[accKey] && item[accKey]?.[1] !== 0;

  const shouldIncludeXAcc = !dataset.find(hasAccWithDur("xAcc")) ? false : true;
  const shouldIncludeYAcc = !dataset.find(hasAccWithDur("yAcc")) ? false : true;
  const shouldIncludeZAcc = !dataset.find(hasAccWithDur("zAcc")) ? false : true;
  const shouldIncludeTemp = !dataset.find(hasKey("temp")) ? false : true;
  const shouldIncludeRh = !dataset.find(hasKey("rh")) ? false : true;
  const shouldIncludePressureRaw = !dataset.find(hasKey("pressureRaw"))
    ? false
    : true;
  const shouldIncludePressureComp = !dataset.find(hasKey("pressureComp"))
    ? false
    : true;
  const shouldIncludeAngle = !dataset.find(hasKey("angle")) ? false : true;
  const shouldIncludeGps =
    !dataset.find(hasKey("gps")) && !dataset.find(hasKey("gpsStatus"))
      ? false
      : true;
  const shouldIncludeExtInput = !dataset.find(hasKey("externalInputs"))
    ? false
    : true;
  const shouldIncludeExtOutput = !dataset.find(hasKey("externalOutputs"))
    ? false
    : true;
  const shouldIncludeExtTimer = !dataset.find(hasKey("externalTimers"))
    ? false
    : true;
  const shouldIncludeExtRh = !dataset.find(hasKey("externalRh")) ? false : true;
  const shouldIncludeExtTemp = !dataset.find(hasKey("externalTemp"))
    ? false
    : true;

  let extRh: Record<string, CommonDataFilter> = {};
  if (shouldIncludeExtRh) {
    // Get all unique external RH sensorIds
    const uniqueSensorIds = uniq(
      dataset.map((data) => data.externalRh?.sensorId).filter((id) => id)
    );

    // Create a filter for each unique sensorId
    extRh = Object.fromEntries(
      uniqueSensorIds.map((id) => [
        id!.toString(),
        {
          dataToggle: {
            isActive: true,
            isUsed: true
          },
          hideDataWithinAlarmLevel: false
        }
      ])
    );
  }

  let extTemp: Record<string, CommonDataFilter> = {};
  if (shouldIncludeExtTemp) {
    // Get all unique external Temp sensorIds
    const uniqueSensorIds = uniq(
      dataset.map((data) => data.externalTemp?.sensorId).filter((id) => id)
    );

    // Create a filter for each unique sensorId
    extTemp = Object.fromEntries(
      uniqueSensorIds.map((id) => [
        id!.toString(),
        {
          dataToggle: {
            isActive: true,
            isUsed: true
          },
          hideDataWithinAlarmLevel: false
        }
      ])
    );
  }

  return {
    xAcc: {
      dataToggle: {
        isActive: shouldIncludeXAcc,
        isUsed: shouldIncludeXAcc
      },
      hideDataWithinAlarmLevel: false
    },
    yAcc: {
      dataToggle: {
        isActive: shouldIncludeYAcc,
        isUsed: shouldIncludeYAcc
      },
      hideDataWithinAlarmLevel: false
    },
    zAcc: {
      dataToggle: {
        isActive: shouldIncludeZAcc,
        isUsed: shouldIncludeZAcc
      },
      hideDataWithinAlarmLevel: false
    },
    temp: {
      dataToggle: {
        isActive: shouldIncludeTemp,
        isUsed: shouldIncludeTemp
      },
      hideDataWithinAlarmLevel: false
    },
    rh: {
      dataToggle: { isActive: shouldIncludeRh, isUsed: shouldIncludeRh },
      hideDataWithinAlarmLevel: false
    },
    pressureRaw: {
      dataToggle: {
        isActive: shouldIncludePressureRaw,
        isUsed: shouldIncludePressureRaw
      },
      hideDataWithinAlarmLevel: false
    },
    pressureComp: {
      dataToggle: {
        isActive: shouldIncludePressureComp,
        isUsed: shouldIncludePressureComp
      },
      hideDataWithinAlarmLevel: false
    },
    angle: {
      dataToggle: {
        isActive: shouldIncludeAngle,
        isUsed: shouldIncludeAngle
      },
      hideDataWithinAlarmLevel: false
    },
    gps: {
      dataToggle: {
        isActive: shouldIncludeGps,
        isUsed: shouldIncludeGps
      },
      hideStatusData: false,
      hideSensorData: false,
      hideScheduleData: false
    },
    extInput: {
      dataToggle: {
        isActive: shouldIncludeExtInput,
        isUsed: shouldIncludeExtInput
      },
      hideDataWithinAlarmLevel: false
    },
    extOutput: {
      dataToggle: {
        isActive: shouldIncludeExtOutput,
        isUsed: shouldIncludeExtOutput
      },
      hideDataWithinAlarmLevel: false
    },
    extTimer: {
      dataToggle: {
        isActive: shouldIncludeExtTimer,
        isUsed: shouldIncludeExtTimer
      },
      hideDataWithinAlarmLevel: false
    },
    extRh,
    extTemp
  };
};

/**
 * Helper function that prepares data for primary graph by filtering out data
 * not currently visible and reduces the dataset of each channel to aprox 300
 * points of a data
 * @param data Dataset
 * @param domain Currently visible domain
 */
export const createPerformantPrimaryGraphData = (
  data: PrimaryGraphData,
  domain: [Date, Date],
  totalMaxPoints = 700,
  graphWidthInPx: number
): PrimaryGraphData => {
  const xDataInDomain = getAccDataInDomain(data.accData?.xAcc ?? [], domain);
  const yDataInDomain = getAccDataInDomain(data.accData?.yAcc ?? [], domain);
  const zDataInDomain = getAccDataInDomain(data.accData?.zAcc ?? [], domain);

  const { xAcc, yAcc, zAcc } = pickNLargestAcc(
    xDataInDomain,
    yDataInDomain,
    zDataInDomain,
    totalMaxPoints
  );

  const tempData = data.tempData && getLineDataInDomain(data.tempData, domain);
  const rhData = data.rhData && getLineDataInDomain(data.rhData, domain);

  const pressureRaw =
    data.pressureData?.pressureRaw &&
    getLineDataInDomain(data.pressureData.pressureRaw, domain);
  const pressureComp =
    data.pressureData?.pressureComp &&
    getLineDataInDomain(data.pressureData.pressureComp, domain);

  // Filter out data outside the domain
  const externalRh: ExternalSensorDataSeries[] | undefined = data.externalRh
    ? data.externalRh.map((series) => {
        return {
          sensorId: series.sensorId,
          data: series.data && getLineDataInDomain(series.data, domain)
        };
      })
    : [];

  // Filter out data outside the domain
  const externalTemp: ExternalSensorDataSeries[] | undefined = data.externalTemp
    ? data.externalTemp.map((series) => {
        return {
          sensorId: series.sensorId,
          data: series.data && getLineDataInDomain(series.data, domain)
        };
      })
    : [];

  return {
    ...data,
    accData: { xAcc, yAcc, zAcc },
    tempData,
    rhData,
    pressureData: { pressureRaw, pressureComp },
    externalRh,
    externalTemp
  };
};

/**
 * Returns a point if it is within the domain
 * @param data
 * @param domain
 * @returns new dataset (might be empty)
 */
export const getDataInDomain = <T extends { x: Date }>(
  data: T[],
  domain: [Date, Date]
) => data.filter((d) => isItemWithinDomain(d, domain));

/**
 * Returns an Acceleration if it starts in the domain or reaches into the domain
 * @param data
 * @param domain
 * @returns new dataset (might be empty)
 */
export const getAccDataInDomain = (
  data: GraphDataPoint[],
  domain: [Date, Date]
) => {
  return data.filter((d) => isAccWithinDomain(d, domain));
};

/**
 * Returns the data in the given domain + one extra data point on each side.
 * This is usefull for datasets that should be rendered using VictoryLine since
 * it needs atleast 2 data points to be able to draw a line
 * @param data
 * @param domain
 */
export const getLineDataInDomain = (
  data: NullableGraphDataPoint[],
  domain: [Date, Date]
) => {
  const inDomain = getDataInDomain(data, domain);

  if (inDomain.length === data.length) return data;

  //If there is no data in domain, choose 2 points right outside the domain on
  //booth sides
  if (inDomain.length === 0) {
    const firstElementIndexAfterDomain = data.findIndex((a) => a.x > domain[0]);
    const firstElementIndexBeforeDomain = firstElementIndexAfterDomain - 1;

    return data.slice(
      firstElementIndexBeforeDomain,
      firstElementIndexAfterDomain + 1
    );
  }

  //Add 1 point before domain and 1 point after the domain

  const first = inDomain[0];
  const last = inDomain[inDomain.length - 1];

  const newFirstIndex = data.indexOf(first) - 1;
  const newLastIndex = data.indexOf(last) + 1;

  return data.slice(newFirstIndex <= 0 ? 0 : newFirstIndex, newLastIndex + 1);
};

// Picks the N largest Accelerations in the data set
export const pickNLargestAcc = (
  unsortedX: GraphDataPoint[],
  unsortedY: GraphDataPoint[],
  unsortedZ: GraphDataPoint[],
  totalMaxPoints: number
) => {
  const totalLength = unsortedX.length + unsortedY.length + unsortedZ.length;
  if (totalLength <= totalMaxPoints) {
    return { xAcc: unsortedX, yAcc: unsortedY, zAcc: unsortedZ };
  }
  const sortedX = sortBy(unsortedX, (d) => Math.abs(d.y[0]));
  const sortedY = sortBy(unsortedY, (d) => Math.abs(d.y[0]));
  const sortedZ = sortBy(unsortedZ, (d) => Math.abs(d.y[0]));

  const xAcc: GraphDataPoint[] = [];
  const yAcc: GraphDataPoint[] = [];
  const zAcc: GraphDataPoint[] = [];

  // Push endpoints to the arrays
  const firstX = head(unsortedX);
  if (!isUndefined(firstX)) xAcc.push(firstX);
  const lastX = last(unsortedX);
  if (!isUndefined(lastX)) xAcc.push(lastX);
  const firstY = head(unsortedY);
  if (!isUndefined(firstY)) yAcc.push(firstY);
  const lastY = last(unsortedY);
  if (!isUndefined(lastY)) yAcc.push(lastY);
  const firstZ = head(unsortedZ);
  if (!isUndefined(firstZ)) zAcc.push(firstZ);
  const lastZ = last(unsortedZ);
  if (!isUndefined(lastZ)) zAcc.push(lastZ);
  // Remove the endpoints from the totalMaxPoints
  totalMaxPoints -= xAcc.length + yAcc.length + zAcc.length;

  const combinedEndpoints = [...xAcc, ...yAcc, ...zAcc];
  let firstTime = Infinity;
  let lastTime = -Infinity;
  combinedEndpoints.forEach((d) => {
    const timestamp = dateToUnix(d.x);
    if (timestamp < firstTime) firstTime = timestamp;
    if (timestamp > lastTime) lastTime = timestamp;
  });
  const timespan = lastTime - firstTime;
  const minimumSpread = (timespan / totalMaxPoints) * 0.5; // in seconds

  let nextX: GraphDataPoint | undefined = undefined;
  let nextY: GraphDataPoint | undefined = undefined;
  let nextZ: GraphDataPoint | undefined = undefined;

  let i = 0;
  // Pick the largerst points from all channels
  while (
    i < totalMaxPoints &&
    (sortedX.length > 0 || sortedY.length > 0 || sortedZ.length > 0)
  ) {
    let currentMin = 0;
    let currentMax = 0;

    // Pop the next largest value from the sorted arrays
    if (isUndefined(nextX) && sortedX.length > 0) {
      nextX = sortedX.pop();
    }
    if (isUndefined(nextY) && sortedY.length > 0) {
      nextY = sortedY.pop();
    }
    if (isUndefined(nextZ) && sortedZ.length > 0) {
      nextZ = sortedZ.pop();
    }

    // Check if the next value is the largest or smallest
    if (!isUndefined(nextX)) {
      if (nextX.y[0] > currentMax) {
        currentMax = nextX.y[0];
      }
      if (nextX.y[0] < currentMin) {
        currentMin = nextX.y[0];
      }
    }
    if (!isUndefined(nextY)) {
      if (nextY.y[0] > currentMax) {
        currentMax = nextY.y[0];
      }
      if (nextY.y[0] < currentMin) {
        currentMin = nextY.y[0];
      }
    }
    if (!isUndefined(nextZ)) {
      if (nextZ.y[0] > currentMax) {
        currentMax = nextZ.y[0];
      }
      if (nextZ.y[0] < currentMin) {
        currentMin = nextZ.y[0];
      }
    }

    // If the next value is the largest or smallest, add it to the array
    if (!isUndefined(nextX)) {
      if (nextX.y[0] === currentMax || nextX.y[0] === currentMin) {
        // If there already is a time closer than minimumSpread on the same side of the y-axis
        const closePoint = xAcc.find(
          (d) =>
            Math.abs(dateToUnix(d.x) - dateToUnix(nextX!.x)) < minimumSpread &&
            d.y[0] * nextX!.y[0] > 0
        );
        if (isUndefined(closePoint)) {
          xAcc.push(nextX);
          i++;
        }
        nextX = undefined;
      }
    }
    if (!isUndefined(nextY)) {
      if (nextY.y[0] === currentMax || nextY.y[0] === currentMin) {
        // If there already is a time closer than minimumSpread on the same side of the y-axis
        const closePoint = yAcc.find(
          (d) =>
            Math.abs(dateToUnix(d.x) - dateToUnix(nextY!.x)) < minimumSpread &&
            d.y[0] * nextY!.y[0] > 0
        );
        if (isUndefined(closePoint)) {
          yAcc.push(nextY);
          i++;
        }
        nextY = undefined;
      }
    }
    if (!isUndefined(nextZ)) {
      if (nextZ.y[0] === currentMax || nextZ.y[0] === currentMin) {
        // If there already is a time closer than minimumSpread on the same side of the y-axis
        const closePoint = zAcc.find(
          (d) =>
            Math.abs(dateToUnix(d.x) - dateToUnix(nextZ!.x)) < minimumSpread &&
            d.y[0] * nextZ!.y[0] > 0
        );
        if (isUndefined(closePoint)) {
          zAcc.push(nextZ);
          i++;
        }
        nextZ = undefined;
      }
    }
  }
  return { xAcc, yAcc, zAcc };
};

/**
 * Helper function that preapers data for overview graph by returning a reduced
 * version of the original dataset containg aprox max 50 points of data in each
 * channel
 * @param data Dataset
 */
export const getOverviewGraphData = (
  data: PrimaryGraphData
): PrimaryGraphData => {
  const totalMaxPoints = 150;

  const unsortedX = data.accData?.xAcc ?? [];
  const unsortedY = data.accData?.yAcc ?? [];
  const unsortedZ = data.accData?.zAcc ?? [];

  const { xAcc, yAcc, zAcc } = pickNLargestAcc(
    unsortedX,
    unsortedY,
    unsortedZ,
    totalMaxPoints
  );
  const tempData = data.tempData;
  const rhData = data.rhData;
  const pressureRaw = data.pressureData?.pressureRaw;
  const pressureComp = data.pressureData?.pressureComp;

  return {
    ...data,
    accData: { xAcc, yAcc, zAcc },
    tempData,
    rhData,
    pressureData: { pressureRaw, pressureComp }
  };
};

/**
 * Returns the abs-max value from all acc-data
 * @param accData
 */
//TODO: what if null?
export const getAccMaxima = (accData: {
  xAcc: GraphDataPoint[];
  yAcc: GraphDataPoint[];
  zAcc: GraphDataPoint[];
}) => {
  const { xAcc, yAcc, zAcc } = accData;
  const absMaxValues = [...xAcc, ...yAcc, ...zAcc].map((d) => Math.abs(d.y[0]));

  const accMaxima = max(absMaxValues);

  return accMaxima;
};

/**
 * Returns 2 functions that can be used to normalize and denormalize values
 * for the given scale. If undefined is supplied the function will return the
 * span [-1, 1]
 * @param lowHigh The span that normalizing/denormalizing should be based on
 * @param scale The scale that will be normalized to/from
 * @returns An object with a normalizer and denormalizer function
 */
export const getNormalizerFunctions = (
  lowHigh: YAxisLowHigh | undefined,
  scale = { low: -1, high: 1 }
) => {
  //todo: fallback value or return early?
  const minYData = lowHigh?.[0] ?? -1;
  const maxYData = lowHigh?.[1] ?? 1;

  /**
   * Used to normalize a datapoint so that it fits within the given scale that
   * is recieved from the higher order function.
   * @param datum A meassured value, E.g 40 if the rh-data was at 40%
   * @returns A normalized value. E.g 0.5
   */
  const normalizeValue = (datum: number) => {
    return (
      (scale.high - scale.low) * ((datum - minYData) / (maxYData - minYData)) +
      scale.low
    );
  };

  /**
   * Used to restore a normalized value to it's prior value.
   * @param t A already normalized value, E.g -0.5
   * @returns A restored value. E.g 40
   */
  const denormalizeValue = (t: number) => {
    /** normalizing t to a value between 0 and 1 */
    const z = (t - scale.low) / (scale.high - scale.low);
    return minYData + (maxYData - minYData) * z;
  };

  return {
    normalizeValue,
    denormalizeValue
  };
};

export const getClosestValue = (target: number, arr: number[]) =>
  arr.reduce((a, b) => {
    const aDiff = Math.abs(a - target);
    const bDiff = Math.abs(b - target);

    return aDiff === bDiff ? (a > b ? a : b) : bDiff < aDiff ? b : a;
  });

/**
 * Returns graph canvas offset based on how many y-axis is shown at the dayjs
 * @param dataFilter
 */
export const getCanvasOffset = (dataFilter: DataFilterStates) => {
  const showAccAxis = isAccFilterActive(dataFilter);
  const externalTempActive = isExternalTempFilterActive(dataFilter);
  const showTempAxis =
    dataFilter.temp.dataToggle.isActive || externalTempActive;
  const externalRhActive = isExternalRhFilterActive(dataFilter);
  const showRhAxis = dataFilter.rh.dataToggle.isActive || externalRhActive;
  const showIOGraph =
    dataFilter.extInput.dataToggle.isActive ||
    dataFilter.extOutput.dataToggle.isActive;
  const showPressureGraph =
    dataFilter.pressureRaw.dataToggle.isActive ||
    dataFilter.pressureComp.dataToggle.isActive;

  const counted = [
    showAccAxis,
    showTempAxis,
    showRhAxis,
    showPressureGraph
  ].reduce((count, showAxis) => (showAxis ? count + 1 : count), 0);
  if (showIOGraph && counted < 2) return 91;
  return counted * 45;
};

/** Returns true if any acceleration axis is active */
export const isAccFilterActive = (dataFilter: DataFilterStates) =>
  dataFilter.xAcc.dataToggle.isActive ||
  dataFilter.yAcc.dataToggle.isActive ||
  dataFilter.zAcc.dataToggle.isActive;

/** Returns true if any external temp is active */
export const isExternalTempFilterActive = (dataFilter: DataFilterStates) => {
  return Object.values(dataFilter.extTemp).some(
    (filter) => filter.dataToggle.isActive
  );
};

/** Returns true if any external rh is active */
export const isExternalRhFilterActive = (dataFilter: DataFilterStates) => {
  return Object.values(dataFilter.extRh).some(
    (filter) => filter.dataToggle.isActive
  );
};

/** Formats labels depending on variance of data */
export const dateAxisFormater = (tick: Date, arr: Date[], timezone: string) => {
  if (!tick || !arr) return "cnt fmt";
  const lowDate = arr[0];
  const highDate = arr[arr.length - 1];
  const dateDiff = highDate.getTime() - lowDate.getTime();

  const spanYear = dateDiff > 31536000000; // 365 days
  const spanMonth = dateDiff > 2592000000; // 30 days
  const spanDay = dateDiff > 86400000; // 24 hours
  const spanHour = dateDiff > 3600000; // 60 minutes
  const spanMinute = dateDiff > 60000; // 60 seconds

  const unixDate = dateToUnix(tick);
  const m = createTzDate(unixDate, timezone);
  const firstMinute = m.get("minute") === 0;
  const firstHour = m.get("hour") === 0 && firstMinute;
  const firstDayOfMonth = m.get("day") === 1 && firstHour;
  const firstDayOfYear = m.get("month") === 1 && firstDayOfMonth;

  if (spanYear) {
    if (firstDayOfYear) return m.format("YYYY");
    else return m.format("MMM DD");
  } else if (spanMonth) {
    if (firstDayOfMonth) return m.format("MMM");
    else return m.format("MMM DD");
  } else if (spanDay) {
    if (firstHour) return m.format("MMM DD");
    else return m.format("HH:mm");
  } else if (spanHour) {
    if (firstMinute) return m.format("HH:mm");
    else return m.format("HH:mm:ss");
  } else if (spanMinute) {
    return m.format("HH:mm:ss");
  } else {
    return m.format("HH:mm:ss.SSS");
  }
};

/**
 * Formats tick values for y-axis. If the difference between low/high values
 * is low, a value with 1 decimal is returned otherwise an integear.
 * @param denormalizedT Already denomalized t
 * @param span The span of the data that the y-axis is connected to
 * @param tickCount Number of ticks being rendered
 */
export const yAxisFormater = (
  denormalizedT: number,
  span: YAxisLowHigh,
  tickCount = 21
) => {
  const [min, max] = span;

  const shouldHaveDecimal = Math.abs(min - max) < tickCount;

  return shouldHaveDecimal
    ? Math.round(denormalizedT * 10) / 10
    : Math.round(denormalizedT);
};

/**
 * Choosing scale axis value to use in Primary Graph depending on if global scale settings are activated or not.
 */
export const graphAxisScaleSelector = (
  fileValue: number,
  globalValue: number,
  globalRowToggle: boolean,
  globalScaleToggle: boolean
): number => {
  if (globalScaleToggle && globalRowToggle) {
    return globalValue;
  } else {
    return fileValue;
  }
};

/**
 * Generate tick values between lower and higher {range} in {steps} steps.
 *
 * E.g. (3, [-1, 1]) => [-1, 0, 1]
 *
 * If {steps}=1, mean of {range} will be returned
 * @param steps
 */
export const generateTickValues = (
  steps: number,
  rangeValues = yAxisDefaultNormalizedValues
) => {
  const [start, end] = rangeValues;

  // If 1 step, return the middle
  if (steps === 1) {
    return [mean(rangeValues)];
  }

  const stepSize = (Math.abs(start) + Math.abs(end)) / (steps - 1);
  const rangeEnd = end + stepSize;

  return range(start, rangeEnd, stepSize);
};

/**
 * Returns y-axis domains for channel depending on the data. Each channels
 * y-axis domain is between the minimum value -1 and the maximum value +1 for
 * that dataset. If a channel doesen't have any data [-1, 1] is set as a default domain.
 * @param data data-object
 */
export const createDefaultYAxisDomain = (data: IDatxContent): YAxisDomain => {
  // Acceleration

  /** Return abs max acceleration value if available, otherwhise undefined */
  const pickAbsMaxAcceleration = ({
    xAcc,
    yAcc,
    zAcc
  }: RecordingDataBlockUnique) => {
    const absMaxAcc = maxBy(
      [xAcc, yAcc, zAcc],
      (acc) => acc && Math.abs(acc[0])
    );

    return absMaxAcc && Math.abs(absMaxAcc[0]);
  };

  const absMaxAccBlock = maxBy(data.data, pickAbsMaxAcceleration);

  const accMaxima =
    absMaxAccBlock && Math.ceil(pickAbsMaxAcceleration(absMaxAccBlock)!);

  // External Temperature
  const pickExternalTempValue = ({ externalTemp }: RecordingDataBlockUnique) =>
    externalTemp?.temp;

  const lowestExternalTempBlock = minBy(data.data, pickExternalTempValue);
  const highestExternalTempBlock = maxBy(data.data, pickExternalTempValue);

  const externalTempMin =
    lowestExternalTempBlock &&
    floor(pickExternalTempValue(lowestExternalTempBlock)!);
  const externalTempMax =
    highestExternalTempBlock &&
    ceil(pickExternalTempValue(highestExternalTempBlock)!);

  // Temperature

  const pickTempValue = ({ temp }: RecordingDataBlockUnique) => temp;

  const lowestTempBlock = minBy(data.data, pickTempValue);
  const highestTempBlock = maxBy(data.data, pickTempValue);

  const tempMin = lowestTempBlock && floor(pickTempValue(lowestTempBlock)!);
  const tempMax = highestTempBlock && ceil(pickTempValue(highestTempBlock)!);

  // External Humidity

  const pickExternalRhValue = ({ externalRh }: RecordingDataBlockUnique) =>
    externalRh?.rh;

  const lowestExternalRhBlock = minBy(data.data, pickExternalRhValue);
  const highestExternalRhBlock = maxBy(data.data, pickExternalRhValue);

  const externalRhMin =
    lowestExternalRhBlock && floor(pickExternalRhValue(lowestExternalRhBlock)!);
  const externalRhMax =
    highestExternalRhBlock &&
    ceil(pickExternalRhValue(highestExternalRhBlock)!);

  // Humidity

  const pickRhValue = ({ rh }: RecordingDataBlockUnique) => rh;

  const lowestRhBlock = minBy(data.data, pickRhValue);
  const highestRhBlock = maxBy(data.data, pickRhValue);

  const rhMin = lowestRhBlock && floor(pickRhValue(lowestRhBlock)!);
  const rhMax = highestRhBlock && ceil(pickRhValue(highestRhBlock)!);

  // Pressure

  const pickRawPressureValue = ({ pressureRaw }: RecordingDataBlockUnique) =>
    pressureRaw;

  const lowestRawPressureBlock = minBy(data.data, pickRawPressureValue);
  const highestRawPressureBlock = maxBy(data.data, pickRawPressureValue);

  const rawPressureMin =
    lowestRawPressureBlock &&
    floor(pickRawPressureValue(lowestRawPressureBlock)!);
  const rawPressureMax =
    highestRawPressureBlock &&
    ceil(pickRawPressureValue(highestRawPressureBlock)!);

  // Compensated Pressure
  const pickCompensatedPressureValue = ({
    pressureComp
  }: RecordingDataBlockUnique) => pressureComp;

  const lowestCompensatedPressureBlock = minBy(
    data.data,
    pickCompensatedPressureValue
  );
  const highestCompensatedPressureBlock = maxBy(
    data.data,
    pickCompensatedPressureValue
  );

  const compensatedPressureMin =
    lowestCompensatedPressureBlock &&
    floor(pickCompensatedPressureValue(lowestCompensatedPressureBlock)!);
  const compensatedPressureMax =
    highestCompensatedPressureBlock &&
    ceil(pickCompensatedPressureValue(highestCompensatedPressureBlock)!);

  // Return values

  const acc: YAxisLowHigh = !isUndefined(accMaxima)
    ? [(accMaxima + 1) * -1, accMaxima + 1]
    : yAxisDefaultNormalizedValues;

  const combinedTempMin = min([tempMin, externalTempMin]);
  const combinedTempMax = max([tempMax, externalTempMax]);

  const temp: YAxisLowHigh =
    !isUndefined(combinedTempMin) && !isUndefined(combinedTempMax)
      ? [combinedTempMin - 1, combinedTempMax + 1]
      : yAxisDefaultNormalizedValues;

  const combinedRhMin = min([rhMin, externalRhMin]);
  const combinedRhMax = max([rhMax, externalRhMax]);

  const rh: YAxisLowHigh =
    !isUndefined(combinedRhMin) && !isUndefined(combinedRhMax)
      ? [combinedRhMin - 1, combinedRhMax + 1]
      : yAxisDefaultNormalizedValues;

  const combinedPressureMin = min([rawPressureMin, compensatedPressureMin]);
  const combinedPressureMax = max([rawPressureMax, compensatedPressureMax]);

  const pressure: YAxisLowHigh =
    !isUndefined(combinedPressureMin) && !isUndefined(combinedPressureMax)
      ? [combinedPressureMin - 10, combinedPressureMax + 10]
      : yAxisDefaultNormalizedValues;

  return { acc, temp, rh, pressure };
};

export const createDefaultDvaYAxisDomain = (
  dvaBlock: VMDvaData
): YAxisLowHigh => {
  const pickAbsMaxDvaAcc = ({ xAlarm, yAlarm, zAlarm }: IDtDva) =>
    Math.abs(maxBy([xAlarm, yAlarm, zAlarm], (acc) => Math.abs(acc))!);

  const absMaxDvaAcc = maxBy(dvaBlock.data, pickAbsMaxDvaAcc)!;
  const absDvaMaxima = ceil(pickAbsMaxDvaAcc(absMaxDvaAcc));

  // return [absDvaMaxima * -1.1, absDvaMaxima * 1.1];
  return [absDvaMaxima * -1, absDvaMaxima * 1];
};

export const defaultTickCount = 21;

/**
 * Returns default tick count for every y-axis
 */
export const getDefaultYAxisTickCount = (): YAxisTickCount => ({
  accTick: defaultTickCount,
  tempTick: defaultTickCount,
  rhTick: defaultTickCount,
  pressureTick: defaultTickCount
});

/**
 * Returns a function that can be used to compare an angle-data-point against
 * the recording parameters to determine if the data is above alarm level
 * @param angleParams
 */
export const angleWarningChecker = (angleParams: VMAngleParams) => {
  const { xAlarmLevel, yAlarmLevel, zAlarmLevel } = angleParams?.params;

  /** compare angle data against parameters supplied to the higher order function */
  return (a: [number, number, number]) =>
    Math.abs(a[0]) >= xAlarmLevel ||
    Math.abs(a[1]) >= yAlarmLevel ||
    Math.abs(a[2]) >= zAlarmLevel;
};

/**
 * Returns a function that can be used to compare an acc-data-point against
 * the recording parameters to determine if the data is above alarm level
 * @param accParams
 */
export const accWarningChecker = (accParams: VMAccParams) => {
  const { Xalarm, Xms, Yalarm, Yms, Zalarm, Zms } = accParams?.params;

  /** compare acceleration data against parameters supplied to the higher order function */
  return (data: RecordingDataBlockFiltered) => {
    if (!data.xAcc || !data.yAcc || !data.zAcc) return false;
    const { xAcc, yAcc, zAcc } = data;

    const accComponent = Math.sqrt(
      Math.pow(xAcc[0], 2) + Math.pow(yAcc[0], 2) + Math.pow(zAcc[0], 2)
    );
    const dur = Math.max(xAcc[1], yAcc[1], zAcc[1]);

    return (
      (xAcc[0] >= Xalarm && xAcc[1] >= Xms) ||
      (yAcc[0] >= Yalarm && yAcc[1] >= Yms) ||
      (zAcc[0] >= Zalarm && zAcc[1] >= Zms) ||
      (accComponent >= Xalarm && dur >= Xms) ||
      (accComponent >= Yalarm && dur >= Yms) ||
      (accComponent >= Zalarm && dur >= Zms)
    );
  };
};

/**
 * Returns a function that can be used to compare a temp-data-point against
 * the recording parameters to determine if the data is above alarm level
 * @param tempParams
 */
export const tempWarningChecker = (tempParams: VMTempParams) => {
  const { lowAlarm, highAlarm } = tempParams?.params;

  /** compare angle data against parameters supplied to the higher order function */
  return (a: number) => a <= lowAlarm || a >= highAlarm;
};

/**
 * Returns a function that can be used to compare an rh-data-point against
 * the recording parameters to determine if the data is above alarm level
 * @param rhParams
 */
export const rhWarningChecker = (rhParams: VMRhParams) => {
  const { lowAlarm, highAlarm } = rhParams?.params;

  /** compare humidity data against parameters supplied to the higher order function */
  return (a: [number, number]) => {
    return a[0] <= lowAlarm || a[1] >= highAlarm;
  };
};

/**
 * Returns a function that can be used to compare a pressure-data-point against
 * the recording parameters to determine if the data is above alarm level
 * @param pressureParams
 */
export const pressureWarningChecker = (pressureParams: VMPressureParams) => {
  const { lowAlarm, highAlarm } = pressureParams.params;

  /** compare pressure data against parameters supplied to the higher order function */
  return (a: [number, number]) => {
    return a[0] <= lowAlarm || a[1] >= highAlarm;
  };
};

export const getMostExtremeValuesForAxis = (d: IDtDva[]) =>
  d.reduce(
    (result, curr) => {
      const { xAlarm, yAlarm, zAlarm } = curr;

      const x = Math.abs(xAlarm) > Math.abs(result.x) ? xAlarm : result.x;
      const y = Math.abs(yAlarm) > Math.abs(result.y) ? yAlarm : result.y;
      const z = Math.abs(zAlarm) > Math.abs(result.z) ? zAlarm : result.z;

      return { x, y, z };
    },
    { x: 0, y: 0, z: 0 }
  );

/**
 * Y-format helper function. Normalizes an y-value using the supplied normalizer
 * function and makes sure that the value isn't outside of the supplied domain
 * @param yValue
 * @param domain
 * @param normalizer normalizer function
 */
export const formatYData = (
  yValue: number,
  domain: YAxisLowHigh | undefined,
  normalizer: (d: number) => number
) => {
  const candidate = yValue;
  const [low, high] = domain ?? [-1, 1];

  if (candidate < low) return normalizer(low);
  if (candidate > high) return normalizer(high);

  return normalizer(candidate);
};

/**
 * Create {steps} bins, where the first bin is always 0 and the last bin is
 * slightly lower than the abs-max acc-value
 * @param numOfBins
 * @param data
 */
export const createDefaultHistogramBins = (maxG: number) => {
  const numOfBins = 8;

  const stop = maxG + 1;
  const step = maxG / numOfBins;

  const bins = range(0, stop, step);

  return { bins, max: maxG };
};

type Accs = {
  xAcc: [number, number];
  yAcc: [number, number];
  zAcc: [number, number];
};
type AccWithDuration = { axis: AccelerationAxis; value: number };

/** Returns the axis that has a duration that is not 0 */
export const getAccWithDuration = ({
  xAcc,
  yAcc,
  zAcc
}: Accs): AccWithDuration =>
  xAcc[1] !== 0
    ? { axis: "x", value: Math.abs(xAcc[0]) }
    : yAcc[1] !== 0
      ? { axis: "y", value: Math.abs(yAcc[0]) }
      : { axis: "z", value: Math.abs(zAcc[0]) };

/**
 * Search the entire array for the datapoint of type channel closest to the timestamp
 * @param goal
 * @param dataset
 */
export const getClosestDataPoint = (goal: ScoreValuesTarget) =>
  goal.dataset.reduce((prev, curr) => {
    const timeCheck =
      Math.abs(curr.timestamp - goal.timestamp) <
      Math.abs(prev.timestamp - goal.timestamp);
    const channel = curr[goal.channel];
    const esti = dtHasEsti(channel) ? channel.sensorId : undefined;
    const estiCheck = !goal.esti || goal.esti === esti;
    return timeCheck && channel && estiCheck ? curr : prev;
  });

/**
 * Returns the index for the data-blocks timestamp that is closest to {timestamp}
 * @param timestamp
 * @param dataset
 */
export const getClosestScoreValuesIndex = (
  timestamp: number,
  dataset: RecordingDataBlockFiltered[]
): number => {
  let indexDraft = 0;
  let diffDraft = Math.abs(timestamp - (dataset[indexDraft]?.timestamp ?? 0));

  let i = 0;

  while (i < dataset.length) {
    const newdiff = Math.abs(timestamp - dataset[i].timestamp);

    if (newdiff < diffDraft && isScoreValuesCompatible(dataset[i])) {
      diffDraft = newdiff;
      indexDraft = i;
    }

    i += 1;
  }

  return indexDraft;
};

/**
 * Search the array for the datapoint of type channel closest to the timestamp
 * starting from lastIndex
 * @param goal
 * @param dataset
 * @param lastIndex index to start the search from
 */
export const getClosestDataPointToIndex = (
  goal: ScoreValuesTarget,
  lastIndex: number | undefined
): Optional<RecordingDataBlockFiltered> => {
  const currentPos = isNil(lastIndex) ? undefined : goal.dataset?.[lastIndex];

  // No position to start from, need to search the entire array
  if (isNil(currentPos)) {
    const dataPoint = getClosestDataPoint(goal);

    return dataPoint;
  }

  // A value exists on the exact timestamp
  if (
    currentPos.timestamp === goal.timestamp &&
    !isNil(currentPos?.[goal.channel])
  ) {
    return currentPos;
  }

  /** has value predicate */
  const findPredicate = (v: RecordingDataBlockFiltered) =>
    !isNil(v?.[goal.channel]);

  const priorValue = findLast(goal.dataset, findPredicate, lastIndex);
  const nextValue = find(goal.dataset, findPredicate, lastIndex);

  //No value of desired type exists in the entire array
  if (isNil(priorValue) && isNil(nextValue)) {
    return undefined;
  }

  if (isNil(priorValue)) {
    return nextValue;
  }

  if (isNil(nextValue)) {
    return priorValue;
  }

  const nextDiff = Math.abs(nextValue.timestamp - goal.timestamp);
  const priorDiff = Math.abs(priorValue.timestamp - goal.timestamp);

  //return value closest to the cursor
  return nextDiff <= priorDiff ? nextValue : priorValue;
};

/**
 * Returns true if accelerations exists for all axises and has the same length
 * @param data
 */
export const isAccelerationsValid = (data: RecordingDataBlock) => {
  if (isNil(data?.xAcc) || isNil(data?.yAcc) || isNil(data?.zAcc)) {
    return false;
  }

  if (
    !(
      data.xAcc.length === data.yAcc.length &&
      data.yAcc.length === data.zAcc.length
    )
  ) {
    return false;
  }

  return true;
};

/** Transform a dva "sample" to milliseconds with a 3 deciaml precission */
export const dvaSampleToMsTickFormater = (sample: number): number =>
  round(sample * 0.625, 3);

/** Transform a dva "sample" to milliseconds with a 3 deciaml precission and adds "ms" */
export const dvaSampleToMsTickFormaterWithPostfix = (sample: number): string =>
  `${dvaSampleToMsTickFormater(sample)}ms`;

/**
 * Search forward for the next score values compatible data point and returns
 * the index for that position
 * @param data
 * @param currentIndex
 */
export const getNextScoreValuesPositionIndex = (
  data: RecordingDataBlockFiltered[],
  currentIndex: number
): number => {
  if (currentIndex === data.length - 1) {
    return currentIndex;
  }

  let nextIndexDraft = currentIndex + 1;

  while (nextIndexDraft < data.length) {
    const nextValueDraft = data[nextIndexDraft];

    if (isScoreValuesCompatible(nextValueDraft)) {
      return nextIndexDraft;
    }
    nextIndexDraft += 1;
  }

  return currentIndex;
};

/**
 * Search backwards for the next score values compatible data point and returns
 * the index for that position
 * @param data
 * @param currentIndex
 */
export const getPriorScoreValuesPositionIndex = (
  data: RecordingDataBlockFiltered[],
  currentIndex: number
): number => {
  if (currentIndex === 0) {
    return currentIndex;
  }

  let priorIndexDraft = currentIndex - 1;

  while (priorIndexDraft !== -1) {
    const priorValue = data[priorIndexDraft];

    if (isScoreValuesCompatible(priorValue)) {
      return priorIndexDraft;
    }
    priorIndexDraft -= 1;
  }

  return currentIndex;
};

/**
 * Returns true if item is compatible with score values, e.g. the item can be
 * "jumped to" and should be displayed in score values component
 * @param item
 */
const isScoreValuesCompatible = (item: RecordingDataBlockFiltered): boolean => {
  const withoutUnusedItems = getScoreValuesRecordingDataBlock(item);

  return Object.values(withoutUnusedItems).some((x) => !isNil(x));
};

/**
 * Returns a datablock where all entries that is not included in score values is
 * set to undefined
 * @param item
 */
const getScoreValuesRecordingDataBlock = (
  item: RecordingDataBlockFiltered
): Partial<RecordingDataBlockFiltered> => {
  return {
    ...item,
    timestamp: undefined,
    errorString: undefined,
    runningStatus: undefined,
    text: undefined,
    timestamp2: undefined
  };
};

/**
 * Returns true if {item} is within {domain}
 * @param item
 * @param domain
 */
export const isAccWithinDomain = (
  item: GraphDataPoint,
  domain: [Date, Date]
) => {
  const pointStart = item.x.getTime();
  const domainStart = domain[0].getTime();
  const domainEnd = domain[1].getTime();
  if (pointStart >= domainStart) {
    return pointStart <= domainEnd;
  }
  // If the item has a duration that could span into the domain
  const pointDuration = item.y[1];
  return pointStart + pointDuration >= domainStart && pointStart <= domainEnd;
};

/**
 * Returns true if {item} is within {domain}
 * @param item
 * @param domain
 */
export const isItemWithinDomain = <T extends { x: Date }>(
  item: T,
  domain: [Date, Date]
) =>
  item.x.getTime() >= domain[0].getTime() &&
  item.x.getTime() <= domain[1].getTime();
