import { isNil, set } from "lodash-es";
import { isUndefined } from "lodash-es";
import {
  DTExternalIO,
  DTExternalRh,
  DTExternalSensor,
  DTExternalTemp,
  DTExternalTimer,
  DTStatusLink,
  DtDataLink,
  DtExtraValueReponse,
  RunningStatusTypes
} from "../models/DTTypes";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { DataFilterStates } from "../state/openDatxSlice";
import { Optional, Require, ValueOf } from "../utils/utilTypes";
import { AccelerationAxis, isAccelerationsValid } from "./graphHelper";

// Types and interfaces

/** Datx Recording Data block according to specification. One number or string
 * per "type" of data */
export interface RecordingDataBlock {
  timestamp: number;
  xAcc?: [number, number][];
  yAcc?: [number, number][];
  zAcc?: [number, number][];
  temp?: number;
  rh?: number;
  pressureRaw?: number;
  pressureComp?: number;
  angle?: [number, number, number][];
  gps?: GPSData;
  runningStatus?: RunningStatusTypes[];
  extraValue?: DtExtraValueReponse[];
  text?: string;
  errorString?: string;
  deviceHealth?: DeviceHealth;
  //the timestamp that comes from dt timestamp
  timestamp2?: number;
  lteStatus?: number[];
  gpsStatus?: number[];
  alarmStatus?: number;
  dataLink?: DtDataLink;
  statusLink?: DTStatusLink;
  externalSensor?: DTExternalSensor;
  externalInputs?: DTExternalIO;
  externalOutputs?: DTExternalIO;
  externalTimers?: DTExternalTimer;
  externalRh?: DTExternalRh;
  externalTemp?: DTExternalTemp;
  extSensMsg?: string;
}

/** Recording Data block that can only hold 1 value from each type */
export interface RecordingDataBlockUnique
  extends Omit<
    RecordingDataBlock,
    "xAcc" | "yAcc" | "zAcc" | "angle" | "extraValue"
  > {
  // All the below fields are single versions of the omitted types

  xAcc?: [number, number];
  yAcc?: [number, number];
  zAcc?: [number, number];
  accComponent?: [number, number];
  angle?: [number, number, number];
}

/** Recording data block where some fields can be null, where null suggests
 * that a value has been filtered out. This is usefull for graphs with continuis
 * data, where null can be used to create a break in the data
 */
export interface RecordingDataBlockFiltered
  extends Omit<
    RecordingDataBlockUnique,
    | "temp"
    | "rh"
    | "pressureRaw"
    | "pressureComp"
    | "externalRh"
    | "externalTemp"
  > {
  // All the below fields are nullable versions of omited types

  temp?: Nullable<number>;
  rh?: Nullable<number>;
  pressureRaw?: Nullable<number>;
  pressureComp?: Nullable<number>;
  externalRh?: Nullable<DTExternalRh>;
  externalTemp?: Nullable<DTExternalTemp>;
}

/** A type that is allowed to be either T or null */
export type Nullable<T> = T | null;

type AccData = [number, number];

export type GPSData = {
  latitude: number;
  longitude: number;
  velocity: number;
  gpsTimestamp: number;
  gpsStatus: number;
};

export type DeviceHealth = {
  memoryUsed: number;
  mainBatteryStatus: number;
  backupBatteryStatus: number;
};

export type DtChannel = keyof RecordingDataBlockUnique;

export type DtValues = ValueOf<RecordingDataBlockUnique>;
/** Any valid dt value except undefined */
export type DtValue<T extends DtChannel> = ValueOf<
  Pick<Required<RecordingDataBlockUnique>, T>
>;

// Functions

/**
 * Creates a new dataset with unique timestamps. If e.g. there is more than one
 * acceleration in a single data-"block", the data will be distributed within
 * that second. Accelerations can also move into an earlier timestamp if the
 * total acceleration-duration is more than 1 second
 * @param dataset
 */
export const createDatasetWithUniqueTimestamps = (
  dataset: IterableIterator<RecordingDataBlock>
): RecordingDataBlockUnique[] => {
  const datasetAsArr = Array.from(dataset);
  datasetAsArr.sort((a, b) => a.timestamp - b.timestamp);

  /** X-axis resverd timestamp. If acceleration data for an axis has a total duration of more than 1
   * second for a timestamp, that axis can be reserved until an earlier timestamp */
  let xReservedUntilTs = 0;

  /** Y-axis resverd timestamp. If acceleration data for an axis has a total duration of more than 1
   * second for a timestamp, that axis can be reserved until an earlier timestamp */
  let yReservecUntilTs = 0;

  /** Z-axis resverd timestamp. If acceleration data for an axis has a total duration of more than 1
   * second for a timestamp, that axis can be reserved until an earlier timestamp */
  let zReservedUntilTs = 0;

  // Iterates from back to front
  return datasetAsArr.reduceRight((arr: RecordingDataBlockUnique[], curr) => {
    const {
      timestamp,
      temp,
      rh,
      pressureRaw,
      pressureComp,
      runningStatus,
      text,
      deviceHealth,
      gps,
      errorString,
      timestamp2,
      lteStatus,
      gpsStatus,
      alarmStatus,
      dataLink,
      statusLink,
      externalSensor,
      externalInputs,
      externalOutputs,
      externalTimers,
      externalRh,
      externalTemp,
      extSensMsg
    } = curr;

    /** Consist of all the values that is allready guaranteed to have unique
     * timestamps. If there are acc/angle data, their first value with also be
     * added to this row. */
    const primaryRow: RecordingDataBlockUnique = {
      timestamp,
      temp,
      rh,
      pressureRaw,
      pressureComp,
      text,
      deviceHealth,
      gps,
      runningStatus,
      errorString,
      timestamp2,
      lteStatus,
      gpsStatus,
      alarmStatus,
      dataLink,
      statusLink,
      externalSensor,
      externalInputs,
      externalOutputs,
      externalTimers,
      externalRh,
      externalTemp,
      extSensMsg
    };

    /** Stores all acc-data that doesen't have the same timestamp as primaryRow */
    let extraAccs: RecordingDataBlockUnique[] = [];
    /** Stores all angle-data that doesen't have the same timestamp as primaryRow */
    let extraAngles: RecordingDataBlockUnique[] = [];

    //Note: This expression makes sure that none of the accelerations are
    //undefined and has the same length
    if (isAccelerationsValid(curr)) {
      /** An array containg data for x,y,z. Sorted from last to first. Contains
       * value + duration for every axis as well as the duration and axis as a seperate
       * value for convinience */
      const accArr = curr
        .xAcc!.map((val, index) => {
          const xAcc = val;
          const yAcc = curr.yAcc![index];
          const zAcc = curr.zAcc![index];

          /** The axis that has a duration */
          const axis: AccelerationAxis =
            xAcc?.[1] > 0 ? "x" : yAcc?.[1] > 0 ? "y" : "z";

          /** Duration for acc in seconds */
          const durInSec =
            axis === "x"
              ? xAcc?.[1] / 1e3
              : axis === "y"
                ? yAcc?.[1] / 1e3
                : zAcc?.[1] / 1e3;

          return {
            xAcc,
            yAcc,
            zAcc,
            axis,
            durInSec
          };
        })
        .reverse();

      // Calculations for total duration for every axis during this timestamp

      // X
      const totalAccDurX = accArr.reduce(
        (count, acc) => count + acc.xAcc[1] / 1e3,
        0
      );
      const spareDurInSecX = totalAccDurX > 1 ? 0 : 0.999 - totalAccDurX;
      const spareDurInSecXPerTimestamp = spareDurInSecX / accArr.length;

      // Y
      const totalAccDurY = accArr.reduce(
        (count, acc) => count + acc.yAcc[1] / 1e3,
        0
      );
      const spareDurInSecY = totalAccDurY > 1 ? 0 : 0.999 - totalAccDurY;
      const spareDurInSecYPerTimestamp = spareDurInSecY / accArr.length;

      // Z
      const totalAccDurZ = accArr.reduce(
        (count, acc) => count + acc.zAcc[1] / 1e3,
        0
      );
      const spareDurInSecZ = totalAccDurZ > 1 ? 0 : 0.999 - totalAccDurZ;
      const spareDurInSecZPerTimestamp = spareDurInSecZ / accArr.length;

      // Checks if there should be an offset in the active timestamp. This does
      // not work well atm

      const xAccOffset =
        Math.floor(xReservedUntilTs) === timestamp
          ? 1 - (xReservedUntilTs - timestamp)
          : 0;

      const yAccOffset =
        Math.floor(yReservecUntilTs) === timestamp
          ? 1 - (yReservecUntilTs - timestamp)
          : 0;

      //skakigt men bör funka om indata korrekt
      const zAccOffset =
        Math.floor(zReservedUntilTs) === timestamp
          ? 1 - (zReservedUntilTs - timestamp)
          : 0;

      /** Start timestamp for all axis for this timestamp. Will be mutated while
       *spacing out acc-data */
      const priorTimestamp = {
        x: timestamp + 0.999 - xAccOffset,
        y: timestamp + 0.999 - yAccOffset,
        z: timestamp + 0.999 - zAccOffset
      };

      // Creating unique timestamps for every acceleration in the current "block"
      accArr.forEach((acc) => {
        const { xAcc, yAcc, zAcc, axis, durInSec } = acc;

        const uniqueTsOutcomes: Record<AccelerationAxis, () => number> = {
          x: () => priorTimestamp.x - durInSec - spareDurInSecXPerTimestamp,
          y: () => priorTimestamp.y - durInSec - spareDurInSecYPerTimestamp,
          z: () => priorTimestamp.z - durInSec - spareDurInSecZPerTimestamp
        };

        const uniqueTimestamp = uniqueTsOutcomes[axis]();

        axis === "x"
          ? (priorTimestamp.x = uniqueTimestamp)
          : axis === "y"
            ? (priorTimestamp.y = uniqueTimestamp)
            : (priorTimestamp.z = uniqueTimestamp);

        extraAccs.push({
          timestamp: uniqueTimestamp,
          xAcc,
          yAcc,
          zAcc
        });
      });

      // Reserves earlier timestamps to avoid overlaps
      xReservedUntilTs = priorTimestamp.x;
      yReservecUntilTs = priorTimestamp.y;
      zReservedUntilTs = priorTimestamp.z;
    }

    if (curr?.angle?.length) {
      const angleLength = curr.angle.length;
      const multierAngle = 0.9 / angleLength;

      curr.angle.forEach((ang, i) => {
        if (i === 0) {
          primaryRow.angle = ang;
          return;
        }

        const uniqueTimestamp = curr.timestamp + i * multierAngle;

        extraAngles.push({
          timestamp: uniqueTimestamp,
          angle: ang
        });
      });
    }

    arr.push(primaryRow, ...extraAccs, ...extraAngles);

    return arr;
  }, []);
};

/**
 * Create a new dataset without values below their alarm level (if its toggled
 * in the filters)
 * @param filters
 * @param recordingParameters
 * @param dataset
 */
export const createFilteredDataset = (
  filters: DataFilterStates,
  recordingParameters: VMRecordingParameters,
  dataset: RecordingDataBlockUnique[]
): RecordingDataBlockFiltered[] => {
  const {
    AccParams,
    TempParams,
    PressureParams,
    RhParams,
    AngleParams,
    ExternalSensorParams
  } = recordingParameters;

  const {
    Xalarm: xAccAlarm,
    Xms: xAccDur,
    Yalarm: yAccAlarm,
    Yms: yAccDur,
    Zalarm: zAccAlarm,
    Zms: zAccDur
  } = AccParams.params;

  const { lowAlarm: tempLowAlarm, highAlarm: tempHighAlarm } =
    TempParams.params;

  const { lowAlarm: pressureLowAlarm, highAlarm: pressureHighAlarm } =
    PressureParams.params;

  const { lowAlarm: rhLowAlarm, highAlarm: rhHighAlarm } = RhParams.params;

  const extRhAlarms = ExternalSensorParams.map((extRh) => {
    return {
      sensorId: extRh.params.sensorTypeId,
      rhMin: extRh.params.sensorConfig?.rhMin
        ? extRh.params.sensorConfig?.rhMin
        : 0,
      rhMax: extRh.params.sensorConfig?.rhMax
        ? extRh.params.sensorConfig?.rhMax
        : 100
    };
  });

  const extTempAlarms = ExternalSensorParams.map((extTemp) => {
    return {
      sensorId: extTemp.params.sensorTypeId,
      tempMin: extTemp.params.sensorConfig?.tempMin
        ? extTemp.params.sensorConfig?.tempMin
        : -40,
      tempMax: extTemp.params.sensorConfig?.tempMax
        ? extTemp.params.sensorConfig?.tempMax
        : 80
    };
  });

  const {
    xAlarmLevel: xAngleAlarm,
    yAlarmLevel: yAngleAlarm,
    zAlarmLevel: zAngleAlarm
  } = AngleParams.params;

  const hideXAccWithinAlarm = filters.xAcc.hideDataWithinAlarmLevel;
  const hideYAccWithinAlarm = filters.yAcc.hideDataWithinAlarmLevel;
  const hideZAccWithinAlarm = filters.zAcc.hideDataWithinAlarmLevel;

  const hideTempWithinAlarm = filters.temp.hideDataWithinAlarmLevel;
  const hideRhWithinAlarm = filters.rh.hideDataWithinAlarmLevel;
  const hidePressureWithinAlarm = filters.pressureComp.hideDataWithinAlarmLevel;

  const hideExternalTempWithinAlarm = Object.keys(filters.extTemp).reduce(
    (map, sensorId) => {
      map[sensorId] = filters.extTemp[sensorId].hideDataWithinAlarmLevel;
      return map;
    },
    {}
  );

  const hideExternalRhWithinAlarm = Object.keys(filters.extRh).reduce(
    (map, sensorId) => {
      map[sensorId] = filters.extRh[sensorId].hideDataWithinAlarmLevel;
      return map;
    },
    {}
  );

  const hideAngleWithinAlarm = filters.angle.hideDataWithinAlarmLevel;

  const dynamicXAccMin = filters.xAcc.accMin;
  const dynamicXAccMax = filters.xAcc.accMax;
  const dynamicXAccDuration = filters.xAcc.accDuration;
  const showXAccDynamicFilter = filters.xAcc.showDynamicFilter;

  const dynamicYAccMin = filters.yAcc.accMin;
  const dynamicYAccMax = filters.yAcc.accMax;
  const dynamicYAccDuration = filters.yAcc.accDuration;
  const showYAccDynamicFilter = filters.yAcc.showDynamicFilter;

  const dynamicZAccMin = filters.zAcc.accMin;
  const dynamicZAccMax = filters.zAcc.accMax;
  const dynamicZAccDuration = filters.zAcc.accDuration;
  const showZAccDynamicFilter = filters.zAcc.showDynamicFilter;

  // Inline functions

  const xAccIfValid = (d: Optional<RecordingDataBlockUnique["xAcc"]>) => {
    // Check if data exists or if the channel is toggled
    if (isNil(d) || !filters.xAcc.dataToggle.isActive) {
      return undefined;
    }

    const [value, dur] = d;
    let valueOk = true;

    const xMinOk = !isUndefined(dynamicXAccMin)
      ? Math.abs(value) >= dynamicXAccMin
      : true;
    const xMaxOk = !isUndefined(dynamicXAccMax)
      ? Math.abs(value) <= dynamicXAccMax
      : true;
    const dynamicDurOk = !isUndefined(dynamicXAccDuration)
      ? dur >= dynamicXAccDuration
      : true;

    const alarmOk = Math.abs(value) >= xAccAlarm;
    const durOk = dur >= xAccDur;

    // If dynamic filter is active and values between are OK
    if (showXAccDynamicFilter) {
      if (xMinOk && xMaxOk && dynamicDurOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    // If alarm level filter is active
    if (valueOk && hideXAccWithinAlarm) {
      if (alarmOk && durOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    return valueOk ? d : undefined;
  };

  const yAccIfValid = (d: Optional<RecordingDataBlockUnique["yAcc"]>) => {
    if (isNil(d) || !filters.yAcc.dataToggle.isActive) {
      return undefined;
    }

    const [value, dur] = d;
    let valueOk = true;

    const yMinOk = !isUndefined(dynamicYAccMin)
      ? Math.abs(value) >= dynamicYAccMin
      : true;
    const yMaxOk = !isUndefined(dynamicYAccMax)
      ? Math.abs(value) <= dynamicYAccMax
      : true;
    const dynamicDurOk = !isUndefined(dynamicYAccDuration)
      ? dur >= dynamicYAccDuration
      : true;

    const alarmOk = Math.abs(value) >= yAccAlarm;
    const durOk = dur >= yAccDur;

    // If dynamic filter is active and values between are OK
    if (showYAccDynamicFilter) {
      if (yMinOk && yMaxOk && dynamicDurOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    // If alarm level filter is active
    if (valueOk && hideYAccWithinAlarm) {
      if (alarmOk && durOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    return valueOk ? d : undefined;
  };

  const zAccIfValid = (d: Optional<RecordingDataBlockUnique["zAcc"]>) => {
    if (isNil(d) || !filters.zAcc.dataToggle.isActive) {
      return undefined;
    }

    const [value, dur] = d;
    let valueOk = true;

    const zMinOk = !isUndefined(dynamicZAccMin)
      ? Math.abs(value) >= dynamicZAccMin
      : true;
    const zMaxOk = !isUndefined(dynamicZAccMax)
      ? Math.abs(value) <= dynamicZAccMax
      : true;
    const dynamicDurOk = !isUndefined(dynamicZAccDuration)
      ? dur >= dynamicZAccDuration
      : true;

    const alarmOk = Math.abs(value) >= zAccAlarm;
    const durOk = dur >= zAccDur;

    // If dynamic filter is active and values between are OK
    if (showZAccDynamicFilter) {
      if (zMinOk && zMaxOk && dynamicDurOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    // If alarm level filter is active
    if (valueOk && hideZAccWithinAlarm) {
      if (alarmOk && durOk) {
        valueOk = true;
      } else {
        valueOk = false;
      }
    }

    return valueOk ? d : undefined;
  };

  const accsIfValid = (
    accs: [Optional<AccData>, Optional<AccData>, Optional<AccData>]
  ): [Optional<AccData>, Optional<AccData>, Optional<AccData>] => {
    const [xAcc, yAcc, zAcc] = accs;

    if (isNil(xAcc) || isNil(yAcc) || isNil(zAcc)) {
      return [undefined, undefined, undefined];
    }

    // Should return 0, 1 or 2 representing x, y and z
    const accWithDur = accs.findIndex((a) => a![1] !== 0);

    if (accWithDur === 0 && xAccIfValid(xAcc)) return accs!;
    if (accWithDur === 1 && yAccIfValid(yAcc)) return accs!;
    if (accWithDur === 2 && zAccIfValid(zAcc)) return accs!;

    return [undefined, undefined, undefined];
  };

  const accComponentIfValid = (
    accs: [Optional<AccData>, Optional<AccData>, Optional<AccData>]
  ): Optional<AccData> => {
    const [xAcc, yAcc, zAcc] = accs;
    if (isNil(xAcc) || isNil(yAcc) || isNil(zAcc)) {
      return undefined;
    }

    // Calculate the acceleration component
    const value = Math.sqrt(
      Math.pow(xAcc[0], 2) + Math.pow(yAcc[0], 2) + Math.pow(zAcc[0], 2)
    );
    const dur = Math.max(xAcc[1], yAcc[1], zAcc[1]);
    const accComponent: AccData = [value, dur];

    let alarmOk = true;

    // Compare component with the alarm levels and durations
    if (
      (Math.abs(value) >= xAccAlarm && dur >= xAccDur) ||
      (Math.abs(value) >= yAccAlarm && dur >= yAccDur) ||
      (Math.abs(value) >= zAccAlarm && dur >= zAccDur)
    ) {
      alarmOk = true;
    } else {
      alarmOk = false;
    }

    // If alarm level filter is active
    if (
      !alarmOk &&
      (hideXAccWithinAlarm || hideYAccWithinAlarm || hideZAccWithinAlarm)
    ) {
      return undefined;
    } else {
      return accComponent;
    }
  };

  const tempIfValid = (d: RecordingDataBlockFiltered["temp"]) => {
    if (isNil(d) || !filters.temp.dataToggle.isActive) {
      return undefined;
    }

    const tempOk = !(tempLowAlarm <= d && d <= tempHighAlarm);

    return hideTempWithinAlarm && !tempOk ? null : d;
  };

  const rhIfValid = (d: RecordingDataBlockFiltered["rh"]) => {
    if (isNil(d) || !filters.rh.dataToggle.isActive) {
      return undefined;
    }

    const rhOk = !(rhLowAlarm <= d && d <= rhHighAlarm);

    return hideRhWithinAlarm && !rhOk ? null : d;
  };

  const pressureRawIfValid = (
    d: Optional<RecordingDataBlockUnique["pressureRaw"]>
  ) => {
    if (isNil(d) || !filters.pressureRaw.dataToggle.isActive) {
      return undefined;
    }

    const pressureOk = !(pressureLowAlarm <= d && d <= pressureHighAlarm);

    return hidePressureWithinAlarm && !pressureOk ? null : d;
  };

  const pressureCompIfValid = (
    d: Optional<RecordingDataBlockUnique["pressureComp"]>
  ) => {
    if (isNil(d) || !filters.pressureComp.dataToggle.isActive) {
      return undefined;
    }

    const pressureOk = !(pressureLowAlarm <= d && d <= pressureHighAlarm);

    return hidePressureWithinAlarm && !pressureOk ? null : d;
  };

  const angleIfValid = (d: Optional<RecordingDataBlockUnique["angle"]>) => {
    if (isNil(d) || !filters.angle.dataToggle.isActive) {
      return undefined;
    }

    const [xAngle, yAngle, zAngle] = d;

    const xAngleOk = xAngle >= xAngleAlarm;
    const yAngleOk = yAngle >= yAngleAlarm;
    const zAngleOk = zAngle >= zAngleAlarm;

    const anglesOk = xAngleOk || yAngleOk || zAngleOk;

    return hideAngleWithinAlarm && !anglesOk ? undefined : d;
  };

  const extRhIfValid = (
    d: Optional<RecordingDataBlockFiltered["externalRh"]>
  ) => {
    if (isNil(d)) {
      return undefined;
    }

    const { sensorId, rh } = d;

    const sensor = extRhAlarms.find((extRh) => extRh.sensorId === sensorId);

    const rhOk =
      !isUndefined(sensor) && !(sensor.rhMin <= rh && rh <= sensor.rhMax);

    return hideExternalRhWithinAlarm[sensorId] && !rhOk ? null : d;
  };

  const extTempIfValid = (
    d: Optional<RecordingDataBlockFiltered["externalTemp"]>
  ) => {
    if (isNil(d)) {
      return undefined;
    }

    const { sensorId, temp } = d;

    const sensor = extTempAlarms.find(
      (extTemp) => extTemp.sensorId === sensorId
    );

    const tempOk =
      !isUndefined(sensor) &&
      !(sensor.tempMin <= temp && temp <= sensor.tempMax);

    return hideExternalTempWithinAlarm[sensorId] && !tempOk ? null : d;
  };

  const runningStatusIfValid = (
    d: RecordingDataBlockFiltered["runningStatus"]
  ) => {
    if (isNil(d) || d?.length === 0) {
      return undefined;
    }
    return d;
  };

  /** Returns true if any value excluding timestamp in newItem has a value */
  const shouldIncludeNewItem = (newItem: RecordingDataBlockFiltered) => {
    const withoutTimestamp = getRecordingDataBlockWithoutTimestamp(newItem);

    return Object.values(withoutTimestamp).some((x) => x !== undefined);
  };

  /** only include channels if they are outside their alarm level (if its
   * toggled on). If the resulting object no longer has any values besides
   * timestamp it will be filtered out */
  return dataset.reduce((arr: RecordingDataBlockFiltered[], item) => {
    const [xAcc, yAcc, zAcc] = accsIfValid([item.xAcc, item.yAcc, item.zAcc]);
    const temp = tempIfValid(item.temp);
    const rh = rhIfValid(item.rh);
    const pressureRaw = pressureRawIfValid(item.pressureRaw);
    const pressureComp = pressureCompIfValid(item.pressureComp);
    const angle = angleIfValid(item.angle);
    const accComponent = accComponentIfValid([item.xAcc, item.yAcc, item.zAcc]);
    const externalRh = extRhIfValid(item.externalRh);
    const externalTemp = extTempIfValid(item.externalTemp);
    const runningStatus = runningStatusIfValid(item.runningStatus);
    // todo: add the rest of the channels here

    const newItem: RecordingDataBlockFiltered = {
      ...item,
      xAcc,
      yAcc,
      zAcc,
      temp,
      rh,
      pressureRaw,
      pressureComp,
      angle,
      accComponent,
      externalRh,
      externalTemp,
      runningStatus
    };

    if (shouldIncludeNewItem(newItem)) {
      arr.push(newItem);
    }

    return arr;
  }, []);
};

export const getRecordingDataBlockWithoutTimestamp = (
  item: RecordingDataBlockFiltered
): Partial<RecordingDataBlockFiltered> => {
  return { ...item, timestamp: undefined };
};

export type extTempItemBlock = Require<
  RecordingDataBlockUnique,
  "externalTemp"
>;
export type extRhItemBlock = Require<RecordingDataBlockUnique, "externalRh">;

/** A type that will include min/max recording data-block for every available
 * datachannel in a datast */
export interface MinMaxRecordingDataBlocks {
  xAcc?: MinMaxType<Require<RecordingDataBlockUnique, "xAcc">>;
  yAcc?: MinMaxType<Require<RecordingDataBlockUnique, "yAcc">>;
  zAcc?: MinMaxType<Require<RecordingDataBlockUnique, "zAcc">>;
  temp?: MinMaxType<Require<RecordingDataBlockUnique, "temp">>;
  rh?: MinMaxType<Require<RecordingDataBlockUnique, "rh">>;
  pressureRaw?: MinMaxType<Require<RecordingDataBlockUnique, "pressureRaw">>;
  pressureComp?: MinMaxType<Require<RecordingDataBlockUnique, "pressureComp">>;
  xAngle?: MinMaxType<Require<RecordingDataBlockUnique, "angle">>;
  yAngle?: MinMaxType<Require<RecordingDataBlockUnique, "angle">>;
  zAngle?: MinMaxType<Require<RecordingDataBlockUnique, "angle">>;
  externalTemp?: Array<MinMaxDataExtSensor<extTempItemBlock>>;
  externalRh?: Array<MinMaxDataExtSensor<extRhItemBlock>>;
}

/** General min/max type */
interface MinMaxType<T extends RecordingDataBlockUnique> {
  min: T;
  max: T;
}

interface MinMaxDataExtSensor<T extends RecordingDataBlockUnique> {
  sensorId: number;
  min: T;
  max: T;
}

export const isMinMaxType = (data: any): data is MinMaxType<any> => {
  return (
    data &&
    data.hasOwnProperty("min") &&
    data.hasOwnProperty("max") &&
    typeof data.min === "object" &&
    typeof data.max === "object"
  );
};

/**
 * Get min/max for every available data channel with data given the {dataset}
 * @param dataset
 */
export const getMinMaxDataset = (
  dataset: RecordingDataBlockUnique[]
): MinMaxRecordingDataBlocks => {
  const initObj: MinMaxRecordingDataBlocks = {
    xAcc: undefined,
    yAcc: undefined,
    zAcc: undefined,
    temp: undefined,
    rh: undefined,
    pressureRaw: undefined,
    pressureComp: undefined,
    xAngle: undefined,
    yAngle: undefined,
    zAngle: undefined,
    externalRh: undefined,
    externalTemp: undefined
  };

  const res = dataset.reduce((obj: MinMaxRecordingDataBlocks, item) => {
    const {
      xAcc,
      yAcc,
      zAcc,
      temp,
      rh,
      pressureRaw,
      pressureComp,
      angle,
      externalRh,
      externalTemp
    } = item;

    if (!isUndefined(xAcc)) {
      /** The measured value of the acceleration */
      const xVal = xAcc[0];

      //set new max for xAcc
      if (
        isNil(obj.xAcc?.max) ||
        Math.abs(xVal) > Math.abs(obj.xAcc!.max.xAcc[0])
      ) {
        set(obj, "xAcc.max", item);
      }

      //set new min for xAcc
      if (isNil(obj.xAcc?.min) || xVal < obj.xAcc!.min.xAcc[0]) {
        set(obj, "xAcc.min", item);
      }
    }

    if (!isUndefined(yAcc)) {
      /** The measured value of the acceleration */
      const yVal = yAcc[0];

      //set new max for yAcc
      if (isNil(obj.yAcc?.max) || yVal > obj.yAcc!.max.yAcc[0]) {
        set(obj, "yAcc.max", item);
      }

      //set new min for yAcc
      if (isNil(obj.yAcc?.min) || yVal < obj.yAcc!.min.yAcc[0]) {
        set(obj, "yAcc.min", item);
      }
    }

    if (!isUndefined(zAcc)) {
      /** The measured value of the acceleration */
      const zVal = zAcc[0];

      //set new max for zAcc
      if (isNil(obj.zAcc?.max) || zVal > obj.zAcc!.max.zAcc[0]) {
        set(obj, "zAcc.max", item);
      }

      //set new min for zAcc
      if (isNil(obj.zAcc?.min) || zVal < obj.zAcc!.min.zAcc[0]) {
        set(obj, "zAcc.min", item);
      }
    }

    if (!isUndefined(temp)) {
      //set new max for temp
      if (isNil(obj.temp?.max) || temp > obj.temp!.max.temp) {
        set(obj, "temp.max", item);
      }

      //set new min for temp
      if (isNil(obj.temp?.min) || temp < obj.temp!.min.temp) {
        set(obj, "temp.min", item);
      }
    }

    if (!isUndefined(rh)) {
      //set new max for rh
      if (isNil(obj.rh?.max) || rh > obj.rh!.max.rh) {
        set(obj, "rh.max", item);
      }

      //set new min for rh
      if (isNil(obj.rh?.min) || rh < obj.rh!.min.rh) {
        set(obj, "rh.min", item);
      }
    }

    if (!isUndefined(pressureRaw)) {
      //set new max for pressureRaw
      if (
        isNil(obj.pressureRaw?.max) ||
        pressureRaw > obj.pressureRaw!.max.pressureRaw
      ) {
        set(obj, "pressureRaw.max", item);
      }

      //set new min for pressureRaw
      if (
        isNil(obj.pressureRaw?.min) ||
        pressureRaw < obj.pressureRaw!.min.pressureRaw
      ) {
        set(obj, "pressureRaw.min", item);
      }
    }

    if (!isUndefined(pressureComp)) {
      //set new max for pressureComp
      if (
        isNil(obj.pressureComp?.max) ||
        pressureComp > obj.pressureComp!.max.pressureComp
      ) {
        set(obj, "pressureComp.max", item);
      }

      //set new min for pressureComp
      if (
        isNil(obj.pressureComp?.min) ||
        pressureComp < obj.pressureComp!.min.pressureComp
      ) {
        set(obj, "pressureComp.min", item);
      }
    }

    if (!isUndefined(angle)) {
      /** Local variables for every axis */
      const [xAxis, yAxis, zAxis] = angle;

      //set new max for xAngle
      if (isNil(obj.xAngle?.max) || xAxis > obj.xAngle!.max.angle[0]) {
        set(obj, "xAngle.max", item);
      }

      //set new min for xAngle
      if (isNil(obj.xAngle?.min) || xAxis < obj.xAngle!.min.angle[0]) {
        set(obj, "xAngle.min", item);
      }

      //set new max for yAngle
      if (isNil(obj.yAngle?.max) || yAxis > obj.yAngle!.max.angle[1]) {
        set(obj, "yAngle.max", item);
      }

      //set new min for yAngle
      if (isNil(obj.yAngle?.min) || yAxis < obj.yAngle!.min.angle[1]) {
        set(obj, "yAngle.min", item);
      }

      //set new max for zAngle
      if (isNil(obj.zAngle?.max) || zAxis > obj.zAngle!.max.angle[2]) {
        set(obj, "zAngle.max", item);
      }

      //set new min for zAngle
      if (isNil(obj.zAngle?.min) || zAxis < obj.zAngle!.min.angle[2]) {
        set(obj, "zAngle.min", item);
      }
    }

    if (!isUndefined(externalTemp)) {
      const sensorId = externalTemp.sensorId;
      const tempItem = item as extTempItemBlock;

      // If no externalTemp is found in obj it is created as an empty array
      if (obj.externalTemp === undefined) {
        obj.externalTemp = [];
      }

      // Get the existing sensor with the same sensorId
      const existingSensor = obj.externalTemp.find(
        (slot) => slot.sensorId === sensorId
      );

      if (existingSensor !== undefined) {
        //set new max for externalTemp with the same sensorId
        if (externalTemp.temp > existingSensor.max.externalTemp.temp) {
          set(existingSensor, "max", tempItem);
        }

        //set new min for externalTemp with the same sensorId
        if (externalTemp.temp < existingSensor.min.externalTemp.temp) {
          set(existingSensor, "min", tempItem);
        }
      } else {
        obj.externalTemp.push({ sensorId, max: tempItem, min: tempItem });
      }
    }

    if (!isUndefined(externalRh)) {
      const sensorId = externalRh.sensorId;
      const rhItem = item as extRhItemBlock;

      // If no externalRh is found in obj it is created as an empty array
      if (obj.externalRh === undefined) {
        obj.externalRh = [];
      }

      // Get the existing sensor with the same sensorId
      const existingSensor = obj.externalRh.find(
        (slot) => slot.sensorId === sensorId
      );

      if (existingSensor !== undefined) {
        //set new max for externalRh with the same sensorId
        if (externalRh.rh > existingSensor.max.externalRh.rh) {
          set(existingSensor, "max", rhItem);
        }

        //set new min for externalRh with the same sensorId
        if (externalRh.rh < existingSensor.min.externalRh.rh) {
          set(existingSensor, "min", rhItem);
        }
      } else {
        obj.externalRh.push({ sensorId, max: rhItem, min: rhItem });
      }
    }

    return obj;
  }, initObj);

  return res;
};
