import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Input, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  size,
  impersonatingHeight143,
  impersonatingHeight320
} from "../../helpers/pageHelper";
import {
  DangerButtonSmall,
  NormalButtonSmall,
  PrimaryButton
} from "../Common/CommonButtons";
import { useGetAllLicensePricesQuery } from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import CreateLicensePriceModal from "./CreateLicensePriceModal";
import RemoveLicensePriceModal from "../Modals/RemoveLicensePriceModal";
import EditLicensePriceModal from "./EditLicensePriceModal";
import { useDispatch, useSelector } from "react-redux";
import {
  openCreateLicenseModal,
  openEditLicensePriceModal,
  openRemoveLicensePriceModal
} from "../../state/modalsSlice";
import {
  setLicensePriceToRemove,
  setLicensePriceToEdit
} from "../../state/licensePriceSlice";
import { selectImpersonate } from "../../state/persistantStateSlice";

/** All the fields for table used in this component */
export interface ITableData {
  key: number;
  id: string;
  name: string;
  description: string;
  actions?: IAdminStoreActions;
}

/** all aviable actions for a field in the table */
interface IAdminStoreActions {
  edit: () => void;
  remove: () => void;
}

const MobitronAdminStore = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isImpersonating } = useSelector(selectImpersonate);

  const columns: ColumnsType<ITableData> = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      width: 20,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: t("tableStoreAdminDesc"),
      dataIndex: "description",
      key: "description",
      width: 60,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      width: 20,
      render: (actions: IAdminStoreActions) => {
        if (isUndefined(actions)) {
          return <></>;
        } else {
          return (
            <Space>
              <NormalButtonSmall icon={<EditOutlined />} onClick={actions.edit}>
                {t("genEdit")}
              </NormalButtonSmall>

              <DangerButtonSmall
                danger
                icon={<DeleteOutlined />}
                onClick={actions.remove}
              >
                {t("genDelete")}
              </DangerButtonSmall>
            </Space>
          );
        }
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  let tableData: ITableData[] | undefined = undefined;

  const { data: licensPrices, isLoading: priceIsLoading } =
    useGetAllLicensePricesQuery();

  if (licensPrices) {
    tableData = licensPrices
      .filter((e) => {
        const dataContainsInput =
          stringValue === "" ||
          e.name.toLowerCase().includes(stringValue.toLowerCase()) ||
          e.description.toLowerCase().includes(stringValue.toLowerCase());
        return dataContainsInput;
      })
      .map((licenseprice, index) => {
        let row = {
          key: index,
          id: licenseprice.id ?? "",
          name: licenseprice.name ?? "",
          description: licenseprice.description,
          actions: {
            edit: () => {
              dispatch(setLicensePriceToEdit(licenseprice));
              dispatch(openEditLicensePriceModal());
            },
            remove: () => {
              dispatch(setLicensePriceToRemove(licenseprice));
              dispatch(openRemoveLicensePriceModal());
            }
          }
        };
        return row;
      });
  }

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("licensesMainMenuTitle")}</LargeHeaderTitle>

              <PrimaryButton
                style={{ marginLeft: size.m1 }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => dispatch(openCreateLicenseModal())}
              >
                {t("tableStoreAdminNewLicenseBtn")}
              </PrimaryButton>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("FilterLicenses")}
              prefix={<SearchOutlined />}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            size="small"
            loading={priceIsLoading}
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>

      <EditLicensePriceModal />
      <CreateLicensePriceModal />
      <RemoveLicensePriceModal />
    </>
  );
};

export default MobitronAdminStore;
