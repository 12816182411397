import React from "react";
import { Card, Row, Col, Select } from "antd";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefaultBattery,
  setDefaultBattery
} from "../../state/sessionSlice";
import { useTranslation } from "react-i18next";
import { selectBatteries } from "../../state/persistantStateSlice";
const { Option } = Select;

const DefaultBatteryCard = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const batteries = useSelector(selectBatteries);
  const defaultBattery = useSelector(selectDefaultBattery);
  const selectAndButtonWidth = 190;
  return (
    <Card style={{ marginBottom: size.m1 }}>
      <Row style={{ marginBottom: size.m1 }}>
        <SmallText strong>{t("defaultBattery")}</SmallText>
      </Row>
      <Row align="middle" style={{ paddingLeft: size.m1 }}>
        <Col span={12}>
          <SmallText>{t("selectDefaultBattery")}:</SmallText>
        </Col>
        <Col>
          <Select
            style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
            defaultValue={defaultBattery}
            onChange={(defaultBatteryValue) => {
              dispatch(setDefaultBattery(defaultBatteryValue));
            }}
            // Keeps Dropdown in place when scrolling
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            {batteries.map((battery, index) => {
              return (
                <Option key={index} value={battery.name}>
                  {battery.name}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default DefaultBatteryCard;
