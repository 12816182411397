import React, { useEffect, useState } from "react";
import { Divider, Input, Popover, Radio, Row, Space } from "antd";
import { SmallTitle } from "../Common/CommonFonts";
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from "@ant-design/icons";
import mobitronColors from "../../styles/mobitronColors";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOpenDatxActiveDashboard,
  selectDashboardInfoById
} from "../../state/openDatxSlice";
import {
  changeDashboard,
  nameDashboard,
  removeDashboard,
  renameDashboard,
  selectDashboard,
  selectRenameActiveDashboard,
  setIsRenaming
} from "../../state/persistantStateSlice";
import {
  GhostButton,
  DangerButtonSmall,
  NormalButtonSmall,
  MenuButton
} from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

const PlusIcon = () => (
  <PlusOutlined style={{ color: mobitronColors.altDarkGreen }} />
);

const AddOutlinedIcon = () => (
  <AppstoreAddOutlined style={{ color: mobitronColors.altDarkGreen }} />
);

interface IProps {
  showLayoutDrawer: () => void;
  fileId: string;
}
const MyDashboards = (props: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dashboardInfo = useSelector(selectDashboardInfoById(props.fileId));
  const { isRenaming, renamingActive } = useSelector(
    selectRenameActiveDashboard
  );

  const [isVisible, setIsVisible] = useState(false);
  const [isNamingNewDashboard, setIsNamingNewDashboard] = useState(false);

  const dashboard = useSelector(selectDashboard);
  const [isEditing, setIsEditing] = useState<string>();

  const hideDropDown = () => {
    setIsVisible(false);
    setIsNamingNewDashboard(false);
  };

  const handleAddCards = () => {
    hideDropDown();
    props.showLayoutDrawer();
  };

  const changeToDashboard = (dashboardKey: string) => {
    const { fileId } = props;

    if (!isEditing) {
      dispatch(
        changeOpenDatxActiveDashboard({
          fileId,
          newDashboardKey: dashboardKey
        })
      );
      dispatch(changeDashboard({ dashboardKey }));
    }
  };

  const createNewDashboard = (newName: string, oldDashboardKey: string) => {
    dispatch(nameDashboard({ newName, dashboardKey: oldDashboardKey }));
    dispatch(
      changeOpenDatxActiveDashboard({
        fileId: props.fileId,
        newDashboardKey: newName
      })
    );
  };

  const renameADashboard = (oldDashboardKey: string, newName: string) => {
    dispatch(renameDashboard({ dashboardKey: oldDashboardKey, newName }));
  };

  /** Reset to previus layout if editing the selected layout or else just cancel edit */
  const cancelRename = (value: string) => {
    if (renamingActive === true) {
      dispatch(
        changeOpenDatxActiveDashboard({
          fileId: props.fileId,
          newDashboardKey: value
        })
      );
      dispatch(changeDashboard({ dashboardKey: value }));
    } else {
      dispatch(setIsRenaming({ isRenaming: false, renamingActive: false }));
    }
    setIsEditing(undefined);
  };

  const deleteDashboard = (dashboardKey: string) => {
    dispatch(removeDashboard({ dashboardKey: dashboardKey }));
  };

  const [newKey, setNewKey] = useState<string>("");

  // Change back to previusly active layout after editing name
  useEffect(() => {
    if (isRenaming === true && renamingActive === true) {
      dispatch(changeDashboard({ dashboardKey: newKey }));
      dispatch(
        changeOpenDatxActiveDashboard({
          fileId: props.fileId,
          newDashboardKey: newKey
        })
      );
    }
  }, [isRenaming]);

  const overlayContent = (
    <div>
      {/* title */}
      <Row style={{ paddingInline: size.m2, paddingBlock: size.s2 }}>
        <SmallTitle>{t("Reports")}</SmallTitle>
      </Row>

      {/* content 1 */}
      <div style={{ width: 315 }}>
        {Object.values(dashboardInfo.available).map((item, index) => (
          <React.Fragment key={index}>
            <Row
              align="middle"
              justify="space-between"
              style={{
                paddingLeft: size.m2,
                paddingRight: size.s2,
                paddingBottom: size.s2
              }}
            >
              <div
                onClick={() => changeToDashboard(item.name)}
                style={{ width: 220 }}
              >
                {isEditing !== item.name ? (
                  <Radio
                    checked={item.name === dashboardInfo.active}
                    value={item.name}
                    name={item.name}
                  >
                    {item.name}
                  </Radio>
                ) : (
                  <Input
                    autoFocus={true}
                    minLength={1}
                    maxLength={25}
                    defaultValue={item.name}
                    name={item.name}
                    variant="borderless"
                    style={{ marginLeft: size.m1 }}
                    onBlur={(e) => cancelRename(e.currentTarget.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Esc" || e.key === "Escape") {
                        cancelRename(e.currentTarget.value);
                      }
                    }}
                    onPressEnter={(e) => {
                      setIsEditing(undefined);
                      setNewKey(e.currentTarget.value);
                      renameADashboard(item.name, e.currentTarget.value);
                    }}
                  />
                )}
              </div>
              <Space>
                <NormalButtonSmall
                  style={{
                    paddingLeft: size.s1,
                    paddingRight: size.s1,
                    boxShadow: "none",
                    visibility: item.isReadOnly ? "hidden" : "visible"
                  }}
                  onClick={() => {
                    if (item.name === dashboard.active) {
                      changeToDashboard("Standard");
                      dispatch(
                        setIsRenaming({
                          isRenaming: false,
                          renamingActive: true
                        })
                      );
                    }
                    setIsEditing(item.name);
                  }}
                >
                  <EditOutlined />
                </NormalButtonSmall>

                <DangerButtonSmall
                  style={{
                    paddingLeft: size.s1,
                    paddingRight: size.s1,
                    boxShadow: "none",
                    visibility: item.isReadOnly ? "hidden" : "visible"
                  }}
                  onClick={() => {
                    if (item.name === dashboard.active) {
                      changeToDashboard("Standard");
                    }
                    deleteDashboard(item.name);
                  }}
                >
                  <DeleteOutlined />
                </DangerButtonSmall>
              </Space>
            </Row>
          </React.Fragment>
        ))}

        <Divider style={{ margin: 0 }} />
        {/* content 2 */}
        <GhostButton
          style={{ width: "100%", textAlign: "left", paddingInline: size.m2 }}
          key="addDashboard"
          icon={<PlusIcon />}
          onClick={(e) => {
            setIsNamingNewDashboard(true);
          }}
        >
          {isNamingNewDashboard ? (
            <Input
              placeholder={t("ReportName")}
              bordered={false}
              autoFocus={true}
              maxLength={25}
              style={{ width: 240, paddingTop: 0, paddingBottom: 0 }}
              onPressEnter={(e) => {
                setIsNamingNewDashboard(false);
                const newName = e.currentTarget.value;
                const dashboardKey = dashboardInfo.active;
                createNewDashboard(newName, dashboardKey);
              }}
              onKeyDown={(e) => {
                if (e.key === "Esc" || e.key === "Escape") {
                  setIsNamingNewDashboard(false);
                }
              }}
            />
          ) : (
            t("NewReport")
          )}
        </GhostButton>
        <Divider style={{ margin: 0 }} />
        <GhostButton
          disabled={dashboardInfo.active === "Standard"}
          key="addCards"
          icon={<AddOutlinedIcon />}
          onClick={handleAddCards}
          style={{ width: "100%", textAlign: "left" }}
        >
          {dashboardInfo.active === "Standard"
            ? t("AddCardsDisabledInStandard")
            : `${t("AddCardsTo")} '${dashboard.active}'`}
        </GhostButton>
      </div>
    </div>
  );

  return (
    <Popover
      content={overlayContent}
      placement="bottomRight"
      trigger={["click"]}
      open={isVisible}
      overlayInnerStyle={{ padding: 0 }}
      onOpenChange={(open) => (open ? setIsVisible(true) : hideDropDown())}
    >
      <MenuButton>{dashboardInfo.active} </MenuButton>
    </Popover>
  );
};

export default MyDashboards;
