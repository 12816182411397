import React from "react";
import { ExtraButtonItem, emptyFunction } from "../Common/MoveableCardProps";
import { useTranslation } from "react-i18next";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import MoveableCard from "../Common/MoveableCard";
import AngleTable from "./AngleTable";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import {
  TransportHeaderData,
  DocumentProps,
  ItemHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import { useSelector } from "react-redux";
import {
  selectAngleData,
  selectDashboardHeader,
  selectForDashboardRangePicker,
  selectGraphDomains,
  selectProjectInfo,
  selectTimezoneById
} from "../../state/openDatxSlice";
import {
  createDateTupleWithOffset,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { getUser, selectCsvFormat } from "../../state/sessionSlice";
import dayjs from "dayjs";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import { angleDataToCsv } from "../../helpers/dataExportHelper";

interface IProps {
  fileId: string;
  dragHandle: string;
  width: number;
  height: number;
  closeCard: ExtraButtonItem;
}
const AngleReportsCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const angleData = useSelector(selectAngleData(props.fileId));
  const timezone = useSelector(selectTimezoneById(props.fileId));
  const projectInfo = useSelector(selectProjectInfo(props.fileId));
  const domains = useSelector(selectGraphDomains(props.fileId));
  const csvFormat = useSelector(selectCsvFormat);

  const { dataDomain } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );
  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;
  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  const exportDatxAsCsv = () => {
    const datxAsCsv = angleDataToCsv(angleData, csvFormat, timezone, t);

    saveAsCsv(datxAsCsv);
  };

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      pdfAction: startExport,
      csvAction: exportDatxAsCsv
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  const documentProps: DocumentProps = {
    documentTitle: t("angle"),
    fileName: "angle_data.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "angle",
    itemTitle: t("Angle")
  };

  const transportHeader: TransportHeaderData = {
    projectName: projectInfo.projectName ?? "",
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart:
      dayjs(domains.zoomDomain.x[0])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportEnd:
      dayjs(domains.zoomDomain.x[1])
        .tz(timezone)
        .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <MoveableCard
      title={t("Angle")}
      dragHandle={props.dragHandle}
      closeMe={props.closeCard}
      extraButtons={extraButtons}
      fileId={props.fileId}
    >
      {isExporting && (
        <PdfExportComponent
          ComponentBody={AngleTable}
          componentProps={{ data: angleData, timezone }}
          documentProps={documentProps}
          itemHeader={itemHeader}
          transportHeader={transportHeader}
          reportExportDone={finishExport}
        />
      )}
      <AngleTable data={angleData} timezone={timezone} />
    </MoveableCard>
  );
};

export default AngleReportsCard;
