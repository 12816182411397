import React, { useState } from "react";
import { Form, Row, Col, Input, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeEditProjectModal, projectsState } from "../../state/projectSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import {
  ProjectForUpdate,
  useProjectByIdQuery,
  useUpdateProjectTitleDescriptionMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import { size } from "../../helpers/pageHelper";
import { skipToken } from "@reduxjs/toolkit/query";
import StandardModal from "../Common/StandardModal";

const { TextArea } = Input;

const EditProjectModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isEditProjectModalOpen, selectedProjectId } =
    useSelector(projectsState);

  const { data: projectById } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const [updateProjectTitleDesc, requestStatus] =
    useUpdateProjectTitleDescriptionMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [title, setTitle] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();

  let project: ProjectForUpdate = {
    projectId: "",
    title: "",
    description: ""
  };

  if (!isUndefined(projectById)) {
    project = {
      projectId: projectById.id,
      title: projectById.title,
      description: projectById.description ?? ""
    };
  }

  const handleUpdateProject = async (request: ProjectForUpdate) => {
    const result: any = await updateProjectTitleDesc(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyUpdatedProject") });
      dispatch(closeEditProjectModal());
    } else {
      notification.error({ message: t("UnableToUpdateProject") });
    }
  };

  const onFinish = () => {
    const newId = project.projectId;
    const newTitle = !isUndefined(title) ? title : project.title;
    const newDescription = !isUndefined(description)
      ? description
      : project.description;

    if (!isUndefined(newTitle) && !isUndefined(newDescription)) {
      const request: ProjectForUpdate = {
        projectId: newId,
        title: newTitle,
        description: newDescription
      };
      handleUpdateProject(request);
    }
  };

  return (
    <StandardModal
      title={t("EditProject")}
      open={isEditProjectModalOpen}
      closable={true}
      destroyOnClose={true}
      zIndex={1045}
      footer={null}
      onCancel={() => {
        dispatch(closeEditProjectModal());
        setTitle(undefined);
        setDescription(undefined);
      }}
    >
      <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
        <Row>
          <Col>
            <Form.Item
              label={t("ProjectTitle")}
              name="projectTitle"
              rules={[{ required: true, message: t("ProjectTitleIsRequired") }]}
              initialValue={!isUndefined(project?.title) ? project?.title : ""}
            >
              <Input
                value={title}
                maxLength={50}
                style={{ width: "300px" }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingBottom: size.m1 }}>
          <Col span={24}>
            <Form.Item
              label={t("ProjectDescription")}
              name="projectDescription"
              initialValue={
                !isUndefined(project?.description) ? project?.description : ""
              }
            >
              <TextArea
                rows={3}
                maxLength={512}
                showCount
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
          >
            {t("SaveChanges")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default EditProjectModal;
