import React, { useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Typography, Input, Menu, Popover, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  size,
  impersonatingHeight143,
  impersonatingHeight320
} from "../../helpers/pageHelper";
import {
  NormalButton,
  NormalButtonSmall,
  PrimaryButton,
  DangerButtonSmall
} from "../Common/CommonButtons";
import {
  LicenseWithCost,
  useGetUsersQuery,
  useGetLicensesQuery,
  useAssignLicenseToUserMutation,
  useRemoveLicenseFromUserMutation
} from "../../state/cargologRestApi";
import { isNil, isUndefined } from "lodash-es";
import CreateUserModal from "../Modals/CreateUserModal";
import {
  openCreateUserModal,
  openEditUserModal,
  openRemoveUserModal,
  setUserToRemove,
  setUser,
  openUserRequestAdminModal
} from "../../state/modalsSlice";
import RemoveUserModal from "../Modals/RemoveUserModal";
import EditUserModal from "../Modals/EditUserModal";
import dayjs from "dayjs";
import UserRequestAdminModal from "./UserRequestAdminModal";
import { selectImpersonate } from "../../state/persistantStateSlice";
import { getUser } from "../../state/sessionSlice";
import {
  openRenewalModal,
  addToRenewalCart
} from "../../state/licenseStoreSlice";

const { Text, Title } = Typography;

/** All the fields for table used in this component */
export interface ITableData {
  name: string;
  email: string;
  userdata: IUserData;
  licenses?: {
    current?: LicenseWithCost[];
    userId: string;
  };
  actions?: IUserActions;
  key: string | number;
}

/** user data fields in the table */
interface IUserData {
  name: string;
  email: string;
}

/** all aviable actions for a field in the table */
interface IUserActions {
  edit: () => void;
  remove: () => void;
  userId: string;
}

const AdminUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);
  const { userId } = useSelector(getUser);

  const [removeLicenseFromUser] = useRemoveLicenseFromUserMutation();
  const [assignLicenseToUser] = useAssignLicenseToUserMutation();

  // Load users from API
  const { data: usersData, isLoading: usersLoading } = useGetUsersQuery();
  const { data: companyLicenses, isLoading: licensesLoading } =
    useGetLicensesQuery({});
  const availableLicenses = companyLicenses?.filter(
    (license) => !license.userId && license.isActivated
  );

  const timezoneOffset = new Date().getTimezoneOffset();
  const licenseInfo = (license: LicenseWithCost) => {
    const expires = dayjs
      .utc(license.endDate)
      .subtract(timezoneOffset, "minutes")
      .format("YYYY-MM-DD");
    return (
      <>
        <Text strong>{license.text} </Text>
        {license.deviceType > 0 && (
          <Text type="secondary">
            {license.deviceType === 1 ? t("cargolog3rdGen") : null}
            {license.deviceType === 2 ? t("cargologEasy") : null}
            {license.deviceType === 3 ? t("vehicle") : null}{" "}
          </Text>
        )}{" "}
        <Text>
          {license.renewAuto ? t("Renews") + ":" : t("Expires") + ":"} {expires}
        </Text>
      </>
    );
  };

  const availableLicensesMenu = (userId: string) => {
    return (
      <Menu style={{ maxHeight: 200, overflowY: "auto", border: 0 }}>
        {availableLicenses && availableLicenses.length > 0 ? (
          availableLicenses?.map((license, index) => (
            <Menu.Item
              onClick={() => addLicense(license.id, userId)}
              style={{
                margin: 0,
                padding: 0,
                height: "24px",
                lineHeight: "24px"
              }}
              key={index}
            >
              {licenseInfo(license)}
            </Menu.Item>
          ))
        ) : (
          <Menu.Item disabled>
            <Text type="secondary">{t("NoAvailableLicenses")}</Text>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const removeLicense = (licenseId: string) => {
    removeLicenseFromUser({ licenseId: licenseId });
  };

  const addLicense = (licenseId: string, userId: string) => {
    assignLicenseToUser({
      userId: userId,
      licenseId: licenseId
    });
  };

  const columns: ColumnsType<ITableData> = [
    {
      title: t("tableName"),
      dataIndex: "userdata",
      key: "userdata",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      width: 20,
      render: (userdata: IUserData) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {userdata.name}
          </Title>
          <Text type="secondary">{userdata.email}</Text>
        </>
      )
    },
    {
      title: t("Licenses"),
      dataIndex: "licenses",
      key: "licenses",
      width: 60,
      render: (licenses) => {
        let buffer: any[] = [];

        // Available licenses
        buffer.push(
          <React.Fragment key={"availableLicenses"}>
            <Popover
              trigger={["click"]}
              // Keeps Dropdown menu in place when scrolling the table
              getPopupContainer={(triggerNode: HTMLElement) =>
                tableData && tableData.length > 4
                  ? (triggerNode.parentNode as HTMLElement)
                  : document.body
              }
              destroyTooltipOnHide={{ keepParent: false }}
              content={() => availableLicensesMenu(licenses?.userId)}
              placement="bottomLeft"
              overlayStyle={{ maxHeight: 200, padding: 0 }}
            >
              <NormalButtonSmall icon={<PlusOutlined />}>
                {t("btnAddALicense")}
              </NormalButtonSmall>
            </Popover>
          </React.Fragment>
        );

        // Assigned licenses
        if (licenses && licenses.current && licenses.current.length > 0) {
          buffer.push(
            licenses.current
              .filter((license: LicenseWithCost) => isNil(license.projectId))
              .map((license: LicenseWithCost, index) => {
                const expired = dayjs().diff(license.endDate, "days") >= 0;
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: size.l3,
                        paddingTop: size.s1
                      }}
                      title={license.id}
                    >
                      <Text strong>{license.text} </Text>
                      {expired && (
                        <Text
                          type="danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(
                              addToRenewalCart({
                                licenseId: license.id,
                                licensePriceId: license.licensePriceId
                              })
                            );
                            dispatch(openRenewalModal());
                          }}
                        >
                          ({t("Expired")}){" "}
                        </Text>
                      )}
                      {license.deviceType > 0 && (
                        <Text type="secondary">
                          {license.deviceType === 1
                            ? t("cargolog3rdGen")
                            : null}
                          {license.deviceType === 2 ? t("cargologEasy") : null}
                          {license.deviceType === 3 ? t("vehicle") : null}{" "}
                        </Text>
                      )}
                      <DangerButtonSmall
                        onClick={() => removeLicense(license.id)}
                      >
                        {t("Remove")}
                      </DangerButtonSmall>
                    </div>
                  </React.Fragment>
                );
              })
          );
        }

        return buffer;
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      width: 20,
      render: (actions: IUserActions) => {
        if (isUndefined(actions)) {
          return <></>;
        } else {
          return (
            <Space>
              <NormalButtonSmall icon={<EditOutlined />} onClick={actions.edit}>
                {t("genEdit")}
              </NormalButtonSmall>

              <DangerButtonSmall
                icon={<DeleteOutlined />}
                disabled={actions.userId === userId}
                onClick={actions.remove}
              >
                {t("genDelete")}
              </DangerButtonSmall>
            </Space>
          );
        }
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  let tableData: ITableData[] | undefined = undefined;

  if (!isUndefined(usersData)) {
    tableData = usersData
      .filter((e) => {
        const dataContainsInput =
          stringValue === "" ||
          e.firstName.toLowerCase().includes(stringValue.toLowerCase()) ||
          e.lastName.toLowerCase().includes(stringValue.toLowerCase()) ||
          e.email.toLowerCase().includes(stringValue.toLowerCase());
        return dataContainsInput;
      })
      .map((user, index) => {
        const userLicenses = companyLicenses?.filter(
          (license) => license.userId === user.userId
        );
        const fullname = user.firstName + " " + user.lastName;
        const row = {
          key: index,
          name: fullname,
          email: user.email,
          userdata: { name: fullname, email: user.email },
          licenses: {
            current: userLicenses,
            userId: user.userId
          },
          actions: {
            edit: () => {
              dispatch(setUser(user));
              dispatch(openEditUserModal());
            },
            remove: () => {
              dispatch(setUserToRemove(user));
              dispatch(openRemoveUserModal());
            },
            userId: user.userId
          }
        };
        return row;
      });
  }

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("CompanyUsers")}</LargeHeaderTitle>
              <PrimaryButton
                style={{ marginLeft: size.m1 }}
                icon={<PlusOutlined />}
                onClick={() => dispatch(openCreateUserModal())}
              >
                {t("createNewUserBtn")}
              </PrimaryButton>
              <NormalButton
                icon={<UserSwitchOutlined />}
                onClick={() => dispatch(openUserRequestAdminModal())}
              >
                {t("UserRequests")}
              </NormalButton>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("FilterUsers")}
              prefix={<SearchOutlined />}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            size="small"
            loading={usersLoading || licensesLoading}
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>
      <CreateUserModal />
      <EditUserModal />
      <RemoveUserModal />
      <UserRequestAdminModal />
    </>
  );
};

export default AdminUsers;
